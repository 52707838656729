import PropTypes from 'prop-types';
import React from 'react';

export class Radio extends React.Component {
  render() {
    const {name, selectedValue, onChange} = this.context.radioGroup;
    const optional = {};
    optional.checked = selectedValue !== undefined && this.props.value !== undefined && this.props.value !== null && selectedValue !== null && (this.props.value.toString() === selectedValue.toString());
    if(typeof onChange === 'function') {
      optional.onChange = onChange.bind(null, {simpleValue:  this.props.value, objectValue: this.props.objectValue});
    }
    let inputProps = {...this.props};
    delete inputProps.objectValue;
    return (
      <input
        {...inputProps}
        type="radio"
        name={name}
        {...optional} />
    );
  }
};

Radio.contextTypes = {
  radioGroup: PropTypes.object
};

export class RadioGroup extends React.Component {
  getChildContext() {
    const {name, selectedValue, onChange} = this.props;
    return {
      radioGroup: {
        name, selectedValue, onChange
      }
    }
  }

  render() {
    const {Component, name, selectedValue, onChange, children, ...rest} = this.props;
    return <Component {...rest}>{children}</Component>;
  }
};

RadioGroup.defaultProps = {
  Component: "div"
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ])
};

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object
};

/*export const MyRadioControl = (props) =>{
  let {name, onChange, selectedValue, options} = props;
  return (
    <div className='radio'>
      {options.map(m => {
        const optional = {};
        optional.checked = selectedValue !== undefined &&
                            m.value !== undefined &&
                            m.value !== null &&
                            selectedValue !== null &&
                            (m.value.toString() === selectedValue.toString());
        optional.value = m.value;
        return (
          <label
              key={`${name}_${m.value}`}
              style={{float: 'left', marginRight:'30px'}}
          >
            <input
              type="radio"
              name={name}
              onChange={onChange}
              {...optional} />
            {m.label}
          </label>
        );
      })}
    </div>);
};*/