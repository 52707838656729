import React from 'react';
import PropTypes from 'prop-types';
import {isNullEmptyOrWhitespace} from "../../constants";

const TextAreaControl = (props) => {
  let {labelText, value, onChange, name, validationMessages} = props;
  let isValid = !validationMessages || (validationMessages && (validationMessages[`${name}`] === '' || validationMessages[`${name}`] === undefined));
  
  let inputStyle = {borderColor: isValid ? '' : 'red'}; 

  return (<div className="form-group">
    <label htmlFor={name}>{labelText}</label>
    <textarea className="form-control" name={name}
              onChange={onChange}
              style={inputStyle}
              placeholder={labelText}
              value={isNullEmptyOrWhitespace(value) ? '' : value}></textarea>
    { !isValid && <div style={{color: 'red'}}>{validationMessages[`${name}`]}</div> }
  </div>);

};

TextAreaControl.propTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validationMessages: PropTypes.object,
  style: PropTypes.string,
};

export default TextAreaControl;