import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost} from "../../utils/request";
import '../../css/divTable.css';
import {toastr} from 'react-redux-toastr';
import {sortBy} from 'lodash';
import {
  FABRIC_SUPPLIED_BY_ALUXOR,
  FABRIC_SUPPLIED_CUSTOMER,
  FABRIC_SUPPLIED_NONE,
  IsNullOrUndefined
} from "../../constants";

class CustomerProductAccessPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      products: [],
      organisationProductAccess: [],
    };
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSelectDeselectAll = this.handleSelectDeselectAll.bind(this);
    this.handleFabricSelection = this.handleFabricSelection.bind(this);
    this.getProductInformation = this.getProductInformation.bind(this);
  }

  handleSave(e) {
    e.preventDefault();
    let data = {
      productItems: this.state.products.map(x => {
        let productAccess = this.state.organisationProductAccess.find(y => y.id === x.id);
        let hasAccess = !IsNullOrUndefined(productAccess);
        return {
          id: x.id,
          hasAccess,
          fabricSupply: !hasAccess || IsNullOrUndefined(productAccess.fabricSupply) ? [] : productAccess.fabricSupply,
        };
      }), organisationId: this.props.organisationId
    };

    apiPost('/api/products/organisation', data)
      .then(x => {
        toastr.success('Saved product access');
        this.setState({organisationProductAccess: x.data});
      });
  }

  handleSelectDeselectAll(e, isSelect) {
    e.preventDefault();
    let {products} = this.state;
    let {organisationId} = this.props;

    this.setState({
      organisationProductAccess: isSelect ? [...products.map(x => {
        return {
          organisationId,
          id: x.id,
        }
      })] : [],
    });
  }

  getProductInformation(productId){
    let selectedProduct = this.state.organisationProductAccess.find(y => y.id === productId);
    let selected = !IsNullOrUndefined(selectedProduct);
    let fabricNone = selected && !IsNullOrUndefined(selectedProduct.fabricSupply) && selectedProduct.fabricSupply.findIndex(y => y === FABRIC_SUPPLIED_NONE) >= 0;
    let fabricAlx = selected && !IsNullOrUndefined(selectedProduct.fabricSupply) && selectedProduct.fabricSupply.findIndex(y => y === FABRIC_SUPPLIED_BY_ALUXOR) >= 0;
    let fabricFit = selected && !IsNullOrUndefined(selectedProduct.fabricSupply) && selectedProduct.fabricSupply.findIndex(y => y === FABRIC_SUPPLIED_CUSTOMER) >= 0;

    return {selectedProduct, selected, fabricNone, fabricAlx, fabricFit};
  }

  handleFabricSelection(e, productId, field) {
    let checked = e.target.checked;

    let organisationProductAccess = [...this.state.organisationProductAccess].map(x => {
      let returnVal = x;
      if (IsNullOrUndefined(returnVal.fabricSupply)) {
        returnVal.fabricSupply = [];
      }
      if (returnVal.id === productId) {
        //if checked and not in there, add it
        if (checked && returnVal.fabricSupply.findIndex(y => y === field) < 0) {
          returnVal.fabricSupply = [...returnVal.fabricSupply, field];
        } else if (!checked && returnVal.fabricSupply.findIndex(y => y === field) >= 0) {
          returnVal.fabricSupply = returnVal.fabricSupply.filter(y => y !== field);
        }
      }
      return returnVal;
    });
    this.setState({organisationProductAccess});
  }

  handleSelection(e, productId) {

    let checked = e.target.checked;
    if (checked && this.state.organisationProductAccess.findIndex(y => y.id === productId) < 0) {
      this.setState({
        organisationProductAccess: [...this.state.organisationProductAccess, {
          organisationId: this.props.organisationId,
          id: productId,
        }]
      });
    } else {
      this.setState({organisationProductAccess: [...this.state.organisationProductAccess.filter(x => x.id !== productId)]});
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.organisationId !== prevProps.organisationId && this.props.organisationId !== 0){
      this.loadData();
    }
  }

  loadData(){
    toastr.warning('Loading product access');
    apiRequest('/api/products/admin')
      .then(x => this.setState({products: x.data}));
    apiRequest(`/api/products/organisation?id=${this.props.organisationId}`)
      .then(x => this.setState({organisationProductAccess: x.data}));
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Product Access</h2>
            </div>
            <div className={'pi-col-md-4'} style={{textAlign: 'right'}}>
              <button
                className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleSave}>
                <i className="fa fa-save"></i>&nbsp;Save
              </button>
              <button
                style={{marginLeft: '10px'}}
                className="btn pi-btn pi-btn-icon-big"
                onClick={(e) => {
                  e.preventDefault();
                  this.loadData();
                }}>
                <i className="fa fa-reload"></i>&nbsp;Reset
              </button>
            </div>

          </div>

          <div className={'pi-row'}>
            <div className={'pi-col-md-12'}>
              <div className={'divTable'} style={{flexDirection: 'column'}}>
                <div className={'divThead'}>
                  <div className={'divTrHead'}>
                    <div className={'divTh'}>Product Group</div>
                    <div className={'divTh'}>Description</div>
                    <div className={'divTh'} style={{textAlign: 'center'}}>No fabric</div>
                    <div className={'divTh'} style={{textAlign: 'center'}}>Supply fabric</div>
                    <div className={'divTh'} style={{textAlign: 'center'}}>Fit fabric</div>
                    <div className={'divTh'} style={{textAlign: 'center'}}>Enabled<br />
                      <a href={''} onClick={(e) => this.handleSelectDeselectAll(e, true)}>select all</a>&nbsp;/&nbsp;
                      <a href={''} onClick={(e) => this.handleSelectDeselectAll(e, false)}>deselect all</a>
                    </div>
                  </div>
                </div>
                <div className={'divTbody'}>
                  {sortBy(this.state.products, x => x.productGroupDescription).map(x => {

                    let {selectedProduct, selected, fabricNone, fabricAlx, fabricFit} = this.getProductInformation(x.id);

                    return (<div key={x.id} className={'divTr'}>
                      <div className={'divTd'}>{x.productGroupDescription}</div>
                      <div className={'divTd'}>{x.description}</div>
                      <div className={'divTd'} style={{textAlign: 'center'}}>
                        <input type='checkbox'
                               checked={fabricNone}
                               onChange={(e) => this.handleFabricSelection(e, x.id, FABRIC_SUPPLIED_NONE)}
                               disabled={!selected}
                        />
                      </div>
                      <div className={'divTd'} style={{textAlign: 'center'}}>
                        <input type='checkbox'
                               checked={fabricAlx}
                               onChange={(e) => this.handleFabricSelection(e, x.id, FABRIC_SUPPLIED_BY_ALUXOR)}
                               disabled={!selected}
                        />
                      </div>
                      <div className={'divTd'} style={{textAlign: 'center'}}>
                        <input type='checkbox'
                               checked={fabricFit}
                               onChange={(e) => this.handleFabricSelection(e, x.id, FABRIC_SUPPLIED_CUSTOMER)}
                               disabled={!selected}
                        />
                      </div>
                      <div className={'divTd'} style={{textAlign: 'center'}}>
                        <input type='checkbox'
                               checked={selected}
                               onChange={(e) => this.handleSelection(e, x.id)}
                        />
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

CustomerProductAccessPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerProductAccessPage));
