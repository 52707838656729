import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost, apiDelete} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {join, values} from 'lodash';
import {
  ROLE_Administrator,
  IsStringValid,
  validationCreator,
  EmailRegex,
  ROLE_CustomerAdmin_Id,
  ROLE_Administrator_Id,
  ROLE_CustomerAdmin,
  ROLE_Fabric_Id,
  ROLE_Fabric,
  ROLE_PC,
  ROLE_PC_Id,
  IsNullOrUndefined,
  PhoneRegex
} from '../../constants';
import SelectControl from "../common/SelectControl";
import {setCookie} from "../../actions/identityActions";
import store from "../../store";

export const UserValidationRules = {
  roles: {
    validator: (val, state, props) => {
      return val === null || val.length === 0 ? validationCreator(false, 'Please select a role') : validationCreator();
    }
  },
  contactNumber: {
    validator: (val, state, props) => {
      if(val==='' || !val){
        return validationCreator();
      }
      return !IsStringValid(val, PhoneRegex) ? validationCreator(false, 'Please enter a valid phone number') : validationCreator();
    }
  },
  /*jobTitle: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid job title') : validationCreator();
    }
  },*/
  firstName: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a first name') : validationCreator();
    }
  },
  lastName: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a last name') : validationCreator();
    }
  },
  userName: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid email') : validationCreator();
    }
  }
};

function isSelectedRow(row, user) {
  return user && user.id === row.original.id;
}

function nullToString(v){
  return v === null || v === undefined ? '' : v;
}
function fieldValid(name, validationMessages) {
  return validationMessages[name] === undefined;
}

class CustomerUsersPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      users: [],
      userColumns: [],
      selectedUser: null,
      validationMessages: {},
    };

    this.loadData = this.loadData.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.selectEditUser = this.selectEditUser.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.cancelSelectUser = this.cancelSelectUser.bind(this);
    this.handleNewUser = this.handleNewUser.bind(this);
    this.validate = this.validate.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.renderUserName = this.renderUserName.bind(this);
    this.renderFirstName = this.renderFirstName.bind(this);
    this.renderLastName = this.renderLastName.bind(this);
    this.renderContactNumber = this.renderContactNumber.bind(this);
    this.renderJobTitle = this.renderJobTitle.bind(this);
    this.renderRoleSelect = this.renderRoleSelect.bind(this);
    this.handleImpersonate = this.handleImpersonate.bind(this);
  }

  validate(){
    let {selectedUser} = this.state;
    let messages = {};
    let isValid = true;

    let runValidator = (rules) => {
      for (let propName in rules) {

        let rule = rules[propName];
        let val = selectedUser[propName];

        let valResult = rule.validator(val, this.state, this.props);
        if (!valResult.isValid) {
          messages[propName] = valResult.message;
          isValid = false;
        }
      }
    };

    runValidator(UserValidationRules);

    this.setState({validationMessages: messages}, this.setColumns);

    return isValid;
  }

  handleResetPassword(row){
    //eslint-disable-next-line
    if(confirm(`Are you sure you'd like to reset this users password?`)) {
      apiPost(`/api/users/reset?userName=${row.userName}`)
        .then(x => {
          if (x.data && x.data.ok) {
            toastr.success(`Password reset, an email has been sent to ${row.userName}`);
          } else {
            toastr.error(`Unable to reset: ${x.data}`);
          }
        });
    }
  }

  handleInvite(row){
    //eslint-disable-next-line
    if(confirm(`Are you sure you'd like to invite this user?`)) {
      apiPost(`/api/users/invite?userName=${row.userName}`)
        .then(x => {
          if (x.data && x.data.ok) {
            toastr.success(`Invite sent to ${row.userName}`);
          } else {
            toastr.error(`Unable to reset: ${x.data}`);
          }
        });
    }
  }

  handleImpersonate(row) {
    //eslint-disable-next-line
    if(confirm('Are you sure you want to impersonate this user?')) {
      apiPost(`/api/users/tokenForImpersonation?userId=${row.id}`)
          .then(x => {
            if (x.data) {
              toastr.info('Logging in...');
              setCookie(x.data, store.dispatch, ()=> {
                toastr.success(`Logged in as ${row.userName}`);
                this.props.history.push('/');
              });
            } else {
              toastr.error(`Unable to impersonate`);
            }
          });
    }
  }

  handleNewUser(){
    if(this.state.users.findIndex(x => x.id === -1) >= 0){
      return;
    }
    let user = {
      organisation: {
        id: this.props.organisationId,
      },
      firstName: '',
      lastName: '',
      userName: '',
      id: -1,
      personId: -1,
      roles: [{id: 6, description: 'Place Order'}],
      fixedOrderId: null,
      accountMessages: [],
      contactNumber: '',
      jobTitle: '',
      isActive: true,
      canDelete: true,
      sendOrderUpdates: true,
    };

    this.setState({users: [...this.state.users, user], selectedUser: user}, this.setColumns);
  }

  selectEditUser(user){
    this.setState({selectedUser: user}, this.validate);
  }

  cancelSelectUser(){
    if(this.state.selectedUser.id === -1){
      this.setState({selectedUser: null, users: [...this.state.users.filter(x => x.id !== -1)], validationMessages: {}}, this.setColumns);
    } else {
      this.setState({selectedUser: null, validationMessages: {}}, this.setColumns);
    }
  }

  saveUser() {
    let user = {...this.state.selectedUser};

    apiPost('/api/users', user)
      .then(x => {
        if (x.data && x.data.id > 0) {
          toastr.success('Saved user');
          this.setState({selectedUser: null}, () => {
            this.setColumns();
            this.loadData();
          });
        }
      });
  }

  deleteUser(user){
    // eslint-disable-next-line no-restricted-globals
    if(confirm(`Are you sure you'd like to delete this user?`)) {
      apiDelete(`/api/users`, user)
        .then(x => {          
          if(x.data.ok===true && x.data.unableToDelete===true) {
            toastr.warning('User associated with orders, user has been disabled');
          } else if(x.data.ok===true) {
            toastr.success('Deleted user');
          }
          this.loadData();
        });
    }
  }

  handleFieldChange(e, name){
    let {value, type, checked} = e.target;
    let user = {...this.state.selectedUser};

    if(name === 'role'){
      let roles = [];
      for (let i = 0; i < e.target.selectedOptions.length; i++){
        roles.push({id: e.target.selectedOptions[i].value, description: e.target.selectedOptions[i].innerText})
      }
      user.roles = roles;
    }

    else if(type === 'checkbox'){
      user[name] = checked;
    }
    else {
      user[name] = value;
    }
    this.setState({selectedUser: user}, this.validate);
  }

  componentDidUpdate(prevProps){
    if(this.props.organisationId !== prevProps.organisationId && this.props.organisationId !== 0){
      this.loadData();
    }
  }

  renderUserName(row) {
    let {selectedUser, validationMessages} = this.state;

    return (isSelectedRow(row, selectedUser) ?
        <input style={{border: fieldValid('userName', validationMessages) ? '' : '1px solid red'}} type='email'
               value={nullToString(selectedUser.userName)} onChange={(e) => this.handleFieldChange(e, 'userName')}/> :
        <span>{row.value}</span>)
  }

  renderFirstName(row) {
    let {selectedUser, validationMessages} = this.state;

    return (isSelectedRow(row, selectedUser) ?
        <input style={{border: fieldValid('firstName', validationMessages) ? '' : '1px solid red'}}
               value={nullToString(selectedUser.firstName)}
               onChange={(e) => this.handleFieldChange(e, 'firstName')}/> :
        <span>{row.value}</span>);
  }
  
  renderLastName(row) {
    let {selectedUser, validationMessages} = this.state;

    return (isSelectedRow(row, selectedUser) ?
        <input style={{border: fieldValid('lastName', validationMessages) ? '' : '1px solid red'}}
               value={nullToString(selectedUser.lastName)}
               onChange={(e) => this.handleFieldChange(e, 'lastName')}/> :
        <span>{row.value}</span>);
  }

  renderContactNumber(row) {
    let {selectedUser, validationMessages} = this.state;

    return (isSelectedRow(row, selectedUser) ?
        <input style={{border: fieldValid('contactNumber', validationMessages) ? '' : '1px solid red', width: '100px'}}
               type='phone' value={nullToString(selectedUser.contactNumber)}
               onChange={(e) => this.handleFieldChange(e, 'contactNumber')}/> :
        <span>{row.value}</span>);
  }

  renderJobTitle(row) {
    let {selectedUser, validationMessages} = this.state;

    return (isSelectedRow(row, selectedUser) ?
        <input style={{border: fieldValid('jobTitle', validationMessages) ? '' : '1px solid red', width: '120px'}}
               value={nullToString(selectedUser.jobTitle)} onChange={(e) => this.handleFieldChange(e, 'jobTitle')}/> :
        <span>{row.value}</span>);
  }

  renderRoleSelect(row){
    let {selectedUser, validationMessages} = this.state;
    let {user} = this.props;
    let isAdmin = user.isInRole(ROLE_Administrator);
    let isFabricMaker = !IsNullOrUndefined(user.organisation.fabricMakerId);
    let isPCer = !IsNullOrUndefined(user.organisation.powderCoaterId);
    
    let roles = [      
      {value: ROLE_CustomerAdmin_Id, label: ROLE_CustomerAdmin},
      {value: 6, label: 'Place Order'},
      isAdmin ? {value: 2, label: 'Product Manager'} : null,
      {value: 5, label: 'Quote Only'},
      {value: 7, label: 'Technical Only'},
      (isAdmin || isFabricMaker) ? {value: ROLE_Fabric_Id, label: ROLE_Fabric} : null,
      (isAdmin || isPCer) ? {value: ROLE_PC_Id, label: ROLE_PC} : null,
    ].filter(x => x !== null);

    let fieldValid = (name) => {
      return validationMessages[name] === undefined;
    };

    return (isSelectedRow(row, selectedUser) ?
        <select
            multiple={true}
            value={selectedUser.roles.map(x => x.id)}
            style={{border: fieldValid('role') ? '' : '1px solid red'}}
            onChange={(e) => this.handleFieldChange(e, 'role')}>
          {roles.map(x =>
              <option value={x.value} key={`role_${x.value}`}>{x.label}</option>
          )}
        </select>
        : <span>{row.value}</span>);
  }

  setColumns() {
    let {selectedUser, validationMessages} = this.state;
    let {user} = this.props;

    let isSelectedRow = (row, user) => {
      return user && user.id === row.original.id;
    };

    this.setState({
      userColumns: [
        {
          id: 'roles',
          Header: 'Role',
          accessor: c => join(c.roles.map(x => x.description), ', '),
          Cell: this.renderRoleSelect,
          maxWidth: 150,
        },
        {
          Header: 'User name',
          accessor: 'userName',
          Cell: this.renderUserName,
        }, {
          Header: 'First Name',
          accessor: 'firstName',
          Cell: this.renderFirstName,
        }, {
          Header: 'Last Name',
          accessor: 'lastName',
          Cell: this.renderLastName,
        }, {
          Header: 'Phone',
          accessor: 'contactNumber',
          Cell: this.renderContactNumber,
          maxWidth: 120,
        }, {
          Header: 'Job Title',
          accessor: 'jobTitle',
          Cell: this.renderJobTitle,
          maxWidth: 130,
        }, {
          Header: 'Is Active',
          accessor: 'isActive',
          Cell: row => <div style={{textAlign: 'center'}}>{(isSelectedRow(row, selectedUser) ?
              <input type='checkbox' checked={selectedUser.isActive} onChange={(e) => this.handleFieldChange(e, 'isActive')} /> :
            <span>{row.value ? <i className={'fa fa-check'} /> : <i className={'fa fa-times'} />}</span>)}</div>,
          maxWidth: 80,
        }, {
          Header: 'Email Updates',
          accessor: 'sendOrderUpdates',
          Cell: row => <div style={{textAlign: 'center'}}>{(isSelectedRow(row, selectedUser) ?
              <input type='checkbox' checked={selectedUser.sendOrderUpdates} onChange={(e) => this.handleFieldChange(e, 'sendOrderUpdates')} /> :
              <span>{row.value ? <i className={'fa fa-check'} /> : <i className={'fa fa-times'} />}</span>)}</div>,
          maxWidth: 110,
        },
        {
          id: 'actions',
          Header: '',
          Cell: row => {
            let isAdmin = user.isInRole(ROLE_Administrator);
            let canInvite =  row.original.roles.findIndex(x => x.id === ROLE_CustomerAdmin_Id) >= 0 && isAdmin;
            let canResetEdit =  (row.original.roles.findIndex(x => x.id === ROLE_Administrator_Id) >= 0 && isAdmin) ||
              (row.original.roles.findIndex(x => x.id === ROLE_Administrator_Id) < 0);
            return (
              !isSelectedRow(row, selectedUser) ? <span>
                { canResetEdit && <a style={{color: '#f58b3c', cursor: 'pointer'}} title={`Edit ${row.original.userName}`} onClick={(e) => {
                  e.preventDefault();
                  this.selectEditUser(row.original);
                }}>
                  <i className="fa fa-edit fa-lg" />
                </a> }
                  { canResetEdit && row.original.canDelete && row.original.id !== user.id &&
                    <a style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '7px'}} title={`Delete ${row.original.userName}`} onClick={(e) => {
                      e.preventDefault();
                      this.deleteUser(row.original);
                    }}>
                      <i className="fa fa-trash fa-lg" />
                    </a> }
                { canResetEdit && <a style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '7px'}} title={`Reset password for ${row.original.userName}`} onClick={(e) => {
                    e.preventDefault();
                    this.handleResetPassword(row.original);
                  }}>
                  <i className="fa fa-refresh fa-lg" />
                </a> }
                { canInvite &&
                  <a style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '7px'}} title={`Send invite ${row.original.userName}`} onClick={(e) => {
                    e.preventDefault();
                    this.handleInvite(row.original);
                  }}>
                    <i className="fa fa-envelope fa-lg" />
                </a> }
                    { isAdmin &&
                    <a style={{color: '#f58b3c', cursor: 'pointer', marginLeft: '7px'}}  title={`Impersonate ${row.original.userName}`} onClick={(e) => {
                      e.preventDefault();
                      this.handleImpersonate(row.original);
                    }}>
                      <i className="fa fa-user-md fa-lg" />
                    </a> }

              </span> :
              <span>
                {values(validationMessages).length === 0 && <a style={{color: '#f58b3c', cursor: 'pointer'}} onClick={this.saveUser}>
                  Save
                </a> }
                <a style={{cursor: 'pointer', marginLeft: '5px', fontSize: 'smaller'}} onClick={this.cancelSelectUser}>
                  Cancel
                </a>
              </span>
            );
          },
          maxWidth: 150,
        }
      ]
    });
  }

  loadData(){
    if(this.props.organisationId > 0) {
      toastr.warning('Loading users');
      apiRequest(`/api/users?organisationId=${this.props.organisationId}`)
        .then(x => this.setState({users: x.data, selectedUser: null}, this.setColumns));
    }
  }

  componentDidMount() {
    this.setColumns();
    this.loadData();
  }

  render() {

    let {users, userColumns, validationMessages} = this.state;

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>User Management</h2>
            </div>
            <div className={'pi-col-md-4'} style={{textAlign: 'right'}}>
              <button
                className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleNewUser}>
                <i className="fa fa-plus"></i>&nbsp;New User
              </button>
            </div>
          </div>
        </div>

        <ReactTable
          sortable={false}
          filterable={false}
          showPagination={false}
          data={users}
          columns={userColumns}
          defaultPageSize={250}
          minRows={6}
        />

        <div className="pi-row">
          <div className={'pi-col-md-12'}>
            <ul>
              {values(validationMessages).map((x,i) => <li style={{fontWeight: 'bold', color: 'red'}} key={i}>{x}</li>)}
            </ul>
          </div>
        </div>
      </div>);
  }
}

CustomerUsersPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerUsersPage));
