import React, { Component } from 'react';
import jQuery from 'jquery';
import {apiDownloadReport, apiPost, apiRequest} from "../../utils/request";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import ColourSelectorControl from '../common/ColourSelectorControl';
import injectSheet from "react-jss";
import {RowFields, styles} from "../orders/OrderPowderCoatingSheet";
import {
    COLOUR_STANDARD_CUSTOM_ID,
    DATE_SHORT_FORMAT,
    isNullEmptyOrWhitespace,
    IsNullOrUndefined, REPORTSERVER_BASEURL
} from "../../constants";
import moment from "moment";
import store from "../../store";
import SelectControl from "../common/SelectControl";
import {DebounceInput} from 'react-debounce-input';
import TextBoxControl from "../common/TextBoxControl";
import {Base64} from "js-base64";
import PcStockOrderPcSheet from "./PcStockOrderPcSheet";
import {toastr} from "react-redux-toastr";

const Reasons = [{value: 'Stock', label: 'Stock'}, 
    {value: 'PC Error/Re-work', label: 'PC Error/Re-work'},
    {value: 'ALX Error', label: 'ALX Error'},
    {value: 'ALX Short Stock', label: 'ALX Short Stock'},
    {value: 'Customer Order', label: 'Customer Order'},
    {value: 'Other', label: 'Other'},
];

class PcRefOrderPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            pcRefItems: [],
            orderItems: new Map(),
            selectedCustomColourId: null,
            selectedStandardColourId: null,
            coloursStandard: [],
            coloursCustom: [],
            sheetIsOpen: false,
            colourDescription: '',
            powderCoater: null,            
            sequenceNumber: '',
            reason: '',
        };

        this.fetchPcRefItems = this.fetchPcRefItems.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
        this.fetchColours = this.fetchColours.bind(this);
        this.handleColourChange = this.handleColourChange.bind(this);
        this.getPcSheetRowData = this.getPcSheetRowData.bind(this);
        this.printMeRef = React.createRef();
        this.handlePrint = this.handlePrint.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    fetchPcRefItems(){
        apiRequest(`api/pcrefs`)
            .then(x => {
                let data = x.data ? x.error ? [] : x.data : [];
                this.setState({pcRefItems: data});
            });
    }

    fetchColours() {
        //TODO: Create fetch for all standard colours
        apiRequest(`/api/colours/2/frame`)
            .then(x => {
                let data = x.data ? x.error ? [] : x.data : [];
                this.setState({coloursStandard: data});
            });

        apiRequest(`/api/colours/customframe`)
            .then(x => {
                let data = x.data ? x.error ? [] : x.data : [];
                this.setState({coloursCustom: data});
            });
    }

    handleFieldUpdate(e) {
        let {orderItems} = this.state;
        let {name, value} = e.target;
        let fieldName = null;
        let nameSplit = name.split('-');
        if (nameSplit.length > 0) {
            fieldName = nameSplit[0];

            if (fieldName === 'quantity') {
                let pcRef = nameSplit[1];
                let sizeId = parseInt(nameSplit[2]);
                if (isNaN(sizeId) || sizeId <= 0) {
                    sizeId = null;
                }
                let val = parseInt(value);
                if (isNaN(val) || val < 0) {
                    val = 0;
                }
                let key = `${pcRef}_${sizeId}`;

                let existing = orderItems.get(key);
                if(IsNullOrUndefined(existing)){
                    existing = {pcRef, sizeId: sizeId, quantity: 0,};
                }

                if(fieldName === 'quantity'){
                    existing = {...existing, quantity: val};
                }

                if(existing.quantity === 0){
                    orderItems.delete(key);
                } else {
                    orderItems.set(key, existing);
                }

                this.setState({orderItems});
            }
        }
    }

    getColumns(){
        let {orderItems} = this.state;

        let filterString = (filter, row) => {
            return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
        };
        let noFilter =  ({filter, onChange}) => (<span />);

        return [{
            Header: 'orderBy',
            accessor: 'orderBy',
            show: false,
        },{
            Header: 'PC Ref',
            accessor: 'pcRef',
            filterMethod: filterString,
        },{
            Header: 'Description',
            accessor: 'description',
            filterMethod: filterString,
        },{
            Header: 'Size',
            accessor: 'size',
            maxWidth: 100,
            Filter: noFilter,
        },{
            Header: 'Material',
            accessor: 'material',
            filterMethod: filterString,
        },{
            Header: 'Is Stocked',
            accessor: 'isStocked',
            Cell: row => <div style={{textAlign: 'center'}}>
                <span>{row.value ? <i className={'fa fa-check'} /> : <i className={'fa fa-times'} />}</span>
            </div>,
            maxWidth: 100,
            Filter: noFilter,
        },{
            Header: 'Quantity',
            accessor: null,
            show: true,
            Cell: row => {
                let {original} = row;
                let existing = orderItems.get(`${original.pcRef}_${original.sizeId}`);
                return (<div style={{textAlign: 'center'}}>
                    <DebounceInput
                        className={'form-control'}
                        type='number'
                        value={existing ? existing.quantity : 0}
                        name={`quantity-${original.pcRef}-${original.sizeId}`}
                        onChange={this.handleFieldUpdate}
                        minLength={0}
                        debounceTimeout={500}
                    />
                </div>);
            },
            maxWidth: 100,
            Filter: noFilter,
        }];
    }

    handleColourChange(val) {
        let {selectedStandardColourId, selectedCustomColourId} = val;
        let {coloursCustom, coloursStandard} = this.state;
        let colourDescription = '';

        if(selectedStandardColourId === COLOUR_STANDARD_CUSTOM_ID && !IsNullOrUndefined(selectedCustomColourId)){
            colourDescription = coloursCustom.filter(x => x.value === selectedCustomColourId)[0].label;
        } else if(!IsNullOrUndefined(selectedStandardColourId)){
            colourDescription = coloursStandard.filter(x => x.id === selectedStandardColourId)[0].colourDescription;
        }

        this.setState({selectedCustomColourId, selectedStandardColourId, colourDescription});
    }

    getPcSheetRowData(){
        let {orderItems, pcRefItems} = this.state;
        let {classes} = this.props;
        let rows = [];
        let rowDataItems = [];

        orderItems.forEach((val, key) => {
            let filteredItems = pcRefItems.filter(x => x.pcRef === val.pcRef && x.sizeId === val.sizeId);
            if (val.quantity <= 0){
                // no op
            } else if(filteredItems.length !== 1){
                console.error('Not found', val, key);
            } else {
                let item = filteredItems[0];
                let rowData = {
                    key: key,
                    pcRef: val.pcRef,
                    description: item.description,
                    length: item.size,
                    number: val.quantity,
                    showImage: true,
                    dimension: IsNullOrUndefined(item.surfaceArea) && IsNullOrUndefined(item.dimensionX) ? '' :
                        IsNullOrUndefined(item.surfaceArea) ? `${item.dimensionX} x ${item.dimensionY} x ${item.dimensionZ}` : `${item.surfaceArea} m2`,
                    material: item.material,
                };
                rowDataItems.push(rowData);
                rows.push(<div key={`row_${key}`} className={classes.divTr}><RowFields {...rowData} classes={classes} hardLeftBorder={false}/></div>);
            }
        });

        return {rows, rowDataItems};
    }

    handlePrint() {
        let container = jQuery(`<div></div>`)
            .css('font-size', '10px')
            .css('font-family', "'Open Sans', Arial, sans-serif")
            .css('line-height', "1.6em");

        jQuery(this.printMeRef.current)
            .clone()
            .css('margin-top', '10px')
            .appendTo(container);

        let body = {
            "base64HtmlHead": Base64.encode(document.head.innerHTML),
            "base64HtmlBody": Base64.encode(container[0].innerHTML),
            "pdfOutput": true,
            "isLandscape": false,
            "templateName": "GeneratePdfDefault"
        };        
        apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `PC_sheet.pdf`);
    }

    async handleSave(){
        let {pcRefItems, coloursStandard, coloursCustom,
            selectedStandardColourId, selectedCustomColourId,
            sheetIsOpen, orderItems, colourDescription, powderCoater, sequenceNumber, reason} = this.state;
        
        let {rowDataItems} = this.getPcSheetRowData();
        
        let pcStockOrder = {
            id:-1,            
            sequenceNumber,
            colourId: selectedStandardColourId,
            customColourId: selectedCustomColourId,
            powderCoaterId: powderCoater.value,
            pcSheetDataJson: JSON.stringify({rowDataItems}),            
            reason,
        };
        let result = await apiPost(`/api/PcStockOrders/${pcStockOrder.id}`, pcStockOrder);
        console.log(result);
        
        if(result && result.data && result.data.id > 0)
        {
            toastr.success('PC Stock Order added to Supplier Jobs');
            this.setState({                
                orderItems: new Map(),
                selectedCustomColourId: null,
                selectedStandardColourId: null,
                sheetIsOpen: false,
                colourDescription: '',
                powderCoater: null,                
                sequenceNumber: '',
                reason: '',
            });            
        } else {         
            toastr.error('Something went wrong...');
            console.log(result);
        }
    }

    componentDidMount() {
        this.fetchPcRefItems();
        this.fetchColours();
    }

    render() {
        let {pcRefItems, coloursStandard, coloursCustom,
            selectedStandardColourId, selectedCustomColourId,
            sheetIsOpen, orderItems, colourDescription, powderCoater, sequenceNumber, reason} = this.state;
        let {classes} = this.props;
        let columns = this.getColumns();

        return (<div className="pi-section">

            <div className="row">
                <div className={'col-8'}>
                    <h4>Place PC order</h4>
                </div>

                { orderItems.size > 0
                    && !isNullEmptyOrWhitespace(colourDescription)                    
                    && !isNullEmptyOrWhitespace(sequenceNumber)                    
                    && !IsNullOrUndefined(powderCoater)
                    && !isNullEmptyOrWhitespace(reason)
                && <div className={'col-4'} style={{textAlign: 'right'}}>
                    <button onClick={() => this.setState({sheetIsOpen: true})}
                            className="btn pi-btn-base pi-btn-icon-big"
                            style={{marginRight: '10px'}}
                    >
                        <i className="fa fa-binoculars"/>&nbsp;View Sheet
                    </button>
                </div> }
            </div>

            <div className="row">
                <div className={'col-6'}>                    

                    <TextBoxControl labelText={'PN'} onChange={(e) => {
                        e.preventDefault();
                        this.setState({sequenceNumber: e.target.value});
                    }} name={'sequenceNumber'} value={sequenceNumber} />

                    <ColourSelectorControl
                        onChange={this.handleColourChange}
                        label='Order'
                        customColours={coloursCustom}
                        standardColours={coloursStandard}
                        selectedStandardColourId={selectedStandardColourId}
                        selectedCustomColourId={selectedCustomColourId}
                        name={'colour'}
                        disabled={false}
                        singleColourSelection={true}
                        awningId={1}
                    />

                    <SelectControl
                        labelText="Powder coater"
                        name="powderCoaterId"
                        options={store.getState().lookups.powderCoaters}
                        onChange={(val) => this.setState({powderCoater: val})}
                        value={IsNullOrUndefined(powderCoater) ? null : powderCoater.value}
                        placeholder="Select Powder Coater"
                    />

                    <SelectControl
                        labelText="Reason"
                        name="reason"
                        options={Reasons}
                        onChange={(val) => this.setState({reason: val})}
                        value={isNullEmptyOrWhitespace(reason) ? null : reason}
                        placeholder="Reason for PC order"
                        simpleValue={true}
                    />
                </div>
            </div>



            <div className="row">
                <div className={'col-12'}>
                    <ReactTable data={pcRefItems} columns={columns} filterable={true}/>
                </div>
            </div>
            
            <PcStockOrderPcSheet
                colourDescription={colourDescription}
                orderRef={sequenceNumber}
                dateSent={moment().format(DATE_SHORT_FORMAT)}
                onPrint={this.handlePrint}
                onSave={this.handleSave}
                onToggle={() => this.setState({sheetIsOpen: !sheetIsOpen})}
                sheetIsOpen={sheetIsOpen}
                rowData={this.getPcSheetRowData().rowDataItems}
                powderCoater={powderCoater}
                comment={reason}
                showEmail={false}
            />
                
        </div>);
    }
}

PcRefOrderPage.propTypes = {
};

export default injectSheet(styles)(PcRefOrderPage);