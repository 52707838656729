import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextBoxControl from '../common/TextBoxControl';
import SelectControl from '../common/SelectControl';
import CheckBoxControl from '../common/CheckBoxControl';
import AwningView from './AwningView';
import {apiPost, apiRequest} from "../../utils/request";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  ORDER_STATUS_QUOTE,
  ORDER_STATUS_ORDER_REQUESTED,
  isRemote,
  isHardwired,
  ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED,
  edit_order_view_screen_amendment_requested,
  edit_order_view_screen_quote,
  orders_list_list_all_organisations_all,
  edit_order_view_order_status_changes_all,
  view_order_cancel_order_amendment_requested,
  edit_order_cancel_order_amendment_requested,
  deobsfucate,
  obsfucate,
  isNullEmptyOrWhitespace, IsNullOrUndefined
} from '../../constants';
import {toastr} from 'react-redux-toastr';
import {uniqBy, some, minBy} from 'lodash';
import OrderSensorSetsControl from './OrderSensorSetsControl';
import OrderStatusChanges from './OrderStatusChanges';

const ValidationRules = {
  customerReference: {
    validator: (val) => {
      return {isValid: (val.trim() !== '' && val.trim().length <= 20), message: `Please enter a Customer Reference Number, less than 20 characters`};
    }
  }
};

const ButtonWrapper = (props) => {
  let propsToUse = {...props, visible: undefined};
  return (props.visible || props.visible == undefined) ?
    <button {...propsToUse}>{props.children}</button> : null;
};

export const NewAwning = (noteText, canSupplyOwnMotor = 0) => {
  return {
    width: '',
    productGroupId: null,
    productId: null,
    projection: null,
    driveMechanismId: null,
    crankHandleId: null,
    sideOfOperation: null,
    remote: null,
    frameColourId: null,
    frameCustomColourId: null,
    includePelmet: null,
    pelmetColourId: null,
    pelmetCustomColourId: null,
    flashingType: null,
    flashingDepth: null,
    flashingColourId: null,
    flashingCustomColourId: null,
    fabricBrandId: null,
    fabricId: null,
    fabricSuppliedBy: null,
    fabricValance: null,
    fabricValanceDepth: null,
    mountingBracketId: null,
    additionalMountingBrackets: 0,
    individualArmAdjusters: null,
    accessories: [],
    productLookupDataId: null,
    wallSwitch: null,
    notes: [],
    noteText: noteText === undefined || noteText === null ? '' : noteText,
    motorId: null,
    trimBiasColourId: null,
    frameFeature: null,
    frameFeatureDriveMechanismId: null,
    frameFeatureSideOfOperation: null,
    drop: null,
    frameFeatureFabricId: null,
    frameFeatureFabricBrandId: null,
    automationSensorType: null,
    motionSensorColour: null,
    automationCableId: null,
    installationBracketId: null,
    additionalCradles: 0,
    includeAdditionalCrankHandle: false,
    include3PinPlug: false,
    includeStabiliserPoles: false,
    mocoType: null,
    includeLed: null,
    hasAdditionalCradles: false,
    frameFeatureCrankHandleId: null,
    isAssembled: true,
    sections: null,
    awningFlashing: {height: 130, depth1: 60, depth2: 20},
    desiredRemoteColour: null,
    numberOfCradles: 0,
    fieldConfiguration: null,
    frameFeatureFieldType: null,
    canSupplyOwnMotor,
  };
};
/*
const NewAwning = {
  "width": "4000",
  "productGroupId": 1,
  "productVariationId": 10,
  "productId": 2,
  "projection": 2000,
  "driveMechanismId": 2,
  "crankHandleId": null,
  "sideOfOperation": "Left",
  "remote": null,
  "frameColourId": 4,
  "frameCustomColourId": null,
  "includePelmet": false,
  "pelmetColourId": null,
  "pelmetCustomColourId": null,
  "flashingType": null,
  "flashingDepth": null,
  "flashingColourId": null,
  "flashingCustomColourId": null,
  "fabricBrandId": null,
  "fabricId": null,
  "fabricSuppliedBy": 0,
  "fabricValance": null,
  "fabricValanceDepth": null,
  "mountingBracketId": 2,
  "additionalMountingBrackets": 0,
  "individualArmAdjusters": false,
  "accessories": [],
  "productVariationLookupDataId": 15,
  "wallSwitch": "Default",
  "notes": [],
  "noteText": "",
  "number": 1,
  "id": -1
};
*/

export function newOrder(organisationId, orderNote, awningNote, organisationCanSupplyOwnMotor = 0){
  return {
    id: -1,
    customerReference: '',
    awnings: [{...NewAwning(awningNote, organisationCanSupplyOwnMotor), number: 1, id: -1}],
    organisationId: organisationId,
    orderStatus: ORDER_STATUS_QUOTE,
    statusChanges: [],
    sensorSets: [],
    orderNote: orderNote,    
  };
}

class EditOrderPage extends Component {

  constructor(props, context) {
    super(props, context);

    let initialOrder = {...newOrder(props.user.organisation.id, props.user.organisation.standardOrderNote, props.user.organisation.standardAwningNote, props.user.organisation.canSupplyOwnMotor)};
    this.state = {
      selectedAwning: initialOrder.awnings[0],
      order: initialOrder,
      validationMessages: {},
      inValidAwnings: new Set(),
      awningMessages: new Map(),
      organisations: [],
      loaded: !props.orderId || props.orderId <= 0,
      hasSetsToCombine: false,
      showCombine: false,
      saving: false,
    };

    this.handleOrderFieldChange = this.handleOrderFieldChange.bind(this);
    this.validateOrder = this.validateOrder.bind(this);
    this.handleNewAwning = this.handleNewAwning.bind(this);
    this.handleRemoveAwning = this.handleRemoveAwning.bind(this);
    this.handleOnSelectedAwningChange = this.handleOnSelectedAwningChange.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAwningMessagesChanged = this.handleAwningMessagesChanged.bind(this);
    this.handlePreviewOrder = this.handlePreviewOrder.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.loadOrder = this.loadOrder.bind(this)
    //this.handleOnUpdateOrderAwningFields = this.handleOnUpdateOrderAwningFields.bind(this);
    this.getNewAwning = this.getNewAwning.bind(this);
    //this.handleOnUpdateOrderAwningFieldValue = this.handleOnUpdateOrderAwningFieldValue.bind(this);
    this.orderSensorSets = React.createRef();
  }

  handleOrderFieldChange(e){
    let x = {...this.state.order};
    let messages = {...this.state.validationMessages};

    if(e.target.type=="checkbox"){
      x[`${e.target.name}`] = e.target.checked;
    } else {
      x[`${e.target.name}`] = e.target.value;
    }

    let validation = ValidationRules[`${e.target.name}`];
    if(validation) {
      let validationResult = validation.validator(e.target.value);
      if (!validationResult.isValid) {
        messages[`${e.target.name}`] = validationResult.message;
      } else {
        messages[`${e.target.name}`] = '';
      }
    }

    this.setState({order: x, validationMessages: messages});
  }

  getNewAwning(){
    let count = minBy(this.state.order.awnings.filter(y => y.id < 0 && IsNullOrUndefined(y.parentOrderAwningId)), x => x.id);
    count = count == undefined ? '-1' : (count.id - 1);
    let newAwning = {...NewAwning(this.state.organisation ? this.state.organisation.standardAwningNote : this.props.user.organisation.standardAwningNote),
      number: (this.state.order.awnings.length + 1),
      id: count,
    };
    return newAwning;
  }

  handleNewAwning(e) {
    e.preventDefault();
    let newAwning = this.getNewAwning();
    this.setState({
      order: {
        ...this.state.order,
        awnings: [...this.state.order.awnings, newAwning],
      },
      selectedAwning: newAwning,
    });
  }

  handleRemoveAwning(e) {
    e.preventDefault();
    //eslint-disable-next-line
    if(!confirm(`Are you sure you want to remove Awning #${this.state.selectedAwning.number}`)){
      return;
    }

    let awnings = [...this.state.order.awnings
      .filter(x => x.id != this.state.selectedAwning.id)
      .map((y, i) => {
        let ret = {...y};
        ret.number = i+1;
        return ret;
      })];

    this.setState({
      order: {
        ...this.state.order,
        awnings,
      },
      selectedAwning: awnings[0],
    });
  }

  handleOnSelectedAwningChange(awning) {
    let newAwnings = [...this.state.order.awnings.map(x => {
      let returnVal = {...x};
      return x.id === awning.id ? {...awning} : returnVal;
    })];

    let {selectedAwning} = this.state;
    if(this.state.selectedAwning && this.state.selectedAwning.id === awning.id){
      selectedAwning = {...awning};
    }

    this.setState({order: {...this.state.order, awnings: newAwnings}, selectedAwning});
  }

  handleCancelOrder(e) {
    e.preventDefault();
    //eslint-disable-next-line
    if (confirm('Are you sure you want to cancel?')) {
      this.setState({saving: true}, () => {
        apiPost(`/api/orders/${this.state.order.id}/cancel`, {...this.state.order})
          .then(x => {
            this.setState({saving: false});
            if (x.data && x.data.ok) {
              toastr.success('Order cancelled');
              this.props.history.push('/orders/list');
            } else {
              toastr.success('Error while submitting');
              console.log(x);
            }
          });
      });
    }
  }

  saveOrder(e, goToPreview = false) {
    e.preventDefault();

    let {order} = this.state;
    let sensorSets = this.orderSensorSets && this.orderSensorSets.current ? this.orderSensorSets.current.currentState() : [];
    let messages = {};
    let isValid = true;
    let inValidAwnings = new Set();

    for (let propName in ValidationRules) {
      let rule = ValidationRules[propName];
      let val = order[propName];
      let valResult = rule.validator(val);
      if (!valResult.isValid) {
        messages[propName] = valResult.message;
        isValid = false;
      }
    }

    for(let i = 0; i< order.awnings.length;i++) {      
      if (IsNullOrUndefined(order.awnings[i].parentOrderAwningId)) {
        if (this.refs[`awning-${order.awnings[i].id}`] && !this.refs[`awning-${order.awnings[i].id}`].isValid()) {
          console.log('invalid');
          isValid = false;
          inValidAwnings.add(order.awnings[i].id);
        } else {
          inValidAwnings.delete(order.awnings[i].id);
        }
      }
    }

    let toPost = {...this.state.order};
    toPost.sensorSets = sensorSets;
    if (isValid) {
      this.setState({saving: true});
      apiPost(`/api/orders/${this.state.order.id}`, toPost)
        .then(x => {
          if(x.error){
            this.setState({saving: false});
//            x.data.json().then(a => toastr.error(a.message));
          } else {
            this.setState({saving: false, order: {...x.data}, inValidAwnings});
            if (goToPreview) {
              this.props.history.push(`/orders/view/${obsfucate(x.data.id)}`);
            } else {
              this.props.history.push('/orders/list');
            }
          }
        })
        .catch(x => {
          console.log(x);
          toastr.error('Error saving order ' + x);
          this.setState({saving: false, order: toPost});
        });
    } else {
      this.setState({validationMessages: messages, inValidAwnings});
    }
  }

  handlePreviewOrder(e){
    this.saveOrder(e, true);
  }

  handleCancel(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  validateOrder(){
    return {};
  }

  handleAwningMessagesChanged(awningId, messages) {
    let {awningMessages} = this.state;
    awningMessages.set(awningId, messages);
    this.setState({awningMessages: new Map(Array.from(awningMessages))});
  }

  /*handleOnUpdateOrderAwningFields(awning, numberOfFields) {
    // the awning that initiates this will be the owner of the fields, this will be added to the awning created / removed
    let baseNewAwning = {...this.getNewAwning(), parentAwningId: awning.id};
    let newAwnings = [...this.state.order.awnings.filter(x => !x.parentAwningId || x.parentAwningId !== awning.id), baseNewAwning];

    //All properties of the parent need to be set... any that can't be different to be set in the API
    for (let i = 0; i < numberOfFields - 1; i++) {
      if (i > 0) {
        newAwnings.push({...baseNewAwning, number: baseNewAwning.number++, id: baseNewAwning.id--});
      }
    }

    this.setState({
      order: {
        ...this.state.order,
        awnings: newAwnings,
      },
      selectedAwning: awning,
    });
  }

  handleOnUpdateOrderAwningFieldValue(parentAwning, awning, width){
    let {order} = this.state;
    //TODO: How are we going to validate...
    this.setState({
      order: {
        ...order,
        awnings: order.awnings.map(x => {
          let retVal = x;
          if(retVal.id === awning.id){
            retVal = {...retVal, width};
          }
          return retVal;
        }),
      }
    });
  }*/

  componentWillReceiveProps(nextProps) {
    if (nextProps.orderId == -1) {
      let order = newOrder(nextProps.user.organisation.id, nextProps.user.organisation.standardOrderNote, nextProps.user.organisation.standardAwningNote, nextProps.user.organisation.canSupplyOwnMotor);
      this.setState({order, selectedAwning: order.awnings[0]});
    }
    if (nextProps.orderId != this.state.order.id && nextProps.orderId > 0) {
      this.setState({loaded: false});
      apiRequest(`/api/orders?id=${nextProps.orderId}`)
        .then(x => {
          if (!x.error) {
            let order = {...x.data[0]};
            this.setState({order, selectedAwning: {...order.awnings[0]}, loaded: true});
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let hasSetsToCombine = ((awnings) => {
      let sets = awnings
          .filter(x => IsNullOrUndefined(x.parentOrderAwningId))
          .filter(x => x.automationSensorType !== null && (isRemote(x) || isHardwired(x)));

      let any = sets
          .findIndex(x =>
              awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId)).findIndex(y =>
                  y.id != x.id &&
                  y.automationSensorType == x.automationSensorType &&
                  isRemote(y) == isRemote(x) &&
                  isHardwired(x) == isHardwired(y)) >= 0) >= 0;
      return any;
    })(this.state.order.awnings);


    if (prevState.hasSetsToCombine != hasSetsToCombine) {
      this.setState({hasSetsToCombine, showCombine: this.state.order.sensorSets.findIndex(x => x.id > 0) >= 0});
    }
    
    
    let orgUrl = prevState.order.organisationId !== this.state.order.organisationId ? `/api/organisations?id=${this.state.order.organisationId}` :
        `/api/organisations/currentuser`;

    if(prevState.order.organisationId !== this.state.order.organisationId || this.state.order.organisationId <= 0) {
      apiRequest(orgUrl)
          .then(x => {
            this.setState({
              organisation: x.data,
              order: {
                ...this.state.order,
                orderNote: x.data.standardOrderNote,
                awnings: this.state.order.awnings.map(y => {
                  return {
                    ...y,
                    noteText: x.data.standardAwningNote,
                    canSupplyOwnMotor: x.data.canSupplyOwnMotor,
                  };
                })
              }
            })
          });
    }
  }

  loadOrder(){
    if (this.props.orderId > 0) {
      apiRequest(`/api/orders?id=${this.props.orderId}`)
        .then(x => {
          if (!x.error) {
            let order = {...x.data[0]};
            this.setState({order, selectedAwning: {...order.awnings[0]}, loaded: true});
          }
        });
    }
  }

  componentWillMount() {
    this.loadOrder();
    apiRequest(`/api/organisations`)
      .then(x => {
        if (!x.error) {
          this.setState({organisations: x.data});
        }
      });

  }

  getButtons() {
    let {order, saving} = this.state;
    let {user} = this.props;
    let isValid =  Array.from(this.state.awningMessages.values()).
      filter(x => {
        return some(Object.keys(x.validationMessages), y => x.validationMessages[y] !== '');
      }).length === 0;

    if (order.orderStatus == ORDER_STATUS_QUOTE || order.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED) {
      let canCancel = user.hasAccessTag(edit_order_cancel_order_amendment_requested) && order.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED;

      return (
        <div className="form-group">
          <button
            disabled={saving}
            onClick={this.saveOrder}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="icon-floppy pi-icon-left"></i>&nbsp;Save Quote
          </button>
          <button
            onClick={this.handlePreviewOrder}
            disabled={saving}
            className="btn pi-btn pi-uppercase pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="fa fa-binoculars"></i>&nbsp;Preview Order
          </button>
          <ButtonWrapper
            visible={canCancel}
            disabled={saving}
            onClick={this.handleCancelOrder}
            style={{marginRight: '10px'}}
            className="btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-ban"></i>&nbsp;Cancel Order
          </ButtonWrapper>
          <button
            onClick={this.handleCancel}
            disabled={saving}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-angle-left"></i>&nbsp;Back
          </button>
          {!isValid && <div style={{color: 'red'}}>Please complete all required fields</div> }
        </div>
      );
    }
    if (order.orderStatus == ORDER_STATUS_ORDER_REQUESTED) {
      return (
        <div className="form-group">
          <button
            disabled={saving}
            onClick={this.handlePreviewOrder}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="fa fa-binoculars"></i>&nbsp;Preview Order
          </button>
          <button
            onClick={this.handleCancel}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-angle-left"></i>&nbsp;Back
          </button>
          {!isValid && <div style={{color: 'red'}}>Please complete all required fields</div> }
        </div>
      );
    }
    return (<div className="form-group">
      <button
        onClick={this.handleCancel}
        className="btn pi-btn pi-uppercase pi-letter-spacing">
        <i className="fa fa-angle-left"></i>&nbsp;Back
      </button>
    </div>);
  }

  render() {

    let {order, selectedAwning, hasSetsToCombine, showCombine} = this.state;
    let {user, siteSettings} = this.props;
    let buttons = this.getButtons();
    let canViewScreen = ((order.orderStatus == ORDER_STATUS_QUOTE && user.hasAccessTag(edit_order_view_screen_quote)) ||
      (order.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED && user.hasAccessTag(edit_order_view_screen_amendment_requested)))
      && (user.hasAccessTag(orders_list_list_all_organisations_all) || user.organisation.id == order.organisationId);

    if(!canViewScreen && this.state.loaded){
      return <div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              You don't have permission to edit this order
            </h4>
          </div>
        </div>
      </div>;
    }

    return this.state.loaded ? (
      <div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-9">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              {this.state.order.id > 0 ? `Edit Order #${this.state.order.id}`: 'Create Order'}
            </h4>

            <form role="form" className="form-horizontal">

            {buttons}

            <hr className="pi-divider"></hr>

            <SelectControl
                validationMessages={this.state.validationMessages}
                labelText="Customer Name"
                name="organisationId"
                options={this.state.organisations}
                simpleValue={true}
                onChange={(val) => this.setState({order: {...this.state.order, organisationId: val}})}
                value={order.organisationId}
                valueKey={'id'}
                labelKey={'description'}
                placeholder="Select Organisation"
            />

            <TextBoxControl
              onChange={this.handleOrderFieldChange}
              labelText="Customer Order Ref"
              value={order.customerReference}
              name="customerReference"
              validationMessages={this.state.validationMessages}
            />

            <hr className="pi-divider"></hr>

            <h6>Awnings</h6>
            <ul className="pi-tabs-navigation">
              {order.awnings.filter(x => isNullEmptyOrWhitespace(x.parentOrderAwningId)).map(awning =>
              {
                let isInvalid = this.state.inValidAwnings.has(awning.id);
                return (
                  <li key={awning.id}
                      className={(selectedAwning != null && selectedAwning.id == awning.id) ? 'pi-active' : ''}
                  >
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      this.setState({selectedAwning: awning});
                    }}
                    style={isInvalid ? {color: 'red', fontWeight: 'bold'}: {}}>
                      Awning No.{awning.number}</a>
                  </li>
                );
              })}
              <li><a href="#tab1" onClick={this.handleNewAwning}><i className="fa fa-plus pi-text-green fa-pull-left fa-lg" />&nbsp;New Awning</a></li>
              { order.awnings.length > 1 && <li><a href="#tab1" onClick={this.handleRemoveAwning}><i className="fa fa-times pi-text-red fa-pull-left fa-lg" />&nbsp;Remove Awning No.{selectedAwning.number}</a></li> }
            </ul>

            <div>&nbsp;</div>

            {order.awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId)).map(awning =>
              <AwningView
                ref={`awning-${awning.id}`}
                key={`awningview-${awning.id}`}
                awning={awning}
                isVisible={(selectedAwning != null && selectedAwning.id == awning.id)}
                onChange={this.handleOnSelectedAwningChange}
                onMessagesChange={this.handleAwningMessagesChanged}
                order={order}
                user={user}                
                siteSettings={siteSettings}
                organisation={this.state.organisation}
              />)}

              <hr className="pi-divider"></hr>

              {hasSetsToCombine && <CheckBoxControl
                onChange={(e)=> {
                  let checked = e.target.checked;
                  this.setState({showCombine: e.target.checked}, () => {
                    if (!checked) {
                      this.setState({order: {...order, sensorSets: []}});
                    }
                  });
                }}
                labelText={'Combine awning sensors into sets?'}
                name={'showCombine'}
                value={this.state.showCombine}
              /> }

              {showCombine && <OrderSensorSetsControl order={this.state.order} ref={this.orderSensorSets} /> }

              <div>&nbsp;</div>
              {buttons}
          </form>

          </div>

          <div className="pi-col-md-3">
            <div className="pi-box pi-border pi-round pi-border-top">              
              <h6 className="pi-weight-600">
                Order Notes
              </h6>
                {Array.from(this.state.awningMessages).map(x => {
                  let a = this.state.order.awnings.filter(y => y.id==x[0])[0];
                  let messages = Array.from(x[1].messages);
                  let validationMessages = Object.values(x[1].validationMessages).filter(x => x !='');
                  //let surchargeMessages = Array.from(x[1].surchargeMessages);
                  return a == undefined ? null : (
                    <div key={a.id}>
                      <h6>Awning {a.number}</h6>
                      {messages.length > 0 || validationMessages.length > 0 ?  <ul>
                        {messages.map((y, i) => {
                          return (<li key={`message-${a.id}${i}`}>{y}</li>);
                        })}
                        {validationMessages.map((y, i) => {
                          return (<li style={{color:'red'}} key={`vmessage-${a.id}${i}`}>{y}</li>);
                        })}
                        {/*surchargeMessages.map((y, i) => {
                          return (<li style={{color:'blue'}} key={`surcharge-message-${a.id}${i}`}>{y[1]}</li>);
                        })*/}
                      </ul> : <span><i>No messages</i></span>}
                    </div>);
                })}
              
              <br />
              <br />
              {/*<div className="alert alert-danger" role="alert" style={{marginLeft: '-15px'}}>*/}
              {/*  Current prices are valid for orders placed before 1 May 2022. New prices will apply from that date.*/}
              {/*</div>*/}
              </div>


            { !isNullEmptyOrWhitespace(siteSettings.AluxorUpdatesMessage) && <div className="pi-box pi-border pi-round pi-border-top">
              <h6 className="pi-weight-600">
                Aluxor Updates
              </h6>              
              <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                {siteSettings.AluxorUpdatesMessage}
              </div>
            </div> }
            
            </div>       
          
        </div>
        <OrderStatusChanges
          visible={user.hasAccessTag(edit_order_view_order_status_changes_all)}
          statusChanges={this.state.order.statusChanges} />
      </div>
      ) :
      <div className="pi-section">
      <div className="pi-row">
        <div className="pi-col-md-8">
          <h4 className="pi-has-bg pi-margin-bottom-20">
            Loading... <i className={'fa fa-spinner fa-spin'} />
          </h4>
        </div>
      </div>
    </div>;
  }
}

EditOrderPage.propTypes = {
  user: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let orderId = -1;
  if(ownProps.match.params && ownProps.match.params.orderId){
    orderId = parseInt(deobsfucate(ownProps.match.params.orderId));
  }

  return {
    user: state.user,
    orderId,
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrderPage));