import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import {toastr} from 'react-redux-toastr';
import initialState from '../reducers/initialState';
import {uniqBy, join, uniq, flatten, concat} from 'lodash';
import {
  DATE_SEARCH_FORMAT,
  DATE_SHORT_FORMAT,
  isNullEmptyOrWhitespace,
  orders_list_list_all_organisations_all, ROLE_Fabric, ROLE_PC
} from "../constants";
import moment from "moment";

export function loadSupportRequestsSuccess({supportRequests, filter}){  
  return {type: types.SUPPORT_REQUESTS_LOAD_SUCCESS, supportRequests, filter};
}

export function loadSupportRequests(filter = initialState.supportRequests.filter) {
  return function (dispatch, getState) {

    let user = getState().user;

    let canViewAll = user && user.hasAccessTag(orders_list_list_all_organisations_all);

    let filterToUse = canViewAll ? filter : {...filter, organisations: [user.organisation.id]};

    let url = constructFilter(filterToUse);

    toastr.warning('Loading support requests...');

    return apiRequest(url)
        .then(x => {
          if (x.data) {
            toastr.success('Support requests loaded');
            dispatch(loadSupportRequestsSuccess({supportRequests: [...x.data], filter}));
          } else {
            toastr.error('Error while loading...');
          }
        });
  };
}

function constructFilter(filter) {
  let url = `/api/supportRequests?`;

  let urlSegmentArray = (name, arrValues) => {
    return arrValues.length === 0 ? '' : '&' + join(arrValues.map(x => `${name}=${x}`), "&");
  };
  let urlSegmentString = (name, value) => {
    return isNullEmptyOrWhitespace(value) ? '' : `&${name}=${value}`;
  };

  url += urlSegmentArray('organisations', filter.organisations);
  url += urlSegmentArray('statuses', filter.statuses);
  url += urlSegmentArray('productIds', filter.productIds);
  url += urlSegmentArray('supportRequestTypeIds', filter.supportRequestTypeIds);
  url += urlSegmentString('customerReferenceNumber', filter.customerReferenceNumber);
  url += urlSegmentString('sequenceNumber', filter.sequenceNumber);
  
  return url;
}


export function updateFilter(filter = initialState.supportRequests.filter){
  return function (dispatch, getState) {
    //check if the filter has changed, if so construct API call
    let {currentFilter} = getState().supportRequests.filter;

    if(JSON.stringify(currentFilter) !== JSON.stringify(filter))
    {
      dispatch({type: types.SUPPORT_REQUESTS_UPDATE_FILTER, filter});      
    } 
  }
}

export function loadProducts(){
  return function (dispatch, getState) {
    //check if the filter has changed, if so construct API call
    let {products} = getState().supportRequests;

    if (products.length === 0) {
      apiRequest('/api/products/admin')
          .then((x) => {
            dispatch({type: types.SUPPORT_REQUESTS_LOADPRODUCTS, products: [...x.data.map(x => {
                    return {value: x.id, label: x.description};
                  }
              ), {value: -1, label: 'Other'}]});
          });
    }
  }
}