import React from 'react';
import PropTypes from 'prop-types';
import {DATE_SHORT_FORMAT} from '../../constants';
import moment from 'moment';
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const StatusColumns = [
  {
    id: 'createdDate',
    Header: 'Date',
    accessor: c => moment(c.createdDate).format(DATE_SHORT_FORMAT),
  },
  {
    Header: 'Person',
    accessor: 'personName',
  },
  {
    Header: 'From status',
    accessor: 'previousOrderStatusDescription',
  },
  {
    Header: 'To status',
    accessor: 'orderStatusDescription',
  },
  {
    Header: 'Reason',
    accessor: 'reasonText',
  },
];

const OrderStatusChanges = (props) => {
  let {statusChanges, visible} = props;
  return (visible && statusChanges.length > 0) ? (
    <div className="pi-row" style={{marginTop: '20px'}}>
      <div className="pi-col-sm-12">
        <h6>Order Status Changes</h6>

        <ReactTable
          data={statusChanges}
          columns={StatusColumns}
          minRows={5}
          noDataText={'No status changes found'}
        />

      </div>
    </div>
  ) : null;
};

OrderStatusChanges.propTypes = {
  statusChanges: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default OrderStatusChanges;