import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import TextViewControl from '../common/TextViewControl';
import AwningProductionSheet from './AwningProductionSheet';
import CustomerDetailsView from './CustomerDetailsView';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import {Aux, DATE_SHORT_FORMAT, REPORTSERVER_BASEURL} from '../../constants';
import jQuery from 'jquery';
import {apiDownloadReport} from "../../utils/request";
import {Base64} from "js-base64";

class OrderProductionSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.printSection = React.createRef();
  }

  handlePrint() {

    let {order} = this.props;

    let container = jQuery(`<div></div>`);

    jQuery(this.printSection.current).clone().css('width', '97%').appendTo(container);

    container
      .css('font-size', '11px')
      //.css('padding-right', '20px')
      //.css('margin-top', '25px')
      //.css('padding-top', '25px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    container.find('h4').css('font-size', '12px');
    container.find('h5').css('font-size', '11px');
    container.find('h6').css('font-size', '10px');

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_productionSheet.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });*/

    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${order.id}_productionSheet.pdf`);

    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {order, print} = this.props;

    if(!order){
      return null;
    }

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-section">

              <div className="pi-row">
                <div className="pi-col-md-8">
                    <button onClick={this.handlePrint}
                            className="btn pi-btn-base pi-btn-icon-big"
                            style={{marginRight: '10px'}}
                    >
                      <i className="fa fa-print"></i>&nbsp;Print</button>
                  <button className="btn pi-btn  pi-btn-icon-big"
                          onClick={this.props.toggleOpen}><i className="fa fa-times"></i>&nbsp;Close</button>
                </div>
              </div>

              <div ref={this.printSection}>
                {order.awnings.map((awning, i) => <Aux key={awning.id}>
                  {i > 0 && <div className={'new-page'} />}
                  <AwningProductionSheet
                    order={order}
                    awning={awning}
                  />
                </Aux>)}

              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"></i>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderProductionSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
};

export default OrderProductionSheet;