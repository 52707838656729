import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import connect from "react-redux/lib/connect/connect";
import {CreateSupportRequestView} from "./CreateSupportRequestView";
import {apiPost, apiRequest} from "../../utils/request";
import {
    deobsfucate,
    isNullEmptyOrWhitespace,
    IsStringValid, SUPPORT_REQUEST_STATUS_COMPLETED,
    SUPPORT_REQUEST_STATUS_INQUIRY, SUPPORT_REQUEST_STATUS_RECEIVED,
    validationCreator
} from "../../constants";
import {EditSupportRequestView} from "./EditSupportRequestView";
import {isNumber} from "lodash/lang";


export const SupportRequestTypes = [
    {label:"Spare Parts", value:1},
    {label:"Product Service", value:2},
    {label:"Warranty", value:3},
    {label:"Technical Information", value:4},
];

export const SupportRequestStatusTypes = [
    {label:"Inquiry", value:SUPPORT_REQUEST_STATUS_INQUIRY},
    {label:"Received", value:SUPPORT_REQUEST_STATUS_RECEIVED},
    {label:"Complete", value:SUPPORT_REQUEST_STATUS_COMPLETED},
];

export const SupportRequestInitialState = {
    id: -1,
    supportRequestStatusType: 1,
    supportRequestType: null,
    productId: null,
    customerReferenceNumber: null,
    isRelationToExistingProductSupplied: null,
    supplyNotes: null,
    originalOrderReference: null,
    originalOrderDate: null,
    locationAddressLine1: null,
    locationAddressLine2: null,
    locationSuburb: null,
    locationPostCode: null,
    locationStateId: null,
    locationCountryId: 1,
    colourId: null,
    customColourId: null,
    supportDescription: null,
    attachments: [],
    aluxorNotes: null,
    sequenceNumber: null,
};

// export const SupportRequestInitialState = {
//     id: -1,
//     supportRequestStatusType: 1,
//     supportRequestType: 1,
//     productId: 2,
//     productDescription: 'Stratos I',
//     customerReferenceNumber: 'abc',
//     isRelationToExistingProductSupplied: false,
//     supplyNotes: 'abc',
//     originalOrderReference: null,
//     originalOrderDate: null,
//     locationAddressLine1: '111',
//     locationAddressLine2: null,
//     locationSuburb: 'Coledale',
//     locationPostCode: '2515',
//     locationStateId: 1,
//     locationCountryId: 1,
//     colourId: null,
//     customColourId: null,
//     supportDescription: 'some description',
//     attachments: [],
// };


export const SupportRequestValidationRules = {
    supportRequestType: {
        validator: (supportRequest) => {
            return isNullEmptyOrWhitespace(supportRequest.supportRequestType) ? validationCreator(false, 'Please select a Request Type') : validationCreator();
        }
    },
    productId: {
        validator: (supportRequest) => {
            return isNullEmptyOrWhitespace(supportRequest.productId) ? validationCreator(false, 'Please select a Product') : validationCreator();
        }
    },
    productDescription: {
        validator: (supportRequest) => {
            return isNullEmptyOrWhitespace(supportRequest.productDescription) ? validationCreator(false, 'Please enter a Product Description') : validationCreator();
        }
    },
    customerReferenceNumber: {
        validator: (supportRequest) => {
            return !IsStringValid(supportRequest.customerReferenceNumber) ? validationCreator(false, 'Please enter a Customer Reference Number') : validationCreator();
        }
    },
    isRelationToExistingProductSupplied: {
        validator: (supportRequest) => {
            return supportRequest.isRelationToExistingProductSupplied === null ? validationCreator(false, 'Please make a selection') : validationCreator();
        }
    },
    supplyNotes: {
        validator: (supportRequest) => {
            return supportRequest.isRelationToExistingProductSupplied === false &&
            !IsStringValid(supportRequest.supplyNotes) ? validationCreator(false, 'Please enter a supply description') : validationCreator();
        }
    },
    originalOrderReference: {
        validator: (supportRequest) => {
            return supportRequest.isRelationToExistingProductSupplied === true &&
            !IsStringValid(supportRequest.originalOrderReference) ? validationCreator(false, 'Please enter an Original Order Reference') : validationCreator();
        }
    },
    originalOrderDate: {
        validator: (supportRequest) => {
            return supportRequest.isRelationToExistingProductSupplied === true &&
            !IsStringValid(supportRequest.originalOrderDate) ? validationCreator(false, 'Please enter an Original Order Date') : validationCreator();
        }
    },
    supportDescription: {
        validator: (supportRequest) => {
            return !IsStringValid(supportRequest.supportDescription) ? validationCreator(false, 'Please enter a Support Description') : validationCreator();
        }
    },
    locationAddressLine1: {
        validator: (supportRequest) => {
            return supportRequest.supportRequestType !== 1 && !IsStringValid(supportRequest.locationAddressLine1) ? validationCreator(false, 'Please enter a location address') : validationCreator();
        }
    },
    locationSuburb: {
        validator: (supportRequest) => {
            return supportRequest.supportRequestType !== 1 && !IsStringValid(supportRequest.locationSuburb) ? validationCreator(false, 'Please enter a location Suburb') : validationCreator();
        }
    },
    locationPostCode: {
        validator: (supportRequest) => {
            return supportRequest.supportRequestType !== 1 && !IsStringValid(supportRequest.locationPostCode) ? validationCreator(false, 'Please enter a location Post Code') : validationCreator();
        }
    },
    locationStateId: {
        validator: (supportRequest) => {
            return supportRequest.supportRequestType !== 1 && supportRequest.locationStateId === null ? validationCreator(false, 'Please select a location State') : validationCreator();
        }
    },
}

class SupportRequestPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            products: [],
            customColours: [],
            standardColours: [],
            supportRequest: null,
        };
        
        this.loadData = this.loadData.bind(this);
        this.saveSupportRequest = this.saveSupportRequest.bind(this);
    }
    
    loadData() {
        let { requestId} = this.props;

        // get a list of all products
        apiRequest('/api/products/admin')
            .then((x) => {
                this.setState({
                    products: [...x.data.map(x => {
                            return {value: x.id, label: x.description};
                        }
                    ), {value: -1, label: 'Other'}]
                });
            });

        apiRequest('/api/colours/customframe')
            .then((x) => {
                this.setState({customColours: [...x.data]});
            });

        apiRequest('/api/colours/standard')
            .then((x) => {
                this.setState({
                    standardColours: x.data.map(x => {
                            return {id: x.value, colourId: x.value, colourDescription: x.label};
                        }
                    ),
                });
            });
        
        if(requestId > 0){
            apiRequest(`/api/SupportRequests/${requestId}`)
                .then(x => {
                    this.setState({supportRequest: {...x.data[0]}});
                });
        }        
    }
    
    saveSupportRequest(supportRequest) {
        apiPost(`/api/SupportRequests/${supportRequest.id}`, supportRequest)
            .then(x => {
                this.props.history.push(`/supportRequests`)
            });
    }
    
    componentDidMount() {        
        this.loadData();
    }

    render(){
        let {products, customColours, standardColours, supportRequest} = this.state;
        let {states, organisations, user, requestId} = this.props;       
        
        return (<div>
            <div className={"row mt-4"}>
                <div className={"col-md-6"}><button
                    className="btn pi-btn-base pi-btn-icon-big"
                    onClick={()=> this.props.history.push('/supportRequests')}>
                    <i className="fa fa-chevron-left"></i>&nbsp;Back
                </button></div>
            </div>
            { requestId < 0 ? <CreateSupportRequestView 
                onSave={this.saveSupportRequest}
                products={products}
                states={states}
                customColours={customColours}
                standardColours={standardColours}
                user={user}
                organisations={organisations}
            /> : <EditSupportRequestView
                onSave={this.saveSupportRequest}
                products={products}
                states={states}
                customColours={customColours}
                standardColours={standardColours}
                user={user}
                organisations={organisations}
                loadedSupportRequest={supportRequest}
            /> }
        </div>);
    }
}

SupportRequestPage.propTypes = {
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

    let requestId = -1;    
    
    if(ownProps.match.params && ownProps.match.params.requestId){
        if(parseInt(ownProps.match.params.requestId) > 0){
            requestId = parseInt(ownProps.match.params.requestId);
        }
        else {
            requestId = parseInt(deobsfucate(ownProps.match.params.requestId));    
        }
    }
    
    return {
        user: state.user,
        siteSettings: state.lookups.siteSettings,
        states: state.lookups.states,
        organisations: state.organisations.items,
        requestId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportRequestPage));