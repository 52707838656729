import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import SelectControl from "../common/SelectControl";
import {apiPost, apiRequest} from "../../utils/request";
import {Aux, isNullEmptyOrWhitespace} from "../../constants";
import RadioControlGroup from "../common/RadioControlGroup";
import {some, uniqBy, flatten} from 'lodash';
import TextViewControl from "../common/TextViewControl";
import {toastr} from 'react-redux-toastr';

const initialState ={
  loading: false,
  motorOptions: {
    motorBrands: [],
    awnings: [],
  },
  savedOptions: {
    motorBrandId: null,
    awnings: []
  },
  saving: false,
};

class MotorSelectionSheet extends Component {

  constructor(props) {
    super(props);

    this.state = {...initialState};

    this.loadOrderMotorOptions = this.loadOrderMotorOptions.bind(this);
    this.handleSetBrandId = this.handleSetBrandId.bind(this);
    this.handleAwningOption = this.handleAwningOption.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  loadOrderMotorOptions() {
    let {order} = this.props;

    this.setState({...initialState, loading: true}, () => {
      apiRequest(`/api/motors/ordermotoroptions?orderid=${order.id}`)
        .then(x => {
          if(x.data){
            let motorOptions = {...x.data};
            this.setState({
              ...initialState,
              loading: false,
              motorOptions,
              savedOptions: {
                motorBrandId: motorOptions.currentMotorBrandId,
                awnings: x.data.awnings.map(y => {
                  return {
                    id: y.id,
                    motorId: y.motorId,
                    myobRemoteLookupId: y.myobRemoteLookupId,
                    awningNumber: y.awningNumber,
                    motionSensorColour: y.motionSensorColour,
                    hasSensorColours: y.motionSensorColours.length > 0,
                    hasRemoteColours: y.remoteColours.length > 0,
                    hasMotorOptions: y.motorOptions.length > 0,
                  }
                })
              }
            });
          }
        });
    });
  }

  handleSave(e) {
    e.preventDefault();
    let {onSave, order, toggleOpen} = this.props;
    let {savedOptions} = this.state;

    toastr.info('Saving...');
    this.setState({saving: true}, () => {
      apiPost(`/api/orders/${order.id}/motorOptions`, savedOptions)
        .then(x => {
          if(x.data) {
            toastr.success('Saved!');
            this.setState({saving: false}, () => {
              onSave({...x.data});
              toggleOpen();
            });
          } else {
            toastr.error('Error while saving!');
          }
        });
    });
  }

  handleSetBrandId(val, e) {
    let {savedOptions, motorOptions} = this.state;

    this.setState({
      savedOptions: {
        ...savedOptions,
        motorBrandId: val.simpleValue,
        awnings: [...savedOptions.awnings.map(a => {
          let brandedMotor = flatten(motorOptions.awnings
              .filter(y => y.id === a.id).map(y => y.motorOptions)).filter(y => y.motorBrandId === val.simpleValue);
          return brandedMotor.length === 1 ? {
            ...a,
            motorId: isNullEmptyOrWhitespace(a.motorId) ? brandedMotor[0].motorId : a.motorId,
          } : {
            ...a,
          };
        })]
      }
    });
  }

  handleAwningOption(propName, val, awning, copyAwningNumbers = []){
    let {savedOptions} = this.state;

    this.setState({
      savedOptions: {
        ...savedOptions,
        awnings: [...savedOptions.awnings.map(a => {
          let retVal = {...a};
          if(awning.id === a.id || copyAwningNumbers.filter(y => y === retVal.awningNumber).length > 0){
            retVal[propName] = val;
          }
          return retVal;
        })]
      }
    });
  }

  isValid(){
    let {savedOptions, motorOptions} = this.state;

    let missing = some(savedOptions.awnings, x => {
      return (x.hasRemoteColours && !x.myobRemoteLookupId)
          || (x.hasSensorColours && !x.motionSensorColour)
          || (x.hasMotorOptions && !x.motorId);
    });
    return !missing;
  }

  componentDidMount() {
    this.loadOrderMotorOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.isOpen && this.props.isOpen){
      this.loadOrderMotorOptions();
    }
  }

  render() {
    let {order, isOpen, toggleOpen} = this.props;
    let {motorOptions, loading, savedOptions, saving} = this.state;
    let valid = this.isValid();

    return order ?
      (<div>
        <Modal isOpen={isOpen}
               toggle={toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-row" style={{marginBottom: '15px'}}>
              <div className="pi-col-sm-12">
                <h4>Motor Selection</h4>
              </div>
            </div>

            {loading ?
              <div className="pi-row">
                <div className="pi-col-md-8">
                  <h4 className="pi-has-bg pi-margin-bottom-20">
                    Loading... <i className={'fa fa-spinner fa-spin'}/>
                  </h4>
                </div>
              </div>
              :
              <Aux>
                <RadioControlGroup
                  name={`motorBrand`}
                  onChange={this.handleSetBrandId}
                  value={savedOptions.motorBrandId}
                  labelText='Motor Brand'
                  validationMessages={{}}
                  options={motorOptions.motorBrands}
                  style={{marginLeft: '0px'}}
                />
              </Aux>
            }

            {order.awnings
              .filter(x => some(motorOptions.awnings.filter(y => y.id === x.id), y => y.motorOptions.length > 0))
              .map(x => {
                let awningOption = motorOptions.awnings.filter(y => y.id === x.id)[0]
                let options = awningOption.motorOptions.filter(m => m.motorBrandId === savedOptions.motorBrandId);
                let savedAwning = savedOptions.awnings.filter(m => m.id === x.id)[0];
                let remoteOptions = awningOption.remoteColours.filter(y => y.motorBrandId === savedOptions.motorBrandId);
                let motionSensorColours = awningOption.motionSensorColours.filter(y => y.motorBrandId === savedOptions.motorBrandId);
                let matchedRemote = remoteOptions.filter(z => z.value === savedAwning.myobRemoteLookupId);
                let matchedNotSameColour = matchedRemote.length === 1 ? matchedRemote[0].colour !== x.desiredRemoteColour : false;

                let applicableSetFilter = motorOptions.sensorSets.filter(y => {
                  return (y.awningNumbers.length > 0 && y.awningNumbers.filter(z => z === awningOption.awningNumber).length > 0);
                });
                let applicableSet = applicableSetFilter.length > 0 ? applicableSetFilter[0] : null;

                let isInSetAndFirstInSet = awningOption.isInSet && motorOptions.sensorSets.filter(y => {
                  return (y.awningNumbers.length > 0 && y.awningNumbers[0] === awningOption.awningNumber);
                }).length > 0;

                return options.length > 0 ? (<Aux key={x.id}>

                  <SelectControl
                    validationMessages={{}}
                    labelText={`Awning ${x.number} motor`}
                    name="motorId"
                    options={options}
                    valueKey={'motorId'}
                    labelKey={'motorDescription'}
                    simpleValue={true}
                    onChange={(val) => this.handleAwningOption('motorId', val, x)}
                    value={savedAwning.motorId}
                    placeholder="Select Motor"
                  />

                  {remoteOptions.length > 0 && <TextViewControl
                    labelText="Requested remote control"
                    value={`${x.remoteDescription} ${isNullEmptyOrWhitespace(x.desiredRemoteColour) ? '' : ' - ' + x.desiredRemoteColour}`}
                  /> }

                  {motionSensorColours.length > 0 && <TextViewControl
                    labelText="Requested motion sensor colour"
                    value={`${x.motionSensorColour}`}
                  /> }

                  {awningOption.sensorTypeDescription && applicableSet && <TextViewControl
                    labelText="Sensor type"
                    value={`${awningOption.sensorTypeDescription} in a set with Awnings #${applicableSet.awningNumbers.reduce((a, b) => `${a}, ${b}`)}`}
                  /> }

                  {awningOption.sensorTypeDescription && !applicableSet && <TextViewControl
                    labelText="Sensor type"
                    value={`${awningOption.sensorTypeDescription}`}
                  /> }

                  { isInSetAndFirstInSet || !awningOption.isInSet ?
                    <Aux>

                      <RadioControlGroup
                      name={`desiredRemoteColour_${x.id}`}
                      onChange={(val) => this.handleAwningOption('myobRemoteLookupId', val.simpleValue, x, awningOption.isInSet ? applicableSet.awningNumbers : [])}
                      value={savedAwning.myobRemoteLookupId}
                      labelText="Remote colour"
                      validationMessages={{}}
                      options={remoteOptions}
                      valueKey={'value'}
                      labelKey={'label'}
                      style={{marginLeft: '0px'}}
                    />

                      {matchedNotSameColour &&
                      <div className={'pi-row'} style={{marginLeft: '0px'}}>
                        <div className={'pi-col-8 alert alert-warning'} style={{width: '90%'}}><i className={'fa fa-exclamation-triangle'} /> The chosen remote not same as customer request</div>
                      </div>
                      }

                    </Aux>
                      : <TextViewControl
                    labelText="Remote colour"
                    value={`Settings from first awning in set are used for set`}
                  /> }

                  { (isInSetAndFirstInSet || !awningOption.isInSet) && motionSensorColours.length > 0 &&
                  <Aux>

                    <RadioControlGroup
                      name={`motionSensorColour_${x.id}`}
                      onChange={(val) => this.handleAwningOption('motionSensorColour', val.simpleValue, x, awningOption.isInSet ? applicableSet.awningNumbers : [])}
                      value={savedAwning.motionSensorColour}
                      labelText="Motion sensor colour"
                      validationMessages={{}}
                      options={motionSensorColours}
                      valueKey={'value'}
                      labelKey={'label'}
                      style={{marginLeft: '0px'}}
                    />

                    {(x.motionSensorColour && savedAwning.motionSensorColour && x.motionSensorColour !== savedAwning.motionSensorColour) &&
                    <div className={'pi-row'} style={{marginLeft: '0px'}}>
                      <div className={'pi-col-8 alert alert-warning'} style={{width: '90%'}}><i className={'fa fa-exclamation-triangle'} /> Motion sensor colour not what customer specified</div>
                    </div>
                    }
                  </Aux>}

                </Aux>) : null;
              })}

          </ModalBody>
          <ModalFooter>
            { valid ? <button className="btn pi-btn pi-btn-base pi-btn-icon-big"
              onClick={this.handleSave}
              disabled={saving}
            >
              <i className="fa fa-save"></i>&nbsp;Save
            </button> : <span style={{color: 'red'}}>Please select all options</span>}
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={toggleOpen}
                    disabled={saving}
            >
              <i className="fa fa-times"></i>&nbsp;Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>)
      : null;
  }
}

MotorSelectionSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default MotorSelectionSheet;