import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Aux, IsNullOrUndefined,
} from "../../constants";
import TextViewControl from "../common/TextViewControl";
import FabricRecoverAwningCosts from "./FabricRecoverAwningCosts";
import {FabricRecoverAwningViewWorksheetData} from "./FabricRecoverAwningViewWorksheetData";
import {CalcPriceView} from "./CalcPriceView";

class FabricRecoverAwningViewer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { };
  }

  render(){

    let {awning, fabricBrands, baseCosts} = this.props;
    let workSheetData = FabricRecoverAwningViewWorksheetData({awning, fabricBrands, baseCosts});
    
    return (
      <div>
        <h3 style={{marginTop: '10px'}}>Awning #{awning.number} Fabric Recovering Calculator</h3>
        <p>Fill in the required details and the applicable pricing will be displayed</p>

        <div className={'row'}>
          <div className='col-6'>
            <TextViewControl labelText={'Product Group'} value={awning.productGroupDescription} />

            <TextViewControl labelText={'Fabric Brand'} value={awning.fabricBrandDescription} />
            <TextViewControl labelText={'Fabric'} value={awning.fabricDescription} />

            
            <TextViewControl
              labelText="Width"
              value={awning.width.toString()}
            />

            <TextViewControl
                labelText="Projection"
                value={awning.projection.toString()}
            />

            <TextViewControl
                labelText="Valance"
                value={awning.fabricValanceString}
            />            
            
            <TextViewControl labelText={'Trim Bias Colour'} value={awning.fabricTrimBiasColour} />

              <TextViewControl
                  labelText="Fabric Instruction"
                  value={awning.fabricInstructionDescription}
              />

            <TextViewControl
                labelText="Fabric Instruction"
                value={awning.fabricInstructionDescription}
            />

            <TextViewControl
                labelText="Fabric notes"
                value={awning.fabricNote}
            />
              
          </div>
          <div className='col-3'>
              <h4>Labour</h4>

              <CalcPriceView labelText={'Men'} value={awning.men} isMoney={false} />
              

              <CalcPriceView
                labelText="Hours"
                value={awning.hours}
                isMoney={false}
              />

              <CalcPriceView
                labelText="Rate"
                value={awning.rate}
                isMoney={false}
              />
          </div>
            <div className='col-3'>
              <h4>Margin</h4>
              <CalcPriceView
                labelText="Recover %"
                value={awning.recoverPercentage}
                isMoney={false}
              />

              <CalcPriceView
                labelText="Wholesale %"
                value={awning.wholeSalePercentage}
                isMoney={false}
              />

              <CalcPriceView
                labelText="Retail %"
                value={awning.retailPercentage}
                isMoney={false}
              />

          </div>
            
        </div>
          
          <FabricRecoverAwningCosts awning={awning} fabricBrands={fabricBrands} baseCosts={baseCosts} />
      </div>      
    );
  }
}

FabricRecoverAwningViewer.propTypes = {
  fabricBrands: PropTypes.array.isRequired,
  baseCosts: PropTypes.array.isRequired,
  awning: PropTypes.object.isRequired,
};

export default FabricRecoverAwningViewer;