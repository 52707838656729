import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiPost, apiRequest} from "../../utils/request";
import {
  deobsfucate,
  EmailRegex,
  FABRIC_RECOVER_ORDER_STATUS_AMEND,
  FABRIC_RECOVER_ORDER_STATUS_CANCELLED,
  FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION,
  FABRIC_RECOVER_ORDER_STATUS_QUOTE,
  INVOICE_TYPE_FABRICRECOVER,
  INVOICE_TYPE_ORDERAWNING,
  isNullEmptyOrWhitespace,
  IsNullOrUndefined,
  IsStringValid, PhoneRegex,
  PRODUCTGROUPID_CONSERVATORY,
  PRODUCTGROUPID_FOLDING_ARM,
  validationCreator
} from "../../constants";
import FabricRecoverAwningEditor, {  
  NewFabricRecoverAwning, OrderAwningValidationRules
} from "./FabricRecoverAwningEditor";
import {minBy, uniqBy} from "lodash";
import OrderInvoiceSheet from "../orders/OrderInvoiceSheet";
import FabricRecoverAcceptOrder from "./FabricRecoverAcceptOrder";
import RecoverEditOrderSection from "./RecoverEditOrderSection";
import RecoverViewOrderSection from "./RecoverViewOrderSection";
import {FabricRecoverAwningViewWorksheetData} from "./FabricRecoverAwningViewWorksheetData";
import FabricRecoverAwningViewer from "./FabricRecoverAwningViewer";
import OrderFabricSheet from "./OrderFabricSheet";
import SelectControl from "../common/SelectControl";
import {ButtonWrapper} from "../orders/OrderSummaryPage";

const newOrder = () => {
  return {
    id: -1
    , fabricRecoverOrderStatus: FABRIC_RECOVER_ORDER_STATUS_QUOTE
    , firstName: ''
    , lastName: ''
    , email1: ''
    , phone1: ''
    , addressLine1: ''
    , addressLine2: ''
    , suburb: ''
    , stateId: null
    , postCode: ''
    , awnings: [NewFabricRecoverAwning()]
    , sequenceNumber: ''
    , fabricSheets: []
  };
};

const initState = {
  fabricBrands: [],
  productGroups: [
    {value: PRODUCTGROUPID_FOLDING_ARM, label: 'Folding Arm'},
    {value: PRODUCTGROUPID_CONSERVATORY, label: 'Conservatory'},
  ],
  order: newOrder(),  
  validationMessages: {},
  awningValidation: new Map(),
  projections: [],
  baseCosts: [],
  saving: false,
  selectedAwningNumber: 1,
  isInvoiceOpen: false,
  valances: [],  
  awningTrimBiasColours: [],
  awningFabrics: [],
  isAcceptOrderOpen: false,
  isViewSelectedSheet: false,
  isPrintSelectedSheet: false,
  selectedSheet: null,
  hasValidationRun: false,
};

const OrderValidationRules = {
  firstName: {
    validator: (order, awning) => {
      return !IsStringValid(order.firstName) ? validationCreator(false, 'Please enter a valid First Name') : validationCreator();
    }
  },
  lastName: {
    validator: (order, awning) => {
      return !IsStringValid(order.lastName) ? validationCreator(false, 'Please enter a valid Last Name') : validationCreator();
    }
  },
  email1: {
    validator: (order, awning) => {
      return !IsStringValid(order.email1, EmailRegex) ? validationCreator(false, 'Please enter a valid accounts email address') : validationCreator();
    }
  },
  phone1: {
    validator: (order, awning) => {
      return !IsStringValid(order.phone1, PhoneRegex) ? validationCreator(false, 'Please enter a valid phone number') : validationCreator();
    }
  },
  addressLine1: {
    validator: (order, awning) => {
      return !IsStringValid(order.addressLine1) ? validationCreator(false, 'Please enter a valid address') : validationCreator();
    }
  },

  postCode: {
    validator: (order, awning) => {
      return !IsStringValid(order.postCode, /^((\d{4})|(\d{6}))$/g) ? validationCreator(false, 'Please enter a valid postcode') : validationCreator();
    }
  },

  suburb: {
    validator: (order, awning) => {
      return !IsStringValid(order.suburb) ? validationCreator(false, 'Please enter a valid suburb') : validationCreator();
    }
  },

  stateId: {
    validator: (order, awning) => {
      return IsNullOrUndefined(order.stateId) ? validationCreator(false, 'Please enter a valid state') : validationCreator();
    }
  },

  fabricMakerId: {
    validator: (order, awning, isAcceptOrderOpen) => {
      return isAcceptOrderOpen && IsNullOrUndefined(order.fabricMakerId) ? validationCreator(false, 'Please select a fabric maker') : validationCreator();
    }
  },

  sequenceNumber: {
    validator: (order, awning, isAcceptOrderOpen) => {
      return isAcceptOrderOpen && isNullEmptyOrWhitespace(order.sequenceNumber) ? validationCreator(false, 'Please enter a PN') : validationCreator();
    }
  },
  
};

function getValidationMessages(validationMessages, propName, order, rules, awning = null, isAcceptOrder=false){

  let validation = rules[propName];
  if(validation) {
    let validationResult = validation.validator(order, awning, isAcceptOrder);
    if (!validationResult.isValid) {
      validationMessages[propName] = validationResult.message;
    } else {
      delete validationMessages[propName];
    }
  }
  return validationMessages;
}

class FabricRecoverOrderPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchOrder = this.fetchOrder.bind(this);
    this.handleOrderFieldChange = this.handleOrderFieldChange.bind(this);
    this.handleOrderSelectChange = this.handleOrderSelectChange.bind(this);    
    this.handleAwningFieldUpdate = this.handleAwningFieldUpdate.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleNewAwning = this.handleNewAwning.bind(this);
    this.handleRemoveAwning = this.handleRemoveAwning.bind(this);
    this.isValid = this.isValid.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleInvoice = this.toggleInvoice.bind(this);
    this.handleAcceptOrder = this.handleAcceptOrder.bind(this);
    this.toggleAcceptOrder = this.toggleAcceptOrder.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.selectedSheetIsOpen = this.selectedSheetIsOpen.bind(this);
    this.selectedSheetIndex = this.selectedSheetIndex.bind(this);
    this.selectedSheetToggleOpen = this.selectedSheetToggleOpen.bind(this);
    this.selectedSheetIsPrint = this.selectedSheetIsPrint.bind(this);
    this.handleOrderComplete = this.handleOrderComplete.bind(this);
    this.handleOrderAmend = this.handleOrderAmend.bind(this);
    this.handleSetAluxorAddress = this.handleSetAluxorAddress.bind(this);
    this.handleOrderCancel = this.handleOrderCancel.bind(this);
  }

  selectedSheetToggleOpen(resetSheet = false){
    if(this.state.isViewSelectedSheet){
      this.setState({isViewSelectedSheet: false, selectedSheet: resetSheet ? null : this.state.selectedSheet});
    } else if(this.state.isPrintSelectedSheet){
      this.setState({isPrintSelectedSheet: false});
    }
  }

  selectedSheetIndex(sheetName) {
    return (this.state.selectedSheet && (this.state.isViewSelectedSheet || this.state.isPrintSelectedSheet) && this.state.selectedSheet.value.sheetName === sheetName) ?
        this.state.selectedSheet.value.index : -1;
  }

  selectedSheetIsOpen(sheetName) {
    return (this.state.selectedSheet && (this.state.isViewSelectedSheet || this.state.isPrintSelectedSheet) && this.state.selectedSheet.value.sheetName === sheetName) ? true : false;
  }

  selectedSheetIsPrint(sheetName){
    return (this.state.selectedSheet && this.state.isPrintSelectedSheet && this.state.selectedSheet.value.sheetName === sheetName) ? true : false;
  }
  
  async fetchOrder() {
    let {orderId} = this.props;
    
    if(orderId >  0) {
      let order = (await apiRequest(`/api/fabricRecoverOrders/${orderId}`)).data;
      this.setState({
        saving: false,
        order,
      }, () => setTimeout(() => this.validate(), 1000));
    } else {
      this.setState({order: newOrder()});
    }
  }  

  handleOrderFieldChange(e){
    let order = {...this.state.order};    
    let propName = e.target.name;
    
    if(e.target.type==="checkbox"){
      order[propName] = e.target.checked;
    } else {
      order[propName] = e.target.value;
    }
    
    let validationMessages = getValidationMessages(this.state.validationMessages,propName, order, OrderValidationRules, null, this.state.isAcceptOrderOpen);

    this.setState({order, validationMessages});
  }

  handleOrderSelectChange(val, propName, valSelector){
    let order = {...this.state.order};

    order[propName] = valSelector(val);
    
    let validationMessages = getValidationMessages(this.state.validationMessages, propName, order, OrderValidationRules, null, this.state.isAcceptOrderOpen);

    this.setState({order, validationMessages});
  }
  
  handleSetAluxorAddress(e) {
    e.preventDefault();
    let {order, validationMessages} = this.state;

    let updatedOrder = {
          ...order,
          addressLine1: '22/9 Powells Road',
          addressLine2: '',
          suburb: 'Brookvale',
          postCode: '2100',
          stateId: 1,
          email1: 'info@aluxor.com.au',
          phone1: '0299072211',
    };
    
    validationMessages = getValidationMessages(validationMessages, 'addressLine1', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'addressLine2', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'suburb', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'stateId', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'postCode', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'email1', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    validationMessages = getValidationMessages(validationMessages, 'phone1', updatedOrder, OrderValidationRules, null, this.state.isAcceptOrderOpen);
    
    this.setState({
      order: updatedOrder,
      validationMessages
    });
  }

  handleAwningFieldUpdate(e, propName, awning, valueFunc, stateFunc = null) {
    let {order, awningValidation, isAcceptOrderOpen} = this.state;

    awning[propName] = valueFunc(e);

    if (stateFunc !== null) {
      awning = stateFunc(awning);
    }
    let messages = awningValidation.has(awning.id) ? awningValidation.get(awning.id) : {};
    let validationMessages = getValidationMessages({...messages}, propName, order, OrderAwningValidationRules, awning, isAcceptOrderOpen);
    awningValidation.set(awning.id, validationMessages);

    this.setState({
      order: {
        ...order, awnings: order.awnings.map(x => {
          return x.id === awning.id ? {...awning} : x;
        })
      },
      awningValidation
    }, this.validate);
  }

  async loadData() {
    let brands = await apiRequest('/api/fabricbrands/all');
    let costs = await apiRequest('/api/fabricbrands/costs');
    let valances = await apiRequest(`/api/fabricbrands/valances`);

    this.setState({
      fabricBrands: brands.data,
      baseCosts: costs.data,
      valances: valances.data,
      projections: uniqBy(costs.data, x => x.projection).map(x => {
        return {
          label: x.projection,
          value: x.projection,
        }
      }),
    });
  }
  
  handleSaveOrder(callback = null) {
    let {order} = this.state;

    this.validate(() => {
      if (this.isValid()) {
        this.setState({saving: true}, () => {
          apiPost(`/api/FabricRecoverOrders/${order.id}`, order)
              .then(x => {
                if (x.data) {                  
                  this.setState({saving: false, order: {...x.data}}, () => {
                    if(callback===null){
                      this.props.history.push('/fabricRecoverOrders/list');  
                    }
                    else {
                      callback();
                    }
                  });
                }
              });
        });
      }
    })
  }

  handleOrderComplete(callback = null) {
    let {order} = this.state;

    //eslint-disable-next-line
    if(confirm(`Are you sure this order is complete?`)) {
      this.setState({saving: true}, () => {
        apiPost(`/api/FabricRecoverOrders/${order.id}/completeOrder`, order.id)
            .then(x => {
              if (x.data) {
                this.setState({saving: false, order: {...x.data}}, () => {
                  if (callback === null) {
                    this.props.history.push('/fabricRecoverOrders/list');
                  } else {
                    callback();
                  }
                });
              }
            });
      });
    }
  }

  handleOrderAmend(callback = null) {    
    let {order} = this.state;
    
    this.setState({saving: true}, () => {
      apiPost(`/api/FabricRecoverOrders/${order.id}/amendOrder`, order.id)
          .then(x => {
            if (x.data) {
              this.fetchOrder();
            }
          });
    });
  }

  handleOrderCancel(callback = null) {
    let {order} = this.state;

    //eslint-disable-next-line
    if (confirm(`Are you sure you want to cancel order?`)) {
      this.setState({saving: true}, () => {
        apiPost(`/api/FabricRecoverOrders/${order.id}/cancelOrder`, order.id)
            .then(x => {
              if (x.data) {
                this.fetchOrder();
              }
            });
      });
    }
  }

  handleAcceptOrder() {
    let {order} = this.state;

    this.setState({order: {...order, fabricRecoverOrderStatus: FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION}}, () => {
      this.handleSaveOrder(() => this.setState({isAcceptOrderOpen: false}));
    });
  }

  handleNewAwning(e) {
    e.preventDefault();
    let {order} = this.state;
    let count = minBy(order.awnings.filter(y => y.id < 0), x => x.id);
    count = count === undefined ? '-1' : (count.id - 1);
    let number = order.awnings.length + 1;
    let newAwning = NewFabricRecoverAwning(count, number);
    this.setState({order: {...order, awnings: [...order.awnings, newAwning]}, selectedAwningNumber: number});
  }

  handleRemoveAwning(e) {
    e.preventDefault();

    let {order, selectedAwningNumber, awningValidation} = this.state;

    //eslint-disable-next-line
    if (!confirm(`Are you sure you want to remove Awning #${selectedAwningNumber}`)) {
      return;
    }

    let awning = order.awnings.find(x => x.number === selectedAwningNumber);
    
    let awnings = [...order.awnings
        .filter(x => x.number !== selectedAwningNumber)
        .map((y, i) => {
          let ret = {...y};
          ret.number = i + 1;
          return ret;
        })];

    awningValidation.delete(awning.id);
    
    this.setState({
      order: {
        ...order,
        awnings,
      },
      selectedAwningNumber: awnings[0].number,
      awningValidation,
    }, this.validate);
  }
  
  isValid(){
    let {validationMessages, awningValidation} = this.state;
    
    let isValid = Object.keys(validationMessages).length === 0;
    
    awningValidation.forEach((obj, key) => {
      if(isValid) {
        isValid = Object.keys(obj).length === 0;
      }
    });
    
    return isValid;
  }
  
  validate(fnCallback) {
    let {validationMessages, order, awningValidation, fabricBrands, baseCosts, isAcceptOrderOpen} = this.state;

    // the order
    for (let prop in OrderValidationRules) {
      validationMessages = getValidationMessages(validationMessages, prop, order, OrderValidationRules, null, isAcceptOrderOpen);
    }

    // validate each awning
    for (let i = 0; i < order.awnings.length; i++) {
      let awning = {...order.awnings[i]};
      let messages = awningValidation.has(awning.id) ? awningValidation.get(awning.id) : {};

      for (let prop in OrderAwningValidationRules) {        
        messages = getValidationMessages({...messages}, prop, order, OrderAwningValidationRules, awning, isAcceptOrderOpen);
      }

      let worksheetData = FabricRecoverAwningViewWorksheetData({awning, fabricBrands, baseCosts});

      if (IsNullOrUndefined(worksheetData)) {
        messages['dataOutOfRange'] = 'Quote values are out of range';
      } else {
        delete messages['dataOutOfRange'];

        let {baseCost, baseCostMultiplier, surchargeCost, wholesaleCost, retailCost, labourCost, subTotal, gst, retailTotal} = worksheetData;
        awning = {
          ...awning,
          baseCost,
          baseCostMultiplier,
          surchargeCost,
          wholesaleCost,
          retailCost,
          labourCost,
          subTotal,
          gst,
          retailTotal
        };
        
        order = {
          ...order, awnings: [...order.awnings.map(x => {
            return x.id === awning.id ? awning : x;
          })]
        };
      }
      awningValidation.set(awning.id, messages);
    }    
    
    this.setState({validationMessages, awningValidation, order, hasValidationRun: true}, fnCallback);
  }

  toggleModal(fieldName, onOpening = null) {

    let getNewState = () => {
      let newState = {...this.state};
      let value = newState[fieldName];
      let toBe = !value;
      newState[fieldName] = toBe;
      return {newState, toBe};
    };

    let {toBe, newState} = getNewState();

    let performSave = () => {      
      this.handleSaveOrder(() => {
        this.setState(getNewState().newState, this.validate);
      })
    };

    if (toBe) {
      if (onOpening !== null) {
        onOpening(performSave)
      } else {
        performSave();
      }
    } else {
      this.setState(newState, this.validate);
    }
  }
  
  toggleInvoice() {
    this.toggleModal('isInvoiceOpen');    
  }

  toggleAcceptOrder() {
    this.toggleModal('isAcceptOrderOpen', async (nextFn) => {
      //fetch the fabrics for each of the awning fabric brands
      let {order} = this.state;
      let awningFabrics = await uniqBy(order.awnings, x => x.fabricBrandId).map(async x => {
        let response = await apiRequest(`/api/fabricbrands/byBrand?fabricBrandId=${x.fabricBrandId}`);
        return {fabricBrandId: x.fabricBrandId, fabrics: [...response.data]};
      });

      let awningTrimBiasColours = await uniqBy(order.awnings, x => x.fabricValance).map(async x => {
        let response = await apiRequest(`api/fabricbrands/trimbiascolours?valanceType=${x.fabricValance}`);
        return {fabricValance: x.fabricValance, trimBiasColours: [...response.data]};
      });
      
      Promise.all(awningFabrics).then(x => {
        Promise.all(awningTrimBiasColours).then(y => {
          this.setState({awningFabrics: x, awningTrimBiasColours: y}, nextFn);  
        });
      });
    });
  }
  
  componentDidMount() {
    this.fetchOrder().then(_ => {});
    this.loadData().then(_ => {});
  }

  render() {

    let {user, orderId, states, fabricMakers, siteSettings} = this.props;
    let {order, validationMessages, baseCosts, fabricBrands, projections, productGroups, awningValidation, 
      saving, selectedAwningNumber, isInvoiceOpen, valances, 
      trimBiasColours, awningFabrics, awningTrimBiasColours, isAcceptOrderOpen, hasValidationRun} = this.state;
    let valid = this.isValid();    
    
    let isReadOnly = order.fabricRecoverOrderStatus !== FABRIC_RECOVER_ORDER_STATUS_QUOTE 
        && order.fabricRecoverOrderStatus !== FABRIC_RECOVER_ORDER_STATUS_AMEND && order.fabricRecoverOrderStatus !== FABRIC_RECOVER_ORDER_STATUS_CANCELLED;
    let statusText = order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_QUOTE ? 'Quote' :
        order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION ? 'In Production' : order.fabricRecoverOrderStatusString;

    let buttonList = [];
    if(!IsNullOrUndefined(order)) {
      for (let i = 0; i < order.fabricSheets.length; i++) {
        buttonList.push({value: {sheetName: 'fabric', index: i}, label: `Fabric Sheet #${i + 1}`});
      }
    }
    return (
      <div>

        <OrderInvoiceSheet
            order={order}
            toggleOpen={this.toggleInvoice}
            isOpen={isInvoiceOpen}
            print={false}
            invoiceType={INVOICE_TYPE_FABRICRECOVER}
            siteSettings={siteSettings}
        />
        
        <FabricRecoverAcceptOrder 
            order={order} 
            isOpen={isAcceptOrderOpen} 
            toggle={this.toggleAcceptOrder} 
            onAwningFieldChange={this.handleAwningFieldUpdate} 
            onOrderFieldChange={this.handleOrderSelectChange} 
            fabricMakers={fabricMakers} 
            fabrics={awningFabrics} 
            trimBiasColours={awningTrimBiasColours}
            onComplete={this.handleAcceptOrder}
            awningValidation={awningValidation}
            orderValidation={validationMessages}
            isValid={valid}
        />

        <OrderFabricSheet
            order={order}
            toggleOpen={this.selectedSheetToggleOpen}
            fabricSheetIndex={this.selectedSheetIndex('fabric')}
            isOpen={this.selectedSheetIsOpen('fabric')}
            print={this.selectedSheetIsPrint('fabric')}
            showEmail={true}
            showSequenceNumber={IsNullOrUndefined(user.organisation.fabricMakerShowSequenceNumber) || user.organisation.fabricMakerShowSequenceNumber}
            orderType={INVOICE_TYPE_FABRICRECOVER}
        />

        <div className={"row mt-2 mb-4"}>
          <div className={"col-4"}>
            <h2>Fabric Recover Order<br />{statusText}</h2>
          </div>
          <div className={"col-8 text-right"}>
            <div className="form-group">
              <button
                  disabled={saving}
                  onClick={()=>{
                    this.props.history.push('/fabricRecoverOrders/list');
                  }}
                  className="btn pi-uppercase pi-weight-700 pi-letter-spacing mr-2"
              >
                <i className="fa fa-angle-left"/>&nbsp;Back
              </button>

              <button
                  disabled={order.id < 0 || saving || !valid}
                  onClick={this.toggleInvoice}
                  className="btn pi-uppercase pi-weight-700 pi-letter-spacing mr-2"
              ><i className="fa fa-file-text-o" />&nbsp;Invoice</button>

              { order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION && <button
                  disabled={order.id < 0 || saving || !valid}
                  onClick={this.handleOrderComplete}
                  className="btn btn-success pi-uppercase pi-weight-700 pi-letter-spacing"
              ><i className="icon-floppy pi-icon-left"/>&nbsp;Complete</button> }

              { order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION && <button
                  disabled={order.id < 0 || saving || !valid}
                  onClick={this.handleOrderAmend}
                  className="btn btn-secondary pi-uppercase pi-weight-700 pi-letter-spacing ml-2" 
              ><i className="icon-floppy pi-icon-left"/>&nbsp;Amend</button> }

              { (order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION || 
                  order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_AMEND ) && <button
                  disabled={order.id < 0 || saving || !valid}
                  onClick={this.handleOrderCancel}
                  className="btn btn-danger pi-uppercase pi-weight-700 pi-letter-spacing ml-2 mr-2"
              ><i className="icon-floppy pi-icon-left"/>&nbsp;Cancel Order</button> }

              { !isReadOnly && order.fabricRecoverOrderStatus !== FABRIC_RECOVER_ORDER_STATUS_AMEND && <button
                  disabled={saving || !valid || !valid}
                  onClick={this.handleSaveOrder}
                  className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing mr-2"
                  >
                <i className="icon-floppy pi-icon-left"/>&nbsp;Save Order
              </button> }

              { !isReadOnly && order.fabricRecoverOrderStatus !== FABRIC_RECOVER_ORDER_STATUS_AMEND && <button
                  disabled={saving || !valid || !hasValidationRun}
                  onClick={this.toggleAcceptOrder}
                  className="btn btn-success pi-uppercase pi-weight-700 pi-letter-spacing"
              >
                <i className="icon-floppy pi-icon-left"/>&nbsp;Accept
              </button> }

              { !isReadOnly &&
                  (order.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_AMEND) && <button
                  disabled={saving || !valid}
                  onClick={this.toggleAcceptOrder}
                  className="btn btn-success pi-uppercase pi-weight-700 pi-letter-spacing"
              >
                <i className="icon-floppy pi-icon-left"/>&nbsp;Re-Accept
              </button> }


            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-4">
            <SelectControl
                onChange={(v) => {
                  this.setState({selectedSheet: v});
                } }
                clearable={true}
                deleteRemoves={true}
                backspaceRemoves={true}
                name={'selectedSheet'}
                value={this.state.selectedSheet}
                options={buttonList}
                simpleValue={false}
                placeholder={'View order...'}
            />
          </div>
          <div className="col-8">
            <ButtonWrapper
                visible={this.state.selectedSheet!==null}
                onClick={()=> this.setState({isViewSelectedSheet: true, isPrintSelectedSheet: false})}
                className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
                style={{marginRight: '10px'}}>
              <i className="fa fa-binoculars pi-icon-left"/>&nbsp;View {this.state.selectedSheet !== null ? this.state.selectedSheet.label : ''}
            </ButtonWrapper>

            <ButtonWrapper
                visible={this.state.selectedSheet!==null}
                onClick={()=> this.setState({isViewSelectedSheet: false, isPrintSelectedSheet: true})}
                className="btn pi-uppercase pi-weight-700 pi-letter-spacing"
                style={{marginRight: '10px'}}>
              <i className="fa fa-print pi-icon-left"/>&nbsp;Print {this.state.selectedSheet !== null ? this.state.selectedSheet.label : ''}
            </ButtonWrapper>
          </div>
        </div>        
        

        { isReadOnly ? 
            <RecoverViewOrderSection 
                order={order} 
                onToggleEdit={this.toggleAcceptOrder} /> 
            :
            <RecoverEditOrderSection 
              order={order} 
              handleOrderFieldChange={this.handleOrderFieldChange} 
              handleOrderSelectChange={this.handleOrderSelectChange}
              handleSetAluxorAddress={this.handleSetAluxorAddress}
              validationMessages={validationMessages} 
              states={states} /> 
        }

        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {order.awnings.map(awning => {
              return <li key={awning.id} className={`page-item${selectedAwningNumber === awning.number ? ' active' : ''}`}><a
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({selectedAwningNumber: awning.number})
                  }}
                  href="#">Awning #{awning.number}</a></li>
            })}
            {!isReadOnly && <li className={`page-item`}><a
                className="page-link"
                onClick={this.handleNewAwning}
                href="#">New Awning</a></li> }
            {order.awnings.length > 1 && !isReadOnly && <li className={`page-item`}><a
                className="page-link"
                onClick={this.handleRemoveAwning}
                href="#">Remove Awning #{selectedAwningNumber}</a></li>}
          </ul>
        </nav>
        
        
        
        {order.awnings.map(awning => {
              let validationMessages = awningValidation.has(awning.id) ? awningValidation.get(awning.id) : {};
              let selected = awning.number === selectedAwningNumber;
              
              return selected ? isReadOnly ? 
                  (<FabricRecoverAwningViewer key={awning.id} fabricBrands={fabricBrands} baseCosts={baseCosts} awning={awning} />) : 
                  (<FabricRecoverAwningEditor
                  key={awning.id}
                  awning={awning}
                  baseCosts={baseCosts}
                  fabricBrands={fabricBrands}
                  productGroups={productGroups}
                  handleAwningFieldUpdate={this.handleAwningFieldUpdate}
                  projections={projections}
                  valances={valances}
                  validationMessages={validationMessages}
              />) : null;
            }
        )}
        
      </div>
    );
  }
}

FabricRecoverOrderPage.propTypes = {
  user: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
  fabricMakers: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  let orderId = -1;
  if(ownProps.match.params && ownProps.match.params.orderId){
    orderId = parseInt(deobsfucate(ownProps.match.params.orderId));
  }

  return {
    user: state.user,
    orderId,
    states: state.lookups.states,
    fabricMakers: state.lookups.fabricMakers,
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabricRecoverOrderPage));