import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import CustomerPage from './CustomerPage';
import CustomerUsersPage from './CustomerUsersPage';
import CustomerTermsPage from './CustomerTermsPage';
import CustomerPortalServicesPage from './CustomerPortalServicesPage';
import CustomerProductAccessPage from './CustomerProductAccessPage';
import {ROLE_Administrator} from '../../constants';
import SiteSettingsPage from "./SiteSettingsPage";

class ManageCustomersPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    let {user, organisationId, organisation} = this.props;



    return (
      <div>
        <div className="pi-section-w pi-section-base pi-shadow-bottom">
          <div className="pi-section pi-row-lg" style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <div className="pi-row-block">
              <ul className="pi-simple-menu pi-has-hover-border pi-full-height pi-hidden-xs">
                <li>
                  <Select
                    style={{marginTop: '30px', width: '275px'}}
                    labelText="Customer"
                    name="customer"
                    options={this.props.organisations.items}
                    valueKey={'id'}
                    labelKey={'description'}
                    onChange={(val) => {
                      if(val === null){
                        return;
                      }
                      let s = this.props.location.pathname.split('/manage/customers');
                      if(s.length <= 1 || s.length > 2){
                        this.props.history.push(`/manage/customers/${val.id}`);
                      }
                      else if(s.length === 2) {
                        this.props.history.push(`/manage/customers/${val.id}/${s[1]
                          .replace(/\//g, '').replace(/[0-9]/g, '')}`);
                      }
                    }}
                    value={organisationId}
                    clearable={false}
                    backspaceRemoves={false}
                  />
                </li>
                <li>
                  <Link to={`/manage/customers/${organisationId}`}>Customer Profile</Link>
                </li>
                { organisation && organisation.requiresAcceptedTerms && <li><Link to={`/manage/customers/${organisationId}/terms`}>Customer Terms</Link></li> }
                <li><Link to={`/manage/customers/${organisationId}/users`}>User Management</Link></li>
                { user.isInRole(ROLE_Administrator) && <li><Link to={`/manage/customers/${organisationId}/portalservices`}>Portal Services</Link></li> }
                { user.isInRole(ROLE_Administrator) &&<li><Link to={`/manage/customers/${organisationId}/productaccess`}>Product Access</Link></li> }
                { user.isInRole(ROLE_Administrator) &&<li><Link to={`/manage/site`}>Site Settings</Link></li> }
              </ul>
            </div>
          </div>
        </div>

        <div className="pi-section-w pi-section-white">
          <Switch>            
            <Route exact path='/manage/customers' component={CustomerPage} />
            <Route exact path='/manage/customers/:id' component={CustomerPage} />
            <Route exact path='/manage/customers/:id/users' component={CustomerUsersPage} />
            <Route exact path='/manage/customers/:id/terms' component={CustomerTermsPage} />
            <Route exact path='/manage/customers/:id/portalservices' component={CustomerPortalServicesPage} />
            <Route exact path='/manage/customers/:id/productaccess' component={CustomerProductAccessPage} />
            <Route exact path='/manage/site' component={SiteSettingsPage} />
          </Switch>
        </div>

        <div className="pi-section-w pi-section-white" style={{minHeight: '600px'}}>
          <div className="pi-section pi-padding-bottom-10">
            <div className="pi-row pi-grid-big-margins">

            </div>
          </div>
        </div>
      </div>);
  }
}

ManageCustomersPage.propTypes = {
  organisations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  organisation: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  let filteredOrgs = state.organisations.items.filter(x => x.id === organisationId);

  return {
    user: state.user,
    organisationId,
    organisations: state.organisations,
    organisation: filteredOrgs.length === 1 ? filteredOrgs[0] : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageCustomersPage));
