import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import {toastr} from 'react-redux-toastr';
import {loadOrders} from "./orderActions";
import {
  ORDER_STATUS_IN_PRODUCTION,
  ORDER_STATUS_ORDER_REQUESTED,
  orders_list_list_all_organisations_all
} from "../constants";
import initialState from "../reducers/initialState";

export function loadCurrentUserSuccess(user){
  return {type: types.LOAD_USER_SUCCESS, user};
}

export function loadCurrentUser() {
  return function (dispatch) {

    return apiRequest(`/api/users/current`)
      .then(x => {
        if (x.data && x.data.ok == undefined) {
          dispatch(loadCurrentUserSuccess({...x.data}));
        }
      });
  };
}
