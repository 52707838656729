import React from 'react';
import {SummaryDivider} from '../common/SummaryControls';
import TextViewControlPrice from '../common/TextViewControlPrice';
import {
  isNullEmptyOrWhitespace,
  IsNullOrUndefined,
  OrganisationOrderAwningSupplyOwnMotor,
  PRODUCTGROUPID_CONSERVATORY, PRODUCTGROUPID_ROOFING_SYSTEMS
} from "../../constants";

const AwningPriceSummary = (props) => {
  let {awning, productionSheetView, canViewPriceSummary, wholesaleOnly, order} = props;

  let awningFrame = awning.isAwning ? 'Awning' : 'Frame';
  let thisByThat = (x,y) =>{
    return y > 1 ? `${x} x ${y}` : x;
  };

  let cost = awning.costs;

  return ((!productionSheetView && cost &&
    canViewPriceSummary) ?
    (<div className="pi-row" key={`${cost.awningId}_${cost.name}`}>
        <div className="pi-col-sm-12">

          <div className="pi-row" style={{paddingTop: '10px', marginBottom: '0px'}}>
            <div className="pi-col-sm-6"><h6 style={{marginBottom: '0px'}}>Awning {isNullEmptyOrWhitespace(awning.fieldNumber) ? `#${awning.number}` : `#${awning.number} - Field ${awning.fieldNumber}`}</h6></div>
            {awning.number == 1 && <div className="pi-col-sm-3" style={{textAlign: 'right', marginBottom: '0px'}}>
              <h6>{wholesaleOnly ? '' : 'Retail'}</h6></div>}
            {awning.number == 1 && <div className="pi-col-sm-3" style={{textAlign: 'right', marginBottom: '0px'}}>
              <h6>Wholesale {wholesaleOnly ? '' : ``}</h6></div>}
          </div>

          <TextViewControlPrice
            labelText={awningFrame}
            retail={cost.awningFrameRetail}
            wholesale={cost.awningFrameWholesale}
            discount={cost.organisationDiscount}
            wholesaleOnly={wholesaleOnly}
              />

            {cost.awningSurchargeRetail > 0 &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                  labelText={`Awning ${awning.productShortName} surcharge`}
                  retail={cost.awningSurchargeRetail}
                  wholesale={cost.awningSurchargeWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

            {!IsNullOrUndefined(cost.installationBracketOptionCode) &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText={cost.installationBracketOptionDescription}
                retail={cost.installationBracketOptionRetail}
                wholesale={cost.installationBracketOptionWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

          {cost.frameBaseCustomColourRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`Frame custom PC`}
            retail={cost.frameBaseCustomColourRetail}
            wholesale={cost.frameBaseCustomColourWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.frameCustomColourRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`Frame PC surcharge`}
            retail={cost.frameCustomColourRetail}
            wholesale={cost.frameCustomColourWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.pelmetBaseRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={awning.pelmetName}
            retail={cost.pelmetBaseRetail}
            wholesale={cost.pelmetBaseWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.pelmetCustomBaseRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`${awning.pelmetName} custom PC`}
            retail={cost.pelmetCustomBaseRetail}
            wholesale={cost.pelmetCustomBaseWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.pelmetCustomRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`${awning.pelmetName} PC surcharge`}
            retail={cost.pelmetCustomRetail}
            wholesale={cost.pelmetCustomWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.flashingBaseRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Flashing"
            retail={cost.flashingBaseRetail}
            wholesale={cost.flashingBaseWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.flashingCustomBaseRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Flashing custom PC"
            retail={cost.flashingCustomBaseRetail}
            wholesale={cost.flashingCustomBaseWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.flashingCustomRetail && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Flashing PC surcharge"
            retail={cost.flashingCustomRetail}
            wholesale={cost.flashingCustomWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.fabricSurchargeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Fabric surcharge"
            retail={cost.fabricSurchargeRetail}
            wholesale={cost.fabricSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}
          {cost.fabricFittingRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Fabric fitting"
            retail={cost.fabricFittingRetail}
            wholesale={cost.fabricFittingWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.isMotorRequired && cost.motorRetail == null && OrganisationOrderAwningSupplyOwnMotor(order, awning)!==true && awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor"
            value='Please call Aluxor'
            wholesaleOnly={wholesaleOnly}
          />}

          {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ?
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor"
            value={IsNullOrUndefined(awning.motorId) ? (IsNullOrUndefined(awning.frameFeature) ? 'TBD' : 'Multi Field') : cost.motorRetail === 0 ? 'Included' : cost.motorRetail}
            wholesaleOnly={wholesaleOnly}
            wholesale={cost.motorWholesale}
            retail={cost.motorRetail > 0 ? cost.motorRetail : undefined}
          /> :
          cost.motorRetail > 0 ?
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor"
            retail={cost.motorRetail}
            wholesale={cost.motorWholesale}
            wholesaleOnly={wholesaleOnly}
          /> : null}

          {cost.motorFittingRetail &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor fitting"
            retail={cost.motorFittingRetail}
            wholesale={cost.motorFittingWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.mountingBracketSurcharge > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Mount bkt surcharge"
            retail={cost.mountingBracketSurcharge}
            wholesale={cost.mountingBracketSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
                  />}

        {cost.installationBracketSurchargeRetail > 0 &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Installation surcharge"
                retail={cost.installationBracketSurchargeRetail}
                wholesale={cost.installationBracketSurchargeWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

          {cost.installationBracketsTotal > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Installation brackets"
            retail={cost.installationBracketsTotal}
            wholesale={cost.installationBracketsTotalWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.frameFeatureAwningFrameRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={awning.frameFeatureDescription}
            retail={cost.frameFeatureAwningFrameRetail}
            wholesale={cost.frameFeatureAwningFrameWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.frameFeatureFabricSurchargeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`${awning.frameFeatureDescription} fabric surcharge`}
            retail={cost.frameFeatureFabricSurchargeRetail}
            wholesale={cost.frameFeatureFabricSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.joinerRetail > 0 && <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Joiner surcharge"
            retail={cost.joinerRetail}
            wholesale={cost.joinerWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.motorJoinerSurchargeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor joiner surcharge"
            retail={cost.motorJoinerSurchargeRetail}
            wholesale={cost.motorJoinerSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.stabiliserPolesRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Stabilier poles"
            retail={cost.stabiliserPolesRetail}
            wholesale={cost.stabiliserPolesWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.cradlesTotal > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Extra cradles"
            retail={cost.cradlesTotal}
            wholesale={cost.cradlesTotalWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.crankHandleRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`Crank handle${awning.includeAdditionalCrankHandle ? 's' : ''}`}
            retail={cost.crankHandleRetail}
            wholesale={cost.crankHandleWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.frameFeatureCrankHandleRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText={`${awning.frameFeatureDescription} crank`}
            retail={cost.frameFeatureCrankHandleRetail}
            wholesale={cost.frameFeatureCrankHandleWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.multiRemoteSurchargeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Remote surcharge"
            retail={cost.multiRemoteSurchargeRetail}
            wholesale={cost.multiRemoteSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.mocoTypeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Automation accessory"
            retail={cost.mocoTypeRetail}
            wholesale={cost.mocoTypeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.ledLightingRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="LED lighting"
            retail={cost.ledLightingRetail}
            wholesale={cost.ledLightingWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

          {cost.motorSurchargeRetail > 0 &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Motor surcharge"
            retail={cost.motorSurchargeRetail}
            wholesale={cost.motorSurchargeWholesale}
            wholesaleOnly={wholesaleOnly}
          />}

            {cost.motorSurchargeSaRetail > 0 &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Motor surcharge SA RC"
                retail={cost.motorSurchargeSaRetail}
                wholesale={cost.motorSurchargeSaWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

          {awning.automationCableId &&
          <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Automation cable"
            retail={cost.autoCableRetail}
            wholesale={cost.autoCableWholesale}
            wholesaleOnly={wholesaleOnly}
                  />}

        {awning.includeRainCover === true &&
            <TextViewControlPrice
            discount={cost.organisationDiscount}
            labelText="Rain Cover"
            retail={cost.rainCoverRetail}
            wholesale={cost.rainCoverWholesale}
            wholesaleOnly={wholesaleOnly}
            />}

            {awning.additionalImRollerCount > 0 &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Additional IM Rollers"
                retail={cost.imRollersRetail}
                wholesale={cost.imRollersWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

            {awning.includeWedges &&
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Wedges"
                retail={cost.wedgesRetail}
                wholesale={cost.wedgesWholesale}
                wholesaleOnly={wholesaleOnly}
            />}

            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Quattro"
                retail={cost.quattroRetail}
                wholesale={cost.quattroWholesale}
                wholesaleOnly={wholesaleOnly}
            />            
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Sintesi Free Standing"
                retail={cost.sintesiFreeStandingRetail}
                wholesale={cost.sintesiFreeStandingWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="LED On Wind Break"
                retail={cost.ledOnWindBreakRetail}
                wholesale={cost.ledOnWindBreakWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Sintesi Infill Panel"
                retail={cost.sintesiInfillPanelMaterialRetail}
                wholesale={cost.sintesiInfillPanelMaterialWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Sintesi Downlights"
                retail={cost.sintesiDownlightsRetail}
                wholesale={cost.sintesiDownlightsWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Gutter Credit"
                retail={cost.gutterCreditRetail}
                wholesale={cost.gutterCreditWholesale}
                wholesaleOnly={wholesaleOnly}
            />

            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Additional Back Posts"
                retail={cost.additionalBackPostRetail}
                wholesale={cost.additionalBackPostWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Additional Side Posts"
                retail={cost.additionalSidePostRetail}
                wholesale={cost.additionalSidePostWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Additional Front Posts"
                retail={cost.additionalFrontPostRetail}
                wholesale={cost.additionalFrontPostWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Post Lights"
                retail={cost.sintesiPostLightsRetail}
                wholesale={cost.sintesiPostLightsWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Audio Set"
                retail={cost.sintesiAudioSetRetail}
                wholesale={cost.sintesiAudioSetWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Heaters"
                retail={cost.sintesiHeatersRetail}
                wholesale={cost.sintesiHeatersWholesale}
                wholesaleOnly={wholesaleOnly}
            />
            <TextViewControlPrice
                discount={cost.organisationDiscount}
                labelText="Heater Extension"
                retail={cost.sintesiHeaterExtRetail}
                wholesale={cost.sintesiHeaterExtWholesale}
                wholesaleOnly={wholesaleOnly}
            />

          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Sintesi Post Credit"
              retail={cost.sintesiPostCreditRetail}
              wholesale={cost.sintesiPostCreditWholesale}
              wholesaleOnly={wholesaleOnly}
          />
          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Windbreak PC Surcharge"
              retail={cost.sintesiWindbreakPcSurchargeRetail}
              wholesale={cost.sintesiWindbreakPcSurchargeWholesale}
              wholesaleOnly={wholesaleOnly}
          />
          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Front Post Surcharge"
              retail={cost.sintesiFrontPostSurchargeRetail}
              wholesale={cost.sintesiFrontPostSurchargeWholesale}
              wholesaleOnly={wholesaleOnly}
          />
          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Back Post Surcharge"
              retail={cost.sintesiBackPostSurchargeRetail}
              wholesale={cost.sintesiBackPostSurchargeWholesale}
              wholesaleOnly={wholesaleOnly}
          />
          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Windbreak PC surcharge"
              retail={cost.sintesiInstallationSurchargeRetail}
              wholesale={cost.sintesiInstallationSurchargeWholesale}
              wholesaleOnly={wholesaleOnly}
          />
          <TextViewControlPrice
              discount={cost.organisationDiscount}
              labelText="Bracket Surcharge"
              retail={cost.bracketSurchargeRetail}
              wholesale={cost.bracketSurchargeWholesale}
              wholesaleOnly={wholesaleOnly}
          />

          <b>
            <TextViewControlPrice
              labelText={`Sub-Total`}
              retail={cost.totalRetail}
              wholesale={cost.totalWholesale}
              discount={cost.organisationDiscount}
              wholesaleOnly={wholesaleOnly}
            />
          </b>

        </div>
      </div>)
    : (!productionSheetView && canViewPriceSummary &&
    <div className="pi-row">
      <div className="pi-col-sm-12">
        <SummaryDivider labelText="Aluxor to confirm price"/>
      </div>
    </div>));
};

export default AwningPriceSummary;