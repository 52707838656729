import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function resourceCartReducer(state = initialState.resourceCart, action) {
    switch (action.type) {
        case types.RESOURCECART_ADD: {
            let existing = state.items.findIndex(x => x.fileName === action.item.fileName) >= 0;
            if(existing){
                return state;
            }
            let newState = {...state, items: [...state.items, {...action.item}]};
            return newState;
        }
        case types.RESOURCECART_REMOVE: {
            let newState = {...state, items: [...state.items.filter(x => x.fileName !== action.item.fileName)]};
            return newState;
        }
        case types.RESOURCECART_CLEAR: {
            return initialState.resourceCart;
        }
        case types.RESOURCECART_OPENCART: {
            return {...state, isOpen: true};
        }
        case types.RESOURCECART_CLOSECART: {
            return {...state, isOpen: false};
        }
        default:
            return state;
    }
}
