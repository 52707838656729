import React, {Component, useRef} from 'react';
import PropTypes, {any} from 'prop-types';
import SelectControl from '../common/SelectControl';
import TextViewControl from '../common/TextViewControl';
import TextViewControlPrice from '../common/TextViewControlPrice';
import TextBoxControl from '../common/TextBoxControl';
import AwningSummaryView from './AwningSummaryView';
import {apiDownloadReport, apiPost, apiRequest} from "../../utils/request";
import { connect } from 'react-redux';
import moment from 'moment';
import {
  DATE_SHORT_FORMAT,
  orders_list_list_all_organisations_all,
  view_order_accept_order_order_requested,
  view_order_editable_dispatch_method_in_production,
  view_order_editable_dispatch_method_order_requested,
  view_order_editable_dispatch_method_production_complete,
  view_order_editable_fabric_detail_in_production,
  view_order_editable_fabric_detail_order_requested,
  view_order_editable_joiner_in_production,
  view_order_editable_joiner_order_requested,
  view_order_editable_motor_in_production,
  view_order_editable_motor_order_requested,
  view_order_editable_order_and_awning_note_all,
  view_order_editable_powder_coater_detail_in_production,
  view_order_editable_powder_coater_detail_order_requested,
  view_order_save_order_all,
  view_order_select_delay_resolved_order_delayed,
  view_order_select_delayed_in_production,
  view_order_select_frieght_action_production_complete,
  view_order_select_link_to_clipboard_all,
  view_order_select_print_order_all,
  view_order_select_production_complete_in_production,
  view_order_select_request_amendment_in_production,
  view_order_select_request_amendment_order_delayed,
  view_order_select_request_amendment_order_requested,
  view_order_show_price_information_all,
  view_order_view_fabric_sheets_in_production,
  view_order_view_pc_sheets_in_production,
  view_order_view_production_sheet_in_production,
  edit_order_view_screen_amendment_requested,
  view_order_view_invoice_sheet_in_production,
  view_order_view_invoice_sheet_product_dispatched,
  view_order_view_invoice_sheet_production_complete,
  view_order_view_dispatch_sheet_product_dispatched,
  view_order_view_flashing_sheet_in_production,
  view_order_select_link_to_login_all,
  view_order_cancel_order_amendment_requested,
  ORDER_STATUS_ORDER_CANCELLED,
  view_order_view_motor_sheet_in_production,
  view_order_view_abs_sheet_all,
  deobsfucate,
  obsfucate,
  PRODUCTGROUPID_FOLDING_ARM,
  Aux,
  IsNullOrUndefined,
  PRODUCTGROUPID_CONSERVATORY,
  REPORTSERVER_BASEURL,
  INVOICE_TYPE_ORDERAWNING, PAYMENT_METHOD_CC, IsRoofSystem, GetOrderPriceMessage
} from '../../constants';
import {toastr} from 'react-redux-toastr';
import {some} from 'lodash';
import { ORDER_STATUS_QUOTE, ORDER_STATUS_ORDER_REQUESTED, ORDER_STATUS_IN_PRODUCTION,
  ORDER_STATUS_ORDER_DELAYED,
  ORDER_STATUS_ORDER_PRODUCTION_COMPLETE, ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED, MoneyFormatter,
  ORDER_STATUS_ORDER_PRODUCTDISPATCHED, DISPATCH_COLLECT, DISPATCH_LOCAL_FREIGHT, DISPATCH_INTERSTATE_FREIGHT,
  COURIER_OTHER, GUEST_ROLE,
  view_order_view_order_status_changes_all,
  view_order_submit_order_quote, view_order_submit_order_amendment_requested,
  view_order_select_edit_quote_quote, view_order_select_edit_quote_amendment_requested,
  isNullEmptyOrWhitespace
} from '../../constants';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { withRouter } from 'react-router';
import OrderStatusChanges from './OrderStatusChanges';
import CustomerDetailsView from './CustomerDetailsView';
import {SummaryDivider} from '../common/SummaryControls';
import OrderPowderCoatingSheet from './OrderPowderCoatingSheet';
import OrderProductionSheet from './OrderProductionSheet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {validationCreator} from './AwningValidators';
import OrderFabricSheet from '../fabric/OrderFabricSheet';
import AwningPriceSummary from './AwningPriceSummary';
import OrderDispatchSheet from './OrderDispatchSheet';
import OrderFlashingSheet from './OrderFlashingSheet';
import OrderInvoiceSheet from './OrderInvoiceSheet';
import OrderMotorSheet from './OrderMotorSheet';
import jQuery from 'jquery';
import {logoutLogin} from "../../actions/identityActions";
import querySearch from "stringquery";
import ReactJson from 'react-json-view';
import MotorSelectionSheet from "../motor/MotorSelectionSheet";
import OrderDiagramsSheet from "../awningdiagrams/OrderDiagramsSheet";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import {Base64} from "js-base64";
import SpareSummaryView from "./SpareSummaryView";
import CustomerInformation from "./customer/CustomerInformation";
import CustomerOrderSummary from "./customer/CustomerOrderSummary";
import CustomerOrderPrintSummary from "./customer/CustomerOrderPrintSummary";

const RequiresSequenceNumber = (order)=> {
  if (order.orderStatus == ORDER_STATUS_ORDER_REQUESTED && order.sequenceNumber == null) {
    return 'Please enter a PN';
  }
  return null;
};

const RequiresCommitmentDate = (order)=> {
  /*if (order.orderStatus == ORDER_STATUS_ORDER_REQUESTED && order.commitmentDate == null) {
    return 'Please enter a commitment date';
  }*/
  return null;
};

const RequiresDispatch = (order)=> {
  if (order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.dispatchTypeId == null) {
    return 'Please select a dispatch method';
  }
  return null;
};

let RequiresFabricMaker = (order)=> {
  if (order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.requiresFabricMaker && order.fabricMakerId == null) {
    return 'Please select a fabric maker';
  }
  return null;
};

let RequiresFabricMakerRecDate = (order)=> {
  if (order.orderStatus === ORDER_STATUS_IN_PRODUCTION && order.requiresFabricMaker && order.fabricRecDate == null) {
    return 'Please specify a received fabric date';
  }
  return null;
};

let RequiresPowderCoater = (order) =>{  
  if(order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.requiresPowderCoater && order.powderCoaterId == null){
    return 'Please select a powder coater';
  }
  return null;
};

let RequiresPowderCoaterRecDate = (order)=> {
  if (order.orderStatus === ORDER_STATUS_IN_PRODUCTION && order.requiresPowderCoater && order.pcRecDate == null) {
    return 'Please specify a received from powder-coater date';
  }
  return null;
};

const RequiresFreightAmount = (order)=> {
  if (order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.requiresFreightAmount && isNullEmptyOrWhitespace(order.freightAmount)) {
    return 'Please enter a freight amount';
  }
  return null;
};

const RequiresMiddleArmSide = (order)=> {
  if (order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.awnings.findIndex(x => x.productGroupId === PRODUCTGROUPID_FOLDING_ARM && x.numberOfArms===3 && (x.middleArmSide <= 0 || IsNullOrUndefined(x.middleArmSide))) >= 0) {
    return 'Please make a middle arm side selection';
  }
  return null;
};

const CellLeft = (props) => {
  return <div style={{textAlign: 'left'}}>{props.children}</div>;
};

const CellRight = (props) => {
  return <div style={{textAlign: 'right'}}>{props.children}</div>;
};

const CellCenter = (props) => {
  return <div style={{textAlign: 'center'}}>{props.children}</div>;
};

const ValidationRules = {
  dispatchTypeId: {
    validator: (val, state) => {
      let message = RequiresDispatch(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  commitmentDate: {
    validator: (val, state) => {
      let message = RequiresCommitmentDate(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  sequenceNumber: {
    validator: (val, state) => {
      let message = RequiresSequenceNumber(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  fabricMakerId: {
    validator: (val, state) => {
      let message = RequiresFabricMaker(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  powderCoaterId: {
    validator: (val, state) => {
      let message = RequiresPowderCoater(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  fabricRecDate: {
    validator: (val, state) => {
      let message = RequiresFabricMakerRecDate(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  pcRecDate: {
    validator: (val, state) => {
      let message = RequiresPowderCoaterRecDate(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
  freightAmount: {
    validator: (val, state) => {
      let message = RequiresFreightAmount(state.order);
      return (val == null) && message ? validationCreator(false, message) : validationCreator();
    }
  },
}

//import html2canvas from 'html2canvas';

const productionSheetUrl = '/orders/view/:orderId/productionSheet';
const summaryViewUrl = '/orders/view/:orderId';

export const ButtonWrapper = (props) => {
  let propsToUse = {...props, visible: undefined};
  return (props.visible || props.visible == undefined) ?
    <button {...propsToUse}>{props.children}</button> : null;
};

const myobResponse = {
  missing: [],
  quote: null,
  created: null,
};

class OrderSummaryPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: {
        customerReference: '',
        awnings: []
      },
      submitting: false,
      loaded: !props.orderId || props.orderId <= 0,
      validationMessages: {},
      requireReason: false,
      statusReason: '',
      desiredStatus: 0,
      couriers: [],
      requiresCourierSelection: false,
      orderStatusChangeReasons: [],
      statusChangeReasonIds: [],
      colorBondColours: [],
      isViewSelectedSheet: false,
      isPrintSelectedSheet: false,
      selectedSheet: null,
      myobOrderDetails: {...myobResponse},
      originalOrder: null,
      isRequestingAcceptance: false,      
      quoteCustomer: null,
      quoteshowPrice: false,
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleEditQuote = this.handleEditQuote.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleAcceptOrder = this.handleAcceptOrder.bind(this);
    this.progressOrder = this.progressOrder.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.awningChanged = this.awningChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getValidationMessages = this.getValidationMessages.bind(this);
    this.loadOrder = this.loadOrder.bind(this);
    this.handleStatusChangeWithReason = this.handleStatusChangeWithReason.bind(this);
    this.handleOnProdComplete = this.handleOnProdComplete.bind(this);
    this.handleFreightOrReadyProduct = this.handleFreightOrReadyProduct.bind(this);
    this.fetchCouriers = this.fetchCouriers.bind(this);
    this.fetchOrderStatusReasons = this.fetchOrderStatusReasons.bind(this);
    this.handlFieldChange = this.handlFieldChange.bind(this);
    this.isValidOrder = this.isValidOrder.bind(this);
    this.fetchColorBondColours = this.fetchColorBondColours.bind(this);
    this.selectedSheetIsOpen = this.selectedSheetIsOpen.bind(this);
    this.selectedSheetIndex = this.selectedSheetIndex.bind(this);
    this.selectedSheetToggleOpen = this.selectedSheetToggleOpen.bind(this);
    this.selectedSheetIsPrint = this.selectedSheetIsPrint.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleSendMyob = this.handleSendMyob.bind(this);
    this.motorSelectionSheetRef = React.createRef();
    this.mainPrint = React.createRef();
    this.customerDetails = React.createRef();
    this.headerSection = React.createRef();
    this.openAbsSheet = this.openAbsSheet.bind(this);
    this.examplePrintRef = React.createRef();
    this.priceSectionRef = React.createRef();
    this.customerInfoRef = React.createRef();
    this.handlePrintCustomerQuote = this.handlePrintCustomerQuote.bind(this);
  }

  handleSendMyob(e, load = false) {
    let {order} = this.state;
    let send = true;
    
    if (order.hasMyobQuote) {
      send = false;
      //eslint-disable-next-line
      if (confirm(`This order has already been sent to MYOB are you sure you want to send again?`)) {
        send = true;
      }
    }
    if (send) {
      this.setState({myobOrderDetails: {...myobResponse}}, () => {
        apiPost(`/api/myoborders/createsalesquote?orderId=${this.state.order.id}`, {})
            .then(x => {
              if (x.data && x.data.success == true) {
                toastr.success('Order submitted to MYOB');
                this.setState({
                  myobOrderDetails: {...x.data}
                }, () => {
                  this.loadOrder();
                });
              } else if (x.data) {
                this.setState({myobOrderDetails: {...x.data}}, () => this.loadOrder());
                toastr.error(`An error occurred while sending to MYOB - message: ${x.data.message}`);
              }
            });
      });
    } else if(load) {
      this.loadOrder();
    }
  }

  handleBack(){
    this.props.history.goBack();
  }

  handleEditQuote(e){
    if (e) {
      e.preventDefault();
    }
    let {order} = this.state;
    this.props.history.push(`/${order.isSparesOrder ? 'spares' : 'orders'}/edit/${obsfucate(order.id)}`);
  }

  handleSubmitOrder(e, accepted = false) {
    if (e) {
      e.preventDefault();
    }

    if (!accepted) {
      this.setState({isRequestingAcceptance: true});
    } else {
      this.setState({submitting: true, isRequestingAcceptance: false, myobOrderDetails: {...myobResponse}}, () => {
        apiPost(`/api/orders/${this.state.order.id}/submit`, {rowVersion: this.state.order.rowVersion})
            .then(x => {
              if (x.error) {
                this.setState({submitting: false});
                x.data.json().then(a => toastr.error(a.message));
              } else if (x.data && x.data.ok) {
                toastr.success('Order submitted');
                this.loadOrder();
              } else {
                this.setState({submitting: false});
                toastr.error('Error while submitting');
                console.log(x);
              }
            });
      });
    }
  }

  handleAcceptOrder(e) {
    if (e) {
      e.preventDefault();
    }
    if(this.motorSelectionSheetRef.current && !this.motorSelectionSheetRef.current.isValid()){
      toastr.error('Please make a Motor Options selection');
      return;
    }

    this.setState({submitting: true, myobOrderDetails: {...myobResponse}}, () => {
      apiPost(`/api/orders/${this.state.order.id}/accept`, this.state.order)
        .then(x => {
          if (x.error) {
            this.setState({submitting: false});
            x.data.json().then(a => toastr.error(a.message));
          } else if (x.data && x.data.ok) {
            toastr.success('Order accepted');
            toastr.info('Sending to MYOB');
            this.setState({
                loaded: false,
                requireReason: false,
                statusReason: '',
                statusChangeReasonIds: [],
                requiresCourierSelection: false,
                submitting: false,
              },
              () => this.handleSendMyob(null,true));
          } else {
            this.setState({submitting: false});
            toastr.error('Error while accepting');
            console.log(x);
          }
        });
    });
  }

  handleCancelOrder(e) {
    if (e) {
      e.preventDefault();
    }
    //eslint-disable-next-line
    if (confirm('Are you sure you want to cancel?')) {
      this.setState({submitting: true, myobOrderDetails: {...myobResponse}}, () => {
        apiPost(`/api/orders/${this.state.order.id}/cancel`, {rowVersion: this.state.order.rowVersion})
          .then(x => {
            if (x.error) {
              this.setState({submitting: false});
              x.data.json().then(a => toastr.error(a.message));
            } else if (x.data && x.data.ok) {
              toastr.success('Order cancelled');
              this.loadOrder();
            } else {
              this.setState({submitting: false});
              toastr.error('Error while cancelling');
              console.log(x);
            }
          });
      });
    }
  }

  handlePrint(e, withPriceSummary = true) {
    if (e) {
      e.preventDefault();
    }

    let container = jQuery(`<div></div>`);

    if(!withPriceSummary){
      jQuery(this.priceSectionRef.current).css('display', 'none');  
    }
    
    
    jQuery(this.headerSection.current).clone().css('display', 'block').appendTo(container);
    jQuery(this.customerDetails.current).clone().appendTo(container);
    jQuery(this.mainPrint.current).clone().appendTo(container);

    container
      .css('font-size', '18px')
      .css('padding-right', '20px')
      .css('font-family', "'Open Sans', Arial, sans-serif'")
      .css('line-height', "1.6em");

    container.find("div[class*='SpareSummaryView-divTable']").css('font-size', '16px');
    container.find("div[class*='SpareSummaryView-divThead']").css('font-size', '18px');
    
    container.find('h4').css('font-size', '18px');
    //container.find('.logo').css('width', '200px');
    container.find('h5').css('font-size', '16px');
    container.find('h6').css('font-size', '14px');
    container.find('.Select').each((i,el) => {
      jQuery(el)
        .parent()
        .removeClass('form-group')
        .addClass('pi-row')
        .html(`<div class="pi-col-sm-4">${jQuery(el).siblings('label').text()}</div><div class="pi-col-sm-8">${jQuery(el).find('.Select-value-label').text()}</div>`);
    });
    container.find('textarea:empty').parent().parent().empty();
    container.find('.awning-summary-diagram').empty();
    container.find('.awning-summary-attachments').empty();

    //this.examplePrintRef.current.innerHTML = container[0].outerHTML;

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    if(!withPriceSummary){
      jQuery(this.priceSectionRef.current).css('display', 'block');
    }    
    
    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${this.state.order.id}.pdf`);
  }

  async handlePrintCustomerQuote(e, mode='print') {
    if (e) {
      e.preventDefault();
    }

    let container = jQuery(`<div></div>`);
    let {order, quoteCustomer} = this.state;

    //jQuery(this.headerSection.current).clone().css('display', 'block').appendTo(container);
    //jQuery(this.customerDetails.current).clone().appendTo(container);
    jQuery(this.mainPrint.current).clone().css('display', 'block').appendTo(container);

    container
        .css('font-size', '18px')
        .css('padding-right', '20px')
        .css('font-family', "'Open Sans', Arial, sans-serif'")
        .css('line-height', "1.6em");

    container.find('h4').css('font-size', '18px');
    //container.find('.logo').css('width', '200px');
    container.find('h5').css('font-size', '16px');
    container.find('h6').css('font-size', '14px');
    container.find('.Select').each((i,el) => {
      jQuery(el)
          .parent()
          .removeClass('form-group')
          .addClass('pi-row')
          .html(`<div class="pi-col-sm-4">${jQuery(el).siblings('label').text()}</div><div class="pi-col-sm-8">${jQuery(el).find('.Select-value-label').text()}</div>`);
    });
    container.find('textarea:empty').parent().parent().empty();
    container.find('.awning-summary-diagram').empty();
    container.find('.awning-summary-attachments').empty();
    container.find('.btn').remove();

    //this.examplePrintRef.current.innerHTML = container[0].outerHTML;

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };
    
    let fileName = `Quote_${order.id}.pdf`;
    
    if(mode==='print') {
      await apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName);
    } else {
      let _this = this;
      await apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName, blob => {

        let reader = new FileReader();
        reader.addEventListener("load", function () {
          toastr.info('Sending email...');
          apiPost(`/api/customers/${order.id}/email/quote`, {
            content: reader.result,
            fileName: fileName,
            customerName: quoteCustomer.name,            
          })
              .then(y => {
                if (y.data && y.data.ok) {
                  toastr.success('Email sent');
                } else {
                  toastr.error('Error while sending email');
                  console.log(y);
                }
              });
        }, false);
        reader.readAsDataURL(blob);
      });
    }
  }

  progressOrder(status, navigate = true, onComplete) {
    let {order, originalOrder} = this.state;
    order.orderStatus = status;

    if(onComplete && JSON.stringify(order) === JSON.stringify(originalOrder)){
      onComplete();
      return;
    }

    this.setState({submitting: true, myobOrderDetails: {...myobResponse}}, () => {
      apiPost(`/api/orders/${this.state.order.id}`, order)
        .then(x => {
          if (x.error) {
            this.setState({submitting: false});
            x.data.json().then(a => toastr.error(a.message));
          } else {
            toastr.success('Order saved');
            if (navigate) {
              this.props.history.push('/orders/list');
            } else {
              this.setState({order: {...x.data}, submitting: false}, () => {
                if(onComplete){
                  onComplete();
                }
              });
            }
          }
        });
    });
  }

  awningChanged(awning) {
    let awnings = this.state.order.awnings.map(x => {
      let ret = {...x};
      if (x.id == awning.id) {
        return {...awning};
      }
      return ret;
    });
    this.setState({order: {...this.state.order, awnings}});
  }

  handleStatusChangeWithReason(e, withNotify=true) {
    if (e) {
      e.preventDefault();
    }
    let {desiredStatus, statusReason, statusChangeReasonIds, order} = this.state;

    if (desiredStatus > 0) {
      let request = {
        orderStatus: desiredStatus,
        reason: statusReason,
        reasonIds: statusChangeReasonIds,
        rowVersion: order.rowVersion
      };
      this.setState({submitting: true, myobOrderDetails: {...myobResponse}}, () => {
        apiPost(`/api/orders/${this.state.order.id}/setstatus?notify=${withNotify}`, request)
          .then(x => {
            if (x.error) {
              this.setState({submitting: false});
              x.data.json().then(a => toastr.error(a.message));
            } else if (x.data && x.data.ok) {
              toastr.success('Order status updated');
              if (desiredStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED &&
                this.props.user.hasAccessTag(edit_order_view_screen_amendment_requested)) {
                this.props.history.push(`/${this.state.order.isSparesOrder ? 'spares' : 'orders'}/edit/${obsfucate(this.state.order.id)}`);
              } else if (desiredStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED) {
                toastr.success('Successfully requested amendment, Aluxor will progress this change');
                this.loadOrder();
              } else {
                this.loadOrder();
              }
            } else {
              toastr.success('Error while submitting');
              console.log(x);
            }
          });
      });
    }
  }

  loadOrder(orderId = null, softload = false){
    let loadId = this.props.orderId;
    if(orderId!==null){
      loadId = orderId;
    }
    if(!softload){
      this.setState({loaded: false, requireReason: false,
        statusReason: '', statusChangeReasonIds: [], requiresCourierSelection: false, submitting: false, originalOrder: null});  
    }
    if(loadId > 0){
      apiRequest (`/api/orders?id=${loadId}`)
        .then(x => {
          if(!x.error && x.data && x.data.length === 1) {
            let order = {...x.data[0]};
            //console.log('ORDER',x.data);
            this.setState({order, loaded: true, originalOrder: {...order}}, this.isValidOrder);
          } else {
            toastr.error('Error while loading');
          }
        });
    }
  }

  handleOnProdComplete(e) {
    if (e) {
      e.preventDefault();
      }
      //eslint-disable-next-line
    if (confirm('Is the order really complete?')) {
      this.setState({desiredStatus: ORDER_STATUS_ORDER_PRODUCTION_COMPLETE}, this.handleStatusChangeWithReason);
    }
  }

  handleFreightOrReadyProduct(e, withNotify= true) {
    
    if (e) {
      e.preventDefault();
    }
    
    if(!withNotify) {
      this.setState({desiredStatus: ORDER_STATUS_ORDER_PRODUCTDISPATCHED, statusReason: ''}, () => this.handleStatusChangeWithReason(e, false));
    } else if (!this.state.requiresCourierSelection && this.state.order.dispatchTypeId !== DISPATCH_COLLECT) {
      this.setState({requiresCourierSelection: true, order: {...this.state.order, courierDispatchDate: moment()}});
    } else if (this.state.requiresCourierSelection && this.state.order.dispatchTypeId !== DISPATCH_COLLECT) {
      let {id, courierId, courierOther, courierConsignmentReference,
        courierDispatchDate, orderNote, dispatchTypeId, dispatchNote, rowVersion, courierNoBoxes} = this.state.order;

      if(!courierId || !courierConsignmentReference || !courierConsignmentReference || !courierDispatchDate || !dispatchTypeId || courierNoBoxes <= 0){
        toastr.warning('Please enter all dispatch details');
        return;
      }
      this.setState({submitting: true, myobOrderDetails: {...myobResponse}}, () => {
          apiPost(`/api/orders/${id}/shipit`, {
            courierId,
            courierOther,
            courierConsignmentReference,
            courierDispatchDate,
            orderNote,
            dispatchTypeId,
            dispatchNote,
            rowVersion,
            courierNoBoxes,
          }).then(x => {
            if (x.error) {
              this.setState({submitting: false});
              x.data.json().then(a => toastr.error(a.message));
            } else if (x.data && x.data.ok) {
              toastr.success('Order shipped');
              this.loadOrder();
            } else {
              this.setState({submitting: false});
              toastr.success('Error while submitting');
              console.log(x);
            }
          });
      });
    } else {
      this.setState({desiredStatus: ORDER_STATUS_ORDER_PRODUCTDISPATCHED, statusReason: ''}, this.handleStatusChangeWithReason);
    }
  }

  componentWillMount(){
    this.loadOrder();
    this.fetchColorBondColours();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.selectedSheet && !this.state.selectedSheet){
      this.setState({selectedSheet: {...this.props.selectedSheet}});

      setTimeout(() => {
        this.setState({isViewSelectedSheet: false, isPrintSelectedSheet: true});
      }, 1000);
    }
    this.fetchCouriers(prevState);
    this.fetchOrderStatusReasons(prevState);
  }
  
  componentWillReceiveProps(nextProps) {
    if (this.state.order.id > 0 && nextProps.orderId != this.state.order.id && nextProps.orderId > 0) {
      this.loadOrder(nextProps.orderId);
      return;
    }

    /*if (nextProps.user.organisation.id != this.state.order.organisationId) {
      this.setState({order: {...this.state.order, organisationId: nextProps.user.organisation.id}});
    }*/
  }

  getValidationMessages(fieldName, val){
    let validationMessages = {...this.state.validationMessages};
    let validation = ValidationRules[`${fieldName}`];
    if (validation) {
      let validationResult = validation.validator(val, this.state);
      if (!validationResult.isValid) {
        validationMessages[`${fieldName}`] = validationResult.message;
      } else {
        validationMessages[`${fieldName}`] = '';
      }
    }
    return validationMessages;
  }

  isValidOrder(){
    let {order} = this.state;

    if(!this.props.user.organisation.id ||
        (!this.props.user.hasAccessTag(orders_list_list_all_organisations_all) && this.props.user.organisation.id != order.organisationId)) {
      return;
    }

    let messages = {};
    let isValid = true;

    for (let propName in ValidationRules) {

      let rule = ValidationRules[propName];
      let val = order[propName];

      let valResult = rule.validator(val, this.state);      
      if (!valResult.isValid) {
        messages[propName] = valResult.message;
        isValid = false;
      }
    }
    this.setState({validationMessages: messages});

    for(let i = 0; i<order.awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId)).length;i++){
      if(this.refs[`awning_${order.awnings[i].id}`]) {
        this.refs[`awning_${order.awnings[i].id}`].isValidAwning();
      }
    }
  }

  handleSelectChange(fieldName, val, valSelector, action = null) {
    let x = {...this.state.order};
    let newState = {...this.state};
    let fieldValue =  valSelector(val);
    x[`${fieldName}`] = fieldValue;

    if(fieldName){
      newState[`${fieldName}`] = val;
    }

    newState.order = x;

    if(action){
      newState = action(val, newState);
    }

    this.setState(newState, this.isValidOrder);
  }

  handlFieldChange(e, action = null) {
    let newState = {...this.state};
    let x = {...this.state.order};

    if (e.target.type == "checkbox") {
      x[`${e.target.name}`] = e.target.checked;
    } else {
      x[`${e.target.name}`] = e.target.value;
    }

    newState.order = x;

    if(action){
      newState = action(x[`${e.target.name}`], newState);
    }
    this.setState(newState, this.isValidOrder);
  }

  fetchCouriers(prevState) {
    let {dispatchTypeId} = this.state.order;

    if (prevState && prevState.order.dispatchTypeId == dispatchTypeId) {
      return;
    }

    if (dispatchTypeId) {
      apiRequest(`/api/lookups/couriers?dispatchTypeId=${dispatchTypeId}`)
        .then(x => {
          if (!x.error) {
            this.setState({couriers: x.data, requiresCourierSelection: false});
          }
        });
    } else {
      this.setState({
        couriers: [],
        requiresCourierSelection: false,
        order: {...this.state.order, courierId: null, courierConsignmentReference: null, courierDispatchDate: null, courierOther: null, courierNoBoxes: null}
      });
    }
  }

  fetchOrderStatusReasons(prevState) {
    let {requireReason, desiredStatus} = this.state;

    if (prevState && prevState.requireReason == requireReason && prevState.desiredStatus == desiredStatus) {
      return;
    }

    if (!requireReason) {
      this.setState({orderStatusChangeReasons: []});
    } else {
      apiRequest(`/api/lookups/statusreasons?status=${desiredStatus}`)
        .then(x => {
          if (!x.error) {
            this.setState({orderStatusChangeReasons: x.data});
          }
        });
    }
  }

  fetchColorBondColours() {
    apiRequest(`/api/colours/colorbond`)
      .then(x => {
        if (!x.error) {
          this.setState({colorBondColours: x.data});
        }
      });
  }

  selectedSheetToggleOpen(resetSheet = false){
    if(this.state.isViewSelectedSheet){
      this.setState({isViewSelectedSheet: false, selectedSheet: resetSheet ? null : this.state.selectedSheet});
    } else if(this.state.isPrintSelectedSheet){
      this.setState({isPrintSelectedSheet: false});
    }
  }

  selectedSheetIndex(sheetName) {
    return (this.state.selectedSheet && (this.state.isViewSelectedSheet || this.state.isPrintSelectedSheet) && this.state.selectedSheet.value.sheetName == sheetName) ?
      this.state.selectedSheet.value.index : -1;
  }

  selectedSheetIsOpen(sheetName) {
    return (this.state.selectedSheet && (this.state.isViewSelectedSheet || this.state.isPrintSelectedSheet) && this.state.selectedSheet.value.sheetName == sheetName) ? true : false;
  }

  selectedSheetIsPrint(sheetName){
    return (this.state.selectedSheet && this.state.isPrintSelectedSheet && this.state.selectedSheet.value.sheetName == sheetName) ? true : false;
  }

  openAbsSheet(){
    this.setState({
      selectedSheet: {value: {sheetName: 'abs'}, label: 'Order Diagrams'},
      //isViewSelectedSheet: true, isPrintSelectedSheet: false,
    }, ()=>{
      this.setState({isViewSelectedSheet: true, isPrintSelectedSheet: false})
    });
  }

  render() {
    let {order, validationMessages} = this.state;
    let {user, siteSettings} = this.props;

    let canViewScreen = user.hasAccessTag(orders_list_list_all_organisations_all) || (user.organisation.id  &&  user.organisation.id == order.organisationId)
      || user.userName == 'Guest'; //TODO: This is temp

    if(!canViewScreen && this.state.loaded){
      return <div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              You don't have permission to view this order
            </h4>
          </div>
        </div>
      </div>;
    }


    let requiresDispatch = RequiresDispatch(order);
    let requiresFabricMaker = RequiresFabricMaker(order) || RequiresFabricMakerRecDate(order);
    let requiresPowderCoater = RequiresPowderCoater(order) || RequiresPowderCoaterRecDate(order);
    let requiresSequenceNumber = RequiresSequenceNumber(order);
    let requiresCommitmentDate = RequiresCommitmentDate(order);
    let requiresFreightAmount = RequiresFreightAmount(order);

    let canEditFabric = (user.hasAccessTag(view_order_editable_fabric_detail_in_production) && order.orderStatus == ORDER_STATUS_IN_PRODUCTION) ||
      (user.hasAccessTag(view_order_editable_fabric_detail_order_requested) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED);

    let canEditPowderCoater = (user.hasAccessTag(view_order_editable_powder_coater_detail_in_production) && order.orderStatus == ORDER_STATUS_IN_PRODUCTION) ||
      (user.hasAccessTag(view_order_editable_powder_coater_detail_order_requested) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED);

    let canEnterInvoiceNumber = (user.hasAccessTag(view_order_editable_powder_coater_detail_in_production) || 
        user.hasAccessTag(view_order_editable_powder_coater_detail_in_production)) && (
            order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || 
            order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE);

    let canEditDispatch = (user.hasAccessTag(view_order_editable_dispatch_method_in_production) && order.orderStatus == ORDER_STATUS_IN_PRODUCTION) ||
      (user.hasAccessTag(view_order_editable_dispatch_method_order_requested) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED) ||
      (user.hasAccessTag(view_order_editable_dispatch_method_production_complete) && order.orderStatus == ORDER_STATUS_ORDER_PRODUCTION_COMPLETE);

    let canEditMotor = (user.hasAccessTag(view_order_editable_motor_in_production) && order.orderStatus == ORDER_STATUS_IN_PRODUCTION) ||
      (user.hasAccessTag(view_order_editable_motor_order_requested) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED);

    let canEditJoiner = (user.hasAccessTag(view_order_editable_joiner_in_production) && order.orderStatus == ORDER_STATUS_IN_PRODUCTION) ||
      (user.hasAccessTag(view_order_editable_joiner_order_requested) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED);


    let requiresMotorSelection = (()=>{
      if(canEditMotor && this.motorSelectionSheetRef.current && !this.motorSelectionSheetRef.current.isValid()){
        return 'A motor selection must be made for all awnings, please select Motor Options';
      }
      return null;
    })();

    let requiresSectionSelection = (()=>{
      if(canEditJoiner && some(order.awnings, a=> a.sections == null && !a.isAssembled) && !order.isSparesOrder){
        return 'A sections selection must be made for all awnings';
      }
      if(canEditJoiner && some(order.awnings, a=> a.sections < 2 && a.width > 7000) && !order.isSparesOrder){
        return 'Please select a valid joiner and section combination';
      }
      return null;
    })();

    let reasonForStatus = (placeHolder) => {
      return (<form className=" pi-search-form-wide" role="form" style={{marginTop: '25px'}}>
        <SelectControl
          labelText="Reasons"
          name="statusChangeReasonIds"
          options={this.state.orderStatusChangeReasons}
          onChange={(val) => {
            this.setState({statusChangeReasonIds: val.split(',').map(x => parseInt(x))});
          }}
          value={this.state.statusChangeReasonIds}
          placeholder="Select Reasons"
          multi={true}
          simpleValue
          style={{borderRadius: '3px 0 0 3px', borderRightWidth: 0, width:'350px'}}
        />

        <TextBoxControl labelText={'Additional/Other reason'}
                        onChange={(e) => this.setState({statusReason: e.target.value})}
                        value={this.state.statusReason}
                        name={'statusReason'} />

        {/*<div className="pi-input-inline" style={{marginRight: '-4px; width: 430px'}}>
          <input type="text" className="form-control pi-input-wide"
                 onChange={(e) => this.setState({statusReason: e.target.value})}
                 placeholder={'Other reason for status change?'} style={{borderRadius: '3px 0 0 3px', borderRightWidth: 0, width:'350px'}}/>
        </div>*/}
        {(this.state.statusReason.trim() != '' || this.state.statusChangeReasonIds != '') &&
        <ButtonWrapper className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleStatusChangeWithReason}
                style={{borderRadius: '0px'}}>
          <i className="fa fa-check"/> Save status</ButtonWrapper>}
        <ButtonWrapper className="btn pi-btn pi-btn-icon-big"
                style={{borderRadius: '0px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px'}}
                onClick={() => this.setState({requireReason: false, statusReason: '', statusChangeReasonIds: []})}
        >
          <i className="fa fa-times"/></ButtonWrapper>
      </form>);
    };

    let buttons = ((order, user)=> {
if(order.orderStatus === undefined){
  return null;
}
      let canPc = (user.hasAccessTag(view_order_view_pc_sheets_in_production) && (order.orderStatus === ORDER_STATUS_IN_PRODUCTION || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE));
      let canFabric = (user.hasAccessTag(view_order_view_fabric_sheets_in_production) && (order.orderStatus === ORDER_STATUS_IN_PRODUCTION || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE));
//      let canMotor = (user.hasAccessTag(view_order_view_motor_sheet_in_production) && (order.orderStatus === ORDER_STATUS_IN_PRODUCTION || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE));
      let canProductionSheet = order.awnings.length > 0 && !order.isSparesOrder &&  (user.hasAccessTag(view_order_view_production_sheet_in_production) && (order.orderStatus === ORDER_STATUS_IN_PRODUCTION || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE));
      let canInvoiceSheet = (user.hasAccessTag(view_order_view_invoice_sheet_in_production) && order.orderStatus === ORDER_STATUS_IN_PRODUCTION) ||
        (user.hasAccessTag(view_order_view_invoice_sheet_product_dispatched) && order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED) ||
        (user.hasAccessTag(view_order_view_invoice_sheet_production_complete) && order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE);
      let canDispatchSheet = (user.hasAccessTag(view_order_view_dispatch_sheet_product_dispatched)
                                && (order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE || order.orderStatus === ORDER_STATUS_IN_PRODUCTION)) ;
      let canFlashingSheet = (user.hasAccessTag(view_order_view_flashing_sheet_in_production)
        && (order.orderStatus === ORDER_STATUS_IN_PRODUCTION || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE))
        && order.awnings.filter(a => a.flashingType > 0).length > 0;

      let canSubmit = (user.hasAccessTag(view_order_submit_order_quote) && order.orderStatus === ORDER_STATUS_QUOTE) ||
        (user.hasAccessTag(view_order_submit_order_amendment_requested) && order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED);

      let canAccept = (!requiresMotorSelection &&
                        !requiresSectionSelection &&
                        !requiresDispatch &&
                        !requiresPowderCoater &&
                        !requiresFabricMaker &&
                        !requiresCommitmentDate &&
                        !requiresFreightAmount &&
                        !RequiresMiddleArmSide(order) &&
                        !requiresSequenceNumber) &&
                        user.hasAccessTag(view_order_accept_order_order_requested) &&
                        order.orderStatus === ORDER_STATUS_ORDER_REQUESTED;

      let canEdit = (user.hasAccessTag(view_order_select_edit_quote_quote) && order.orderStatus === ORDER_STATUS_QUOTE) ||
        (user.hasAccessTag(view_order_select_edit_quote_amendment_requested) && order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED);

      let canRequestAmendment = (user.hasAccessTag(view_order_select_request_amendment_in_production) && order.orderStatus === ORDER_STATUS_IN_PRODUCTION) ||
        (user.hasAccessTag(view_order_select_request_amendment_order_delayed) && order.orderStatus === ORDER_STATUS_ORDER_DELAYED) ||
        (user.hasAccessTag(view_order_select_request_amendment_order_requested) && order.orderStatus === ORDER_STATUS_ORDER_REQUESTED);

      let canProductionComplete = (
        !requiresPowderCoater &&
        !requiresFabricMaker) &&
          (user.hasAccessTag(view_order_select_production_complete_in_production) && order.orderStatus === ORDER_STATUS_IN_PRODUCTION);
      let canDelay = (user.hasAccessTag(view_order_select_delayed_in_production) && order.orderStatus === ORDER_STATUS_IN_PRODUCTION);
      let canResolve = (user.hasAccessTag(view_order_select_delay_resolved_order_delayed) && order.orderStatus === ORDER_STATUS_ORDER_DELAYED);
      let canLogin = user.hasAccessTag(view_order_select_link_to_login_all);
      let canCancel = user.hasAccessTag(view_order_cancel_order_amendment_requested) && order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED;
      let canSendToMyob = user.hasAccessTag(view_order_accept_order_order_requested) &&
              (order.orderStatus === ORDER_STATUS_IN_PRODUCTION
            || order.orderStatus === ORDER_STATUS_ORDER_DELAYED            
            || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE 
            || order.orderStatus === ORDER_STATUS_ORDER_PRODUCTDISPATCHED 
            || order.orderStatus === ORDER_STATUS_ORDER_CANCELLED);
      
      //console.log(canProductionComplete, requiresPowderCoater, requiresFabricMaker, user.hasAccessTag(view_order_select_production_complete_in_production),  order.orderStatus == ORDER_STATUS_IN_PRODUCTION, ORDER_STATUS_IN_PRODUCTION, order.orderStatus);
      //console.log(order,user.hasAccessTag(view_order_cancel_order_amendment_requested), view_order_cancel_order_amendment_requested, ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED, order.orderStatus, order.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED);

      let text = '';
      if (order.dispatchTypeId === DISPATCH_LOCAL_FREIGHT || order.dispatchTypeId === DISPATCH_INTERSTATE_FREIGHT || order.dispatchTypeId === DISPATCH_COLLECT) {
        text = 'Dispatched';
      }

      let buttonList = [];
      if(canProductionSheet){
        buttonList.push({value: { sheetName: 'production'}, label: 'Production Sheet'});
      }
      if(canInvoiceSheet){
        buttonList.push({value: { sheetName: 'invoice'}, label: 'Invoice'});
      }
      if(canDispatchSheet){
        buttonList.push({value: { sheetName: 'dispatch'}, label: 'Dispatch Sheet'});
      }
      if(canFlashingSheet){
        buttonList.push({value: { sheetName: 'flashing'}, label: 'Flashing Sheet'});
      }
      if(order && order.fabricSheets && canFabric){
        for(let i=0; i< order.fabricSheets.length; i++){
          buttonList.push({value: { sheetName: 'fabric', index: i}, label: `Fabric Sheet #${i+1}`});
        }
      }
      if(order && order.awningPcSections && canPc){
        for(let i=0; i< order.awningPcSections.length; i++){
          buttonList.push({value: { sheetName: 'pc', index: i}, label: `Powder Coating Sheet #${i+1}`});
        }
      }
      if(order && order.sparePcSections && canPc){
        for(let i=0; i< order.sparePcSections.length; i++){
          buttonList.push({value: { sheetName: 'pc', index: i}, label: `Powder Coating Sheet #${i+1}`});
        }
      }
      /*if(order && order.motorSheets && canMotor){
        for(let i=0; i< order.motorSheets.length; i++){
          buttonList.push({value: { sheetName: 'motor', index: i}, label: `Motor Sheet ${order.motorSheets[i].motorBrand}`});
        }
      }*/

      if(user.hasAccessTag(view_order_view_abs_sheet_all) && some(order.awnings, x => x.productGroupId === PRODUCTGROUPID_FOLDING_ARM || x.productGroupId === PRODUCTGROUPID_CONSERVATORY)) {
        buttonList.push({value: {sheetName: 'abs'}, label: 'Order Diagrams'});
      }      
      
      return (<div>
        
        <div ref={this.examplePrintRef}></div>
        
        <Modal isOpen={this.state.isRequestingAcceptance}
               toggle={() => {this.setState({isRequestingAcceptance: !this.state.isRequestingAcceptance})}}
        >
          <ModalBody>

              <div className="pi-row" style={{marginBottom: '15px'}}>
                <div className="pi-col-sm-12">
                  <h2 style={{textAlign: 'center'}}>Terms & Conditions</h2>
                </div>
              </div>
            <div className="pi-row" style={{marginBottom: '15px'}}>
              <div className="pi-col-sm-12" style={{textAlign: 'center'}}>
                <p>Please confirm that you accept the Terms and Conditions</p>
                <p>These can be viewed <a href='/terms/Aluxor-Awnings-Terms-and-Conditions-of-Sale.pdf' target='_blank'>here</a></p>
              </div>
            </div>

          </ModalBody>
          <ModalFooter>
            <button onClick={(e) => this.handleSubmitOrder(e, true)}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-check" />&nbsp;I Accept</button>
            <button onClick={(e) => {
              e.preventDefault();
              this.setState({isRequestingAcceptance: false});
            }}
                    className="btn pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-times" />&nbsp;I don't accept</button>
          </ModalFooter>
        </Modal>

        <div className="pi-row" style={{paddingTop: '10px', paddingBottom: '10px'}}>

        <div className="pi-col-md-8">
          <ButtonWrapper
            visible={canSubmit}
            onClick={this.handleSubmitOrder}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="icon-floppy pi-icon-left"/>&nbsp;Submit Order
          </ButtonWrapper>
          <ButtonWrapper
            visible={canAccept}
            onClick={this.handleAcceptOrder}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="icon-floppy pi-icon-left"/>&nbsp;Accept Order
          </ButtonWrapper>
          <ButtonWrapper
            visible={canEdit}
            onClick={this.handleEditQuote}
            className="btn pi-btn pi-uppercase pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="fa fa-binoculars"/>&nbsp;Edit Quote
          </ButtonWrapper>
          <ButtonWrapper
              visible={canEdit}
              onClick={(e) => {
                if (e) {
                  e.preventDefault();
                }
                this.progressOrder(order.orderStatus, true);
              }}
              style={{marginRight: '10px'}}
              className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-save"/>&nbsp;Save Quote
          </ButtonWrapper>

          <ButtonWrapper
            visible={canProductionComplete}
            onClick={this.handleOnProdComplete}
            style={{marginRight: '10px'}}
            className="btn pi-btn-base pi-uppercase pi-letter-spacing">
            <i className="fa fa-check"/>&nbsp;Prod. Complete
          </ButtonWrapper>

          <ButtonWrapper
            visible={canCancel}
            onClick={this.handleCancelOrder}
            style={{marginRight: '10px'}}
            className="btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-ban"/>&nbsp;Cancel Order
          </ButtonWrapper>

          <ButtonWrapper
            visible={!requiresFreightAmount && !requiresSequenceNumber && !requiresCommitmentDate && !requiresMotorSelection && !requiresSectionSelection && !requiresDispatch && !requiresPowderCoater && !requiresFabricMaker &&
            order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE && user.hasAccessTag(view_order_select_frieght_action_production_complete)}
            onClick={this.handleFreightOrReadyProduct}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="fa fa-shipping-fast"/>&nbsp;{text}
          </ButtonWrapper>

          <ButtonWrapper
              visible={!requiresFreightAmount && !requiresSequenceNumber && !requiresCommitmentDate && !requiresMotorSelection && !requiresSectionSelection && !requiresDispatch && !requiresPowderCoater && !requiresFabricMaker &&
                  order.orderStatus === ORDER_STATUS_ORDER_PRODUCTION_COMPLETE && user.hasAccessTag(view_order_select_frieght_action_production_complete)}
              onClick={(e) => this.handleFreightOrReadyProduct(e, false)}
              className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
              style={{marginRight: '10px'}}>
            <i className="fa fa-shipping-fast"/>&nbsp;{`${text} w/out Notify`}
          </ButtonWrapper>

          <ButtonWrapper
            visible={canRequestAmendment}
            onClick={() => this.setState({requireReason: true, desiredStatus: ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED})}
            style={{marginRight: '10px'}}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-edit"/>&nbsp;Cancel/Request Amendment
          </ButtonWrapper>

          <ButtonWrapper
            visible={canDelay}
            onClick={() => this.setState({requireReason: true, desiredStatus: ORDER_STATUS_ORDER_DELAYED})}
            style={{marginRight: '10px'}}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-clock"/>&nbsp;Order Delayed
          </ButtonWrapper>

          <ButtonWrapper
            visible={canResolve}
            onClick={() => this.setState({requireReason: true, desiredStatus: ORDER_STATUS_IN_PRODUCTION})}
            style={{marginRight: '10px'}}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-clock"/>&nbsp;Delay Resolved
          </ButtonWrapper>

          <ButtonWrapper
            visible={canSendToMyob}
            onClick={this.handleSendMyob}
            style={{marginRight: '10px'}}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-cloud-upload-alt"/>&nbsp;Send to MYOB
          </ButtonWrapper>

          <ButtonWrapper
            visible={canEditMotor && (order.requiresMotorSelection || some(order.awnings, x => x.isMotorRequired))}
            onClick={(e) => {
              this.progressOrder(order.orderStatus, false, () => {
                this.setState({
                  selectedSheet: {value: {sheetName: 'mss'}, label: 'Motor Selection Sheet'},
                  isViewSelectedSheet: true, isPrintSelectedSheet: false
                });
              });
            }}
            style={{marginRight: '10px'}}
            className="btn pi-btn pi-uppercase pi-letter-spacing">
            <i className="fa fa-cogs"/>&nbsp;Motor Options
          </ButtonWrapper>


          {this.state.requireReason && reasonForStatus()}

          {this.state.requiresCourierSelection &&
          <form className=" pi-search-form-wide" role="form" style={{marginTop: '25px'}}>
            <SelectControl
              labelText="Courier"
              name="courierId"
              options={this.state.couriers}
              onChange={(val) => this.handleSelectChange('courierId', val, (v) => v.value)}
              value={order.courierId}
              placeholder="Select Courier"
              validationMessages={this.state.validationMessages}
              style={{borderRadius: '3px 0 0 3px', borderRightWidth: 0, width: '350px'}}
            />

            {order.courierId == COURIER_OTHER && <TextBoxControl
              onChange={this.handlFieldChange}
              labelText="Courier other"
              value={order.courierOther}
              name="courierOther"
              validationMessages={this.state.validationMessages}
            />}

            <TextBoxControl
              onChange={this.handlFieldChange}
              labelText="Consignment Ref"
              value={order.courierConsignmentReference}
              name="courierConsignmentReference"
              validationMessages={this.state.validationMessages}
            />

            <TextBoxControl
                onChange={this.handlFieldChange}
                labelText="No. Boxes"
                value={order.courierNoBoxes}
                name="courierNoBoxes"
                validationMessages={this.state.validationMessages}
                type={"number"}
            />

            <DatePicker
              onChange={(d) => {
                this.setState({order: {...this.state.order, courierDispatchDate: d ? d.format() : null}});
              }}
              labelText="Dispatch date"
              selected={order.courierDispatchDate ? moment(order.courierDispatchDate) : moment()}
              name="courierDispatchDate"
              validationMessages={this.state.validationMessages}
              dateFormat={DATE_SHORT_FORMAT}
              minDate={moment()}
              onChangeRaw={(e)=> {
                e.target.value = '';
              }}
            />

            {this.state.order.courierId &&
            <ButtonWrapper className="btn pi-btn-base pi-btn-icon-big"
                           onClick={this.handleFreightOrReadyProduct}
                           style={{borderRadius: '0px', marginTop: '10px'}}>
              <i className="fa fa-check"/></ButtonWrapper>}
            <ButtonWrapper className="btn pi-btn pi-btn-icon-big"
                           style={{
                             borderRadius: '0px',
                             borderTopRightRadius: '3px',
                             borderBottomRightRadius: '3px',
                             marginTop: '10px'
                           }}
                           onClick={() =>
                             this.setState({
                               requiresCourierSelection: false, order:
                                 {
                                   ...this.state.order,
                                   courierId: null,
                                   courierDispatchDate: moment().format(DATE_SHORT_FORMAT)
                                 }
                             })}
            >
              <i className="fa fa-times"/></ButtonWrapper>
          </form>
          }
        </div>
          <div className="pi-col-md-4" style={{textAlign: 'right'}}>

            <ButtonWrapper                
                onClick={(e) => {
                  if (e) {
                    e.preventDefault();
                  }
                  this.loadOrder();
                }}
                style={{marginRight: '10px'}}
                className="btn pi-btn pi-uppercase pi-letter-spacing">
              <i className="fa fa-rotate-right"/>&nbsp;
            </ButtonWrapper>
            
            <ButtonWrapper
              visible={user.hasAccessTag(view_order_save_order_all)}
              onClick={(e) => {
                if (e) {
                  e.preventDefault();
                }
                this.progressOrder(order.orderStatus, false);
              }}
              style={{marginRight: '10px'}}
              className="btn pi-btn pi-uppercase pi-letter-spacing">
              <i className="fa fa-save"/>&nbsp;
            </ButtonWrapper>
            {order.isSparesOrder ?
                <ButtonWrapper
                    onClick={(e) => this.handlePrint(e, true)}
                    visible={user.hasAccessTag(view_order_select_print_order_all)}
                    style={{marginRight: '10px'}}
                    className="btn pi-btn pi-uppercase pi-letter-spacing"                    
                >
                  <i className="fa fa-print"/>&nbsp;
                </ButtonWrapper> 
                :
                <Aux>
                  <ButtonWrapper
                      onClick={(e) => this.handlePrint(e, false)}
                      visible={user.hasAccessTag(view_order_select_print_order_all)}
                      style={{marginRight: '10px'}}
                      className="btn pi-btn pi-uppercase pi-letter-spacing"
                      data-toggle="tooltip" title={"Print with-out Price Summary"}
                  >
                    <i className="fa fa-print"/>&nbsp;&nbsp;<i className="fa fa-dollar"
                                                               style={{position: 'relative'}}><i className="fa fa-ban"
                                                                                                 style={{
                                                                                                   position: 'absolute',
                                                                                                   top: '-8px',
                                                                                                   left: '-8px',
                                                                                                   fontSize: '200%',
                                                                                                   color: 'rgba(217, 83, 79, 0.7)'
                                                                                                 }}/></i>&nbsp;
                  </ButtonWrapper>
                  <ButtonWrapper
                      onClick={(e) => this.handlePrint(e, true)}
                      visible={user.hasAccessTag(view_order_select_print_order_all)}
                      style={{marginRight: '10px'}}
                      className="btn pi-btn pi-uppercase pi-letter-spacing"
                      data-toggle="tooltip" title={"Print with Price Summary"}
                  >
                    <i className="fa fa-print"/>&nbsp;&nbsp;<i className="fa fa-dollar"/>
                  </ButtonWrapper>
                </Aux>
            }
                
          {user.hasAccessTag(view_order_select_link_to_clipboard_all) &&
          <CopyToClipboard text={order.urlSpaPath}
                           onCopy={() => toastr.success('Copied order URL to clipboard')}>
            <ButtonWrapper
              style={{marginRight: '10px'}}
              className="btn pi-btn pi-uppercase pi-letter-spacing">
              <i className="fa fa-link"/>&nbsp;
            </ButtonWrapper>
          </CopyToClipboard>}

          <ButtonWrapper
            visible={canLogin}
            onClick={(e) => {
              if (e) {
                e.preventDefault();
              }
              this.props.dispatch(logoutLogin());
            }}
            className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
            style={{marginRight: '10px'}}>
            <i className="icon-floppy pi-icon-left"/>&nbsp;Login
          </ButtonWrapper>

          </div>
        </div>

        { requiresMotorSelection && <div className="pi-row" style={{paddingTop: '10px', paddingBottom: '10px'}}>
          <div className="pi-col-md-12">
            <h4 style={{color: 'red'}}>{requiresMotorSelection}</h4>
          </div>
        </div> }

        <div className="pi-row" style={{paddingTop: '10px', paddingBottom: '10px'}}>

          <div className="pi-col-md-4">
            <SelectControl
              onChange={(v) => {
                this.setState({selectedSheet: v});
              } }
              clearable={true}
              deleteRemoves={true}
              backspaceRemoves={true}
              name={'selectedSheet'}
              value={this.state.selectedSheet}
              options={buttonList}
              simpleValue={false}
              placeholder={'View order...'}
            />
          </div>
          <div className="pi-col-md-8">
            <ButtonWrapper
              visible={this.state.selectedSheet!==null}
              onClick={()=> this.setState({isViewSelectedSheet: true, isPrintSelectedSheet: false})}
              className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
              style={{marginRight: '10px'}}>
              <i className="fa fa-binoculars pi-icon-left"/>&nbsp;View {this.state.selectedSheet !== null ? this.state.selectedSheet.label : ''}
            </ButtonWrapper>

            <ButtonWrapper
              visible={this.state.selectedSheet!==null}
              onClick={()=> this.setState({isViewSelectedSheet: false, isPrintSelectedSheet: true})}
              className="btn pi-uppercase pi-weight-700 pi-letter-spacing"
              style={{marginRight: '10px'}}>
              <i className="fa fa-print pi-icon-left"/>&nbsp;Print {this.state.selectedSheet !== null ? this.state.selectedSheet.label : ''}
            </ButtonWrapper>
          </div>
        </div>
      </div>);

    })(order, user);

    let headerText = ((order)=> {
      if (order.orderStatus == ORDER_STATUS_QUOTE) {
        return `Preview Order Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_REQUESTED) {
        return `Order Requested Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_IN_PRODUCTION) {
        return `Order In Production Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_DELAYED) {
        return `Order Delayed Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_PRODUCTION_COMPLETE) {
        return `Order Production Complete Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_PRODUCTDISPATCHED) {
        return `Order Dispatched Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED) {
        return `Amendment Requested Ref: ${order.customerReference}`;
      } else if (order.orderStatus == ORDER_STATUS_ORDER_CANCELLED) {
        return `Order Cancelled Ref: ${order.customerReference}`;
      }

    })(this.state.order);

    let anyNonRoofing = order.awnings.findIndex(x => !IsRoofSystem(x)) >= 0;
    let anyRoofing = order.awnings.findIndex(x => IsRoofSystem(x)) >= 0;
    
    let showCustomerInformationView = order.organisationEnableCustomerInformation &&
        order.orderStatus === ORDER_STATUS_QUOTE;    
    
    return this.state.loaded ?
        showCustomerInformationView ?
            (<Aux>

              <Modal isOpen={this.state.isRequestingAcceptance}
                     toggle={() => {this.setState({isRequestingAcceptance: !this.state.isRequestingAcceptance})}}
              >
                <ModalBody>

                  <div className="pi-row" style={{marginBottom: '15px'}}>
                    <div className="pi-col-sm-12">
                      <h2 style={{textAlign: 'center'}}>Terms & Conditions</h2>
                    </div>
                  </div>
                  <div className="pi-row" style={{marginBottom: '15px'}}>
                    <div className="pi-col-sm-12" style={{textAlign: 'center'}}>
                      <p>Please confirm that you accept the Terms and Conditions</p>
                      <p>These can be viewed <a href='/terms/Aluxor-Awnings-Terms-and-Conditions-of-Sale.pdf' target='_blank'>here</a></p>
                    </div>
                  </div>

                </ModalBody>
                <ModalFooter>
                  <button onClick={(e) => this.handleSubmitOrder(e, true)}
                          className="btn pi-btn-base pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-check" />&nbsp;I Accept</button>
                  <button onClick={(e) => {
                    e.preventDefault();
                    this.setState({isRequestingAcceptance: false});
                  }}
                          className="btn pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-times" />&nbsp;I don't accept</button>
                </ModalFooter>
              </Modal>

              {/*<div className="pi-row" style={{marginBottom: '15px', marginTop: '25px', display: 'none'}} ref={this.headerSection}>
                <div className="pi-col-sm-12">
                  <h4>
                    <img src="img/logo-full.png" width={300} alt="Aluxor Logo" />
                    <span style={{marginLeft: "100px", fontSize: "30px", fontWeight: "bold"}}>Preliminary Quote</span>
                  </h4>
                </div>
              </div>*/}
              
              
              <div className={"row mb-5 mt-4"}>
                <div className={"col-12"}>
                  <ButtonWrapper
                      onClick={this.handleEditQuote}
                      className="btn pi-btn pi-uppercase pi-letter-spacing mr-4">
                    <i className="fa fa-keyboard-o"/>&nbsp;Edit Quote
                  </ButtonWrapper>
                  { !IsNullOrUndefined(this.state.quoteCustomer) && 
                  <Aux>                      
                    <ButtonWrapper
                      onClick={()=> {
                        this.setState({quoteCustomer: null}, this.customerInfoRef.current.handleEditClient);                        
                      }}
                      className="btn pi-btn pi-uppercase pi-letter-spacing mr-4">
                    <i className="fa fa-home"/>&nbsp;Edit Customer
                    </ButtonWrapper>
                    { !this.state.quoteCustomer.anonymousEmail && this.state.showPrice && <ButtonWrapper
                        onClick={(e) => {
                          this.handlePrintCustomerQuote(e, 'email');
                        }}
                        className="btn pi-btn pi-uppercase pi-letter-spacing mr-4">
                      <i className="fa fa-send"/>&nbsp;Email Customer
                    </ButtonWrapper> }
                    { this.state.showPrice && <ButtonWrapper
                        onClick={this.handlePrintCustomerQuote}
                        className="btn pi-btn pi-uppercase pi-letter-spacing mr-4">
                      <i className="fa fa-print"/>&nbsp;Print
                    </ButtonWrapper> }
                    { !this.state.showPrice ? <ButtonWrapper
                        onClick={()=> {
                          this.setState({showPrice: true});
                        }}
                        className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing pull-right">
                      <i className="icon-check pi-icon-left"/>&nbsp;Prepare Quote
                    </ButtonWrapper> :
                    <ButtonWrapper
                        onClick={this.handleSubmitOrder}
                        className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing pull-right">
                      <i className="icon-floppy pi-icon-left"/>&nbsp;Submit Order
                    </ButtonWrapper> }
                  </Aux>
                  }
                </div>
              </div>
              <div ref={this.customerDetails}>
                <CustomerInformation                    
                    parentRef={this.customerInfoRef}
                    states={this.props.states} 
                    orderId={order.id} 
                    onCustomerReady={(savedCustomer)=> {
                      this.setState({quoteCustomer: {...savedCustomer}});
                    } } />
              </div>
              <div ref={this.mainPrint} style={{display: 'none'}}>
                <CustomerOrderPrintSummary
                    order={order}
                    quoteCustomer={this.state.quoteCustomer}
                    quoteAddons={this.props.quoteAddons}
                    onReloadOrder={() => this.loadOrder(order.id, true)}
                />
              </div>  
                <CustomerOrderSummary
                    order={order} 
                    quoteAddons={this.props.quoteAddons}
                    onReloadOrder={() => this.loadOrder(order.id, true)}
                    showPrice={this.state.showPrice}
                />
              
              </Aux>)
        :(<div className="pi-section" style={{paddingLeft: '0px'}}>

        <OrderPowderCoatingSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          pcSectionIndex={ this.selectedSheetIndex('pc') }
          isOpen={ this.selectedSheetIsOpen('pc') }
          print={this.selectedSheetIsPrint('pc')}
          showEmail={true}
          orderType={1}
        />

        <OrderProductionSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          isOpen={this.selectedSheetIsOpen('production')}
          print={this.selectedSheetIsPrint('production')}
        />

        <OrderFabricSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          fabricSheetIndex={this.selectedSheetIndex('fabric')}
          isOpen={this.selectedSheetIsOpen('fabric')}
          print={this.selectedSheetIsPrint('fabric')}
          showEmail={true}
          showSequenceNumber={IsNullOrUndefined(user.organisation.fabricMakerShowSequenceNumber) || user.organisation.fabricMakerShowSequenceNumber}
          orderType={INVOICE_TYPE_ORDERAWNING}
        />

        <OrderDispatchSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          isOpen={this.selectedSheetIsOpen('dispatch')}
          print={this.selectedSheetIsPrint('dispatch')}
        />

        <OrderFlashingSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          isOpen={this.selectedSheetIsOpen('flashing')}
          colorBondColours={this.state.colorBondColours}
          print={this.selectedSheetIsPrint('flashing')}
        />

        <OrderInvoiceSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          isOpen={this.selectedSheetIsOpen('invoice')}
          print={this.selectedSheetIsPrint('invoice')}
          invoiceType={INVOICE_TYPE_ORDERAWNING}
          siteSettings={siteSettings}
        />

        <OrderMotorSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          motorSheetIndex={this.selectedSheetIndex('motor')}
          isOpen={this.selectedSheetIsOpen('motor')}
          print={this.selectedSheetIsPrint('motor')}
        />

        <OrderDiagramsSheet
          order={order}
          toggleOpen={this.selectedSheetToggleOpen}
          isOpen={this.selectedSheetIsOpen('abs')}
          print={this.selectedSheetIsPrint('abs')}
        />

        <MotorSelectionSheet
                              ref={this.motorSelectionSheetRef}
                              order={order}
                             toggleOpen={() => {
                               this.selectedSheetToggleOpen(true);
                             }}
                             isOpen={this.selectedSheetIsOpen('mss')}
                              onSave={(order) => this.setState({order: {...order}, originalOrder: {...order}})}
        />

        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
               <a href={''} onClick={(e) => {
                e.preventDefault();
                this.props.history.goBack();
              }}><i className={'fa fa-arrow-left'} />&nbsp;</a>{headerText}
            </h4>
          </div>
        </div>

        {!this.state.submitting && buttons}
        {this.state.submitting &&
          <div className="pi-row">
            <div className="pi-col-md-8">
              <h4 className="pi-has-bg pi-margin-bottom-20">
                Processing... <i className={'fa fa-spinner fa-spin'} />
              </h4>
            </div>
          </div>}

        <div className="pi-row" style={{marginBottom: '15px', display: 'none'}}>
          <div className="pi-col-sm-6">
            <h4><img src="img/logo-full.png" width={300} alt="Aluxor Logo"/></h4>
          </div>
          <div className="pi-col-sm-6">
            <div className={"pi-row"}>
              <div className="pi-col-sm-12">
                  <h2>Customer Quote</h2>
              </div>
            </div>
          </div>
          {/*<div className="pi-col-sm-4">
            <h4 style={{float:'right', paddingTop: '5px'}}>ALX Ref #{order.id}</h4>
          </div>*/}
        </div>

        <div className="pi-row" style={{marginBottom: '15px', marginTop: '25px', display: 'none'}} ref={this.headerSection}>
          <div className="pi-col-sm-12">
            <h4>
              <img src="img/logo-full.png" width={300} alt="Aluxor Logo" />
              <span style={{marginLeft: "100px", fontSize: "30px", fontWeight: "bold"}}>Customer Quote</span>
            </h4>
          </div>          
        </div>

        {this.state.myobOrderDetails.missing && this.state.myobOrderDetails.missing.length > 0 &&
        <div className="pi-row" style={{marginTop: '10px'}}>
          <div className="pi-col-md-12">
            <h4>The following are missing MYOB codes, these must be resolved to send to MYOB</h4>
            <ReactJson src={this.state.myobOrderDetails.missing}
                       displayObjectSize={true}
                       displayDataTypes={false}
                       name={'Missing MYOB codes'}
            />
          </div>
        </div> }

        {this.state.myobOrderDetails.created &&
        <div className="pi-row" style={{marginTop: '10px'}}>
          <div className="pi-col-md-12">
            <h4>The following MYOB quote was created successfully</h4>
            <ReactJson src={this.state.myobOrderDetails.created}
                       displayObjectSize={true}
                       displayDataTypes={false}
                       name={'Created Quote'}
            />
          </div>
        </div> }

        {this.state.myobOrderDetails.quote && !this.state.myobOrderDetails.created &&
        <div className="pi-row" style={{marginTop: '10px'}}>
          <div className="pi-col-md-12">
            <h4>The following MYOB quote was sent to MYOB however it failed</h4>
            <h5>{this.state.myobOrderDetails.message}</h5>
            <ReactJson src={this.state.myobOrderDetails.quote}
                       displayObjectSize={true}
                       displayDataTypes={false}
                       name={'Failed Quote'}
            />
          </div>
        </div> }

        <div ref={this.customerDetails}>
          <CustomerDetailsView order={order} showSequenceNumber={canEditPowderCoater} />
        </div>

        { order.orderStatus == ORDER_STATUS_ORDER_PRODUCTDISPATCHED &&
          <div ref="freight_details">
            <div className="pi-row">
              <div className="pi-col-sm-6">
                <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                  <div className="pi-row">
                    <div className="pi-col-sm-12"><h6>Freight Details</h6></div>
                  </div>
                  {(order.courierName || order.courierOther) &&
                      <TextViewControl labelText={'Courier'}
                                       value={`${order.courierName} ${order.courierOther != null ? order.courierOther : ''}`} /> }
                  <TextViewControl labelText={'Consignment'} value={order.courierConsignmentReference} />
                  {!isNullEmptyOrWhitespace(order.courierNoBoxes) && <TextViewControl labelText={'No. boxes'} value={order.courierNoBoxes} /> }
                  { order.courierDispatchDate && <TextViewControl labelText={'Dispatch Date'} value={moment(order.courierDispatchDate).format(DATE_SHORT_FORMAT)} /> }
                  <TextViewControl labelText={'Dispatch'} value={order.dispatchTypeDescription} />
                  <TextViewControl labelText={'Dispatch note'} value={order.dispatchNote} />
                </div>
              </div>
              {order.courierDispatchCentre && <div className="pi-col-sm-6">
                <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                  <div className="pi-row">
                    <div className="pi-col-sm-12"><h6>Dispatch Centre Info</h6></div>
                  </div>
                  <TextViewControl labelText={'Centre'} value={order.courierDispatchCentre} />
                  <TextViewControl labelText={'Address'} value={order.courierDispatchCentreAdress} />
                  <TextViewControl labelText={'Contact'} value={order.courierDispatchCentreContactName} />
                  <TextViewControl labelText={'Phone'} value={order.courierDispatchCentrePhone1} />
                  {order.courierDispatchCentreEmail1 != '' && <TextViewControl labelText={'Email'} value={order.courierDispatchCentreEmail1} /> }
                  <TextViewControl labelText={'Notes'} value={order.courierDispatchCentreNotes} />
                </div>
              </div>}
            </div>
          </div> }

        { (canEnterInvoiceNumber && !canEditDispatch) &&
            <div className="pi-row" style={{marginBottom: '15px'}}>
              <div className="pi-col-sm-6">
                <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}></div>
              </div>
              <div className="pi-col-sm-6">
                <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                  <TextBoxControl
                      onChange={this.handlFieldChange}
                      labelText="Invoice number"
                      value={order.invoiceNumber}
                      name="invoiceNumber"
                      validationMessages={this.state.validationMessages}
                  />
                </div>
              </div>
            </div>
          }
        
        { canEditPowderCoater &&   
        <div className="pi-row" style={{marginBottom: '15px'}}>
          <div className="pi-col-sm-6">
            <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
              <div className="pi-row">
                <div className="pi-col-sm-12">
                  
                  <TextBoxControl
                      onChange={this.handlFieldChange}
                      labelText="PN"
                      value={order.sequenceNumber}
                      name="sequenceNumber"
                      validationMessages={this.state.validationMessages}
                      disabled={order.orderStatus !== ORDER_STATUS_ORDER_REQUESTED}
                  />
                  

                   <div className="pi-row">
                    <div className="pi-col-sm-12">
                      <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Commitment date&nbsp;</label>
                      <DatePicker
                          onChange={(d) => {
                            this.setState({
                              order: {
                                ...this.state.order,
                                commitmentDate: d ? d.format() : null
                              }
                            }, this.isValidOrder);
                          }}
                          selected={order.commitmentDate ? moment(order.commitmentDate) : null}
                          name="commitmentDate"
                          dateFormat={DATE_SHORT_FORMAT}
                          minDate={moment()}
                          onChangeRaw={(e) => {
                            e.target.value = '';
                          }}
                          readOnly={order.orderStatus !== ORDER_STATUS_ORDER_REQUESTED}
                      />
                      {(validationMessages && validationMessages.commitmentDate != '') &&
                      <div style={{color: 'red'}}>{validationMessages.commitmentDate}</div>}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            </div>
          <div className="pi-col-sm-6">
            <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
              <TextBoxControl
                  onChange={this.handlFieldChange}
                  labelText="Invoice number"
                  value={order.invoiceNumber}
                  name="invoiceNumber"
                  validationMessages={this.state.validationMessages}
              />              
              <div className="pi-row">
                <div className="pi-col-sm-12">
              <label htmlFor="estimatedDeliveryDate" style={{minWidth: '150px'}}>Est. Completion Date&nbsp;</label>
              <DatePicker
                  onChange={(d) => {
                    this.setState({order: {...this.state.order, estimatedDeliveryDate: d ? d.format() : null}}, this.isValidOrder);
                  }}
                  selected={order.estimatedDeliveryDate ? moment(order.estimatedDeliveryDate) : null}
                  name="estimatedDeliveryDate"
                  dateFormat={DATE_SHORT_FORMAT}
                  minDate={moment()}
                  onChangeRaw={(e)=> {
                    e.target.value = '';
                  }}
              />
                </div>
            </div>
              
          </div>
        </div>
        </div>}

        <div className="pi-row" style={{marginBottom: '15px'}}>
            <div className="pi-col-sm-6">
              <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                <div className="pi-row">
                  <div className="pi-col-sm-12">

                    {order.requiresFabricMaker && canEditFabric &&
                    <div>

                      <SelectControl
                        labelText="Fabric maker"
                        name="fabricMakerId"
                        options={this.props.fabricMakers}
                        onChange={(val) => this.handleSelectChange('fabricMakerId', val, (v) => v.value)}
                        value={order.fabricMakerId}
                        placeholder="Select Fabric Maker"
                        validationMessages={this.state.validationMessages}
                      />

                      {/*<div className="form-group">*/}
                      {/*  <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Ordered&nbsp;</label>*/}
                      {/*  <DatePicker*/}
                      {/*    onChange={(d) => {*/}
                      {/*      this.setState({order: {...this.state.order, fabricOrderDate: d ? d.format() : null}});*/}
                      {/*    }}*/}
                      {/*    selected={order.fabricOrderDate ? moment(order.fabricOrderDate) : null}*/}
                      {/*    name="fabricOrderDate"*/}
                      {/*    dateFormat={DATE_SHORT_FORMAT}*/}
                      {/*    minDate={moment()}*/}
                      {/*    onChangeRaw={(e)=> {*/}
                      {/*      e.target.value = '';*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*</div>*/}

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Ordered</label>
                        <span>{IsNullOrUndefined(order.fabricOrderDate) ? 'Unsent' : moment(order.fabricOrderDate).format(DATE_SHORT_FORMAT)}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Scheduled</label>
                        <span>{IsNullOrUndefined(order.fabricSupplierCompleteDate) ? 'N/A' : moment(order.fabricSupplierCompleteDate).format(DATE_SHORT_FORMAT)}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Completed</label>
                        <span>{IsNullOrUndefined(order.fabricSupplierCompletedDate) ? 'N/A' : moment(order.fabricSupplierCompletedDate).format(DATE_SHORT_FORMAT)}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Received&nbsp;</label>
                        <DatePicker
                          onChange={(d) => {
                            this.setState({order: {...this.state.order, fabricRecDate: d ? d.format() : null}}, this.isValidOrder);
                          }}
                          selected={order.fabricRecDate ? moment(order.fabricRecDate) : null}
                          name="fabricRecDate"
                          dateFormat={DATE_SHORT_FORMAT}
                          minDate={moment()}
                          onChangeRaw={(e)=> {
                            e.target.value = '';
                          }}
                        />
                        { (validationMessages && validationMessages.fabricRecDate != '') && <div style={{color: 'red'}}>{validationMessages.fabricRecDate}</div> }
                      </div>
                      </div>
                    }                    
                  </div>
                </div>

                {canEditDispatch &&
                <div className="pi-row">
                  <div className="pi-col-sm-12">
                    <SelectControl
                      labelText="Dispatch method"
                      name="dispatchTypeId"
                      options={this.props.dispatchTypes}
                      onChange={(val) => this.handleSelectChange('dispatchTypeId', val, (v) => v.value)}
                      value={order.dispatchTypeId}
                      placeholder="Select Dispatch Method"
                      validationMessages={this.state.validationMessages}
                    />

                    { order.orderStatus === ORDER_STATUS_ORDER_REQUESTED && order.requiresFreightAmount && <TextBoxControl
                        onChange={this.handlFieldChange}
                        labelText="Freight Amount"
                        value={order.freightAmount}
                        name="freightAmount"
                        validationMessages={this.state.validationMessages}
                        type={'number'}
                    /> }
                    
                    <div className="pi-row">
                      <div className="pi-col-sm-4"><label htmlFor="dispatchNote">Dispatch note</label></div>
                      <div className="pi-col-sm-8">
                        <textarea style={{maxWidth: '99%'}} className={'form-control'}
                                  value={order.dispatchNote == null ? '' : order.dispatchNote}
                                  onChange={(ev) => this.setState({order: {...order, dispatchNote: ev.target.value}})}
                                  name='dispatchNote'
                        />
                      </div>
                    </div>

                  </div>
                </div>}

              </div>
            </div>
          
            <div className="pi-col-sm-6">
              <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>

                { canEnterInvoiceNumber && canEditDispatch &&
                    <div className="pi-row">
                    <div className="pi-col-sm-12">
                  <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
                    <TextBoxControl
                        onChange={this.handlFieldChange}
                        labelText="Invoice number"
                        value={order.invoiceNumber}
                        name="invoiceNumber"
                        validationMessages={this.state.validationMessages}
                    />
                  </div>
                    </div>
                    </div>
                }
                
                <div className="pi-row">                  
                  <div className="pi-col-sm-12">                    
                    {order.requiresPowderCoater && canEditPowderCoater &&
                    <div>
                      <SelectControl
                        labelText="Powder coater"
                        name="powderCoaterId"
                        options={this.props.powderCoaters}
                        onChange={(val) => this.handleSelectChange('powderCoaterId', val, (v) => v.value)}
                        value={order.powderCoaterId}
                        placeholder="Select Powder Coater"
                        validationMessages={this.state.validationMessages}
                      />
                      <div className="form-group">
                        <label htmlFor="pcSentDate" style={{minWidth: '150px'}}>Sent&nbsp;</label>
                        <DatePicker
                          onChange={(d) => {
                            this.setState({order: {...this.state.order, pcSentDate: d ? d.format() : null}});
                          }}
                          selected={order.pcSentDate ? moment(order.pcSentDate) : null}
                          name="pcSentDate"
                          dateFormat={DATE_SHORT_FORMAT}
                          minDate={moment()}
                          onChangeRaw={(e)=> {
                            e.target.value = '';
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Scheduled</label>
                        <span>{IsNullOrUndefined(order.pcSupplierCompleteDate) ? 'N/A' : moment(order.pcSupplierCompleteDate).format(DATE_SHORT_FORMAT)}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Completed</label>
                        <span>{IsNullOrUndefined(order.pcSupplierCompletedDate) ? 'N/A' : moment(order.pcSupplierCompletedDate).format(DATE_SHORT_FORMAT)}</span>
                      </div>

                      <div className="form-group">
                        <label htmlFor="pcRecDate" style={{minWidth: '150px'}}>Received&nbsp;</label>
                        <DatePicker
                          onChange={(d) => {
                            this.setState({order: {...this.state.order, pcRecDate: d ? d.format() : null}}, this.isValidOrder);
                          }}
                          selected={order.pcRecDate ? moment(order.pcRecDate) : null}
                          name="pcRecDate"
                          dateFormat={DATE_SHORT_FORMAT}
                          minDate={moment()}
                          onChangeRaw={(e)=> {
                            e.target.value = '';
                          }}
                        />
                        { (validationMessages && validationMessages.pcRecDate != '') && <div style={{color: 'red'}}>{validationMessages.pcRecDate}</div> }
                      </div>

                    </div>
                    }
                    {/*order.powderCoaterId && (!(order.orderStatus == ORDER_STATUS_IN_PRODUCTION || order.orderStatus == ORDER_STATUS_IN_PRODUCTION) || !canEditPowderCoater) &&
                    <div>
                      <TextViewControl
                        labelText="Powder coater"
                        value={order.powderCoaterDescription}
                      />
                      {order.pcSentDate && <TextViewControl
                        labelText="PC sent"
                        value={moment(order.pcSentDate).format(DATE_SHORT_FORMAT)}
                      />}
                      {order.pcRecDate && <TextViewControl
                        labelText="PC received"
                        value={moment(order.pcRecDate).format(DATE_SHORT_FORMAT)}
                      />}
                    </div>
                    */}


                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="pi-row" ref={this.mainPrint}>
            { order.isSparesOrder && <div className="pi-col-sm-12">
              <div className="pi-section"
                   style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>

                <div className="pi-row">
                  <div className="pi-col-sm-12"><h4 style={{marginBottom: '10px'}}>Spares Order Summary</h4></div>
                </div>
                <div className="pi-row">
                  <div className="pi-col-sm-12">

                    <SpareSummaryView
                        spares={order.spares}
                        isWholesaleOnly={order.organisationWholesaleOnly}
                        wholesaleCostTotal={order.wholesaleCostTotal}
                    />                    

                    { order.paymentMethodType === PAYMENT_METHOD_CC && <div className="mt-2">
                      <b><i>You have selected Credit Card Payment, price excludes 2% Credit Card fee</i></b>
                    </div> }
                    <div>
                      <b><i>In the event of a discrepancy between the hard copy price list distributed and the above summary price, the hard copy price list will apply. {GetOrderPriceMessage(order, siteSettings)}</i></b>
                      {/*<br /><b><i><span style={{color:"red"}}>Current prices are valid for orders placed before 1 May 2022. New prices will apply from that date.</span></i></b>*/}
                    </div>                    
                  </div>
                </div>
              </div>
            </div> }

            <div className="pi-col-sm-6">
              {order.awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId)).map((awning, i) => <div key={awning.id}>
                {i > 0 && <div className={'html2pdf__page-break'}/> }
                <AwningSummaryView
                        ref={`awning_${awning.id}`}
                        awning={awning}
                        awningCount={order.awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId)).length}
                        awningSetCount={order.awnings.filter(x => !IsNullOrUndefined(x.parentOrderAwningId)).length}
                        onChange={this.awningChanged}
                        orderStatus={order.orderStatus}
                        order={order}
                        canAddNote={user.hasAccessTag(view_order_editable_order_and_awning_note_all)}
                        canEditJoiner={canEditJoiner}
                        canEditFabricInstructions={ (user.hasAccessTag(view_order_editable_order_and_awning_note_all) && order.orderStatus == ORDER_STATUS_ORDER_REQUESTED) }
                        onExpandArmBracketSheet={this.openAbsSheet}
                        canEditAttachment={user.hasAccessTag(view_order_save_order_all)}
                        canEditMotor={canEditMotor}
                        order={order}
              />
              </div>)}
            </div>
            {(user.hasAccessTag(view_order_show_price_information_all) && !order.isSparesOrder) &&
             <div className="pi-col-sm-6"  ref={this.priceSectionRef}>
               <div className="pi-section"
                    style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px', border: '2px solid #666'}}>
                 
               <div className="pi-row">
                 <div className="pi-col-sm-12"><h4 style={{marginBottom: '4px'}}>Order Price Summary (excl. GST)</h4></div>
               </div>

              {order.awnings.map(awning => <div key={awning.id}>
                <AwningPriceSummary
                  awning={awning}
                  productionSheetView={false}
                  canViewPriceSummary={user.hasAccessTag(view_order_show_price_information_all)}
                  wholesaleOnly={order.organisationWholesaleOnly || IsRoofSystem(awning)}
                  order={order}
                />
              </div>)
              }

                 <div ref="sensorcosts">
             { (order.sensorCostsTotal > 0) && <SummaryDivider labelText="Sensor Cost Summary" /> }

                 {order.sensorCosts.map(c => {

                   return (<Aux key={`sensorcost_${c.rowKey}`}>
                     <div className="pi-row">
                       <div className="pi-col-sm-6">{c.sensorTypeDescription} {c.awningCount > 1 ? `set (${c.awningCount} awnings)` : `x 1`}</div>
                       <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{order.organisationWholesaleOnly || !anyNonRoofing ? '' : MoneyFormatter.format(c.total)}</div>
                       <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{MoneyFormatter.format(c.totalWholesale)}</div>
                     </div>

                     {c.additionalCosts.remoteSurchargeRetail &&
                     <div className="pi-row">
                       <div className="pi-col-sm-6">Remote surcharge</div>
                       <div className="pi-col-sm-3"
                            style={{textAlign: 'right'}}>{MoneyFormatter.format(c.additionalCosts.remoteSurchargeRetail)}</div>
                       <div className="pi-col-sm-3"
                            style={{textAlign: 'right'}}>{MoneyFormatter.format(c.additionalCosts.remoteSurchargeWholesale)}</div>
                     </div>
                     }

                   </Aux>);
                   

                 })}
                 </div>

                 { (order.sensorCostsTotal > 0) && <div className="pi-row">
                   <div className="pi-col-sm-6">Sensor Total</div>
                   <div className="pi-col-sm-3" style={{textAlign: 'right'}}><strong>{order.organisationWholesaleOnly || !anyNonRoofing ? '' : MoneyFormatter.format(order.sensorCostsTotal)}</strong></div>
                   <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{MoneyFormatter.format(order.sensorCostsTotalWholesale)}</div>
                 </div> }

                <div className="pi-row">
                  <div className="pi-col-sm-12">
                    <div ref="totalcost" style={{fontWeight: 'bolder', paddingTop:'10px'}}>
                      { order.costTotal == 0 || !user.hasAccessTag(view_order_show_price_information_all) ?
                        (user.hasAccessTag(view_order_show_price_information_all) &&
                          <TextViewControlPrice
                            style={{paddingTop: '10px'}}
                            labelText="Order total"
                            value={'Aluxor to confirm'}
                            wholesaleOnly={order.organisationWholesaleOnly || !anyNonRoofing}
                          /> )
                        :
                        <TextViewControlPrice
                          style={{paddingTop: '10px'}}
                          discount={order.organisationDiscount}
                          labelText="Order Total"
                          retail={order.costTotal}
                          wholesale={order.wholesaleCostTotal}
                          wholesaleOnly={(order.organisationWholesaleOnly || !anyNonRoofing)}
                        />
                      }
                    </div>
                  </div>
                </div>
                 {anyRoofing && <div className="pi-row mt-2">
                   <div className="pi-col-sm-12">
                     <b><i>50% deposit is required at the time of ordering Sintesi systems.</i></b>
                   </div>
                 </div>
                 } 
                 <div className="pi-row mt-2">
                   <div className="pi-col-sm-12">
                     <small><i>In the event of a discrepancy between the hard copy price list distributed and the above summary price, the hard copy price list will apply. {GetOrderPriceMessage(order, siteSettings)}</i></small>
                     {/*<br /><b><i><span style={{color:"red"}}>Current prices are valid for orders placed before 1 May 2022. New prices will apply from that date.</span></i></b>*/}
                   </div>
                </div>
                <div className="pi-row mt-2">
                    <div className="pi-col-sm-12">
                        <small><i>Planned Completion Dates of any product is an estimate only which may be dependent on factors outside of the control of Aluxor. Whilst Aluxor will use commercially reasonable endeavours to meet such dates, Aluxor will not be responsible for the consequences of any delay or non-delivery.</i></small>
                    </div>
                </div>                 
              </div>
            </div> }
          </div>
              

          { (user.hasAccessTag(view_order_editable_order_and_awning_note_all) || !isNullEmptyOrWhitespace(order.orderNote))  &&
          <div className="pi-row">
            <div className="pi-col-sm-6"> <div className="pi-section" style={{marginTop: '15px', paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
          <div className="pi-row">
            <div className="pi-col-sm-4"><label htmlFor="orderNote">Order note</label></div>
            <div className="pi-col-sm-8">
              { !user.hasAccessTag(view_order_editable_order_and_awning_note_all) ? <span>{order.orderNote}</span> :
                <textarea style={{maxWidth: '99%'}} className={'form-control'}
                          value={order.orderNote == null ? '' : order.orderNote}
                          onChange={(ev) => {
                            this.setState({order: {...order, orderNote: ev.target.value}});
                          }}
                          onBlur={(e) => {
                            if (e) {
                              e.preventDefault();
                            }
                            this.progressOrder(order.orderStatus, false);
                          }}
                          name='orderNote'
                />}
            </div>
          </div> </div></div></div>}



        <OrderStatusChanges
          visible={this.props.user.hasAccessTag(view_order_view_order_status_changes_all)}
          statusChanges={order.statusChanges} />

      </div>)
        :(<div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              Loading... <i className={'fa fa-spinner fa-spin'} />
            </h4>
          </div>
        </div>
      </div>);
  }
}

OrderSummaryPage.propTypes = {
  user: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let orderId = -1;
  if(ownProps.match.params && ownProps.match.params.orderId){
    orderId = parseInt(deobsfucate(ownProps.match.params.orderId));
  }
  let query = querySearch(ownProps.location.search);

  return {
    user: state.user,
    orderId,
    fabricMakers: state.lookups.fabricMakers,
    dispatchTypes: state.lookups.dispatchTypes,
    powderCoaters: state.lookups.powderCoaters,
    selectedSheet: query.print ? {value: { sheetName: 'invoice'}, label: 'Invoice'} : undefined,
    siteSettings: state.lookups.siteSettings,
    states: state.lookups.states,
    quoteAddons: state.lookups.quoteAddons,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderSummaryPage));