import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import {fromPairs} from 'lodash';

export function loadFabricMakersSuccess(fabricMakers){
  return {type: types.LOAD_LOOKUP_FABRICMAKERS_SUCCESS, fabricMakers};
}

export function loadPowderCoatersSuccess(powderCoaters){
  return {type: types.LOAD_LOOKUP_POWDERCOATERS_SUCCESS, powderCoaters};
}

export function loadDispatchTypesSuccess(dispatchTypes){
  return {type: types.LOAD_LOOKUP_DISPATCHMETHODS_SUCCESS, dispatchTypes};
}

export function loadOrganisationStatusesSuccess(organisationStatuses){
  return {type: types.LOAD_LOOKUP_organisationStatuses_SUCCESS, organisationStatuses};
}
export function loadCreditApplicationStatusesSuccess(creditApplicationStatuses){
  return {type: types.LOAD_LOOKUP_creditApplicationStatuses_SUCCESS, creditApplicationStatuses};
}
export function loadCreditTermsSuccess(creditTerms){
  return {type: types.LOAD_LOOKUP_creditTerms_SUCCESS, creditTerms};
}
export function loadDispatchCentresSuccess(dispatchCentres){
  return {type: types.LOAD_LOOKUP_dispatchCentres_SUCCESS, dispatchCentres};
}
export function loadStatesSuccess(states){
  return {type: types.LOAD_LOOKUP_states_SUCCESS, states};
}
export function loadPaymentMethodsSuccess(paymentMethods){
  return {type: types.LOAD_LOOKUP_paymentMethods_SUCCESS, paymentMethods};
}

export function loadLookups() {
  return function (dispatch) {
    let requestFabricMakers = apiRequest(`/api/lookups/fabricMakers`)
      .then(x => {
        dispatch(loadFabricMakersSuccess([...x.data]));
      });

    let requestPowderCoaters = apiRequest(`/api/lookups/powderCoaters`)
      .then(x => {
        dispatch(loadPowderCoatersSuccess([...x.data]));
      });

    let requestDispatchTypes = apiRequest(`/api/lookups/dispatchTypes`)
      .then(x => {
        dispatch(loadDispatchTypesSuccess([...x.data]));
      });

    let dispatchCentres = apiRequest(`/api/lookups/dispatchCentres`)
      .then(x => {
        dispatch(loadDispatchCentresSuccess([...x.data]));
      });
    let creditTerms = apiRequest(`/api/lookups/creditTerms`)
      .then(x => {
        dispatch(loadCreditTermsSuccess([...x.data]));
      });
    let creditApplicationStatuses = apiRequest(`/api/lookups/creditApplicationStatuses`)
      .then(x => {
        dispatch(loadCreditApplicationStatusesSuccess([...x.data]));
      });
    let organisationStatuses = apiRequest(`/api/lookups/organisationStatuses`)
      .then(x => {
        dispatch(loadOrganisationStatusesSuccess([...x.data]));
      });

    let states = apiRequest(`/api/lookups/addressstates`)
      .then(x => {
        dispatch(loadStatesSuccess([...x.data]));
      });

    let paymentMethods = apiRequest(`/api/lookups/paymentmethods`)
      .then(x => {
        dispatch(loadPaymentMethodsSuccess([...x.data]));
      });

    let productsForSearch = apiRequest(`/api/products/forSearch`)
      .then(x => {
        dispatch({type: types.LOAD_LOOKUP_productsSearch_SUCCESS, productsForSearch: [...x.data]});
      });

    let coloursForSearch = apiRequest(`/api/colours/forSearch`)
      .then(x => {
        dispatch({type: types.LOAD_LOOKUP_coloursSearch_SUCCESS, coloursForSearch: [...x.data]});
      });

    let motorBrandsForSearch = apiRequest(`/api/motors/forSearch`)
      .then(x => {
        dispatch({type: types.LOAD_LOOKUP_motorBrandsSearch_SUCCESS, motorBrandsForSearch: [...x.data]});
      });

      let driveMechanisms = apiRequest(`/api/lookups/driveMechanisms`)
          .then(x => {
              dispatch({type: types.LOAD_LOOKUP_driveMechanisms_SUCCESS, driveMechanisms: [...x.data]});
          });

      let siteSettings = apiRequest(`/api/SiteSettings`)
          .then(x => {
              let siteSettings = fromPairs(x.data.map(x => [x.settingKey,
                  x.settingValue]));
              
              dispatch({type: types.LOAD_LOOKUP_siteSettings_SUCCESS, siteSettings});
          });

      let standardColours =  apiRequest('/api/colours/standardForSearch')     
          .then(x => {
          dispatch({type: types.LOAD_LOOKUP_standardColoursForSearch_SUCCESS, standardColoursForSearch: [...x.data]});
      });

      let quoteAddons = apiRequest(`/api/lookups/quoteAddons`)
          .then(x => {
              dispatch({type: types.LOAD_LOOKUP_quoteAddons_SUCCESS, quoteAddons: [...x.data]});
          });

    return Promise.all([requestFabricMakers, requestPowderCoaters, requestDispatchTypes, dispatchCentres,
      creditTerms, creditApplicationStatuses, organisationStatuses, states, paymentMethods, productsForSearch,
      coloursForSearch, motorBrandsForSearch, siteSettings, standardColours, driveMechanisms]);
  };
}

export function loadSiteSettings() {
    return function (dispatch) {
        return apiRequest(`/api/SiteSettings`)
            .then(x => {
                let siteSettings = fromPairs(x.data.map(x => [x.settingKey,
                    x.settingValue]));

                dispatch({type: types.LOAD_LOOKUP_siteSettings_SUCCESS, siteSettings});
            });
    };
}
