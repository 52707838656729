import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {apiRequest, apiDelete, apiPost} from "../../utils/request";
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import moment from 'moment';
import {DATE_SHORT_FORMAT, obsfucate} from "../../constants";
import SelectControl from "../common/SelectControl";
import {toastr} from 'react-redux-toastr';
import ReactJson from 'react-json-view';


const OrderColumns = (thisRef) => {
  let selectItem = (e, id) => {
    let {selectedOrderIds} = thisRef.state;
    let exists = selectedOrderIds.findIndex(x => x === id) >= 0;
    let checked = e.target.checked;

    if (checked && !exists) {
      thisRef.setState({selectedOrderIds: [...selectedOrderIds, id]});
    } else if (!checked && exists) {
      thisRef.setState({selectedOrderIds: [...selectedOrderIds.filter(x => x !== id)]});
    }
  };

  let selectAll = (e) => {    
    let filteredOrders = thisRef.state.orders.filter(x => x.motorBrandId === thisRef.state.selectedBrand.value).map(x => x.id);    
    let checked = e.target.checked;
    thisRef.setState({
      selectedOrderIds: checked ? filteredOrders : [],
      allSelected: checked});
  };

  let checked = (id) => {
    let {selectedOrderIds} = thisRef.state;
    let returnVal = selectedOrderIds.findIndex(x => x === id) >= 0;
    return returnVal;
  };

  return [
    {
      id: 'brandId',
      Header: 'Brand',
      accessor: d => d.motorBrandDescription,
    },
    {
      id: 'sequenceNumber',
      Header: 'PN',
      accessor: d => d.sequenceNumber,
      Cell: r => <Link
        to={`/orders/view/${obsfucate(r.original.id)}`}
        style={{marginRight: '20px'}}
        target={'_blank'}
      >{r.value}</Link>
    },
    {
      id: 'organisationShortName',
      Header: 'Customer',
      accessor: d => d.organisationShortName,
    },
    {
      id: 'createdDate',
      Header: 'Date',
      accessor: d => moment(d.orderDisplayDate).format(DATE_SHORT_FORMAT),
    },
    {
      id: 'actions',
      Header: <input type={'checkbox'}
                      checked={thisRef.state.allSelected}
                     onChange={(e) => selectAll(e)} />,
      Cell: r => <div style={{textAlign: 'center'}}>
                    <input type={'checkbox'}
                          checked={checked(r.original.id)}
                          onChange={(e) => selectItem(e, r.original.id)} />
                </div>
    },
  ]
};

const MotorColumns = [
  {
    id: 'motorBrandDescription',
    Header: 'Motor Brand Description',
    accessor: d => d.motorBrandDescription,
  },
    {
    id: 'motorDescription',
    Header: 'Motor Description',
    accessor: d => d.motorDescription,
  },
  {
    id: 'myobCode',
    Header: 'MyobCode',
    accessor: d => d.myobCode,
  },
  {
    id: 'count',
    Header: 'Required Count',
    accessor: d => d.count,
  }
];

const SensorColumns = [
  {
    id: 'motorBrandDescription',
    Header: 'Motor Brand Description',
    accessor: d => d.motorBrandDescription,
  },
  {
    id: 'description',
    Header: 'Sensor',
    accessor: d => d.description,
  },
  {
    id: 'count',
    Header: 'Required Count',
    accessor: d => d.count,
  }
];

const Brands = [{
  value: 1,
  label: 'Somfy'
}, {
  value: 2,
  label: 'Becker'
}, {
  value: 4,
  label: 'Aluxor'
}];

class MotorOrderPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      orders: [],
      selectedBrand: Brands[0],
      selectedOrderIds: [],
      allSelected: false,
      sending: false,
      unfoundMyobCodes: [],
      successOrder: null,
      failedOrder: null,
      productionMotors: [],
      productionSensors: [],
    };

    this.loadData = this.loadData.bind(this);
    this.sendOrder = this.sendOrder.bind(this);
  }

  loadData(orderSent = null) {
    apiRequest('/api/myoborders/OrdersForMyobOrder')
        .then(x => {
          this.setState({
            orders: x.data, selectedBrand: Brands[0], selectedOrderIds: [],
            allSelected: false, sending: false, successOrder: orderSent, failedOrder: null, unfoundMyobCodes: []
          });
        });

    apiRequest('/api/myoborders/MotorsForProduction')
        .then(x => {
          this.setState({productionMotors: x.data});
        });

    apiRequest('/api/myoborders/SensorsForProduction')
        .then(x => {
          this.setState({productionSensors: x.data});
        });
  }

  sendOrder() {
    let ids = '';
    let {selectedOrderIds, selectedBrand} = this.state;
    for (let i = 0; i < selectedOrderIds.length; i++) {
      ids += `orderIds=${selectedOrderIds[i]}&`;
    }

    this.setState({sending: true, successOrder: null, failedOrder: null, unfoundMyobCodes: []}, () => {
      apiPost(`/api/myoborders/createmotororder?motorBrandId=${selectedBrand.value}&${ids}`)
        .then(x => {
          if (x.data.ok) {
            toastr.success('Successfully submitted to MYOB');
            this.loadData(x.data.createdOrders);
          } else if(x.data.unfoundMyobCodes && x.data.unfoundMyobCodes.length > 0) {
            toastr.error('There were missing MYOB codes in inventory, please review');
            this.setState({unfoundMyobCodes: x.data.unfoundMyobCodes, sending: false});
          } else if(x.data.order) {
            toastr.error('Failed to send order to MYOB please review order');
            this.setState({failedOrder: x.data.order, sending: false, unfoundMyobCodes: []});
          } else {
            console.log(x);
            toastr.error('Something went wrong while sending to MYOB');
            this.setState({sending: false, unfoundMyobCodes: [], failedOrder: null, successOrder: null});
          }
        });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render(){
    let {orders, selectedBrand, selectedOrderIds, sending} = this.state;
    let filteredOrders = orders.filter(x => x.motorBrandId === selectedBrand.value);

    return (
    <div className="pi-section">
      <div className="pi-row">
        <div className="pi-col-md-12">
          <h4>Orders for MYOB motor order</h4>
        </div>
      </div>
      <div className="pi-row">
        <div className="pi-col-md-12">
          The following orders are In Production and have not been sent to MYOB to be ordered, one brand at a time can be ordered, a single order can have motors/sensors for several orders
        </div>
      </div>
      <div className="pi-row" style={{marginTop: '10px'}}>
        <div className="pi-col-md-12">
          <button className="btn pi-btn-icon-big"
                  onClick={() => this.loadData(null)}
                  style={{borderRadius: '0px'}}>
            <i className="fa fa-cloud-upload"></i> Reload
          </button>
          &nbsp;
          <button className="btn pi-btn-base pi-btn-icon-big"
                         onClick={this.sendOrder}
                         style={{borderRadius: '0px'}}
                        disabled={selectedOrderIds.length === 0 || sending}>
            <i className="fa fa-cloud-upload"></i> Send order to MYOB
          </button>{selectedOrderIds.length === 0 && <small><i>&nbsp;&nbsp; (Please select at least one order to send)</i></small> }
        </div>
      </div>

      {this.state.unfoundMyobCodes.length > 0 &&
      <div className="pi-row" style={{marginTop: '10px'}}>
        <div className="pi-col-md-12">
          <h4>The following are missing MYOB codes, these must be resolved to create a motor order for the selected orders</h4>
          <ReactJson src={this.state.unfoundMyobCodes}
                     displayObjectSize={true}
                     displayDataTypes={false}
                     name={'Missing MYOB codes'}
          />
        </div>
      </div> }

      {this.state.failedOrder &&
      <div className="pi-row" style={{marginTop: '10px'}}>
        <div className="pi-col-md-12">
          <h4>The following order was sent to MYOB, however it failed for an unknown reason</h4>
          <ReactJson src={this.state.failedOrder}
                     displayObjectSize={true}
                     displayDataTypes={false}
                     name={'Failed Order'}
          />
        </div>
      </div> }

      {this.state.successOrder &&
      <div className="pi-row" style={{marginTop: '10px'}}>
        <div className="pi-col-md-12">
          <h4>The following order was successfully sent to MYOB</h4>
          <ReactJson src={this.state.successOrder}
                     displayObjectSize={true}
                     displayDataTypes={false}
                     name={'Success Order'}
          />
        </div>
      </div> }

      <div className="pi-row" style={{marginTop: '10px'}}>
        <div className="pi-col-md-6">
          <SelectControl
            labelText="Brand"
            name="selectedBrand"
            options={Brands}
            onChange={(v) => this.setState({selectedBrand: v, selectedOrderIds: [], allSelected: false})}
            value={selectedBrand}
            placeholder="Brand"
          />
        </div>
      </div>
      <div className="pi-row">
        <div className="pi-col-md-12">
          <ReactTable
            data={filteredOrders}
            columns={OrderColumns(this)}
            defaultPageSize={10}
          />
        </div>
      </div>
      
      <div className="pi-row mt-5">
        <div className="pi-col-md-12">
          <h4>Required Motors in Production</h4>
          <ReactTable
              data={this.state.productionMotors}
              columns={MotorColumns}
              defaultPageSize={10}
          />
        </div>
      </div>

      <div className="pi-row mt-5">
        <div className="pi-col-md-12">
          <h4>Required Sensors in Production</h4>
          <ReactTable
              data={this.state.productionSensors}
              columns={SensorColumns}
              defaultPageSize={10}
          />
        </div>
      </div>
    </div>);
  }
}



MotorOrderPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MotorOrderPage));
