import {SummaryDivider} from "../common/SummaryControls";
import TextViewControl from "../common/TextViewControl";
import {
  Aux,
  AwningBracketTotal,
  isHardwired, isManual, isMotor, isNullEmptyOrWhitespace,
  IsNullOrUndefined,
  isRemote,
  PRODUCTGROUPID_CONSERVATORY
} from "../../constants";
import React from "react";


export const AwningProductSpecifications = (props) => {
  let {awning, showSize, showProduct, customerName, reference, date, cols} = props;
  if(cols === undefined){
    cols = 1
  }
  
  let {fieldConfiguration} = awning;
  let hasFieldConfiguration = !IsNullOrUndefined(fieldConfiguration);

  let {bracketTotal, bracketTotalDualMount, bracketTotalSingleMount} = AwningBracketTotal(awning);
  let averageLoadIncAdditional = bracketTotal > 0 ? Math.round((awning.averageLoadPerBracket * awning.numberOfMountingBracket) / bracketTotal, 2) : awning.averageLoadPerBracket;
  let weight = awning.weightExcMotor;

  if (!IsNullOrUndefined(awning.fieldConfiguration)) {
    weight = weight * awning.fieldConfiguration.numberOfFields;
  }

  let hasRightInformation = !isNullEmptyOrWhitespace(customerName) || !isNullEmptyOrWhitespace(reference) || !isNullEmptyOrWhitespace(date);

  let col2 = ()=> {
    return (<Aux>
    {awning.numberOfCradles > 0 && <TextViewControl
        labelText="Cradles"
        value={awning.numberOfCradles}
    />}
    {awning.additionalCradles > 0 && <TextViewControl
        labelText="Extra cradles"
        value={awning.additionalCradles}
    />}

    {(awning.numberOfCradles + awning.additionalCradles) > 0 && <TextViewControl
        labelText="Note"
        value={'Align cradle with fabric seam'}
    />}

      {(isManual(awning) || isMotor(awning)) &&  <TextViewControl
          labelText="Drive mechanism"
          value={isMotor(awning) ? "Motor" : "Manual (Gearbox)"}
      />}
      
    {((!isRemote(awning) && !isHardwired(awning)) || (!awning.driveMechanismId)) && weight > 0 && <TextViewControl
        labelText="Weight (exc. Motor)"
        value={`${weight} kg`}
    />}

    {(isRemote(awning) || isHardwired(awning)) && weight > 0 && <TextViewControl
        labelText="Weight"
        value={`${weight + 5} kg`}
    />}
    <TextViewControl
        labelText="Outside spacing"
        value={awning.outsideArmSpacing == 0 ? 'At ends' : awning.outsideArmSpacing}
        visible={awning.productGroupId !== PRODUCTGROUPID_CONSERVATORY}
    />

    {awning.insideArmSpacing > 0 && <TextViewControl
        labelText="Inside spacing"
        value={awning.insideArmSpacing}
        visible={awning.productGroupId !== PRODUCTGROUPID_CONSERVATORY}
    />}
    {awning.oversquare && <div className="pi-row">
      <div className="pi-col-sm-4"><strong>Oversquare</strong></div>
      <div className="pi-col-sm-8">&nbsp;</div>
    </div>}

    {awning.averageLoadPerBracket !== null && awning.averageLoadPerBracket > 0 && <TextViewControl
        labelText="Average load"
        value={`${averageLoadIncAdditional} kg per bracket`}
    />}

    {awning.intermediateRoller > 0 && <TextViewControl
        labelText="IM rollers"
        value={`${awning.intermediateRoller}`}
    />}
    {awning.additionalImRollerCount > 0 && <TextViewControl
        labelText="Additional IM rollers"
        value={`${awning.additionalImRollerCount}`}
    />}
  </Aux>)};
  
  return (<Aux>

    {hasRightInformation && 
    <div className="row mt-2">
      <div className={"col-6"}>
        <TextViewControl
            labelText="Customer Name"
            value={customerName}
            boldLabel={true}
        />
        <TextViewControl
            labelText="Reference"
            value={reference}
            boldLabel={true}
        />
      </div>
      <div className={"col-6"}>
        <TextViewControl
            labelText="Date"
            value={date}
            boldLabel={true}
        />
      </div>
    </div>
    }
    
      <div className={`row mt-2`}>
        <div className={"col-12"} style={{fontWeight: 'bold'}}>
          Product Specifications
        </div>
      </div>

      <div className={`row`}>       
        <div className={cols===2 ? 'col-6': 'col-12'}>
          {showProduct && <TextViewControl
              labelText="Product"
              value={awning.productDescription}
          />}

          {showSize && awning.width > 0 && awning.projection > 0 && <Aux>

            <TextViewControl
                labelText="Size"
                value={`${awning.width.toString()} x ${awning.projection.toString()}`}
                visible={!hasFieldConfiguration}
            />

            {fieldConfiguration && fieldConfiguration.fields.map(field => {
              return (<TextViewControl
                  key={`${awning.id}_${field.number}`}
                  labelText={`Field size #${field.number}`}
                  value={`${field.width.toString()} x ${awning.projection.toString()}${field.isMotorRequired ? `  ${field.sideOfOperation} motor` : ''}`}
              />);
            })}

          </Aux>}

          <TextViewControl
              labelText="Bracket type"
              value={`${awning.mountingBracketDescription ? awning.mountingBracketDescription : 'Not Selected'} x ${bracketTotal}${awning.additionalMountingBrackets > 0 ? ' (incl. ' + awning.additionalMountingBrackets + ' additional)' : ''}`}
              visible={bracketTotal > 0}
          />

          <TextViewControl
              labelText="Dual Mount"
              value={`${bracketTotalDualMount}`}
              visible={!IsNullOrUndefined(bracketTotalDualMount) && bracketTotalDualMount > 0}
          />
          
          <TextViewControl
              labelText="Single Mount"
              value={bracketTotalSingleMount}
              visible={(!IsNullOrUndefined(bracketTotalSingleMount) && bracketTotalSingleMount > 0)}
          />

          <TextViewControl
              labelText="Arms"
              value={awning.numberOfArms}
              visible={awning.productGroupId !== PRODUCTGROUPID_CONSERVATORY && awning.numberOfArms > 0}
          />

          {awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption && <TextViewControl
              labelText="Installation config"
              value={awning.fieldConfiguration.installationBracketOption.description}
          />}
          {awning.installationBracketOption && <TextViewControl
              labelText="Installation config"
              value={awning.installationBracketOption.description}
          />}

          {awning.installationBracketDescription && bracketTotal > 0 && <TextViewControl
              labelText="Installation bracket"
              value={`${awning.installationBracketDescription} x ${bracketTotal.toString()}`}
          />}
          {awning.installationBracketDescription && bracketTotal === 0 && <TextViewControl
              labelText="Installation bkt"
              value={`${awning.installationBracketDescription}`}
          />}
          {!IsNullOrUndefined(awning.installationBracketLength) && <TextViewControl
              labelText="Post height"
              value={`${awning.installationBracketLength}mm`}
          />}

          {awning.includeWedges && bracketTotal > 0 && <TextViewControl
              labelText="Wedges"
              value={bracketTotal}
          />}

          {awning.includeLed === true && <TextViewControl
              labelText="LED Lighting"
              value="Yes"
          />}
          {awning.includeStabiliserPoles && <TextViewControl
              labelText="Stabiliser poles"
              value='Yes'
          />}
          { cols !== 2 && col2()}
        </div>
        
        { cols === 2 && <div className={"col-6"}>
          {col2()}
        </div>}          
          
      </div>
    
  </Aux>);
};