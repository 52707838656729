import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost} from "../../utils/request";
import '../../css/divTable.css';
import {toastr} from 'react-redux-toastr';

class CustomerPortalServicesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      portalServices: [],
      organisationPortalServices: [],
    };
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  handleSave(e) {
    e.preventDefault();
    let data = {
      portalServiceItems: this.state.portalServices.map(x => {
        let hasAccess = this.state.organisationPortalServices.findIndex(y => y.portalServiceId === x.id) >= 0;
        return {
          id: x.id,
          hasAccess
        };
      }), organisationId: this.props.organisationId
    };

    apiPost('/api/portalServices/organisation', data)
      .then(x => {
        toastr.success('Saved portal service access');
        this.setState({organisationPortalServices: x.data});
      });
  }

  handleSelection(e, portalServiceId) {
    let checked = e.target.checked;

    if (checked && this.state.organisationPortalServices.findIndex(y => y.portalServiceId === portalServiceId) < 0) {
      this.setState({
        organisationPortalServices: [...this.state.organisationPortalServices, {
          organisationId: this.props.organisationId,
          portalServiceId
        }]
      });
    } else {
      this.setState({organisationPortalServices: [...this.state.organisationPortalServices.filter(x => x.portalServiceId !== portalServiceId)]});
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.organisationId !== prevProps.organisationId && this.props.organisationId !== 0){
      this.loadData();
    }
  }

  loadData(){
    toastr.warning('Loading portal services');
    apiRequest('/api/portalServices')
      .then(x => this.setState({portalServices: x.data}));
    apiRequest(`/api/portalServices/organisation?id=${this.props.organisationId}`)
      .then(x => this.setState({organisationPortalServices: x.data}));
  }

  componentDidMount() {
   this.loadData();
  }

  render() {
    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Accessible Portal Services</h2>
            </div>
            <div className={'pi-col-md-4'} style={{textAlign: 'right'}}>
              <button
                className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleSave}>
                <i className="fa fa-save"></i>&nbsp;Save
              </button>
              <button
                style={{marginLeft: '10px'}}
                className="btn pi-btn pi-btn-icon-big"
                onClick={(e) => {
                  e.preventDefault();
                  this.loadData();
                }}>
                <i className="fa fa-reload"></i>&nbsp;Reset
              </button>
            </div>

          </div>

          <div className={'pi-row'}>
            <div className={'pi-col-md-12'}>
              <div className={'divTable'} style={{flexDirection: 'column'}}>
                <div className={'divThead'}>
                  <div className={'divTrHead'}>
                    <div className={'divTh'}>Title</div>
                    <div className={'divTh'}>Description</div>
                    <div className={'divTh'} style={{textAlign: 'center'}}>Enabled</div>
                  </div>
                </div>
                <div className={'divTbody'}>
                  {this.state.portalServices.map(x => {
                    let selected = this.state.organisationPortalServices.findIndex(y => y.portalServiceId === x.id) >= 0;

                    return (<div key={x.id} className={'divTr'}>
                      <div className={'divTd'}>{x.title}</div>
                      <div className={'divTd'}>{x.description}</div>
                      <div className={'divTd'} style={{textAlign: 'center'}}>
                        <input type='checkbox'
                               checked={selected}
                               onChange={(e) => this.handleSelection(e, x.id)}
                        />
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

CustomerPortalServicesPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPortalServicesPage));
