import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {DebounceInput} from 'react-debounce-input';
import {IsNullOrUndefined} from "../../constants";

const TextBoxControl = (props) => {
  let {labelText, value, onChange, name, type, icon, validationMessages, isDebounce, debounceeTimeOut, image,
    disabled, labelMarginBottom, align, style, inputBorderStyle} = props;
  let isValid = !validationMessages || (validationMessages && (validationMessages[`${name}`] == '' || validationMessages[`${name}`] == undefined));
  let styled = style === undefined ? {textAlign: align ? align : undefined} : style;

  let inputStyle = {borderColor: isValid ? '' : 'red', textAlign: align ? align : undefined};
  if(!IsNullOrUndefined(inputBorderStyle)){
    inputStyle = {...inputStyle, border: inputBorderStyle};
  }
  
  let input = (isDebounce && !disabled) ? (
      <DebounceInput
        minLength={0}
        debounceTimeout={debounceeTimeOut}
        type={type ? type : 'text'}
        className={'form-control'}
        value={value == null ? '' : value}
        onChange={onChange}
        name={name}
        placeholder={labelText}
        style={inputStyle} />
    ) :
    (<input type={type ? type : 'text'}
                      className={'form-control'}
                      value={value === null || value === undefined ? '' : value}
                      onChange={onChange}
                      name={name}
                      placeholder={labelText}
                      style={inputStyle} disabled={disabled} />);

  return (
    <div className="form-group" style={styled}>
      <label htmlFor={name} style={{marginBottom: labelMarginBottom ? labelMarginBottom : undefined}}>{labelText}</label>
      {image && <img src={image} style={{paddingLeft: '10px', width: '120px', marginLeft: '-10px'}}></img>}
      {icon ?
        <div className="pi-input-with-icon">
          <div className="pi-input-icon"><i className={`icon-${icon}`}></i></div>
          {input}
        </div>
        : input
      }

      { !isValid && <div style={{color: 'red'}}>{validationMessages[`${name}`]}</div> }
    </div>
  );
};

TextBoxControl.propTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  validationMessages: PropTypes.object,
};

export default TextBoxControl;