import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import RadioControlGroup from "../common/RadioControlGroup";
import {
  Aux,
  FABRIC_SUPPLIED_BY_ALUXOR,
  isNullEmptyOrWhitespace, MoneyFormatter,
  PRODUCTGROUPID_CONSERVATORY,
  PRODUCTGROUPID_FOLDING_ARM
} from "../../constants";
import TextBoxControl from "../common/TextBoxControl";
import {uniqBy, find} from 'lodash';
import SelectControl from "../common/SelectControl";
import TextViewControl from "../common/TextViewControl";

const initialAwning = {
  width: 0,
  productGroupId: 0,
  projection: 0,
  fabricBrandId: 0,
  wholeSalePercentage: 65,
  retailPercentage: 60,
  recoverPercentage: 35,
  men: 1,
  hours: 1,
  rate: 80,
};

/*const initialAwning = {
  width: 4000,
  productGroupId: 1,
  projection: 2500,
  fabricBrandId: 5,
  wholeSalePercentage: 65,
  retailPercentage: 60,
  recoverPercentage: 35,
  men: 1,
  hours: 1,
  rate: 80,
};*/

const CalcPriceView = (props) => {
  let {labelText, value, isMoney} = props;
  if(isMoney===undefined){
    isMoney = true;
  }

  return value == null ? null : (
    <div className="pi-row">
      <div className="pi-col-sm-6" style={{fontWeight: 'bold'}}>{labelText}&nbsp;</div>
      <div className="pi-col-sm-6" style={{textAlign: 'right'}}>{value == null ? 'N/A' : isMoney ? MoneyFormatter.format(value) : value}</div>
    </div>
  );
};

class FabricRecoverSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      awning: initialAwning,
      baseCosts: [],
      fabricBrands: [],
      productGroups: [
        {value: PRODUCTGROUPID_FOLDING_ARM, label: 'Folding Arm'},
        {value: PRODUCTGROUPID_CONSERVATORY, label: 'Conservatory'},
        ],
      projections: [],
      validationMessages: {},
    };

    this.loadData = this.loadData.bind(this);
    this.getWorkSheetData = this.getWorkSheetData.bind(this);
  }

  async loadData() {
    let brands = await apiRequest('/api/fabricbrands/all');
    let costs = await apiRequest('/api/fabricbrands/costs');

    this.setState({
      awning: {...initialAwning},
      fabricBrands: brands.data,
      baseCosts: costs.data,
      projections: uniqBy(costs.data, x => x.projection).map(x => {
        return {
          label: x.projection,
          value: x.projection,
        }
      }),
    });
  }

  getWorkSheetData() {
    let {fabricBrands, baseCosts, awning} = this.state;
    let {
      width, productGroupId, projection,
      fabricBrandId, retailPercentage, wholeSalePercentage,
      men, hours, rate,
    } = awning;

    let cost = find(baseCosts, x =>
      x.productGroupId === productGroupId
      && width >= x.widthFrom
      && width <= x.widthTo
      && projection === x.projection);

    let fabricBrand = find(fabricBrands, x => x.id === fabricBrandId);

    if (!isNullEmptyOrWhitespace(cost) && !isNullEmptyOrWhitespace(fabricBrand)) {
      let surcharge = fabricBrand.baseCostMultiplier * cost.baseCost;
      let wholeSale = surcharge / (wholeSalePercentage / 100)
      let retail = wholeSale / (retailPercentage / 100);
      let labour = men * hours * rate;
      let subTotal = retail + labour;

      return {
        baseCost: cost.baseCost,
        surcharge,
        baseCostMultiplier: fabricBrand.baseCostMultiplier,
        wholeSale,
        retail,
        labour,
        subTotal,
        gst: subTotal * 0.1,
        retailTotal: subTotal * 1.1,
      };
    }

    return null;
  }

  componentDidMount() {
    this.loadData();
  }

  render(){

    let {awning, productGroups,validationMessages, projections, fabricBrands} = this.state;

    let workSheetData = this.getWorkSheetData();

    return (
      <div>
        <h3 style={{marginTop: '10px'}}>Fabric Recovering Calculator</h3>
        <p>Fill in the required details and the applicable pricing will be displayed</p>

        <div className={'pi-row'}>
          <div className='pi-col-md-6'>
            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`productGroupId`}
              onChange={(x) =>  {this.setState({awning: {...awning, productGroupId: x.simpleValue}})}}
              value={awning.productGroupId}
              labelText='Product Group'
              validationMessages={validationMessages}
              options={productGroups}
            />

            <SelectControl
              validationMessages={validationMessages}
              labelText="Fabric Brand"
              name="fabricBrandId"
              options={fabricBrands}
              simpleValue={true}
              onChange={(x) =>  {this.setState({awning: {...awning, fabricBrandId: x}})}}
              value={awning.fabricBrandId}
              placeholder="Select Fabric Brand"
              valueKey={'id'}
              labelKey={'description'}
            />

            {awning.productGroupId > 0 &&
            <Aux>
              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={(e) => {
                  this.setState({awning: {...awning, width: parseInt(e.target.value)}})
                }}
                labelText="Width"
                value={awning.width.toString()}
                name="width"
                type="number"
                validationMessages={validationMessages}
              />

              <RadioControlGroup
                style={{marginLeft: '0px'}}
                name={`projection`}
                onChange={(x) =>  {this.setState({awning: {...awning, projection: parseInt(x.simpleValue)}})}}
                value={awning.projection}
                labelText='Projection'
                validationMessages={validationMessages}
                options={projections}
              />
            </Aux>
            }
          </div>
          <div className='pi-col-md-3'>
            {workSheetData ? <Aux>
              <h4>Labour</h4>
              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  this.setState({awning: {...awning, men: parseInt(e.target.value)}})
                }}
                labelText="Men"
                value={awning.men}
                name="men"
                type="number"
                validationMessages={validationMessages}
              />

              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  this.setState({awning: {...awning, hours: parseInt(e.target.value)}})
                }}
                labelText="Hours"
                value={awning.hours}
                name="hours"
                type="number"
                validationMessages={validationMessages}
              />

              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  this.setState({awning: {...awning, rate: parseInt(e.target.value)}})
                }}
                labelText="rate"
                value={awning.rate}
                name="rate"
                type="number"
                validationMessages={validationMessages}
              />
            </Aux> : <h3>Out of range</h3>
            }
          </div>
          <div className='pi-col-md-3'>
            {workSheetData && <Aux>
              <h4>Margin</h4>
              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  let val = parseFloat(e.target.value);
                  this.setState({awning: {
                      ...awning,
                      recoverPercentage: val,
                      wholeSalePercentage: 100 - val,
                    }
                  });
                }}
                labelText="Recover %"
                value={awning.recoverPercentage}
                name="recoverPercentage"
                type="number"
                validationMessages={validationMessages}
              />

              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  let val = parseFloat(e.target.value);
                  this.setState({awning: {
                      ...awning,
                      recoverPercentage: 100 - val,
                      wholeSalePercentage: val,
                    }
                  });
                }}
                labelText="Wholesale %"
                value={awning.wholeSalePercentage}
                name="wholeSalePercentage"
                type="number"
                validationMessages={validationMessages}
              />

              <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={100}
                onChange={(e) => {
                  this.setState({awning: {...awning, retailPercentage: parseFloat(e.target.value)}})
                }}
                labelText="Retail %"
                value={awning.retailPercentage}
                name="retailPercentage"
                type="number"
                validationMessages={validationMessages}
              />

            </Aux> }
          </div>
        </div>
        <div className={'pi-row'} style={{marginBottom: '25px'}}>
          <div className='pi-col-md-6'>&nbsp;</div>
          {workSheetData &&
            <div className='pi-col-md-3' style={{border: '2px solid black', paddingTop: '10px', paddingBottom: '10px'}}>

              <h3>Costs</h3>

              <CalcPriceView labelText={'Base cost'} value={workSheetData.baseCost} />
              <CalcPriceView labelText={'Multiplier'} value={workSheetData.baseCostMultiplier} isMoney={false} />
              <CalcPriceView labelText={'Surcharge'} value={workSheetData.surcharge} />
              <CalcPriceView labelText={'Wholesale'} value={workSheetData.wholeSale} />
              <CalcPriceView labelText={'Retail'} value={workSheetData.retail} />
              <CalcPriceView labelText={'Labour'} value={workSheetData.labour} />
              <CalcPriceView labelText={'Sub total'} value={workSheetData.subTotal} />
              <CalcPriceView labelText={'GST'} value={workSheetData.gst} />
              <CalcPriceView labelText={'Retail total'} value={workSheetData.retailTotal} />

            </div> }
          {workSheetData &&
          <div className='pi-col-md-3' style={{borderRight: '2px solid black', borderBottom: '2px solid black', borderTop: '2px solid black', paddingTop: '10px', paddingBottom: '10px'}}>
            <h3>Recovery % Guide</h3>
            <CalcPriceView labelText={'EC, S1'} value={'30%'} isMoney={false} />
            <CalcPriceView labelText={'S3, TG'} value={'32.5%'} isMoney={false} />
            <CalcPriceView labelText={'OL, DS'} value={'35%'} isMoney={false} />
            <CalcPriceView labelText={'SD'} value={'40%'} isMoney={false} />
            <CalcPriceView labelText={'VS'} value={'35%'} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />

          </div> }
        </div>

      </div>      
    );
  }
}

FabricRecoverSheet.propTypes = {
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabricRecoverSheet));