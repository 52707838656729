import React from 'react';
import TextBoxControl from '../common/TextBoxControl';
import SelectControl from '../common/SelectControl';
import CheckBoxControl from '../common/CheckBoxControl';
import PropTypes from 'prop-types';
import {IsStringValid, validationCreator} from "../../constants";

export const AdminValidationRules = {

  shortName: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid short name') : validationCreator();
    }
  },
  myobCompanyName: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid MYOB company name') : validationCreator();
    }
  },
  discountAwning: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },
  discountFrame: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },
  discountSpareParts: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },discountBand1: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },discountBand2: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },discountBand3: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },discountBand4: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },discountBand5: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a valid discount') : validationCreator();
    }
  },
  creditLimit: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a credit limit') : validationCreator();
    }
  },
  rating: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /^([0-5]{1})$/g) ? validationCreator(false, 'Please enter a rating (1-5)') : validationCreator();
    }
  },
  creditTermsId: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a credit limit') : validationCreator();
    }
  },
  statusCreditApplicationId: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a credit application status') : validationCreator();
    }
  },
  organisationStatusId: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /\d/g) ? validationCreator(false, 'Please enter a client status') : validationCreator();
    }
  },
};

export const CustomerAdminControl = (props) => {
  let {organisation, validationMessages, handleFieldChange, creditTerms, handleSelectChange, style,
    creditApplicationStatuses, organisationStatuses, dispatchTypes, dispatchCentres, fabricMakers, powderCoaters} = props;

  return (<div className={'pi-row'} style={style}>
    <div className={'pi-col-md-5'}>
      <h4>Client Administration</h4>
      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Short Name"
        value={organisation.shortName}
        name="shortName"
        validationMessages={validationMessages}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="MYOB Name"
        value={organisation.myobCompanyName}
        name="myobCompanyName"
        validationMessages={validationMessages}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Frame (%)"
        value={organisation.discountBand1}
        name="discountBand1"
        validationMessages={validationMessages}
        type={'number'}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Awning (%)"
        value={organisation.discountBand2}
        name="discountBand2"
        validationMessages={validationMessages}
        type={'number'}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Accessories (%)"
        value={organisation.discountBand3}
        name="discountBand3"
        validationMessages={validationMessages}
        type={'number'}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Spare Parts (%)"
        value={organisation.discountBand4}
        name="discountBand4"
        validationMessages={validationMessages}
        type={'number'}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Services (%)"
        value={organisation.discountBand5}
        name="discountBand5"
        validationMessages={validationMessages}
        type={'number'}
      />

      {/*
      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Discount Awning (%)"
        value={organisation.discountAwning}
        name="discountAwning"
        validationMessages={validationMessages}
        type={'number'}
      />
      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Discount Frame (%)"
        value={organisation.discountFrame}
        name="discountFrame"
        validationMessages={validationMessages}
        type={'number'}
      />
      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Discount Spare (%)"
        value={organisation.discountSpareParts}
        name="discountSpareParts"
        validationMessages={validationMessages}
        type={'number'}
      /> */}

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Credit Limit"
        value={organisation.creditLimit}
        name="creditLimit"
        validationMessages={validationMessages}
        type={'number'}
      />
      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Rating (1-5)"
        value={organisation.rating}
        name="rating"
        validationMessages={validationMessages}
        type={'number'}
      />
      <CheckBoxControl
        onChange={handleFieldChange}
        labelText="Is Active"
        value={organisation.isActive}
        name="isActive"
      />

      <CheckBoxControl
        onChange={handleFieldChange}
        labelText="Wholesale Only"
        value={organisation.wholesaleOnly}
        name="wholesaleOnly"
      />

      <CheckBoxControl
        onChange={handleFieldChange}
        labelText="Allowed Frame Only"
        value={organisation.isAllowedFrameOnly}
        name="isAllowedFrameOnly"
      />

      <CheckBoxControl
          onChange={handleFieldChange}
          labelText="Enable Customer Quoting"
          value={organisation.enableCustomerInformation}
          name="enableCustomerInformation"
      />

    </div>
    <div className={'pi-col-md-1'}>
      &nbsp;
    </div>
    <div className={'pi-col-md-5'}>
      <h4>&nbsp;</h4>
      <SelectControl
        clearable={true}
        deleteRemoves={true}
        backspaceRemoves={true}
        validationMessages={validationMessages}
        labelText="Credit Terms"
        name="creditTermsId"
        options={creditTerms}
        onChange={(v) => handleSelectChange(v, 'creditTermsId', (v) => v === null ? null : v.value)}
        value={organisation.creditTermsId}
      />

      <SelectControl
        clearable={true}
        deleteRemoves={true}
        backspaceRemoves={true}
        validationMessages={validationMessages}
        labelText="Credit Application Status"
        name="statusCreditApplicationId"
        options={creditApplicationStatuses}
        onChange={(v) => handleSelectChange(v, 'statusCreditApplicationId', (v) => v === null ? null : v.value)}
        value={organisation.statusCreditApplicationId}
      />

      <SelectControl
        validationMessages={validationMessages}
        labelText="Client Status"
        name="organisationStatusId"
        options={organisationStatuses}
        onChange={(v) => handleSelectChange(v, 'organisationStatusId', (v) => v === null ? null : v.value)}
        value={organisation.organisationStatusId}
      />

      <SelectControl
        clearable={true}
        deleteRemoves={true}
        backspaceRemoves={true}
        validationMessages={validationMessages}
        labelText="Dispatch Centre"
        name="dispatchCentreId"
        options={dispatchCentres}
        onChange={(v) => handleSelectChange(v, 'dispatchCentreId', (v) => v === null ? null : v.value)}
        value={organisation.dispatchCentreId}
      />

      <SelectControl
        clearable={true}
        deleteRemoves={true}
        backspaceRemoves={true}
        validationMessages={validationMessages}
        labelText="Default Dispatch"
        name="dispatchTypeId"
        options={dispatchTypes}
        onChange={(v) => handleSelectChange(v, 'dispatchTypeId', (v) => v === null ? null : v.value)}
        value={organisation.dispatchTypeId}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Notes"
        value={organisation.notes}
        name="notes"
        validationMessages={validationMessages}
      />

      <TextBoxControl
        onChange={handleFieldChange}
        labelText="Standard Order Note"
        value={organisation.standardOrderNote}
        name="standardOrderNote"
        validationMessages={validationMessages}
      />

      <SelectControl
          clearable={true}
          deleteRemoves={true}
          backspaceRemoves={true}
          validationMessages={validationMessages}
          labelText="Is a Fabric Maker"
          name="fabricMakerId"
          options={fabricMakers}
          onChange={(v) => handleSelectChange(v, 'fabricMakerId', (v) => v === null ? null : v.value)}
          value={organisation.fabricMakerId}
      />

      <SelectControl
          clearable={true}
          deleteRemoves={true}
          backspaceRemoves={true}
          validationMessages={validationMessages}
          labelText="Default Fabric Maker"
          name="defaultFabricMakerId"
          options={fabricMakers}
          onChange={(v) => handleSelectChange(v, 'defaultFabricMakerId', (v) => v === null ? null : v.value)}
          value={organisation.defaultFabricMakerId}
      />

      <SelectControl
          clearable={true}
          deleteRemoves={true}
          backspaceRemoves={true}
          validationMessages={validationMessages}
          labelText="Is a Powder Coater"
          name="powderCoaterId"
          options={powderCoaters}
          onChange={(v) => handleSelectChange(v, 'powderCoaterId', (v) => v === null ? null : v.value)}
          value={organisation.powderCoaterId}
      />

      <CheckBoxControl
          onChange={handleFieldChange}
          labelText="Require Acceptance of Terms"
          value={organisation.requiresAcceptedTerms}
          name="requiresAcceptedTerms"
      />

      

    </div>
  </div>)
};

CustomerAdminControl.propTypes = {
  organisation: PropTypes.object.isRequired,
  validationMessages: PropTypes.object.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  creditTerms: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  creditApplicationStatuses: PropTypes.array.isRequired,
  organisationStatuses: PropTypes.array.isRequired,
  dispatchTypes: PropTypes.array.isRequired,
  dispatchCentres: PropTypes.array.isRequired,
  powderCoaters: PropTypes.array.isRequired,
  fabricMakers: PropTypes.array.isRequired,
};