import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {orderBy} from 'lodash';
import {obsfucate, PORTALSERVICE_AwningQuote, PORTALSERVICE_SpareQuote} from "../../constants";

class HomePage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    let {user} = this.props;


    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          {user.accountMessages.length > 0 &&
            <div className={'pi-row'} style={{marginBottom: '25px'}}>
              <div className={'pi-col-md-12'}>
                {user.accountMessages.map((x,i) => (<div style={{color: 'red'}} key={i}>{x}</div>)) }
              </div>
            </div>
          }

          <div className="pi-row">
            {orderBy(user.portalServices.filter(x => x.url && x.isEnabled && x.id !== PORTALSERVICE_SpareQuote && x.id !== PORTALSERVICE_AwningQuote ), 'orderBy').map(service => (
              <div key={service.id} className="pi-col-sm-3 pi-col-xs-6 pi-padding-bottom-50">
                <div className="pi-icon-box-vertical pi-icon-box-vertical-icon-bigger pi-text-center">
                  <img src={service.image} alt="" onClick={(e) => {
                    e.preventDefault();
                    let url = (this.props.user && this.props.user.fixedOrderId) ? `/orders/view/${obsfucate(this.props.user.fixedOrderId)}` : service.url;
                    this.props.history.push(url);
                  }} />
                  <h4><br/>
                    {(this.props.user && this.props.user.fixedOrderId)?
                      <Link to={`/orders/view/${obsfucate(this.props.user.fixedOrderId)}`} className="pi-link-dark">{service.title}</Link>:
                      <Link to={service.url} className="pi-link-dark">{service.title}</Link> }
                    </h4>
                  <p style={{minHeight: '50px'}}>
                    {service.description}
                  </p>
                </div>
              </div>))}
          </div>
        </div>
      </div>);
  }
}

HomePage.propTypes = {
  organisations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    organisations: state.organisations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
