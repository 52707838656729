import React from "react";
import {connect} from "react-redux";

class HttpInfoControl extends React.Component {
    constructor(props, context) {
        super(props, context);        
    }
    
    render() {
        return this.props.httpInfo.pendingCount > 0 &&
            (<span style={{color: '#f58b3c'}}>Loading resources...<i
                className={'fa fa-spinner fa-spin fa-2x'}/></span>);

    }
}

function mapStateToProps(state, ownProps) {
    return {
        httpInfo: state.httpInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HttpInfoControl);