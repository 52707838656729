import {find, orderBy} from "lodash";
import {isNullEmptyOrWhitespace} from "../../constants";
import PropTypes from 'prop-types';

export const FabricRecoverAwningViewWorksheetData = (props) => {
    let {awning, fabricBrands, baseCosts} = props;

    let {
        width, productGroupId, projection,
        fabricBrandId, retailPercentage, wholeSalePercentage,
        men, hours, rate,
    } = awning;

    let cost = find(orderBy(baseCosts, ['projection'], ['asc']), x =>
        x.productGroupId === productGroupId
        && width >= x.widthFrom
        && width <= x.widthTo
        && projection > 0
        && projection <= x.projection);

    let fabricBrand = find(fabricBrands, x => x.id === fabricBrandId);

    if (!isNullEmptyOrWhitespace(cost) && !isNullEmptyOrWhitespace(fabricBrand)) {
        let surchargeCost = fabricBrand.baseCostMultiplier * cost.baseCost;
        let wholesaleCost = surchargeCost / (wholeSalePercentage / 100)
        let retailCost = wholesaleCost / (retailPercentage / 100);
        let labourCost = men * hours * rate;
        let subTotal = retailCost + labourCost;

        return {
            baseCost: cost.baseCost,
            surchargeCost,
            baseCostMultiplier: fabricBrand.baseCostMultiplier,
            wholesaleCost,
            retailCost,
            labourCost,
            subTotal,
            gst: subTotal * 0.1,
            retailTotal: subTotal * 1.1,
        };
    }

    return null;
};

FabricRecoverAwningViewWorksheetData.propTypes = {
    fabricBrands: PropTypes.array.isRequired,
    baseCosts: PropTypes.array.isRequired,
    awning: PropTypes.object.isRequired,
};