import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactFileReader from 'react-file-reader';
import FileSaver from 'file-saver';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import {apiDownload, apiRequest} from "../../utils/request";

class AwningAttachments extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      attachments: props.attachments ? props.attachments : [],
      selectedAttachment: null,
    };

    this.handleAttachmentFieldChange = this.handleAttachmentFieldChange.bind(this);
    this.handleSaveAttachment = this.handleSaveAttachment.bind(this);
    this.validateAttachment = this.validateAttachment.bind(this);
    this.handleCancelAttachment = this.handleCancelAttachment.bind(this);
    this.handleNewAttachment = this.handleNewAttachment.bind(this);
    this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
    this.handleSelectAttachment = this.handleSelectAttachment.bind(this);
  }

  handleAttachmentFieldChange(e) {
    if (e.base64 && e.base64 !== '' && e.fileList.length === 1 && e.fileList[0].name !== '') {
      if (e.fileList[0].size > 5000000) {
        toastr.error('File must be less than 5 Mb');
        this.setState({
          selectedAttachment: {
            ...this.state.selectedAttachment,
            fileContents: '',
            fileName: ''
          }
        });
      } else {
        this.setState({
          selectedAttachment: {
            ...this.state.selectedAttachment,
            fileContents: e.base64,
            fileName: e.fileList[0].name
          }
        });
        this.handleSaveAttachment();
      }
    } else {
      toastr.warning('Bad file');
    }
  }

  handleSaveAttachment(e) {
    if(e){
      e.preventDefault();  
    }   
    
    let added = false;
    let attachments = [...this.state.attachments].map(x => {
      let returnVal = x;
      if (returnVal.id === this.state.selectedAttachment.id) {
        added = true;
        return this.state.selectedAttachment;
      }
      return returnVal;
    });
    if (!added) {
      attachments = [...this.state.attachments, this.state.selectedAttachment];
    }
    if(this.props.onChange){
      this.props.onChange(attachments);
    }
    this.setState({attachments, selectedAttachment: null});
  }

  validateAttachment(){
    let {selectedAttachment} = this.state;
    if(this.state.selectedAttachment){
      if(selectedAttachment.fileName.trim() === ''){
        return {valid: false, error: ''};
      }
    }
    return {valid: true, error: ''};
  }

  handleCancelAttachment(e){
    e.preventDefault();
    this.setState({selectedAttachment: null});
  }

  handleNewAttachment(e) {
    e.preventDefault();
    let count = this.state.attachments.reduce((a, b) => {
      return b.id < 0 ? a + 1 : a;
    }, 0);
    this.setState({selectedAttachment: {id: (-1 - count), fileName: ''}});
  }

  handleAttachmentDelete(c, e){
    e.preventDefault();
    let attachments = this.state.attachments.filter(x => x.id !== c.id);
    if(this.props.onChange){
      this.props.onChange(attachments);
    }
    this.setState({attachments});
  }

  handleSelectAttachment(ev, attachment) {
    ev.preventDefault();
    apiDownload(`/api/attachments?storedFileName=${attachment.storedFileName}`, attachment.fileName);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.attachments.length > 0 && (this.props.attachments.filter(x => x.id > 0).length !== this.state.attachments.filter(x => x.id > 0).length)) {
      this.setState({attachments: this.props.attachments});
    }
  }

  render(){
    const isValidAttachment = this.validateAttachment();
    const {readOnly} = this.props;
    return (<div>
      <div className="form-group pi-row">
        <h6>
          {this.props.headerText ? this.props.headerText : 'Attachments'}&nbsp;{!readOnly && <button
            className="btn pi-btn-base pi-uppercase pi-letter-spacing"
          onClick={this.handleNewAttachment}><i
          className="fa fa-plus"/>&nbsp;&nbsp;Add attachment</button> }
        </h6>
      </div>
      <div className="form-group pi-row">
        <div className="pi-col-12 pi-col-md-12 pi-col-xl-12">
          {this.state.selectedAttachment ?
            (<div className="form-horizontal">
              <div className="form-group pi-row">
                <div className="pi-col-md-2" style={{minWidth: '100px'}}>
                  <ReactFileReader fileTypes={"*"} base64 multipleFiles={false} handleFiles={this.handleAttachmentFieldChange}>
                    <button
                      onClick={(e) => e.preventDefault()}
                      className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing">Upload</button>
                  </ReactFileReader>
                </div>
                <div className="pi-col-md-10">{this.state.selectedAttachment.fileName ? this.state.selectedAttachment.fileName : 'Please select file by clicking upload'}</div>              </div>
              <div className="form-group pi-row">
                <div className="pi-col-md-8">
                  {isValidAttachment.valid ?
                    <button
                      className="btn pi-btn-base pi-uppercase pi-letter-spacing"
                      onClick={this.handleSaveAttachment} style={{marginRight: '10px'}}><i
                      className="fa fa-check"/>&nbsp;
                    </button> :
                    <span className="text-danger">{isValidAttachment.error}</span>
                  }
                  <button
                    className="btn"
                    onClick={this.handleCancelAttachment}><i
                    className="fa fa-undo"/>&nbsp;
                  </button>
                </div>
              </div>
            </div>)
            :
            (<table className="table table-responsive">
              <thead>
              <tr>
                <th>Filename</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              {this.state.attachments.length === 0 &&
              <tr>
                <td colSpan="4">No attachment details</td>
              </tr>
              }
              {this.state.attachments.map(c => (
                <tr key={c.id}>
                  <td>
                    {c.id > 0 ?
                      <a href="#" onClick={(e) => this.handleSelectAttachment(e, c)}>{c.fileName}</a>
                      :
                      <span>{c.fileName} (pending save)</span>
                    }
                  </td>
                  <td>{!readOnly && <button
                    className="btn pi-btn-base"
                    onClick={(e) => this.handleAttachmentDelete(c, e)}><i
                    className="fa fa-times"/></button>}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>)}

        </div>
      </div>
    </div>);
  }
}

AwningAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  headerText: PropTypes.string,
};

export default AwningAttachments;