import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function organisationsReducer(state = initialState.organisations, action) {
  switch (action.type) {
    case types.LOAD_ORGANISATIONS_SUCCESS:
      return {loaded: true, items: [...action.organisations]};
    default:
      return state;
  }
}
