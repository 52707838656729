import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {LOAD_LOOKUP_quoteAddons_SUCCESS} from "../actions/actionTypes";

export default function lookupsReducer(state = initialState.lookups, action) {
  switch (action.type) {
    case types.LOAD_LOOKUP_FABRICMAKERS_SUCCESS:
      return {...state, fabricMakers: [...action.fabricMakers]};
    case types.LOAD_LOOKUP_POWDERCOATERS_SUCCESS:
      return {...state, powderCoaters: [...action.powderCoaters]};
    case types.LOAD_LOOKUP_DISPATCHMETHODS_SUCCESS:
      return {...state, dispatchTypes: [...action.dispatchTypes]};
    case types.LOAD_LOOKUP_dispatchCentres_SUCCESS:
      return {...state, dispatchCentres: [...action.dispatchCentres]};
    case types.LOAD_LOOKUP_creditTerms_SUCCESS:
      return {...state, creditTerms: [...action.creditTerms]};
    case types.LOAD_LOOKUP_creditApplicationStatuses_SUCCESS:
      return {...state, creditApplicationStatuses: [...action.creditApplicationStatuses]};
    case types.LOAD_LOOKUP_organisationStatuses_SUCCESS:
      return {...state, organisationStatuses: [...action.organisationStatuses]};
    case types.LOAD_LOOKUP_states_SUCCESS:
      return {...state, states: [...action.states]};
    case types.LOAD_LOOKUP_paymentMethods_SUCCESS:
      return {...state, paymentMethods: [...action.paymentMethods]};
    case types.LOAD_LOOKUP_productsSearch_SUCCESS:
      return {...state, productsForSearch: [...action.productsForSearch]};
    case types.LOAD_LOOKUP_coloursSearch_SUCCESS:
      return {...state, coloursForSearch: [...action.coloursForSearch]};
    case types.LOAD_LOOKUP_standardColoursForSearch_SUCCESS:
      return {...state, standardColoursForSearch: [...action.standardColoursForSearch]};
    case types.LOAD_LOOKUP_motorBrandsSearch_SUCCESS:
      return {...state, motorBrandsForSearch: [...action.motorBrandsForSearch]};
    case types.LOAD_LOOKUP_siteSettings_SUCCESS:
      return {...state, siteSettings: {...action.siteSettings}};
    case types.LOAD_LOOKUP_driveMechanisms_SUCCESS:
      return {...state, driveMechanisms: [...action.driveMechanisms]};
    case types.LOAD_LOOKUP_quoteAddons_SUCCESS:
      return {...state, quoteAddons: [...action.quoteAddons]};
    default:
      return state;
  }
}
