import {
  COLOUR_STANDARD_CUSTOM_ID, hasInstallationPosts, IsNullOrUndefined,
} from '../../constants';
import {colourValidation, validationCreator} from "./AwningValidators";

const validRanges = {
  backHeightMin: 500,
  backHeightMax: 4500,
  frontHeightMin: 500,
  frontHeightMax: 3500,
  //TODO: What are these max/mins?
  verticalScreenWidthMin: 100,
  verticalScreenWidthMax: 3000,
  verticalScreenProjectionMin: 100,
  verticalScreenProjectionMax: 3000,
}


const ValidationRules = {
  backHeight: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.backHeightMin || val > validRanges.backHeightMax ?
          validationCreator(false, `Please enter a Back Height between ${validRanges.backHeightMin} and ${validRanges.backHeightMax}`) : validationCreator();
    }
  },
  frontHeight: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.frontHeightMin || val > validRanges.frontHeightMax ?
          validationCreator(false, `Please enter a Front Height between ${validRanges.frontHeightMin} and ${validRanges.frontHeightMax}`) : validationCreator();
    }
  },
  fallBackToFront: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a fall back to front`) : validationCreator();
    }
  },
  frontDifferential: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a front differential`) : validationCreator();
    }
  },
  freeStandingType: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a free standing option`) : validationCreator();
    }
  },
  isQuatroFinish: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a quattro finish option`) : validationCreator();
    }
  },
  windbreakColourId: {
    validator: (val, state) => {      
      return colourValidation(val,state);
    }
  },
  hasFrontGutter: {
    validator: (val, state) => {
      let hasPosts = hasInstallationPosts(state.awning, state.fieldInstallationOptions);

      if(val===false && hasPosts){
        return validationCreator(false, `Please enter a front gutter option`);
      }
      
      return IsNullOrUndefined(val)  ?
          validationCreator(false, `Please enter a front gutter option`) : validationCreator();
    }
  },
  hasInfillPanelLeft: {
    validator: (val, state) => {
      if(state.awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Left') < 0){
        return validationCreator();
      }
      
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a left infill panel option`) : validationCreator();
    }
  },
  additionalWindbreakCount: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter an additional windbreak option`) : validationCreator();
    }
  },
  additionalFrontPostCount: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter an additional front post option`) : validationCreator();
    }
  },
  additionalBackPostCount: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter an additional back post option`) : validationCreator();
    }
  },
  additionalSidePostCount: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter an additional side post option`) : validationCreator();
    }
  },
  heaterCount: {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a heater option`) : validationCreator();
    }
  },
  hasHeaterExtension: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) && sintesi.heaterCount > 0  ?
          validationCreator(false, `Please enter a heater extension channel option`) : validationCreator();
    }
  },
  hasAudioSet: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a audio set option`) : validationCreator();
    }
  },
  hasInfillPanelRight: {
    validator: (val, state) => {
      if(state.awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Right') < 0){
        return validationCreator();
      }
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a right infill panel option`) : validationCreator();
    }
  },
  hasInfillPanelBack: {    
    validator: (val, state) => {
      if(state.awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Back') < 0){
        return validationCreator();
      }
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a back infiil panel option`) : validationCreator();
    }
  },
  infillPanelMaterial: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) && (sintesi.hasInfillPanelLeft || sintesi.hasInfillPanelRight  || sintesi.hasInfillPanelBack ) ?
          validationCreator(false, `Please enter a infiil panel material option`) : validationCreator();
    }
  },
  verticalScreenIsManual: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) && sintesi.verticalScreens.length > 0 ?
          validationCreator(false, `Please select a vertical screen drive option`) : validationCreator();
    }
  },
  verticalScreenFabricBrandId: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) && sintesi.verticalScreens.length > 0 ?
          validationCreator(false, `Please select a vertical screen fabric brand`) : validationCreator();
    }
  },
  verticalScreenFabricId: {
    validator: (val, state, sintesi) => {
      return IsNullOrUndefined(val) && sintesi.verticalScreens.length > 0 ?
          validationCreator(false, `Please select a vertical screen fabric option`) : validationCreator();
    }
  },
  "verticalScreens_1_sideOfOperation": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a side of operation`) : validationCreator();
    }
  },
  "verticalScreens_1_width": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenWidthMin || val > validRanges.verticalScreenWidthMax ?
          validationCreator(false, `Please enter a vertical screen width between ${validRanges.verticalScreenWidthMin} and ${validRanges.verticalScreenWidthMax}`) : validationCreator();
    }
  },
  "verticalScreens_1_projection": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenProjectionMin || val > validRanges.verticalScreenProjectionMax ?
          validationCreator(false, `Please enter a vertical screen projection between ${validRanges.verticalScreenProjectionMin} and ${validRanges.verticalScreenProjectionMax}`) : validationCreator();
    }
  },
  "verticalScreens_2_sideOfOperation": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a side of operation`) : validationCreator();
    }
  },
  "verticalScreens_2_width": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenWidthMin || val > validRanges.verticalScreenWidthMax ?
          validationCreator(false, `Please enter a vertical screen width between ${validRanges.verticalScreenWidthMin} and ${validRanges.verticalScreenWidthMax}`) : validationCreator();
    }
  },
  "verticalScreens_2_projection": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenProjectionMin || val > validRanges.verticalScreenProjectionMax ?
          validationCreator(false, `Please enter a vertical screen projection between ${validRanges.verticalScreenProjectionMin} and ${validRanges.verticalScreenProjectionMax}`) : validationCreator();
    }
  },
  "verticalScreens_3_sideOfOperation": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a side of operation`) : validationCreator();
    }
  },
  "verticalScreens_3_width": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenWidthMin || val > validRanges.verticalScreenWidthMax ?
          validationCreator(false, `Please enter a vertical screen width between ${validRanges.verticalScreenWidthMin} and ${validRanges.verticalScreenWidthMax}`) : validationCreator();
    }
  },
  "verticalScreens_3_projection": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) || val < validRanges.verticalScreenProjectionMin || val > validRanges.verticalScreenProjectionMax ?
          validationCreator(false, `Please enter a vertical screen projection between ${validRanges.verticalScreenProjectionMin} and ${validRanges.verticalScreenProjectionMax}`) : validationCreator();
    }
  },
  "verticalScreens_4_sideOfOperation": {
    validator: (val, state) => {
      return IsNullOrUndefined(val) ?
          validationCreator(false, `Please enter a side of operation`) : validationCreator();
    },
    "verticalScreens_4_width": {
      validator: (val, state) => {
        return IsNullOrUndefined(val) || val < validRanges.verticalScreenWidthMin || val > validRanges.verticalScreenWidthMax ?
            validationCreator(false, `Please enter a vertical screen width between ${validRanges.verticalScreenWidthMin} and ${validRanges.verticalScreenWidthMax}`) : validationCreator();
      }
    },
    "verticalScreens_4_projection": {
      validator: (val, state) => {
        return IsNullOrUndefined(val) || val < validRanges.verticalScreenProjectionMin || val > validRanges.verticalScreenProjectionMax ?
            validationCreator(false, `Please enter a vertical screen projection between ${validRanges.verticalScreenProjectionMin} and ${validRanges.verticalScreenProjectionMax}`) : validationCreator();
      }
    }
  }
  
};

export const GetSintesiValidationMessages = (sintesi, state, fieldName = null) => {    
  let messages = {};  
  
  for (let propName in ValidationRules) {
    if(IsNullOrUndefined(fieldName) || propName === fieldName) {
      let rule = ValidationRules[propName];
      let val = null;
      let skip = false;
      
      if(propName.startsWith("verticalScreens_")){
        let s = propName.split("_");
        let screen = sintesi.verticalScreens.find(x => x.sideType === parseInt(s[1]));
        if(!IsNullOrUndefined(screen)){
          val = screen[s[2]];
        } else {
          skip = true;
        }
      } 
      else if (propName === "windbreakColourId") {
            val = {
              selectedStandardColourId: sintesi.windbreakColourId,
              selectedCustomColourId: sintesi.windbreakCustomColourId,
            };
      }
      else {
        val = sintesi[propName];
      }
      
      if(!skip) {
        let valResult = rule.validator(val, state, sintesi);
        if (!valResult.isValid) {
          messages[propName] = valResult.message;
        }
      }
    }
  }

  return messages;
};