export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_ORGANISATIONS_SUCCESS = 'LOAD_ORGANISATIONS_SUCCESS';

export const LOAD_LOOKUP_FABRICMAKERS_SUCCESS = 'LOAD_LOOKUP_FABRICMAKERS_SUCCESS';
export const LOAD_LOOKUP_POWDERCOATERS_SUCCESS = 'LOAD_LOOKUP_POWDERCOATERS_SUCCESS';
export const LOAD_LOOKUP_DISPATCHMETHODS_SUCCESS = 'LOAD_LOOKUP_DISPATCHMETHODS_SUCCESS';
export const LOAD_LOOKUP_organisationStatuses_SUCCESS = 'LOAD_LOOKUP_organisationStatuses_SUCCESS';
export const LOAD_LOOKUP_creditApplicationStatuses_SUCCESS = 'LOAD_LOOKUP_creditApplicationStatuses_SUCCESS';
export const LOAD_LOOKUP_creditTerms_SUCCESS = 'LOAD_LOOKUP_creditTerms_SUCCESS';
export const LOAD_LOOKUP_dispatchCentres_SUCCESS = 'LOAD_LOOKUP_dispatchCentres_SUCCESS';
export const LOAD_LOOKUP_dispatchTypes_SUCCESS = 'LOAD_LOOKUP_dispatchTypes_SUCCESS';
export const LOAD_LOOKUP_states_SUCCESS = 'LOAD_LOOKUP_states_SUCCESS';
export const LOAD_LOOKUP_paymentMethods_SUCCESS = 'LOAD_LOOKUP_paymentMethods_SUCCESS';
export const LOAD_LOOKUP_productsSearch_SUCCESS = 'LOAD_LOOKUP_productsSearch_SUCCESS';
export const LOAD_LOOKUP_coloursSearch_SUCCESS = 'LOAD_LOOKUP_coloursSearch_SUCCESS';
export const LOAD_LOOKUP_standardColoursForSearch_SUCCESS = 'LOAD_LOOKUP_standardColoursForSearch_SUCCESS';
export const LOAD_LOOKUP_motorBrandsSearch_SUCCESS = 'LOAD_LOOKUP_motorBrandsSearch_SUCCESS';
export const LOAD_LOOKUP_driveMechanisms_SUCCESS = 'LOAD_LOOKUP_driveMechanisms_SUCCESS';
export const LOAD_LOOKUP_siteSettings_SUCCESS = 'LOAD_LOOKUP_siteSettings_SUCCESS';
export const LOAD_LOOKUP_quoteAddons_SUCCESS = 'LOAD_LOOKUP_quoteAddons_SUCCESS';

export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';
export const LOAD_SUPPLIER_ORDERS_SUCCESS = 'LOAD_SUPPLIER_ORDERS_SUCCESS';
export const SUPPLIER_ORDERS_UPDATE_ORDER = 'SUPPLIER_ORDERS_UPDATE_ORDER';
export const LOADING_SUPPLIER_ORDERS = 'LOADING_SUPPLIER_ORDERS_SUCCESS';
export const ORDERS_UPDATE_FILTER = 'ORDERS_UPDATE_FILTER';
export const SUPPLIER_ORDERS_UPDATE_FILTER = 'SUPPLIER_ORDERS_UPDATE_FILTER';
export const ORDERS_UPDATE_NONPOSTFILTER = 'ORDERS_UPDATE_NONPOSTFILTER';
export const SUPPLIER_ORDERS_UPDATE_NONPOSTFILTER = 'SUPPLIER_ORDERS_UPDATE_NONPOSTFILTER';

export const LOAD_SPARES_SUCCESS = 'LOAD_SPARES_SUCCESS';
export const SPARES_UPDATE_FILTER = 'SPARES_UPDATE_FILTER';

export const IDENTITY_LOGIN = 'IDENTITY_LOGIN';
export const IDENTITY_LOGOUT = 'IDENTITY_LOGOUT';

export const RESOURCECART_ADD = 'RESOURCECART_ADD';
export const RESOURCECART_REMOVE = 'RESOURCECART_REMOVE';
export const RESOURCECART_CLEAR = 'RESOURCECART_CLEAR';
export const RESOURCECART_OPENCART = 'RESOURCECART_OPENCART';
export const RESOURCECART_CLOSECART = 'RESOURCECART_CLOSECART';

export const HTTP_INFO_SET_COUNT = "HTTP_INFO_SET_COUNT";
export const HTTP_INFO_PENDING_START = "HTTP_INFO_PENDING_START";
export const HTTP_INFO_PENDING_END = "HTTP_INFO_PENDING_END";


export const SUPPORT_REQUESTS_LOAD_SUCCESS = 'SUPPORT_REQUESTS_LOAD_SUCCESS';
export const SUPPORT_REQUESTS_UPDATE_FILTER = 'SUPPORT_REQUESTS_UPDATE_FILTER';
export const SUPPORT_REQUESTS_LOADPRODUCTS = 'SUPPORT_REQUESTS_LOADPRODUCTS';