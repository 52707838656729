import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/lib/connect/connect";
import {CalculateFinishedProjection, CanHaveWedges, isNullEmptyOrWhitespace, TextInputParseInt} from "../../constants";
import TextBoxControl from "../common/TextBoxControl";
import CheckBoxControl from "../common/CheckBoxControl";
import TextViewControl from "../common/TextViewControl";
import {Link} from "react-router-dom";
import {min, max} from "lodash";

const MinAngles = [{
    minWidth: 1400,
    maxWidth: 4500,
    minAngle: 6,
    minFinishedProjection: 1000,
    maxFinishedProjection: 3730,
}, {
    minWidth: 1400,
    maxWidth: 4000,
    minAngle: 6,
    minFinishedProjection: 3731,
    maxFinishedProjection: 4420,
}, {
    minWidth: 1400,
    maxWidth: 3500,
    minAngle: 6,
    minFinishedProjection: 4421,
    maxFinishedProjection: 5110,
}, {
    minWidth: 1400,
    maxWidth: 3500,
    minAngle: 7,
    minFinishedProjection: 5111,
    maxFinishedProjection: 6800,
}, {
    minWidth: 3501,
    maxWidth: 4000,
    minAngle: 7,
    minFinishedProjection: 4421,
    maxFinishedProjection: 5800,
}, {
    minWidth: 4001,
    maxWidth: 4500,
    minAngle: 7,
    minFinishedProjection: 3731,
    maxFinishedProjection: 5800,
}, {
    minWidth: 3501,
    maxWidth: 4500,
    minAngle: 8,
    minFinishedProjection: 5801,
    maxFinishedProjection: 6800,
}, {
    minWidth: 4501,
    maxWidth: 6000,
    minAngle: 8,
    minFinishedProjection: 1000,
    maxFinishedProjection: 6800,
}];

class SintesiLookupPage extends Component {
    constructor(props, context) {
        super(props, context);

        let minWidth = min(MinAngles.map(x => x.minWidth));
        let maxWidth = max(MinAngles.map(x => x.maxWidth));
        let minFinishedProjection = min(MinAngles.map(x => x.minFinishedProjection));
        let maxFinishedProjection = max(MinAngles.map(x => x.maxFinishedProjection));
        
        this.state = {
            horizontalProjection: null, 
            backHeight: null,
            frontHeight: null, 
            fallBackToFront: null, 
            hasPosts: false,
            width: null,
            calculated: {
                finishedProjection: null, 
                finishedAngle: null,
                minAngle: null,
                isDangerZone: false,
                isOutOfRange: false,
            },     
            ranges: {
                minWidth,
                maxWidth,
                minFinishedProjection,
                maxFinishedProjection,
            }
        };
        
        this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
        this.selectMinAngle = this.selectMinAngle.bind(this);
    }
    
    handleFieldUpdate(e){
        let newState = {...this.state};
     
        if(e.target.type==="checkbox"){
            newState[e.target.name] = e.target.checked;
        } else {
            newState[e.target.name] = TextInputParseInt(e.target.value);
        }

        let {horizontalProjection, backHeight, frontHeight, fallBackToFront, hasPosts } = newState;        
        
        let calculated = CalculateFinishedProjection(horizontalProjection, backHeight,
            frontHeight, fallBackToFront, hasPosts);
        
        let nextState = this.selectMinAngle({...newState, calculated});
        
        this.setState(nextState);
    }    
    
    selectMinAngle(nextState) {
        let {finishedProjection, finishedAngle, isOutOfRange} = nextState.calculated;
        let {horizontalProjection, backHeight, frontHeight, fallBackToFront, hasPosts, width} = nextState;

        let minAngle = min(MinAngles
            .filter(x => width >= x.minWidth && width <= x.maxWidth)
            .filter(x => finishedProjection >= x.minFinishedProjection && finishedProjection <= x.maxFinishedProjection)
            .map(x => x.minAngle));

        isOutOfRange = (minAngle === undefined && finishedProjection > 0) || horizontalProjection < 1000;
        let isDangerZone = (finishedAngle < minAngle) || isOutOfRange;
        
        return {...nextState, calculated: {...nextState.calculated, minAngle, isDangerZone, isOutOfRange}};
    }
  
    render(){
        let {horizontalProjection,backHeight, frontHeight, fallBackToFront, hasPosts, width, calculated, ranges } = this.state;
        let {finishedProjection, finishedAngle, minAngle, isDangerZone, isOutOfRange} = calculated;        
        
        return (<div>
            <h4 style={{marginTop: '10px'}}><Link to={'/products/armbracketspacing'}>Awning Specifications</Link> | <Link to={`/fabrics/lookup`}>Fabric Lookup</Link> | <Link to={`/colours/lookup`}>Colour Lookup</Link> | Sintesi Lookup
            </h4>
            <p>Fill in the required details and the Sintesi finished projection and angle will be displayed</p>

            <div className={"row mb-5"}>
                <div className={"col-md-10"}>
                    <img src={"img/ST_lookup_diagram.png"} alt={"Sintesi Diagram"} />
                </div>
            </div>
            
            <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handleFieldUpdate}
                labelText="Width"
                value={width}
                name="width"
                type="number"
            />
            <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handleFieldUpdate}
                labelText="Horizontal Projection (is back of headbox to end of guiderail)"
                value={horizontalProjection}
                name="horizontalProjection"
                type="number"                
            />
            <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handleFieldUpdate}
                labelText="Back Height"
                value={backHeight}
                name="backHeight"
                type="number"
            />
            <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handleFieldUpdate}
                labelText={"Front Height"}
                value={frontHeight}
                name="frontHeight"
                type="number"
            />
            <TextBoxControl
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handleFieldUpdate}
                labelText="Fall (back to front)"
                value={fallBackToFront}
                name="fallBackToFront"
                type="number"
            />
            {/*<CheckBoxControl*/}
            {/*    onChange={this.handleFieldUpdate}*/}
            {/*    labelText="Has Posts"*/}
            {/*    value={hasPosts}*/}
            {/*    name="hasPosts"              */}
            {/*/>*/}
            
            <div className={"row mb-3"}><div className={"col-12"}>&nbsp;</div></div>

            { finishedProjection > 0 &&
                <div className={`alert alert-info`} role="alert">
                    <TextViewControl
                        labelText={"Finished Projection (calculated)"}
                        value={finishedProjection} />
                </div> }

            { minAngle > 0 &&
                <div className="alert alert-info" role="alert">
                    <TextViewControl
                        labelText={"Minimum Specified Angle"}
                        value={minAngle.toString() + " degrees"} />
                </div> }

            { isOutOfRange > 0 &&
                <div className="alert alert-warning" role="alert">
                    Current values are out of range, these are:
                    <ul>
                        <li>Width must be between <strong>{ranges.minWidth}mm - {ranges.maxWidth}mm</strong></li>
                        <li>Horizontal projection must greater or equal to <strong>1000mm</strong></li>
                        <li>Finished projection must be less than <strong>{ranges.maxFinishedProjection}mm</strong></li>
                    </ul>
                </div> }
            
            { finishedAngle > 0 &&
                <div className={`alert alert-${isDangerZone ? "danger" : "success"}`} role="alert">
                    <TextViewControl
                        labelText={"Finished Angle (calculated)"}
                        value={finishedAngle.toString() + " degrees"} />
                    { isDangerZone && !isOutOfRange && <div>
                        The finished angle is less than the Minimum Specified Angle, please adjust the dimensions 
                    </div> }
                    { isOutOfRange && <div>
                        Minimum Specified Angle cannot be calculated as values are out of range
                    </div> }
                </div> }
            
        </div>);
    }
}



SintesiLookupPage.propTypes = {
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SintesiLookupPage));