import React, {Component} from 'react';
import {
  PRODUCTGROUPID_CONSERVATORY, PRODUCTGROUPID_FOLDING_ARM,
} from "../../constants";

import DiagramSD from "./DiagramSD";
import DiagramFA, {GetDataForFAModel} from "./DiagramFA";
import ReactJson from "react-json-view";

class AwningDiagramTestHarness extends Component {
  constructor(props, context) {
    super(props, context);

  }

  render() {

    let awning = {
      "width": 4000,
      "outsideArmSpacing": 0,
      "numberOfArms": 0,
      "bracketTotal": 0,
      "insideArmSpacing": 0,
      "middleArmSideDescription": "Left",
      "productArmBracketDeadSpace": 0,
      "mountingBracketWidth": 0,
      "number": 1,
      "productId": 9,
      "projection": 2000,
      "driveMechanismId": null,
      "id": 0,
      "installationBracketId": null,
      "motorId": null,
      "productGroupId": 2,
      "frameFeature": 6,
      "drop": null,
      "frameFeatureCrankHandleId": null,
      "frameFeatureDriveMechanismId": null,
      "frameFeatureSideOfOperation": null,
      "frameFeatureFabricBrandId": null,
      "frameFeatureFabricId": null,
      "frameFeatureFieldType": 7,
      "frameFeatureSteerAroundType": null,
      "fabricValance": null,
      "trimBiasColourId": null,
      "fieldConfiguration": {
        "numberOfFields": 3,
        "numberOfMotors": 2,
        "maxWidth": 6000,
        "minWidth": 1350,
        "instBracketPermutationId": null,
        "fields": [
          {
            "number": 1,
            "width": 4000,
            "sideOfOperation": "Left",
            "isMotorRequired": true
          },
          {
            "number": 2,
            "width": 2000,
            "sideOfOperation": null
          },
          {
            "number": 3,
            "width": 2000,
            "sideOfOperation": null
          }
        ],
        "sideOfOperation": "Right"
      },
      "sideOfOperation": "Right",
      "steerAroundDimensions": null,
      "crankHandleId": null,
      "fabricBrandId": null,
      "fabricId": null,
      "installationBracketTypeId": null,
      "desiredRemoteColour": null,
      "remote": null,
      "includePelmet": false,
      "pelmetColourId": null,
      "pelmetCustomColourId": null,
      "additionalCradles": 0,
      "hasAdditionalCradles": false,
      "additionalMountingBrackets": 0,
      "productDescription": "Sundream",
      "productLookupDataId": 1035,
      "mountingBracketDescription": "Standard",
      "mountingBracketId": 36,
      "sections": 1,
      "isAssembled": true,
      "numberOfMountingBracket": 4,
      "averageLoadPerBracket": 0,
      "weightExcMotor": 57,
      "numberOfCradles": 0,
      "intermediateRoller": 0
    };
    let awningSingle = {"sideOfOperation": "Right", "width":4000,"outsideArmSpacing":0,"numberOfArms":0,"bracketTotal":0,"insideArmSpacing":0,"middleArmSideDescription":"Left","productArmBracketDeadSpace":0,"mountingBracketWidth":0,"number":1,"productId":9,"projection":2000,"driveMechanismId":null,"id":0,"installationBracketId":null,"motorId":null,"productGroupId":2,"frameFeature":null,"drop":null,"frameFeatureCrankHandleId":null,"frameFeatureDriveMechanismId":null,"frameFeatureSideOfOperation":null,"frameFeatureFabricBrandId":null,"frameFeatureFabricId":null,"frameFeatureFieldType":null,"frameFeatureSteerAroundType":null,"fabricValance":null,"trimBiasColourId":null,"fieldConfiguration":null,"steerAroundDimensions":null,"crankHandleId":null,"fabricBrandId":null,"fabricId":null,"installationBracketTypeId":null,"desiredRemoteColour":null,"remote":null,"includePelmet":false,"pelmetColourId":null,"pelmetCustomColourId":null,"additionalCradles":0,"hasAdditionalCradles":false,"additionalMountingBrackets":0,"productDescription":"Sundream","productLookupDataId":1035,"mountingBracketDescription":"Standard","mountingBracketId":36,"sections":1,"isAssembled":true,"numberOfMountingBracket":4,"averageLoadPerBracket":0,"weightExcMotor":61,"numberOfCradles":0};

    let ecAwning = {
      "productGroupDescription": "Folding Arm",
      "productDescription": "Eco",
      "driveMechanismDescription": "Motorised – Hardwired",
      "crankHandleDescription": null,
      "frameColourDescription": "Silver [STD] (Dulux Citi Pearl 97188471 Matt)",
      "frameColourShortDescription": "Silver [STD]",
      "frameCustomColourDescription": null,
      "pelmetColourDescription": null,
      "pelmetColourShortDescription": null,
      "pelmetCustomColourDescription": null,
      "flashingColourDescription": null,
      "flashingColourShortDescription": null,
      "flashingCustomColourDescription": null,
      "fabricSuppliedByDescription": "Aluxor to supply fabric",
      "fabricBrandDescription": "Dickson Infinity",
      "fabricDescription": "Black (Noir) (DC-INF-6028-320)",
      "mountingBracketDescription": "Universal",
      "numberOfMountingBracket": 2,
      "sdSingleTracks": null,
      "sdCoupledTracks": null,
      "bracketTotal": 2,
      "numberOfArms": 2,
      "outsideArmSpacing": 600,
      "insideArmSpacing": 0,
      "numberOfCradles": 0,
      "weightExcMotor": 40,
      "myobFrame": "1020402000",
      "myobAwning": "2020402000",
      "myobFabricSurcharge": "5010402000",
      "gearboxIfManual": "7:1",
      "weightPelmet": 7,
      "oversquare": false,
      "remoteDescription": null,
      "isAwning": true,
      "defaultSections": 1,
      "costs": {
        "name": null,
        "awningId": 2245,
        "fabricSuppliedBy": 1,
        "awningFrameRetail": 2724.0000,
        "awningFrameWholesale": 1635.0,
        "awningFrameCode": "2020402000",
        "frameBaseCustomColourRetail": null,
        "frameBaseCustomColourWholesale": null,
        "frameBaseCustomColourCode": null,
        "frameCustomColourRetail": null,
        "frameCustomColourWholesale": null,
        "frameCustomColourCode": null,
        "pelmetBaseRetail": null,
        "pelmetBaseWholesale": null,
        "pelmetBaseCode": null,
        "pelmetCustomBaseRetail": null,
        "pelmetCustomBaseWholesale": null,
        "pelmetCustomBaseCode": null,
        "pelmetCustomRetail": null,
        "pelmetCustomWholesale": null,
        "pelmetCustomCode": null,
        "flashingBaseRetail": null,
        "flashingBaseWholesale": null,
        "flashingBaseCode": null,
        "flashingCustomBaseRetail": null,
        "flashingCustomBaseWholesale": null,
        "flashingCustomBaseCode": null,
        "flashingCustomRetail": null,
        "flashingCustomWholesale": null,
        "flashingCustomCode": null,
        "fabricFittingRetail": null,
        "fabricFittingWholesale": null,
        "fabricFittingCode": null,
        "fabricSurchargeRetail": 252.0000,
        "fabricSurchargeWholesale": 152.0,
        "fabricSurchargeCode": "5010402000",
        "motorRetail": 0.0000,
        "motorWholesale": null,
        "motorCode": "Motor EC H1",
        "isMotorRequired": true,
        "installationBracketsRetail": null,
        "installationBracketsWholesale": null,
        "installationBracketsCode": null,
        "installationBracketsQuantity": 2,
        "mountingBracketSurchargeBaseRetail": null,
        "mountingBracketSurchargeBaseWholesale": null,
        "mountingBracketSurchargeBaseCode": null,
        "mountingBracketSurchargeBaseQuantity": 2,
        "mountingBracketSurchargeAddRetail": 90.0000,
        "mountingBracketSurchargeAddWholesale": 54.0,
        "mountingBracketSurchargeAddCode": "0000601001",
        "mountingBracketSurchargeAddQuantity": 0,
        "frameFeatureAwningFrameRetail": null,
        "frameFeatureAwningFrameWholesale": null,
        "frameFeatureAwningFrameCode": null,
        "frameFeatureFabricSurchargeRetail": null,
        "frameFeatureFabricSurchargeWholesale": null,
        "frameFeatureFabricSurchargeCode": null,
        "motorJoinerSurchargeRetail": null,
        "motorJoinerSurchargeWholesale": null,
        "motorJoinerSurchargeCode": null,
        "joinerRetail": null,
        "joinerWholesale": null,
        "joinerCode": null,
        "stabiliserPolesRetail": null,
        "stabiliserPolesWholesale": null,
        "stabiliserPolesCode": null,
        "cradlesRetail": null,
        "cradlesWholesale": null,
        "cradlesCode": null,
        "cradlesQuantity": 0,
        "crankHandleRetail": null,
        "crankHandleWholesale": null,
        "crankHandleCode": null,
        "frameFeatureCrankHandleRetail": null,
        "frameFeatureCrankHandleWholesale": null,
        "frameFeatureCrankHandleCode": null,
        "multiRemoteSurchargeRetail": null,
        "multiRemoteSurchargeWholesale": null,
        "multiRemoteSurchargeCode": null,
        "mocoTypeRetail": null,
        "mocoTypeWholesale": null,
        "mocoTypeCode": null,
        "ledLightingRetail": null,
        "ledLightingWholesale": null,
        "ledLightingCode": null,
        "motorFittingRetail": null,
        "motorFittingWholesale": null,
        "motorFittingCode": null,
        "motorSurchargeRetail": null,
        "motorSurchargeWholesale": null,
        "motorSurchargeCode": null,
        "motorSurchargeDescription": null,
        "motorSurchargeSaRetail": null,
        "motorSurchargeSaWholesale": null,
        "motorSurchargeSaCode": null,
        "awningSurchargeRetail": null,
        "awningSurchargeWholesale": null,
        "awningSurchargeCode": null,
        "awningSurchargeDescription": null,
        "installationBracketSurchargeRetail": null,
        "installationBracketSurchargeWholesale": null,
        "installationBracketSurchargeCode": null,
        "installationBracketSurchargeDescription": null,
        "installationBracketOptionRetail": null,
        "installationBracketOptionWholesale": null,
        "installationBracketOptionCode": null,
        "installationBracketOptionDescription": null,
        "autoCableRetail": null,
        "autoCableWholesale": null,
        "autoCableCode": null,
        "rainCoverRetail": null,
        "rainCoverWholesale": null,
        "rainCoverCode": null,
        "isAwning": true,
        "organisationDiscount": 42,
        "mountingBracketSurcharge": 0.0000,
        "mountingBracketSurchargeWholesale": 0.0,
        "installationBracketsTotal": null,
        "installationBracketsTotalWholesale": null,
        "cradlesTotal": 0.0,
        "cradlesTotalWholesale": 0.0,
        "totalRetail": 2976.0000,
        "totalWholesale": 1787.0
      },
      "motorDescription": null,
      "motorBrandId": null,
      "motorBrandDescription": null,
      "trimBiasColourDescription": null,
      "fabricValanceDescription": "Straight",
      "frameFeatureDescription": null,
      "frameFeatureDriveMechanismDescription": null,
      "frameFeatureFabricBrandDescription": null,
      "frameFeatureFabricDescription": null,
      "mocoTypeDescription": null,
      "automationCableDescription": "2.5M white (standard)",
      "installationBracketDescription": null,
      "hasAdditionalCradles": false,
      "sensorTypeDescription": null,
      "frameFeatureCrankHandleDescription": null,
      "cuttingDimension": {
        "awningId": 2245,
        "width": 4000,
        "projection": 2000,
        "drop": null,
        "sections": 1,
        "isAssembled": true,
        "backPanel": null,
        "bottomPanel": null,
        "bottomShell": null,
        "centrePanel": null,
        "fabricWidth": -100,
        "frontBar": -67,
        "pelmet": 20,
        "pelmetFlashing": 20,
        "roller": -67,
        "selectapitchRod": null,
        "skirt": null,
        "supportBar": -16,
        "topPanel": null,
        "topShell": null,
        "vvDropRail": null,
        "vvFabricScreen": null,
        "vvFrontRail": null,
        "vvRoller": null,
        "frontRail": null,
        "steerAroundRoller": null,
        "intermediateRoller": null,
        "sdTracksSingle": null,
        "sdTracksCoupled": null,
        "maxiTracks": null,
        "rainCover": null,
        "crossRail": null,
        "postHeight": null,
        "zipGuidance": null,
        "fabricProjection": null,
        "installationBracketLength": null,
        "sdTracksSingleQty": null,
        "sdTracksCoupledQty": null,
        "cuttingBackPanel": null,
        "cuttingBottomPanel": null,
        "cuttingBottomShell": null,
        "cuttingCentrePanel": null,
        "cuttingWidth": 3900,
        "cuttingFrontBar": 3933,
        "cuttingSupportBar": 3984,
        "cuttingRoller": 3933,
        "cuttingSelectapitchRod": null,
        "cuttingPelmet": 4020,
        "cuttingPelmetFlashing": 4020,
        "cuttingVVFabricScreen": null,
        "cuttingVVFrontRail": null,
        "cuttingVVDropRail": null,
        "cuttingVVRoller": null,
        "cuttingSkirt": null,
        "cuttingTopPanel": null,
        "cuttingTopShell": null,
        "cuttingWithJoinerFrontBar": 3933,
        "cuttingWithJoinerSupportBar": 3984,
        "cuttingWithJoinerRoller": 3933,
        "cuttingWithJoinerPelmet": 4020,
        "cuttingWithJoinerPelmetFlashing": 4020,
        "cuttingWithTopPanel": null,
        "cuttingWithBackPanel": null,
        "cuttingWithCentrePanel": null,
        "cuttingWithJoinerSkirt": null,
        "cuttingFrontRail": null,
        "cuttingSteerAroundRoller": null,
        "cuttingIntermediateRoller": null,
        "cuttingSDTracksSingle": null,
        "cuttingFabricProjection": null,
        "cuttingSDTracksCoupled": null,
        "cuttingMaxiTracks": null,
        "cuttingRainCover": null,
        "cuttingCrossRail": null,
        "cuttingZipGuidance": null,
        "cuttingPostHeight": null
      },
      "extrusionSmallSize": 855,
      "extrusionLargeSize": 920,
      "mountingBracketPcRef": "FA_UNIV",
      "installationBracketPcRef": null,
      "pcSections": null,
      "isImported": false,
      "awningImport": null,
      "frameFeatureShortName": null,
      "productShortName": "EC",
      "mountingBracketComponentMultiplier": 1,
      "installationBracketTypeId": null,
      "installationBracketTypeDescription": null,
      "fabricSupports": null,
      "flashingTypeDescription": "No",
      "pelmetName": "Pelmet",
      "canMotorFittingCharge": false,
      "fabricInstructionDescription": "Top and bottom pocket 6mm spline",
      "averageLoadPerBracket": 54,
      "fabricIsStocked": true,
      "frameFeatureFabricIsStocked": false,
      "mountingBracketWidth": 60,
      "productArmBracketDeadSpace": 40,
      "myobRemoteLookupDescription": null,
      "myobRemoteLookupSupplierCode": null,
      "isInSet": false,
      "isInSetAndFirstAwning": false,
      "organisationFabricMakerId": null,
      "organisationShortName": "ALX",
      "motorSpring": null,
      "topProfileSupport": null,
      "intermediateRoller": null,
      "frameFeatureSteerAroundRollers": null,
      "frameFeatureMotorDescription": null,
      "installationBracketOption": null,
      "parentOrderAwningId": null,
      "fieldNumber": null,
      "orderId": 1210,
      "number": 1,
      "width": 4000,
      "productGroupId": 1,
      "productLookupDataId": 294,
      "productId": 1,
      "projection": 2000,
      "driveMechanismId": 2,
      "crankHandleId": null,
      "sideOfOperation": "Right",
      "remote": null,
      "frameColourId": 2,
      "frameCustomColourId": null,
      "includePelmet": false,
      "pelmetColourId": null,
      "pelmetCustomColourId": null,
      "flashingType": 0,
      "flashingColourId": null,
      "flashingCustomColourId": null,
      "fabricSuppliedBy": 1,
      "fabricBrandId": 27,
      "fabricId": 1036,
      "fabricValance": 1,
      "fabricValanceDepth": null,
      "mountingBracketId": 2,
      "additionalMountingBrackets": 0,
      "wallSwitch": "Wall Switch",
      "notes": [

      ],
      "noteText": null,
      "motorId": null,
      "trimBiasColourId": null,
      "frameFeature": null,
      "drop": null,
      "frameFeatureDriveMechanismId": null,
      "frameFeatureSideOfOperation": null,
      "frameFeatureFabricBrandId": null,
      "frameFeatureFabricId": null,
      "automationSensorType": null,
      "motionSensorColour": null,
      "automationCableId": 1,
      "mocoType": null,
      "includeLed": null,
      "includeStabiliserPoles": false,
      "includeAdditionalCrankHandle": false,
      "additionalCradles": 0,
      "installationBracketId": null,
      "frameFeatureCrankHandleId": null,
      "isAssembled": true,
      "sections": 1,
      "middleArmSide": 0,
      "middleArmSideDescription": "Not selected",
      "arms": [
        {
          "side": 1,
          "sideDescription": "Left"
        },
        {
          "side": 2,
          "sideDescription": "Right"
        }
      ],
      "awningFlashing": {
        "depth1": 60,
        "depth2": 20,
        "height": 130,
        "length": 4020,
        "colourDescription": null,
        "customColourDescription": null,
        "colorbondColourId": null,
        "colorbondColourDescription": null,
        "isZincalume": false
      },
      "motorFittingCharge": false,
      "fabricNote": null,
      "fabricInstruction": 1,
      "attachments": [

      ],
      "desiredRemoteColour": null,
      "myobRemoteLookupId": null,
      "fieldConfiguration": null,
      "frameFeatureFieldType": null,
      "frameFeatureSteerAroundType": null,
      "mountingBracketLength": null,
      "installationBracketLength": null,
      "instBracketPermutationId": null,
      "isMotorRequired": true,
      "steerAroundDimensions": null,
      "includeRainCover": null,
      "id": 2245,
      "createdDate": "2020-05-01T09:11:54.6436274+10:00",
      "updatedDate": "0001-01-01T00:00:00+00:00",
      "createdByPersonName": "Simon Pinn",
      "updatedByPersonName": "Simon Pinn"
    };

    let dataEcInput = {
      outsideArmSpacing: 140,
      width: 3000,
      numberOfArms: 2,
      insideArmSpacing: null,
      middleArmSide: 0,
      bracketTotal: 2,
      rollerBktEndCap: 42,
      armBktCentreToOutsideX1: 30,
      requiredSpaceMntBktX2: 55,
      toleranceX3: 10,
      armBktCentreToInside: 30,
      centreOfMountBkt: 27.5,
      allowancePosition: 40
    };

    let dataEcOutput = GetDataForFAModel(dataEcInput);

    return (
      <div>

        {/*  <DiagramSD awning={awning} width={800}/>

        <DiagramSD awning={awningSingle} width={800}/>*/}



        <ReactJson src={{dataEcInput, dataEcOutput}}
                   displayObjectSize={true}
                   displayDataTypes={false}
                   name={'Data'}
        />

        <DiagramFA awning={ecAwning} width={800} />

      </div>
    );
  }
}

export default AwningDiagramTestHarness;