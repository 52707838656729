import * as types from './actionTypes';
import {apiPost, apiPostNoAuth, apiPostWithToken} from '../utils/request';
import {toastr} from 'react-redux-toastr';
import { push } from 'react-router-redux';
import store from '../store';
import {obsfucate} from "../constants";

export function setCookie(token, dispatch, onSuccess = null) {
  apiPostWithToken(`/api/users/setcookie`, token)
    .then(y => {
      if (y.data && y.data.ok) {
        dispatch(loginSuccess(token));
        if(onSuccess){
            onSuccess();
        }
      } else {
        toastr.error('Unable to set cookie');
      }
    });
}

export function loginSuccess(token){
  return {type: types.IDENTITY_LOGIN, accessToken:token };
}

export function logoutSuccess(){
  return {type: types.IDENTITY_LOGOUT };
}

export function login(userName = null, password = null) {
  return function (dispatch, getState) {
    let login = {
      userName,
      password
    };

    let identity = getState().identity;

    if(!identity.accessToken && userName === null) {
      return apiPostNoAuth(`/api/users/token`)
        .then(x => {
          if (x.data && x.data.ok == undefined) {
            setCookie(x.data, dispatch);
          }
        });
    } else {
      return apiPost(`/api/users/login`, login)
        .then(x => {
          if (x.data && x.data.ok) {
            setCookie(x.data.token, dispatch);
          } else {
            toastr.error('Login incorrect');
          }
        });
    }
  };
}

export function loginWithOrderToken(orderToken, history) {
  return function (dispatch, getState) {

    return apiPost(`/api/users/orderToken?token=${orderToken}`)
      .then(x => {
        if (x.data && x.data.ok) {
          setCookie(x.data.token, dispatch);

          let s = x.data.orderUrl.split('/');
          let url = '';

          for(let i = 0; i < s.length; i++) {
            if(s[i] !== '') {
              if(i <= s.length - 2){
                url += '/' + s[i];
              } else {
                let r = new RegExp(/(\d+)(\?.*)?/g);
                let id = r.exec(s[i]);
                if(id !== null){
                  url += '/' + obsfucate(id[1]) + (id[2] ? id[2] : '');
                }
              }
            }
          }

          history.push(url);
        } else {
          toastr.error('Login incorrect');
        }
      });

  }
}

export function loginReset(password, userToken, history) {

  return function (dispatch) {
    let login = {
      password,
      userToken
    };

    return apiPost(`/api/users/loginReset`, login)
      .then(x => {
        if (x.data && x.data.ok == undefined) {
          setCookie(x.data, dispatch);
          history.push('/');
        } else {
          toastr.error(`Unable to reset ${x.data}`);
        }
      });

  }
}

export function logout(history) {
  return function (dispatch) {
    return apiPostNoAuth(`/api/users/signout`)
      .then(x => {
        dispatch(logoutSuccess());
        if(history) {
          history.push('/');
        }
      });
  };
}

export function logoutLogin() {
  return function (dispatch) {
    return apiPostNoAuth(`/api/users/signout`)
      .then(x => {
        dispatch(logoutSuccess());
        dispatch(login());
      });
  };
}

export function validateUserToken() {
  return function (dispatch) {
    return apiPostNoAuth(`/api/users/signout`)
      .then(x => {
        dispatch(logoutSuccess());
      });
  };
}
