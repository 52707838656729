import * as types from './actionTypes';
import {apiRequest} from '../utils/request';

export function loadOrganisationsSuccess(organisations){
  return {type: types.LOAD_ORGANISATIONS_SUCCESS, organisations};
}

export function loadOrganisations() {
  return function (dispatch) {
    return apiRequest(`/api/organisations`)
      .then(x => {
        if (x.data && x.data.ok === undefined) {
          dispatch(loadOrganisationsSuccess([...x.data]));
        }
      });
  };
}
