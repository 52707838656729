import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import TextViewControl from '../common/TextViewControl';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import {
  Aux,
  DATE_SHORT_FORMAT,
  DISPATCH_COLLECT,
  isNullEmptyOrWhitespace,
  ORDER_STATUS_IN_PRODUCTION,
  PRODUCTGROUPID_CONSERVATORY,
  PRODUCTGROUPID_FOLDING_ARM,
  PRODUCTGROUPID_ROOFING_SYSTEMS,
  REPORTSERVER_BASEURL,
  SpaceString
} from '../../constants';
import injectSheet from 'react-jss';
import jQuery from 'jquery';
import { chain, zipObject} from 'lodash';
import {Base64} from "js-base64";
import {apiDownloadReport} from "../../utils/request";
import {AwningProductSpecifications} from "./AwningProductSpecifications";
import DiagramFA from "../awningdiagrams/DiagramFA";
import DiagramSD from "../awningdiagrams/DiagramSD";
import DiagramST from "../awningdiagrams/DiagramST";


const styles = {
  divTable: {
    fontFamily: `Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif`,
    margin: '20px 0',
    fontSize: '10px',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
  },
  divThead: {
    display: 'table-header-group',
    fontWeight: '500',
    fontSize: '9px'
  },
  divTbody: {
    display: 'table-header-group',
    width: '100%',
  },
  divTr: {
    display: 'table-row',
    width: '100%',
    height: '25px'
  },
  divTrHead: {
    display: 'table-row',
    width: '100%'
  },
  divTh: {
    display: 'table-cell',
    padding: '0 10px',
    border: '1px solid #ddd',
  },
  divTd: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
  },
  'divTd:first-of-type': {
    borderLeft: '1px solid #ddd',
  },
  'divtbody .div-tr:nth-of-type(2n)': {
    background: '#eee',
  },
};

class OrderDispatchSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.printSection = React.createRef();
  }

  handlePrint() {

    let {order} = this.props;

    let container = jQuery(`<div></div>`);

    jQuery(this.printSection.current).clone().css('width', '97%').appendTo(container);

    container
      .css('font-size', '10px')
      //.css('padding-right', '20px')
      .css('margin-top', '5px')
      .css('padding-top', '25px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    container.find('h4').css('font-size', '12px');
    container.find('h5').css('font-size', '11px');
    container.find('h6').css('font-size', '10px');
    container.find('.pi-row:first-child').css('margin-top', '0px');

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_dispatchSheet.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });*/

    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${order.id}_dispatchSheet.pdf`);

    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {order, classes, print} = this.props;

    if(!order){
      return null;
    }

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-section">

              <div className="pi-row">
                <div className="pi-col-md-8">
                  <button onClick={this.handlePrint}
                          className="btn pi-btn-base pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-print"></i>&nbsp;Print</button>
                  <button className="btn pi-btn  pi-btn-icon-big"
                          onClick={this.props.toggleOpen}><i className="fa fa-times"></i>&nbsp;Close</button>
                </div>
              </div>

              <div ref={this.printSection}>

                {/*<div className="pi-row" style={{marginBottom: '15px', marginTop: '25px'}}>
                <div className="pi-col-sm-4">
                  <h4><img src="img/logo-full.png" alt="Aluxor Logo"></img></h4>
                </div>
                <div className="pi-col-sm-4"></div>
                <div className="pi-col-sm-4">
                </div>
              </div> */}

              <div className="pi-row" style={{marginBottom: '15px', marginTop: '25px'}}>
                <div className="pi-col-sm-4">
                  <h4><img src="img/logo-full.png" alt="Aluxor Logo"></img></h4>
                </div>
                <div className="pi-col-sm-2"></div>

                <div className="pi-col-sm-3" style={{textAlign: 'right', padding: '10px', fontWeight: 'bold' }}>
                  ALX Ref
                </div>
                <div className="pi-col-sm-3" style={{padding: '8px', border: '2px solid #2e343c', fontWeight: 'bold'}}>
                  {order.sequenceNumber}
                </div>
              </div>

              <div className="pi-row" style={{borderTop: '2px solid #2e343c', borderBottom: '2px solid #2e343c'}}>
                <div className="pi-col-sm-6">
                  <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>
                    <TextViewControl labelText={'Customer'} value={order.organisationDescription} />
                    <TextViewControl labelText={'Customer ref'} value={order.customerReference} />
                    <TextViewControl labelText={'Delivery address'} value={order.organisationAddressComplete} />
                  </div>
                </div>

                <div className="pi-col-sm-6">
                  <div className="pi-section" style={{paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px', paddingRight: '0px'}}>
                    { !order.isSparesOrder && <TextViewControl labelText={'No. in Order'} value={order.awnings.length} /> }
                    { order.isSparesOrder && order.statusChanges.filter(x => x.orderStatus === 3).length > 0 &&
                      <TextViewControl labelText={'Date accepted'} value={moment(order.orderDisplayDate).format(DATE_SHORT_FORMAT)} /> }
                  </div>
                </div>
              </div>

              { chain(order.dispatchSheet.dispatchItems)
                .groupBy("section")
                .toPairs()
                .map(pair => zipObject(['section', 'items'], pair))
                .value().map((s, si) => {
                  let items = s.items.filter(x => x.label);
                return (<div key={s.section}>

                  <div className="pi-row" style={{paddingTop: '10px'}}>
                    <div className="pi-col-sm-10"><h6 style={{marginBottom: '4px'}}>{s.section}</h6></div>
                    {si == 0 && <div className="pi-col-sm-1" style={{paddingLeft: '0px',paddingRight: '0px', textAlign: 'center'}}><h6>Disp chk</h6></div> }
                    { si == 0 && <div className="pi-col-sm-1" style={{paddingLeft: '0px',paddingRight: '0px', textAlign: 'center'}}><h6>Rec chk</h6></div>}
                  </div>

                  {items.findIndex(i => i.image !== null) < 0 ? items.map((i, ii) => {
                    return (<div className="pi-row" key={`${i.label}_${ii}`}>
                        <div className="pi-col-sm-8">{i.label}</div>
                        <div className="pi-col-sm-1" style={{paddingLeft: '0px'}}>{i.value == null ? '' : i.value}</div>
                        <div className="pi-col-sm-1">&nbsp;</div>
                        <div className="pi-col-sm-1" style={{border: '1px solid #2e343c', borderRight:'0px', borderBottom: ii == items.length-1 ? '1px solid #2e343c' : '0px'}}>&nbsp;</div>
                        <div className="pi-col-sm-1" style={{border: '1px solid #2e343c', borderBottom: ii == items.length-1 ? '1px solid #2e343c' : '0px'}}>&nbsp;</div>
                      </div>)
                  }) :
                    items.map((i, ii) => {
                      return (<div className="pi-row" key={`${i.label}_${ii}`}>
                        <div className="pi-col-sm-8" style={{paddingTop: '12px'}}>{i.label}</div>
                        <div className="pi-col-sm-1" style={{paddingLeft: '0px', paddingTop: '12px'}}>{i.value == null ? '' : i.value}</div>
                        <div className="pi-col-sm-1" style={{paddingLeft: '0px'}}><img style={{height: '50px'}} src={`/img/powder_coating_sheet/${i.image}`} /></div>
                        <div className="pi-col-sm-1" style={{border: '1px solid #2e343c', borderRight:'0px', borderBottom: ii == items.length-1 ? '1px solid #2e343c' : '0px', height: '50px'}}>&nbsp;</div>
                        <div className="pi-col-sm-1" style={{border: '1px solid #2e343c', borderBottom: ii == items.length-1 ? '1px solid #2e343c' : '0px', height: '50px'}}>&nbsp;</div>
                      </div>)
                    })
                  }
                </div>);
              })}

              <div className="pi-row" style={{marginTop:'40px', borderBottom: '2px solid #2e343c', paddingBottom: '17px'}}>
                <div className="pi-col-sm-2" style={{fontWeight: 'bold'}}>Dispatch note</div>
                <div className={ order.isSparesOrder ? 'pi-col-sm-8' : 'pi-col-sm-10' } style={{paddingLeft: '0px'}}>{order.dispatchNote}</div>
                { order.isSparesOrder && <div className="pi-col-sm-1" style={{fontWeight: 'bold'}}>Boxes</div> }
                { order.isSparesOrder && <div className="pi-col-sm-1" style={{paddingLeft: '0px', border: '1px solid', height: '40px'}}>&nbsp;</div> }
              </div>
              {order.isSparesOrder && !isNullEmptyOrWhitespace(order.orderNote) && <div className="pi-row" style={{marginTop:'15px', borderBottom: '2px solid #2e343c', paddingBottom: '17px'}}>
                <div className="pi-col-sm-2" style={{fontWeight: 'bold'}}>Order note</div>
                <div className={ order.isSparesOrder ? 'pi-col-sm-8' : 'pi-col-sm-10' } style={{paddingLeft: '0px'}}>{order.orderNote}</div>
              </div> }
              <div className="pi-row" style={{marginTop: '17px'}}>
                <div className="pi-col-sm-12" style={{fontWeight: 'bold'}}>{order.dispatchTypeDescription}</div>
              </div>
              <div className="pi-row" style={{borderBottom: '2px solid', paddingBottom: '10px'}}>
                <div className="pi-col-sm-6">
                  { order.dispatchTypeId !== DISPATCH_COLLECT && <div className={'pi-row'} style={{marginLeft: '0px'}}>
                    <TextViewControl labelText={'Courier'} value={order.courierName ? order.courierName : order.courierOther} />
                    <TextViewControl labelText={'Con. note'} value={order.courierConsignmentReference} />
                    {!isNullEmptyOrWhitespace(order.courierNoBoxes) && <TextViewControl labelText={'No. boxes'} value={order.courierNoBoxes} /> }
                    <TextViewControl labelText={'Dispatch date'} value={order.courierDispatchDate ? moment(order.courierDispatchDate).format(DATE_SHORT_FORMAT) : ''} />
                    <TextViewControl labelText={'Dispatched by'} value={''} />
                  </div> }

                  {order.dispatchTypeId === DISPATCH_COLLECT &&
                  <Aux>

                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Collected by</div>
                      <div className="pi-col-sm-8" style={{borderBottom: '1px solid'}}>&nbsp;</div>
                    </div>
                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Date</div>
                      <div className="pi-col-sm-8" style={{borderBottom: '1px solid'}}>&nbsp;</div>
                    </div>
                    <div className="pi-row" style={{height: '40px'}}>
                      <div className="pi-col-sm-4">Dispatched by</div>
                      <div className="pi-col-sm-8" style={{borderBottom: '1px solid'}}>&nbsp;</div>
                    </div>
                  </Aux>
                  }
                </div>
                <div className="pi-col-sm-6">                  
                  {order.isSparesOrder && <div className={'pi-row'} style={{height: '40px'}}>
                    <div className="pi-col-sm-6" style={{fontWeight: 'bold'}}>&nbsp;</div>
                    <div className="pi-col-sm-2" style={{fontWeight: 'bold'}}>Invoice</div>
                    <div className="pi-col-sm-4" style={{paddingLeft: '0px', border: '1px solid', height: '40px'}}>&nbsp;</div>
                  </div> }
                </div>
              </div>

              { !order.isSparesOrder && <div className="pi-row">
                <div className="pi-col-sm-4" style={{fontWeight: 'bold'}}>Number of boxes</div>
                <div className="pi-col-sm-8" style={{padding: '5px'}}>

                    <div className={classes.divTable} style={{marginTop: '10px'}}>
                      <div className={classes.divThead}>
                        <div className={classes.divTrHead}>
                          <div className={classes.divTh}>Length</div>
                          <div className={classes.divTh}>Width</div>
                          <div className={classes.divTh}>Depth</div>
                          <div className={classes.divTh}>No.</div>
                        </div>
                      </div>
                      <div className={classes.divTbody}>
                        <div className={classes.divTr}>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                        </div>
                        <div className={classes.divTr}>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                        </div>
                        <div className={classes.divTr}>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                          <div className={classes.divTd}>&nbsp;</div>
                        </div>
                      </div>
                    </div>

                </div>
              </div> }


                { order.awnings.map((awning, i) => {
                  return (
                      <div key={awning.id} style={{pageBreakInside:'avoid', pageBreakBefore: i===0 ? 'always': 'auto', pageBreakAfter: i >= order.awnings.length-1 ? 'auto': 'always'}}>
                        <AwningProductSpecifications awning={awning} showSize={true} showProduct={true} cols={2}/>
                        <div style={{marginBottom: '20px'}}>&nbsp;</div>

                        {awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM &&
                        <Aux>
                          <DiagramFA awning={awning} width={800}/>
                          <div style={{marginBottom: '10px'}}/>
                        </Aux>}

                        {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY &&
                        <Aux>
                          <DiagramSD awning={awning} width={800}/>
                          <div style={{marginBottom: '10px'}}/>
                        </Aux>}

                        {awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS &&
                        <Aux>
                          <DiagramST awning={awning} width={800}/>
                          <div style={{marginBottom: '10px'}}/>
                        </Aux>}

                      </div>);
                })
                }
              
              
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"></i>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderDispatchSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(OrderDispatchSheet);