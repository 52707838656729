import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DATE_SHORT_FORMAT, FLASHINGTYPE_WallSeal, PRODUCTID_OLYMPUS
} from '../../constants';
import moment from 'moment';
import SelectControl from '../common/SelectControl';
import CheckBoxControl from '../common/CheckBoxControl';
import TextViewControl from '../common/TextViewControl';
import TextBoxControl from '../common/TextBoxControl';
import {apiRequest} from "../../utils/request";
import RadioControlGroup from '../common/RadioControlGroup';

class AwningFlashingSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      colorBondColoursByPc: [],
      awning: props.awning,
      matchedColorBondColourId: null,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.fetchColorBondColoursByPc = this.fetchColorBondColoursByPc.bind(this);
    this.getCurrentState = this.getCurrentState.bind(this);
  }

  handleFieldChange(e) {

    let {awning} = this.state;
    let {name, value, type} = e.target;

    if (type === "checkbox") {
      awning.awningFlashing[name] = e.target.checked;
    } else {
      awning.awningFlashing[name] = value;
    }

    this.setState({ awning });
  }

  fetchColorBondColoursByPc(prevState) {

    let {flashingCustomColourId} = this.state.awning;

    if (prevState && prevState.awning.flashingCustomColourId === flashingCustomColourId) {
      return;
    }

    if (!flashingCustomColourId) {
      flashingCustomColourId = -1;
    }
    apiRequest(`/api/colours/colorbondbypc?powderCoatColourId=${flashingCustomColourId}`)
      .then(x => {
        if (!x.error) {
          let colorbondColourId = this.state.awning.awningFlashing.colorbondColourId;
          let matchedColourIndex = x.data.findIndex(a => a.value == colorbondColourId);
          let isManual = x.data.length == 1 || (this.state.awning.awningFlashing.colorbondColourId && matchedColourIndex < 0);

          this.setState({
            colorBondColoursByPc: x.data,
            matchedColorBondColourId: isManual ? -1 : (matchedColourIndex >= 0 ? x.data[matchedColourIndex].value : null)
          });
        }
      });

  }

  getCurrentState(){
    return this.state.awning;
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchColorBondColoursByPc(prevState);
  }

  componentDidMount(){
    this.fetchColorBondColoursByPc();
  }

  render() {
    let {order} = this.props;
    let {awning} = this.state;

    let isWallFix = awning && awning.flashingType === FLASHINGTYPE_WallSeal;

    return awning && awning.id > 0 ? (<div>

      <div className="pi-row" style={{paddingTop: '25px', fontSize: 'larger'}}>
        <div className="pi-col-sm-12">
          <div className="pi-row">
            <div className="pi-col-md-8">
              <TextViewControl
                labelText="Order"
                value={`${order.sequenceNumber}.${awning.number}`}
                boldLabel={true}
              />

              <TextViewControl
                labelText="To"
                value={"Ridgeway"}
                boldLabel={true}
              />

              <TextViewControl
                labelText={"Date"}
                value={moment().format(DATE_SHORT_FORMAT)}
                boldLabel={true}
              />

            </div>
            <div className="pi-col-md-4">
                <img src="img/logo-full.png" alt="Aluxor Logo" style={{maxWidth: '60%'}}></img>
            </div>
          </div>
          
          <div className="pi-row">
            <div className="pi-col-md-8">

            { awning.awningFlashing.isZincalume ? 
              <TextViewControl
              labelText="Finish"
              value={'Zincalume'}
              boldLabel={true}
            />:
              awning.awningFlashing.colorbondColourId ?
                <TextViewControl
                  labelText="Finish"
                  value={this.props.colorBondColours.filter(x => x.value ===awning.awningFlashing.colorbondColourId)[0].label}
                  boldLabel={true}
                /> :
                <TextViewControl
                  labelText="Finish"
                  value={'Not selected'}
                  boldLabel={true}
              />  }

            </div>
          </div>

          <div className="pi-row print-hide" style={{marginTop: '20px'}}>
            <div className="pi-col-md-8">

              { awning.awningFlashing.customColourDescription ?
                <TextViewControl
                  labelText="Customer Colour"
                  value={awning.awningFlashing.customColourDescription}
                  boldLabel={true}
                /> :
                <TextViewControl
                  labelText="Customer Colour"
                  value={awning.awningFlashing.colourDescription}
                  boldLabel={true}
                />  }

            </div>
          </div>

          <div className={"pi-row print-hide"}>
            <div className="pi-col-md-8">
              <div className="pi-row">
                <div className="pi-col-sm-4">
                  <strong>Select Finish</strong>
                </div>
                <div className="pi-col-sm-8">
                  <CheckBoxControl
                    onChange={(e) => {
                      let newValue = e.target.checked;
                      this.setState({
                        awning: {...this.state.awning,
                          awningFlashing: {
                            ...this.state.awning.awningFlashing,
                            isZincalume: newValue,
                            colorbondColourId: newValue ? null : this.state.awning.awningFlashing.colorbondColourId,
                          }},
                        matchedColorBondColourId: null,
                                        });
                       //TODO: What happened here...
                       //awning.awningFlashing[name] =
                       //  this.handleFieldChange(e)
                    }}
                    labelText="Zincalume finish"
                    value={awning.awningFlashing.isZincalume}
                    name="isZincalume"
                  />

                  {!awning.awningFlashing.isZincalume && <RadioControlGroup
                    name={`colorbondColourId_${awning.id}`}
                    style={{marginLeft: '0px'}}
                    onChange={(x, e) => {
                      this.setState({
                        matchedColorBondColourId: x.simpleValue,
                        awning: {
                          ...awning, awningFlashing: {
                            ...awning.awningFlashing,
                            colorbondColourId: x.simpleValue === -1 ? null : x.simpleValue
                          }
                        }
                      });
                    }}
                    value={this.state.matchedColorBondColourId}
                    labelText='Matched Colorbond Colour'
                    validationMessages={{}}
                    options={this.state.colorBondColoursByPc}
                  />}

                  {(this.state.matchedColorBondColourId === -1 && !awning.awningFlashing.isZincalume) && <SelectControl
                    labelText="Colorbond colour"
                    name="colorbondColourId"
                    options={this.props.colorBondColours}
                    onChange={(val) => this.handleFieldChange({ target: {
                      name: 'colorbondColourId',
                      value: val.value,
                      type: 'selectControl'
                    }})}
                    value={awning.awningFlashing.colorbondColourId}
                  /> }

                </div>
              </div>
            </div>
          </div>

          <div className={"pi-row"}><div className="pi-col-sm-12">&nbsp;</div></div>
          { !isWallFix && <div className="pi-row">
            <div className="pi-col-md-12">
              <div className={"pi-row"}>

                <div className="pi-col-sm-3">
                  <TextBoxControl
                    align={'center'}
                    labelMarginBottom={'0px'}
                    onChange={() => {}}
                    labelText="Length"
                    value={awning.awningFlashing.length.toString()}
                    name="length"
                    type="number"
                    disabled={true}
                  />
                </div>
                <div className="pi-col-sm-3">
                  <TextBoxControl
                    align={'center'}
                    labelMarginBottom={'0px'}
                    onChange={(e) => this.handleFieldChange(e)}
                    labelText="Depth 1"
                    value={awning.awningFlashing.depth1.toString()}
                    name="depth1"
                    type="number"
                  />
                </div>
                <div className="pi-col-sm-3">
                  <TextBoxControl
                    align={'center'}
                    labelMarginBottom={'0px'}
                    onChange={(e) => this.handleFieldChange(e)}
                    labelText="Depth 2"
                    value={awning.awningFlashing.depth2.toString()}
                    name="depth2"
                    type="number"
                  />
                </div>
                <div className="pi-col-sm-3">
                  <TextBoxControl
                    align={'center'}
                    labelMarginBottom={'0px'}
                    onChange={(e) => this.handleFieldChange(e)}
                    labelText="Height"
                    value={awning.awningFlashing.height.toString()}
                    name="height"
                    type="number"
                  />
                </div>
              </div>

              <div className={"pi-row"}>
                <div className="pi-col-md-12">
                  <img src='img/flashing.png' style={{marginTop: '50px'}} />
                </div>
              </div>


            </div>
          </div> }

          {isWallFix && <div className="pi-row">
            <div className="pi-col-md-12">
              <div className={"pi-row"}>

                <div className="pi-col-sm-3">
                  <TextBoxControl
                    align={'center'}
                    labelMarginBottom={'0px'}
                    onChange={() => {}}
                    labelText="Length"
                    value={awning.awningFlashing.length.toString()}
                    name="length"
                    type="number"
                    disabled={true}
                  />
                </div>
              </div>
              <div className={"pi-row"}>
                <div className="pi-col-md-12">
                  <img src={`img/flashing_${awning.productId === PRODUCTID_OLYMPUS ? 'OL' : 'DS'}.png`} style={{marginTop: '50px'}} />
                </div>
              </div>
            </div>
          </div>}

        </div>
      </div>

    </div>) : null;
  }
}

AwningFlashingSheet.propTypes = {
  awning: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  colorBondColours: PropTypes.array.isRequired,
};

export default AwningFlashingSheet;
