import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import SelectControl from "../common/SelectControl";
import TextBoxControl from "../common/TextBoxControl";
import {
    Aux,    
    isNullEmptyOrWhitespace,    
    orders_list_list_all_organisations_all,
    SUPPORT_REQUEST_STATUS_INQUIRY,
    SUPPORT_REQUEST_STATUS_RECEIVED,    
} from "../../constants";
import {YesOrNo} from "../orders/AwningView";
import ColourSelectorControl from "../common/ColourSelectorControl";
import AwningAttachments from "../orders/AwningAttachments";
import TextAreaControl from "../common/TextAreaControl";
import DatePickerControl from "../common/DatePickerControl";
import {
    SupportRequestInitialState,
    SupportRequestValidationRules,
    SupportRequestTypes,
    SupportRequestStatusTypes
} from "./SupportRequestPage";
import moment from "moment";
import SelectControlV2 from "../common/SelectControlV2";

export const EditSupportRequestView = (props) => {
    let {onSave, products, states, customColours, standardColours, organisations, user, loadedSupportRequest} = props;

    const [supportRequest, setSupportRequest] = useState({...SupportRequestInitialState});
    const [validationMessages, setValidation] = useState({});
    const [isValid, setIsValid] = useState(null);

    const onFieldUpdateByName = (name, value) => {
        let updateRequest = {...supportRequest};
        updateRequest[name] = value;
        setSupportRequest(updateRequest);
    };

    const onFieldUpdate = (e) => {
        let updateRequest = {...supportRequest};
        updateRequest[e.target.name] = e.target.value;
        setSupportRequest(updateRequest);
    };

    useEffect(() => {
        if(isNullEmptyOrWhitespace(loadedSupportRequest)){
            setSupportRequest({...SupportRequestInitialState});
        } else {
            setSupportRequest({...loadedSupportRequest});    
        }        
    }, [loadedSupportRequest]);
    
    useEffect(() => {
        if (isValid === false) {
            runValidation();
        }
    }, [supportRequest, isValid]);    

    useEffect(() => {
        setSupportRequest({...supportRequest, organisationId: user.organisation.id});
    }, [user.organisation.id]);

    const runValidation = () => {
        let newValidationMessages = {};
        let isValid = true;
        for (let rule in SupportRequestValidationRules) {
            let result = SupportRequestValidationRules[rule].validator(supportRequest);
            if (!result.isValid) {
                newValidationMessages[rule] = result.message;
                isValid = false;
            }
        }
        setValidation({...newValidationMessages});
        setIsValid(isValid);
        return isValid;
    };

    const handleOnSave = () => {
        if (runValidation()) {
            onSave(supportRequest);
        }
    };

    const handleOnReceived = () => {
        if (runValidation()) {
            onSave({...supportRequest, supportRequestStatusType: 2});
        }
    };

    const handleOnComplete = () => {
        if (runValidation()) {
            onSave({...supportRequest, supportRequestStatusType: 3});
        }
    };
    
    const handleReset = () =>{
        setSupportRequest({...SupportRequestInitialState, organisationId: user.organisation.id});
        setIsValid(null);
        setValidation({});
    };

    let isAluxor = user.hasAccessTag(orders_list_list_all_organisations_all);
    
    return (supportRequest.id > 0 ? <Aux>
        <div className={"row mt-5 mb-5"}>
            <div className={"col-6"}>
                <h2>Action Product Support Request</h2>
            </div>
            <div className={"col-6 text-right"}>
                <button
                    className="btn pi-btn-base pi-btn-icon-big mr-2"
                    onClick={handleOnSave}>
                    <i className="fa fa-save"></i>&nbsp;Save
                </button>
                {isAluxor && supportRequest.supportRequestStatusType === SUPPORT_REQUEST_STATUS_INQUIRY && 
                    <button onClick={handleOnReceived}
                        className="btn btn-success pi-btn-icon-big mr-2">
                            <i className="fa fa-check" />&nbsp;Mark Received</button> }
                {isAluxor && supportRequest.supportRequestStatusType === SUPPORT_REQUEST_STATUS_RECEIVED &&
                     <button onClick={handleOnComplete}
                              className="btn btn-success pi-btn-icon-big mr-2">
                        <i className="fa fa-check" />&nbsp;Mark Complete</button> }
                <button
                    className="btn pi-btn pi-btn-icon-big success"
                    onClick={handleReset}>
                    <i className="fa fa-reload"></i>&nbsp;Reset
                </button>
            </div>
        </div>
        <div className={'row'}>
            <div className='col-md-6'>
                {user.hasAccessTag(orders_list_list_all_organisations_all) && <SelectControlV2
                    onChange={(value) => onFieldUpdateByName('organisationId', value)}
                    name={'organisationId'}
                    value={supportRequest.organisationId}
                    options={organisations}
                    simpleValue={true}
                    labelText={"Organisation"}
                    valueKey={'id'}
                    labelKey={'description'}
                    validationMessages={validationMessages}
                    disabled={true}
                />}

                <SelectControl
                    onChange={(value) => onFieldUpdateByName('supportRequestType', value)}
                    name={'supportRequestType'}
                    value={supportRequest.supportRequestType}
                    options={SupportRequestTypes}
                    simpleValue={true}
                    labelText={"Request Type"}
                    validationMessages={validationMessages}
                />
                
                <SelectControlV2
                    onChange={(value) => onFieldUpdateByName('supportRequestStatusType', value)}
                    name={'supportRequestStatusType'}
                    value={supportRequest.supportRequestStatusType}
                    options={SupportRequestStatusTypes}
                    simpleValue={true}
                    labelText={"Request Status"}
                    disabled={!isAluxor}
                    validationMessages={validationMessages}
                />

                <SelectControl
                    onChange={(value) => onFieldUpdateByName('productId', value)}
                    name={'productId'}
                    value={supportRequest.productId}
                    options={products}
                    simpleValue={true}
                    labelText={"Product"}
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={onFieldUpdate}
                    labelText="Product Description"
                    value={supportRequest.productDescription}
                    name="productDescription"
                    type="text"
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={onFieldUpdate}
                    labelText="Customer Reference Number"
                    value={supportRequest.customerReferenceNumber}
                    name="customerReferenceNumber"
                    type="text"
                    validationMessages={validationMessages}
                />

                <SelectControl
                    onChange={(value) => onFieldUpdateByName('isRelationToExistingProductSupplied', value)}
                    name={'isRelationToExistingProductSupplied'}
                    value={supportRequest.isRelationToExistingProductSupplied}
                    options={YesOrNo}
                    simpleValue={true}
                    labelText={"Does this relate to an existing product supplied by Aluxor?"}
                    validationMessages={validationMessages}
                />

                {supportRequest.isRelationToExistingProductSupplied === false &&
                    <TextAreaControl
                        labelText={"Supplied By"}
                        name={"supplyNotes"}
                        onChange={onFieldUpdate}
                        value={supportRequest.supplyNotes}
                        validationMessages={validationMessages}
                    />
                }
                {supportRequest.isRelationToExistingProductSupplied === true &&
                    <Aux>
                        <TextBoxControl
                            isDebounce={true}
                            debounceeTimeOut={300}
                            onChange={onFieldUpdate}
                            labelText="Original Order Customer Reference"
                            value={supportRequest.originalOrderReference}
                            name="originalOrderReference"
                            type="text"
                            validationMessages={validationMessages}
                        />                        
                        
                        <DatePickerControl
                            labelText={"Original Order Date"}
                            onChange={(val) => onFieldUpdateByName('originalOrderDate', val ? val.format() : null)}
                            selected={supportRequest.originalOrderDate ? moment(supportRequest.originalOrderDate) : null}
                            name={"originalOrderDate"}
                            validationMessages={validationMessages}
                        />

                    </Aux>
                }

                <ColourSelectorControl
                    onChange={(val) => {
                        setSupportRequest({
                            ...supportRequest,
                            colourId: val.selectedStandardColourId,
                            customColourId: val.selectedCustomColourId
                        });
                    }}
                    label=''
                    customColours={customColours}
                    standardColours={standardColours}
                    selectedStandardColourId={supportRequest.colourId}
                    selectedCustomColourId={supportRequest.customColourId}
                    name={'colour'}
                    validationMessages={validationMessages}
                    disabled={false}
                    awningId={0}
                    radioStyle={{paddingLeft: '15px'}}
                    singleColourSelection={false}
                    linkText={'Clear selection'}
                    onLink={(e) => {
                        e.preventDefault();
                        setSupportRequest({...supportRequest, colourId: null, customColourId: null});
                    }}
                />

                <TextAreaControl labelText={"Support Description"}
                                 name="supportDescription"
                                 onChange={onFieldUpdate}
                                 value={supportRequest.supportDescription}
                                 validationMessages={validationMessages}/>

                <AwningAttachments
                    attachments={supportRequest.attachments}
                    onChange={(attachments) => {
                        setSupportRequest({...supportRequest, attachments});
                    }}
                />

            </div>
            <div className='col-md-6'>
                <TextBoxControl
                    onChange={onFieldUpdate}
                    labelText="Location of Awning Address Line 1"
                    value={supportRequest.locationAddressLine1}
                    name="locationAddressLine1"
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    onChange={onFieldUpdate}
                    labelText="Location of Awning Address Line 2"
                    value={supportRequest.locationAddressLine2}
                    name="locationAddressLine2"
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    onChange={onFieldUpdate}
                    labelText="Location of Awning Postcode"
                    value={supportRequest.locationPostCode}
                    name="locationPostCode"
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    onChange={onFieldUpdate}
                    labelText="Location of Awning Suburb"
                    value={supportRequest.locationSuburb}
                    name="locationSuburb"
                    validationMessages={validationMessages}
                />

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Location of Awning State"
                    name="locationStateId"
                    options={states}
                    valueKey={'id'}
                    labelKey={'description'}
                    simpleValue={true}
                    onChange={(v) => onFieldUpdateByName('locationStateId', v)}
                    value={supportRequest.locationStateId}
                    placeholder="Delivery State"
                />

                <div className={"row mt-6"}><div className={"col-md-12"}>&nbsp;</div></div>

                { isAluxor && <TextAreaControl labelText={"Aluxor Notes"}
                                 name="aluxorNotes"
                                 onChange={onFieldUpdate}
                                 value={supportRequest.aluxorNotes}
                                 validationMessages={validationMessages}/> }
                { isAluxor && <TextBoxControl
                    onChange={onFieldUpdate}
                    labelText="Aluxor Production Number"
                    value={supportRequest.sequenceNumber}
                    name="sequenceNumber"
                    validationMessages={validationMessages}
                /> }
            </div>
        </div>
    </Aux> : null);
}

EditSupportRequestView.propTypes = {        
    onSave: PropTypes.func.isRequired,    
    products: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    standardColours: PropTypes.array.isRequired,
    customColours: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    organisations: PropTypes.array.isRequired,
    loadedSupportRequest: PropTypes.object,
};