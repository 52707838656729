import React from 'react';
import PropTypes from 'prop-types';
import TextBoxControl from '../common/TextBoxControl';
import {
  Aux, fieldHeadboxWidthAddition, fieldWidthAddition, fieldWidthDeduction, headBoxWith,
  isNullEmptyOrWhitespace,
  SIDE_LEFT, 
} from "../../constants";
import RadioControlGroup from "../common/RadioControlGroup";
import TextBoxControlWithEnter from "../common/TextBoxControlWithEnter";

function getFieldName(awningId, number, name){
  return `${awningId}-${number}-${name}`;
}

export function GetFieldConfigValidationMessages(fieldConfiguration, awningId){

  let returnVal = {};

  for(let i = 0; i < fieldConfiguration.fields.length; i++) {
    let field = fieldConfiguration.fields[i];
    let message = `Field ${field.number} width between: ${fieldConfiguration.minWidth} and ${fieldConfiguration.maxWidth}`;
    let widthVal = field.width;
    let sideOfOperationVal = field.sideOfOperation;
    let fieldNameWidth = getFieldName(awningId, field.number, 'width');

    if (isNullEmptyOrWhitespace(widthVal)) {
      returnVal[fieldNameWidth] = `Field ${field.number} width cannot be empty`;
    } else {
      let intVal = parseInt(widthVal);
      if (intVal < fieldConfiguration.minWidth || intVal > fieldConfiguration.maxWidth) {
        returnVal[fieldNameWidth] = message;
      }
    }

    if(field.isMotorRequired){
      let fieldNameSide = getFieldName(awningId, field.number, 'sideOfOperation');

      if (isNullEmptyOrWhitespace(sideOfOperationVal)) {
        returnVal[fieldNameSide] = `Field ${field.number} needs a side of operation selected`;
      }
    }
  }

  return returnVal;
}

const AwningFieldsControl = (props) => {
  let {fieldConfiguration, awningId, onFieldConfigurationUpdated, headboxWidth, style, explicitEdit, awning, fieldWidthMessage} = props;

  let isVisible = fieldConfiguration !== null && fieldConfiguration !== undefined;  

  let handleFieldChange = (e, field) => {
    let width = parseInt(field.width);
    let sideOfOperation = field.sideOfOperation;
    if(e.target.name === getFieldName(awningId, field.number, 'width')){
      width = parseInt(e.target.value);
      //if explicit edit and middle field then subtract 30, else subtract 60
      if(explicitEdit) {
        width = fieldWidthDeduction(width, field, fieldConfiguration, awning);
      }
    } else if(e.target.name === getFieldName(awningId, field.number, 'sideOfOperation')){
      sideOfOperation = e.target.value;
    }
    if(isNaN(width)){
      width=0;
    }    
    onFieldConfigurationUpdated(awningId, field, width, sideOfOperation);
  };

  if (!isVisible) {
    return null;
  }  
  
  let {fields} = fieldConfiguration;
  let validationMessages = GetFieldConfigValidationMessages(fieldConfiguration, awningId);

  // let fieldsTotalWidth = fields.reduce((x, y) => {
  //   return x + parseInt(fieldWidthAddition (y.width, y, fieldConfiguration, awning));
  // }, 0) + headboxWidth;
  //
  // let fieldsTotalHeadboxWidth = fields.reduce((x, y) => {
  //   return x + parseInt(fieldHeadboxWidthAddition(y.width, y, fieldConfiguration, awning));
  // }, 0) + headboxWidth;
  //
  //
  // console.log('headbox', headboxWidth);
  // console.log('field width', fields.reduce((x, y) => {
  //   return x + parseInt(fieldWidthAddition (y.width, y, fieldConfiguration, awning));
  // }, 0));
  // console.log('field headbox', fields.reduce((x, y) => {
  //   return x + parseInt(fieldHeadboxWidthAddition (y.width, y, fieldConfiguration, awning));
  // }, 0));

  const outsideFieldSpacing = headBoxWith(awning) / 2;

  let totalFieldWidth = fields.reduce((t, c) => {
    return fieldWidthAddition(c.width, c, awning.fieldConfiguration, awning) + t;
  }, 0);

  let totalHeadbox = (2 * outsideFieldSpacing);
  
  let totalWidth = totalFieldWidth + totalHeadbox;
  
  // if(fieldsTotalWidth === fieldsTotalHeadboxWidth){
  //   fieldsTotalHeadboxWidth = headboxWidth;
  // }
  
  //Run the width validator when rendering, to create the validation messages, key them by the same name
  return (<div>

    {!explicitEdit && !isNullEmptyOrWhitespace(fieldWidthMessage) && <h5 style={{color:'#f58b3c', marginLeft:'-15px'}}>{fieldWidthMessage}</h5> }
    {fields.map(field => {
      let sides = field.sidesOfOperation.map(s => {
        let side = SIDE_LEFT === s ? "Left" : "Right";
        return {value: side, label: side};
      });

      return (<Aux key={`${awningId}_${field.number}`}>
        {!explicitEdit ? <TextBoxControl
                style={style}
                isDebounce={true}
                disabled={field.readOnly}
                debounceeTimeOut={300}
                onChange={e => handleFieldChange(e, field)}
                labelText={`Field #${field.number} Width`}
                value={field.width.toString()}
                name={getFieldName(awningId, field.number, 'width')}
                type="number"
                validationMessages={validationMessages}
            /> :
            <TextBoxControlWithEnter
                style={style}
                isDebounce={true}
                disabled={field.readOnly}
                debounceeTimeOut={300}
                onChange={e => handleFieldChange(e, field)}
                labelText={`Field #${field.number} Fixing Center Width`}
                labelTextView={`Field #${field.number} Track Center Width`}
                value={field.width.toString()}
                editValue={fieldWidthAddition (field.width, field, fieldConfiguration, awning).toString()}
                name={getFieldName(awningId, field.number, 'width')}
                type="number"
                validationMessages={validationMessages}
            />
        }
        <RadioControlGroup
            style={style}
            name={getFieldName(awningId, field.number, 'sideOfOperation')}
            onChange={(v, e) => handleFieldChange(e, field)}
            value={field.sideOfOperation}
            labelText={`Field #${field.number} Side of Operation`}
            validationMessages={validationMessages}
            options={sides}
            visible={field.isMotorRequired}
        />
      </Aux>);
    })}

    { totalWidth > 0 && <h5 style={{color:'#f58b3c', marginLeft:'-15px'}}>
      Total System Width (field widths + headbox width): {totalFieldWidth} + {totalHeadbox} = <b>{totalWidth}</b></h5> }
  </div>);
};

AwningFieldsControl.propTypes = {
    fieldConfiguration: PropTypes.object,
    awningId: PropTypes.number.isRequired,
    onFieldConfigurationUpdated: PropTypes.func.isRequired,
    headboxWidth: PropTypes.number.isRequired,
    explicitEdit: PropTypes.bool,
    awning: PropTypes.object.isRequired,
    fieldWidthMessage: PropTypes.string
};

export default AwningFieldsControl;