import React from 'react';
import PropTypes from 'prop-types';
import TextBoxControl from '../common/TextBoxControl';
import RadioControlGroup from "../common/RadioControlGroup";
import {Aux, IsNullOrUndefined} from "../../constants";
import {apiDownload} from "../../utils/request";

function getFieldName(awningId, field){
  return `${awningId}_${field}`;
}

export function GetSADimensionValidationMessages(steerAroundDimensions, awningId, steerAroundType){

  let returnVal = {};

  for(let field in steerAroundDimensions) {
    if (isFieldVisible(field, steerAroundType)) {
      let val = parseInt(steerAroundDimensions[field]);
      let fieldName = getFieldName(awningId, field);

      if (isNaN(val) || val < 0) {
        returnVal[fieldName] = 'Please enter zero or a positive number';
      } else if ((field === 'alpha' || field === 'beta') && val > 90) {
        returnVal[fieldName] = 'Please enter a angle less or equal to 90 degrees';
      } else if (val === 0) {
        returnVal[fieldName] = 'Please enter a positive number';
      }
    }
  }

  return returnVal;
}

function isFieldVisible(field, steerAroundType){

  if(field === 'c1' || field === 'c2' || field === 'c3'){
    return false;
  }

  if((steerAroundType === 1 || steerAroundType === 5) && (field === 'h2' || field === 'h5' || field === 'beta')){
    return false;
  }
  if((steerAroundType === 2 || steerAroundType === 6) && (field === 'h3')){
    return false;
  }
  if((steerAroundType === 4 || steerAroundType === 8) && (field === 'h3' || field === 'h4' || field === 'h5')){
    return false;
  }

  return true;
}

const AwningSADimensionControl = (props) => {
  let {steerAroundDimensions, awningId, onSteerAroundDimensionsUpdated, onAwningFieldUpdated, style, steerAroundType, steerAroundTypes} = props;

  let isVisible = steerAroundDimensions !== null && steerAroundDimensions !== undefined;

  let handleFieldChange = (e, field) => {
    let val = {...steerAroundDimensions};
    let parsedValue = parseInt(e.target.value);
    if(Number.isInteger(parsedValue) && parsedValue > 0){
      val[field] = parsedValue;
      onSteerAroundDimensionsUpdated(val);
    }    
  };

  if (!isVisible) {
    return null;
  }

  let validationMessages = GetSADimensionValidationMessages(steerAroundDimensions, awningId, steerAroundType);

  let fieldControl = (props) => {
    let {label, fieldName} = props;

    let v = isFieldVisible(fieldName, steerAroundType);
    let value = IsNullOrUndefined(steerAroundDimensions[fieldName]) ? '' : steerAroundDimensions[fieldName];

    return v ? (<TextBoxControl
      style={{width: '90%'}}
      isDebounce={true}
      debounceeTimeOut={300}
      onChange={e => handleFieldChange(e, fieldName)}
      labelText={label}
      value={value.toString()}
      name={getFieldName(awningId, fieldName)}
      type="number"
      validationMessages={validationMessages}
    />) : null;
  };

  let downloadFile = (e) => {
    e.preventDefault();
    //07.21_SD_SA_Type2_2019
    //`api/resources/files/7.2${steerAroundType-1}_Sundream_SteerAround_Type${steerAroundType}.pdf`
    apiDownload(`api/resources/files/07.2${steerAroundType - 1}_SD_SA_Type${steerAroundType}_2019.pdf`, `07.2${steerAroundType - 1}_SD_SA_Type${steerAroundType}_2019.pdf`);
  };

  return (<div>

    <RadioControlGroup
      style={style}
      name={`frameFeatureSteerAroundType_${awningId}`}
      onChange={(x, e) => {
        onAwningFieldUpdated(e, null, "number");
      }}
      value={steerAroundType}
      labelText='Steer Around Type'
      validationMessages={validationMessages}
      options={steerAroundTypes}
      imageUrlSelector={(x) => `/img/powder_coating_sheet/SA_STYPE${x.value}.png`}
    />

    {steerAroundType > 0 &&
    <Aux>
      <div className={'row'}>
        <div className={'col-12'}>
          <h4 style={{marginLeft: '-15px'}}>Please view <a href={''} onClick={downloadFile}>this PDF</a> to determine which dimensions are required</h4>
        </div>
      </div>

      <div className={'row'}>
        <div className={'col-4'}>
          {fieldControl({label: 'Track length H1', fieldName: 'h1'})}
          {fieldControl({label: 'Track length H2', fieldName: 'h2'})}
          {fieldControl({label: 'Track length H3', fieldName: 'h3'})}          
        </div>
        <div className={'col-4'}>
          {fieldControl({label: 'Horizontal height H4', fieldName: 'h4'})}
          {fieldControl({label: 'Horizontal height H5', fieldName: 'h5'})}
        </div>
        {/*<div className={'col-4'}>
          {fieldControl({label: 'C1', fieldName: 'c1'})}
          {fieldControl({label: 'C2', fieldName: 'c2'})}
          {fieldControl({label: 'C3', fieldName: 'c3'})}
        </div>*/}
        <div className={'col-4'}>
          {fieldControl({label: 'Angle Alpha', fieldName: 'alpha'})}
          {fieldControl({label: 'Angle Beta', fieldName: 'beta'})}
        </div>
      </div>
    </Aux>
    }



  </div>);
};

AwningSADimensionControl.propTypes = {
    steerAroundDimensions: PropTypes.object,
    awningId: PropTypes.number.isRequired,
    onSteerAroundDimensionsUpdated: PropTypes.func.isRequired,
    onAwningFieldUpdated: PropTypes.func.isRequired,
    steerAroundTypes: PropTypes.array,
    steerAroundType: PropTypes.number,
};

export default AwningSADimensionControl;