import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {flatten, uniq} from 'lodash';

/*function filterOrders(unfilteredItems, filter) {
  return [...unfilteredItems.filter(x =>
    x.awnings.length === 0 || (x.awnings.findIndex(a =>
    (a.width >= filter.minWidth && a.width <= filter.maxWidth) &&
    (a.projection == filter.projection || filter.projection == 0)
    &&
    ((filter.colours.length === 0) || (
      filter.colours.map(c => c.value).findIndex(c =>
        (c === a.flashingColourDescription)
        || (c === a.frameColourDescription)
        || (c === a.pelmetColourDescription)
        || (c === a.colourDescription)
      ) >= 0
    ))
    && ((filter.motors.length === 0)
      || (filter.motors.map(c => c.value).findIndex(c =>
        (c === a.motorBrandDescription)) >= 0)
    )
    ) >= 0))];
}

function filterColours(orders, filter) {
  let status = filter.filtered.filter(x => x.id === 'orderStatusDescription');

  return uniq(flatten(orders
    .filter(o => o.distinctColours.length > 0 && (status.length === 0 || (status[0].value === o.orderStatusDescription || status[0].value === 'all')))
    .map(o => o.distinctColours))).sort()
    .map(x => {
      return {label: x, value: x};
    });
}

function filterMotors(orders) {
  return uniq(flatten(orders
    .filter(o => o.motorBrands.length > 0)
    .map(o => o.motorBrands))).sort()
    .map(x => {
      return {label: x, value: x};
    });
}*/

export default function ordersReducer(state = initialState.orders, action) {
  switch (action.type) {
    case types.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        items: [...action.orders],
        colours: [], //TODO: load
        motors: [], //TODO: load
        unfilteredItems: [...action.orders],
        filter: {
          ...action.filter
        },
        nonPostFilter: {
          ...action.nonPostFilter
        }
      };
    case types.ORDERS_UPDATE_FILTER: {
      return {
        ...state,
        filter: {...action.filter},
        items: [],
        loaded: false,
      };
    }
    case types.ORDERS_UPDATE_NONPOSTFILTER: {
      return {
        ...state,
        nonPostFilter: {...action.nonPostFilter},
      };
    }
    default:
      return state;
  }
}
