import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {login, loginReset} from '../../actions/identityActions';
import TextBoxControl from '../common/TextBoxControl';
import {validationCreator, IsStringValid, EmailRegex} from "../../constants";

const ValidationRules = {

  password: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a password') : validationCreator();
    }
  },
  confirmPassword: {
    validator: (val, state, props) => {
      return !IsStringValid(val) || state.login.password !== val ? validationCreator(false, 'Passwords must match') : validationCreator();
    }
  },
};

class PasswordResetPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      login: {
        confirmPassword: '',
        password: '',
      }
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleFieldChange(e) {
    let {value, name, type, checked} = e.target;

    let login = {...this.state.login};
    login[name] = type === 'checkbox' ? checked : value;

    this.setState({login}, this.validate);
  }

  validate(){
    let {login} = this.state;
    let messages = {};
    let isValid = true;

    let runValidator = (rules) => {
      for (let propName in rules) {

        let rule = rules[propName];
        let val = login[propName];

        let valResult = rule.validator(val, this.state, this.props);
        if (!valResult.isValid) {
          messages[propName] = valResult.message;
          isValid = false;
        }
      }
    };

    runValidator(ValidationRules);

    this.setState({validationMessages: messages});

    return isValid;
  }


  handleLogin(e){
    e.preventDefault();
    if(!this.validate()){
      return;
    }

    let {password} = this.state.login;

    this.props.dispatch(loginReset(password, this.props.userToken, this.props.history));
  }

  handleReset(e) {
    e.preventDefault();
    this.setState({login: {userName: '', password: ''}});
  }

  render() {
    let {validationMessages, login} = this.state;

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">

          <div className={'form-horizontal'}>

            <div className="pi-row">
              <div className={'pi-col-md-6'}>

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="New Password"
                  value={login.password}
                  name="password"
                  type={'password'}
                  validationMessages={validationMessages}
                  style={{marginLeft: '0px'}}
                />


                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Confirm New Password"
                  value={login.confirmPassword}
                  name="confirmPassword"
                  type={'password'}
                  validationMessages={validationMessages}
                  style={{marginLeft: '0px'}}
                />

              </div>
            </div>
            <div className="pi-row">
              <div className={'pi-col-md-6'}>&nbsp;</div>
            </div>
            <div className="pi-row">
              <div className={'pi-col-md-6'}>
                <button
                  className="btn pi-btn-base pi-btn-icon-big"
                  onClick={this.handleLogin}>
                  <i className="fa fa-save"></i>&nbsp;Set Password
                </button>
                <button
                  style={{marginLeft: '10px'}}
                  className="btn pi-btn pi-btn-icon-big"
                  onClick={this.handleReset}>
                  <i className="fa fa-reload"></i>&nbsp;Reset
                </button>
              </div>

            </div>

            <div className="pi-row">
              <div className={'pi-col-md-6'}>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

PasswordResetPage.propTypes = {
  user: PropTypes.object.isRequired,
  identity: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    identity: state.identity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage));