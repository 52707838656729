import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import {
  DATE_SHORT_FORMAT,
  MoneyFormatter,
  isCustomerCollect,
  REPORTSERVER_BASEURL,
  INVOICE_TYPE_ORDERAWNING, INVOICE_TYPE_FABRICRECOVER, isNullEmptyOrWhitespace, IsRoofSystem, GetOrderPriceMessage
} from '../../constants';
import injectSheet from 'react-jss';
import {apiDownloadReport, apiRequest} from "../../utils/request";
import TextViewControl from '../common/TextViewControl';
import jQuery from 'jquery';
import {Base64} from "js-base64";

export const InvoiceTableStyles = {
  divTable: {
    fontFamily: `Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif`,
    margin: '20px 0',
    fontSize: '10px',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
  },
  divThead: {
    display: 'table-header-group',
    fontWeight: '500',
    fontSize: '9px'
  },
  divTbody: {
    display: 'table-header-group',
  },
  divTr: {
    display: 'table-row',
  },
  divTrHead: {
    display: 'table-row',
    width: '100%',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  divTh: {
    display: 'table-cell',
    padding: '0 10px',
    border: '1px solid #ddd',
  },
  divTd: {
    display: 'table-cell',
    border: '1px solid #ddd',
    textAlign: 'center',
    paddingLeft: '5px',
  },
  divTdNoBorder: {
    display: 'table-cell',
    border: '0px',
    textAlign: 'center',
    paddingLeft: '5px',
  },
  divTdRight: {
    display: 'table-cell',
    border: '1px solid #ddd',
    textAlign: 'right',
    paddingRight: '5px',
  },
  'divTd:first-of-type': {
    borderLeft: '1px solid #ddd',
  },
  'divtbody .div-tr:nth-of-type(2n)': {
    background: '#eee',
  },  
};

const defaultInvoiceData = { lines: [], gst: 0, subTotal: 0, total: 0};

class OrderInvoiceSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: props.order,
      invoiceData: defaultInvoiceData,
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.loadInvoiceData = this.loadInvoiceData.bind(this);
    this.printSection = React.createRef();
  }

  handlePrint() {

    let {order, invoiceType} = this.props;

    let container = jQuery(`<div></div>`);

    jQuery(this.printSection.current).clone().appendTo(container);

    container
      .css('font-size', '12px')
      .css('padding-right', '20px')
      .css('margin-top', '5px')
      .css('padding-top', '25px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    container.find('h1').css('font-size', '40px');
    container.find('h2').css('font-size', '25px');
    container.find('h3').css('font-size', '16px');
    container.find('h4').css('font-size', '12px');
    container.find('h5').css('font-size', '11px');
    container.find('h6').css('font-size', '10px');
    container.find('.pi-row:first-child').css('margin-top', '0px');

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_pf-invoice.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });*/
    let name = invoiceType === INVOICE_TYPE_FABRICRECOVER ? 're' : 'pf';
    
    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${order.id}_${name}-invoice.pdf`);

    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen){
      this.loadInvoiceData();
    }
  }

  loadInvoiceData() {
    let {order, invoiceType} = this.props;
    this.setState({invoiceData: defaultInvoiceData});
    apiRequest(`/api/${invoiceType === INVOICE_TYPE_FABRICRECOVER ? 'FabricRecoverOrders' : 'orders'}/${order.id}/invoice`)
      .then(x => {
        let data = x.data ? x.error ? defaultInvoiceData : x.data : defaultInvoiceData;
        this.setState({invoiceData: data.invoice, printed: false});
      });
  }


  render(){
    let {classes, order, print, invoiceType, siteSettings} = this.props;
    let {invoiceData, printed} = this.state;

    if(!order){
      return null;
    }

    if(print && !printed && invoiceData.lines.length > 0) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-section">

              <div className="pi-row">
                <div className="pi-col-md-8">
                  <button onClick={this.handlePrint}
                          className="btn pi-btn-base pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-print"/>&nbsp;Print</button>
                  <button className="btn pi-btn  pi-btn-icon-big"
                          onClick={this.props.toggleOpen}><i className="fa fa-times"/>&nbsp;Close</button>
                </div>
              </div>

              <div ref={this.printSection}>

                <div className="pi-row" style={{marginBottom: '15px', marginTop: '25px'}}>
                  <div className="pi-col-sm-6">
                    <h4><img src="img/logo-full.png" width={300} alt="Aluxor Logo"/></h4>
                  </div>
                  <div className="pi-col-sm-6">
                    <div className={"pi-row"}>
                      <div className="pi-col-sm-12">
                        <div className={"pi-row"}>
                          <div className="pi-col-sm-6">
                            ABN: 43 326 634 246
                          </div>
                          <div className="pi-col-sm-6" style={{textAlign: 'right'}}>
                            Telephone: +61 2 9907 2211
                          </div>
                        </div>
                        <div className={"pi-row"}>
                          <div className="pi-col-sm-6">
                            Unit 22 / 9 Powells Road
                          </div>
                          <div className="pi-col-sm-6" style={{textAlign: 'right'}}>
                            Facsimilie: +61 2 9907 2219
                          </div>
                        </div>
                        <div className={"pi-row"}>
                          <div className="pi-col-sm-6">
                            BROOKVALE NSW 2100
                          </div>
                          <div className="pi-col-sm-6" style={{textAlign: 'right'}}>
                            Email: info@aluxor.com.au
                          </div>
                        </div>
                        <div className={"pi-row"}>
                          <div className="pi-col-sm-6"/>
                          <div className="pi-col-sm-6" style={{textAlign: 'right'}}>
                            NSW DFT Licence 241699C
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="pi-row" style={{marginBottom: '15px'}}>
                  <div className="pi-col-sm-6">
                    <TextViewControl labelText={'Customer'} value={order.organisationDescription} />
                    <TextViewControl labelText={'Address'} value={order.organisationAddressComplete} />
                    <TextViewControl labelText={'Email'} value={order.email1} />
                    <TextViewControl labelText={'Phone'} value={order.phone1} />
                  </div>
                  <div className="pi-col-sm-6">
                    <h1 style={{textAlign: 'right'}}>{invoiceType === INVOICE_TYPE_FABRICRECOVER ? 'Quote' : 'Pro Forma Invoice'}</h1>
                  </div>
                </div>

                <div className="pi-row">
                  <div className="pi-col-md-12">

                    <div className={classes.divTable} style={{marginBottom: '0px'}}>

                      <div className={classes.divThead}>
                        <div className={classes.divTrHead}>
                          <div className={classes.divTh} style={{width: '21%'}}>Order Ref</div>
                          <div className={classes.divTh} style={{width: '21%'}}>ALX Ref.</div>
                          <div className={classes.divTh} style={{width: '22%'}}>Payment</div>
                          <div className={classes.divTh} style={{width: '18%'}}>PF Invoice Date</div>
                          <div className={classes.divTh} style={{width: '18%'}}>PF Invoice #</div>
                        </div>
                      </div>
                      <div className={classes.divTbody}>
                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '21%'}}>{order.customerReference}</div>
                          <div className={classes.divTd} style={{width: '21%'}}>{order.sequenceNumber}</div>
                          <div className={classes.divTd} style={{width: '22%'}}>{order.organisationCreditTermsDescription}</div>
                          <div className={classes.divTd} style={{width: '18%'}}>{moment().format(DATE_SHORT_FORMAT)}</div>
                          <div className={classes.divTd} style={{width: '18%'}}>{invoiceType === INVOICE_TYPE_FABRICRECOVER ? 'RE' : 'PF'}-{order.id}</div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.divTable} style={{marginBottom: '0px', marginTop:'0px'}}>
                      <div className={classes.divThead}>
                        <div className={classes.divTrHead}>
                          <div className={classes.divTh} style={{width: '5%'}}>Qty</div>
                          <div className={classes.divTh} style={{width: '10%'}}>Item No.</div>
                          <div className={classes.divTh} style={{width: '49%'}}>Description</div>
                          <div className={classes.divTh} style={{width: '15%', textAlign: 'right', paddingRight: '5px'}}>Unit Price (exl GST)</div>
                          <div className={classes.divTh} style={{width: '6%'}}>&nbsp;</div>
                          <div className={classes.divTh} style={{width: '15%', textAlign: 'right', paddingRight: '5px'}}>Amount excl. GST</div>
                        </div>
                      </div>
                      <div className={classes.divTbody}>
                        {invoiceData.lines.map((x, i) => {
                          return (<div key={`${i}_${x.code}`} className={classes.divTr}>
                            <div className={classes.divTd} style={{width: '5%'}}>{x.quantity}</div>
                            <div className={classes.divTd} style={{width: '10%'}}>{x.code}</div>
                            <div className={classes.divTd} style={{width: '49%', textAlign: 'left' }}>{x.description}</div>
                            <div className={classes.divTdRight} style={{width: '15%'}}>{(order.organisationWholesaleOnly || order.isSparesOrder) ? MoneyFormatter.format(x.unitWholesale) : MoneyFormatter.format(x.unitRetail)}</div>
                            <div className={classes.divTd} style={{width: '6%'}}>{(x.wholesale !== x.retail && !order.organisationWholesaleOnly && !order.isSparesOrder) ? `${x.discount}%` : ''}</div>
                            <div className={classes.divTdRight} style={{width: '15%'}}>{MoneyFormatter.format(x.wholesale)}</div>
                          </div>);
                        })}

                        {!isNullEmptyOrWhitespace(order.quoteNote) && order.quoteNote.split('\n').filter(x=> !isNullEmptyOrWhitespace(x)).map(x => {
                          return (<div key={x} className={classes.divTr} style={{border: '1px solid #ddd'}}>
                            <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                            <div className={classes.divTd} style={{width: '10%'}}>&nbsp;</div>
                            <div className={classes.divTd} style={{width: '49%', textAlign: 'left' }}>{x}</div>
                            <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                            <div className={classes.divTd} style={{width: '6%'}}>&nbsp;</div>
                            <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                          </div>); })}

                        <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                          <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '10%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '49%'}}>&nbsp;</div>
                          <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '6%'}}>&nbsp;</div>
                          <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                        </div>

                        { order.awnings.findIndex(x => IsRoofSystem(x)) >= 0 && <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                          <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '10%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '49%', textAlign: 'left'}}><b><i>50% deposit is required at the time of ordering Sintesi systems.</i></b></div>                          
                          <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '6%'}}>&nbsp;</div>
                          <div className={classes.divTdRight} style={{width: '15%'}}>&nbsp;</div>
                        </div> }

                      </div>
                    </div>
                    <div className={classes.divTable} style={{marginBottom: '0px', marginTop:'0px'}}>
                      <div className={classes.divTbody}>
                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '15%', fontWeight: 'bold', paddingTop: '10px', textAlign: 'right', paddingRight: '5px'}}>
                            EFT Payments
                          </div>
                          <div className={classes.divTd} style={{width: '49%', paddingTop: '10px', textAlign: 'left'}}>
                            Commonwealth Bank, Aluxor Industries Pty Ltd<br />
                            BSB: 062-000 AccountNo: 1165 5586
                          </div>
                          <div className={classes.divTd} style={{width: '21%', fontWeight: 'bold', paddingTop: '10px', textAlign: 'right', paddingRight: '5px'}}>
                            Sub Total:
                          </div>

                          <div className={classes.divTdRight} style={{width: '15%', paddingTop: '10px'}}>
                            {MoneyFormatter.format(invoiceData.subTotalWholesale)}
                          </div>
                        </div>
                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '15%', fontWeight: 'bold', paddingTop: '10px', textAlign: 'right', paddingRight: '5px'}}>
                            Cheque Payments
                          </div>
                          <div className={classes.divTd} style={{width: '49%', textAlign: 'left', paddingTop: '10px'}}>
                            Aluxor Industries Pty Ltd<br />
                            PO Box 7464 WARRINGAH MALL NSW 2100
                          </div>
                          <div className={classes.divTd} style={{width: '21%', fontWeight: 'bold', paddingTop: '10px', textAlign: 'right', paddingRight: '5px'}}>
                            Freight:
                          </div>

                          <div className={classes.divTdRight} style={{width: '15%', paddingTop: '10px'}}>
                            { invoiceData.freightAmount ? MoneyFormatter.format(invoiceData.freightAmount) : isCustomerCollect(order)? '' : 'TBA'}
                          </div>
                        </div>

                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '15%', borderRight:'0px', borderBottom: '0px'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '49%', borderLeft:'0px', borderBottom: '0px'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '21%', fontWeight: 'bold', textAlign: 'right', paddingRight: '5px'}}>
                            GST:
                          </div>
                          <div className={classes.divTdRight} style={{width: '15%'}}>
                            {MoneyFormatter.format(invoiceData.gst)}
                          </div>
                        </div>

                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '15%', borderRight:'0px', borderTop: '0px'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '49%', borderLeft:'0px', borderTop: '0px'}}>&nbsp;</div>
                          <div className={classes.divTd} style={{width: '21%', fontWeight: 'bold', textAlign: 'right', paddingRight: '5px'}}>
                            Total incl. GST:
                          </div>
                          <div className={classes.divTdRight} style={{width: '15%'}}>
                            {MoneyFormatter.format(invoiceData.total)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.divTable}>
                      <div className={classes.divTbody}>
                        <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '100%', paddingTop: '10px'}}>Late payments will incur an administration charge of 2% per month on outstanding balances.</div>
                        </div>
                        {!isCustomerCollect(order) && <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '100%', fontWeight: 'bold'}}>Freight charges to be advised</div>
                        </div>}
                        { invoiceType === INVOICE_TYPE_ORDERAWNING && <div className={classes.divTr}>
                          <div className={classes.divTd} style={{width: '100%', paddingTop: '10px'}}>In the event of a discrepancy between the hard copy price list distributed and the {invoiceType === INVOICE_TYPE_FABRICRECOVER ? 'quote' : 'pro forma invoice'}, the hard copy price list will apply. {GetOrderPriceMessage(order, siteSettings)}.
                          </div>
                        </div> }
                        {/*<div className={classes.divTr}>*/}
                        {/*  <div className={classes.divTd} style={{width: '100%', paddingTop: '10px', color: "red"}}><b><i>Current prices are valid for orders placed before 1 May 2022. New prices will apply from that date.</i></b>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times" />&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderInvoiceSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
  invoiceType: PropTypes.number.isRequired,
  siteSettings: PropTypes.object.isRequired,
};

export default injectSheet(InvoiceTableStyles)(OrderInvoiceSheet);