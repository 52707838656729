import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function identityReducer(state = initialState.identity, action){
  switch(action.type) {
    case types.IDENTITY_LOGIN:
      return {...state, accessToken: action.accessToken};
    case types.IDENTITY_LOGOUT:
      return {...state, accessToken: undefined};
    default:
      return state;
  }
}
