import React from 'react';
import PropTypes from 'prop-types';
import AwningSADimensionControl from "./AwningSADimensionControl";
import {Aux, IsNullOrUndefined, isRemote} from "../../constants";
import {SummaryDivider} from "../common/SummaryControls";
import TextViewControl from "../common/TextViewControl";

const AwningSADimensionSummary = (props) => {
    let {awning} = props;

    return (IsNullOrUndefined(awning.steerAroundDimensions)) ? null :
        (<Aux>

            <SummaryDivider labelText="Steer Around"/>

            <div className="pi-row">
                <div className={`pi-col-sm-4`}>Type</div>
                <div className={`pi-col-sm-8`}>
                    <img src={`/img/powder_coating_sheet/SA_STYPE${awning.frameFeatureSteerAroundType}.png`}/>
                </div>
            </div>

            <TextViewControl
                labelText="Track length H1"
                value={awning.steerAroundDimensions.h1}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.h1)}
            />
            <TextViewControl
                labelText="Track length H2"
                value={awning.steerAroundDimensions.h2}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.h2)}
            />
            <TextViewControl
                labelText="Track length H3"
                value={awning.steerAroundDimensions.h3}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.h3)}
            />
            <TextViewControl
                labelText="Horizontal height H4"
                value={awning.steerAroundDimensions.h4}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.h4)}
            />
            <TextViewControl
                labelText="Horizontal height H5"
                value={awning.steerAroundDimensions.h5}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.h5)}
            />
            <TextViewControl
                labelText="Angle alpha"
                value={awning.steerAroundDimensions.alpha}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.alpha)}
            />
            <TextViewControl
                labelText="Angle beta"
                value={awning.steerAroundDimensions.beta}
                visible={!IsNullOrUndefined(awning.steerAroundDimensions.beta)}
            />

            <TextViewControl
                labelText="Drive mech."
                value={'RC operation requires HW motor + Receiver'}
                visible={isRemote(awning)}
            />

            <TextViewControl
                labelText="Mounting packer"
                value={awning.bracketTotal}
            />

        </Aux>);
};


AwningSADimensionSummary.propTypes = {
    awning: PropTypes.object.isRequired,    
};

export default AwningSADimensionSummary;