import React from 'react';
import PropTypes from 'prop-types';
import {
    Aux,
    FABRIC_SUPPLIED_NONE, FLASHINGTYPE_WallSeal,
    isNullEmptyOrWhitespace,
    IsNullOrUndefined, isSDSM, isSelectaPitch,
    isSteerAround,
    isVarioVolant, PRODUCTID_OLYMPUS, SD_CONTINUOUS_ANGLE_BRACKET_ID
} from "../../constants";
import {SummaryDivider} from "./SummaryControls";
import TextViewControlCutting from "./TextViewControlCutting";


const AwningCuttingDeductionsControl = (props) => {
    let {awning, cuttingDeductions, awningRequiresFabric, headerText} = props;

    let thisByThat = (x, y) => {
        return y > 1 ? `${x} x ${y}` : x;
    };
    let is70mmROller = awning && awning.productId === PRODUCTID_OLYMPUS && awning.projection === 3000;
    if(isNullEmptyOrWhitespace(awning.productShortName)){
        awning.productShortName = awning.productDescription;
    }
    
    return (
        <Aux>
            <SummaryDivider labelText={headerText} />

            {awningRequiresFabric && IsNullOrUndefined(cuttingDeductions.cuttingFabricProjection) &&
                <TextViewControlCutting
                    labelText="Fabric"
                    value={thisByThat(cuttingDeductions.cuttingWidth, cuttingDeductions.projection)}
                />}
            {awningRequiresFabric && !IsNullOrUndefined(cuttingDeductions.cuttingFabricProjection) &&
                <TextViewControlCutting
                    labelText="Fabric"
                    value={thisByThat(cuttingDeductions.cuttingWidth, cuttingDeductions.cuttingFabricProjection)}
                />}
            {(isVarioVolant(awning) && awning.fabricSuppliedBy !== FABRIC_SUPPLIED_NONE) && <TextViewControlCutting
                labelText="VV fabric screen"
                value={thisByThat(cuttingDeductions.cuttingVVFabricScreen, awning.drop)}
            />}

            {isVarioVolant(awning) && <TextViewControlCutting
                labelText="VV front rail"
                value={cuttingDeductions.cuttingVVFrontRail}
            />}
            {isVarioVolant(awning) && <TextViewControlCutting
                labelText="VV drop rail"
                value={cuttingDeductions.cuttingVVDropRail}
            />}
            {isVarioVolant(awning) && <TextViewControlCutting
                labelText="VV roller"
                value={cuttingDeductions.cuttingVVRoller}
            />}

            {!isVarioVolant(awning) && !awning.sections && <TextViewControlCutting
                labelText="Front bar"
                value={cuttingDeductions.cuttingFrontBar}
            />}
            {!isVarioVolant(awning) && awning.sections && <TextViewControlCutting
                labelText="Front bar"
                value={thisByThat(cuttingDeductions.cuttingWithJoinerFrontBar, awning.sections)}
            />}

            {!isVarioVolant(awning) && !awning.sections && !awning.oversquare && cuttingDeductions.cuttingSkirt &&
                <TextViewControlCutting
                    labelText="Skirt"
                    value={cuttingDeductions.cuttingSkirt}
                />}

            {!isVarioVolant(awning) && awning.sections && !awning.oversquare && cuttingDeductions.cuttingWithJoinerSkirt &&
                <TextViewControlCutting
                    labelText="Skirt"
                    value={thisByThat(cuttingDeductions.cuttingWithJoinerSkirt, awning.sections)}
                />}

            {awning.includePelmet && !awning.sections && <TextViewControlCutting
                labelText={'Pelmet'}
                value={cuttingDeductions.cuttingPelmet}
            />}
            {awning.includePelmet && awning.sections && <TextViewControlCutting
                labelText={'Pelmet'}
                value={thisByThat(cuttingDeductions.cuttingWithJoinerPelmet, awning.sections)}
            />}

            {!awning.sections && cuttingDeductions.cuttingTopPanel && <TextViewControlCutting
                labelText="Top panel"
                value={cuttingDeductions.cuttingTopPanel}
            />}

            {awning.sections && cuttingDeductions.cuttingTopPanel && <TextViewControlCutting
                labelText="Top panel"
                value={thisByThat(cuttingDeductions.cuttingWithTopPanel, awning.sections)}
            />}

            {cuttingDeductions.cuttingTopShell && <TextViewControlCutting
                labelText="Top shell"
                value={cuttingDeductions.cuttingTopShell}
            />}

            {!awning.sections && cuttingDeductions.cuttingBackPanel && <TextViewControlCutting
                labelText="Back panel"
                value={cuttingDeductions.cuttingBackPanel}
            />}

            {awning.sections && cuttingDeductions.cuttingBackPanel && <TextViewControlCutting
                labelText="Back panel"
                value={thisByThat(cuttingDeductions.cuttingWithBackPanel, awning.sections)}
            />}
            {cuttingDeductions.cuttingBottomPanel && <TextViewControlCutting
                labelText="Bottom panel"
                value={cuttingDeductions.cuttingBottomPanel}
            />}
            {cuttingDeductions.cuttingBottomShell && <TextViewControlCutting
                labelText="Bottom shell"
                value={cuttingDeductions.cuttingBottomShell}
            />}
            {!awning.sections && cuttingDeductions.cuttingCentrePanel && <TextViewControlCutting
                labelText="Centre panel"
                value={cuttingDeductions.cuttingCentrePanel}
            />}
            {awning.sections && cuttingDeductions.cuttingCentrePanel && <TextViewControlCutting
                labelText="Centre panel"
                value={thisByThat(cuttingDeductions.cuttingWithCentrePanel, awning.sections)}
            />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingFrontRail) && <TextViewControlCutting
                labelText="Front rail"
                value={cuttingDeductions.cuttingFrontRail}
            />}

            {!awning.sections && <TextViewControlCutting
                labelText="Support bar"
                value={cuttingDeductions.cuttingSupportBar}
            />}
            {awning.sections && cuttingDeductions.cuttingWithJoinerSupportBar && <TextViewControlCutting
                labelText="Support bar"
                value={thisByThat(cuttingDeductions.cuttingWithJoinerSupportBar, awning.sections)}
            />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingIntermediateRoller) && awning.intermediateRoller > 0 &&
                <TextViewControlCutting
                    labelText={`Intermediate Roller x ${awning.intermediateRoller + awning.additionalImRollerCount}`}
                    value={cuttingDeductions.cuttingIntermediateRoller}
                />}

            {isSteerAround(awning) && !isNullEmptyOrWhitespace(cuttingDeductions.cuttingSteerAroundRoller) &&
                <TextViewControlCutting
                    labelText="Steer Around Roller"
                    value={cuttingDeductions.cuttingSteerAroundRoller}
                />}

            {!awning.sections && <TextViewControlCutting
                labelText={is70mmROller ? 'Roller 70' : 'Roller'}
                value={cuttingDeductions.cuttingRoller}
            />}
            {awning.sections && <TextViewControlCutting
                labelText={is70mmROller ? 'Roller \u230070' : 'Roller'}
                value={thisByThat(cuttingDeductions.cuttingWithJoinerRoller, awning.sections)}
            />}
            {isSelectaPitch(awning) && <TextViewControlCutting
                labelText="Selectapitch rod"
                value={cuttingDeductions.cuttingSelectapitchRod}
            />}

            {awning.flashingType > 0 && !awning.sections && awning.flashingType !== FLASHINGTYPE_WallSeal &&
                <TextViewControlCutting
                    labelText="Flashing"
                    value={cuttingDeductions.cuttingPelmetFlashing}
                />}
            {awning.flashingType > 0 && awning.sections && awning.flashingType !== FLASHINGTYPE_WallSeal &&
                <TextViewControlCutting
                    labelText="Flashing"
                    value={thisByThat(cuttingDeductions.cuttingWithJoinerPelmetFlashing, awning.sections)}
                />}

            {awning.flashingType === FLASHINGTYPE_WallSeal && !awning.sections && <TextViewControlCutting
                labelText="Wall seal"
                value={cuttingDeductions.cuttingPelmetFlashing}
            />}
            {awning.flashingType === FLASHINGTYPE_WallSeal && awning.sections && <TextViewControlCutting
                labelText="Wall seal"
                value={thisByThat(cuttingDeductions.cuttingWithJoinerPelmetFlashing, awning.sections)}
            />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksSingle) && cuttingDeductions.sdTracksSingleQty > 0 &&
                !awning.frameFeatureSteerAroundType && <TextViewControlCutting
                    labelText={`${isSDSM(awning) ? 'Maxi Tracks' : `${awning.productShortName} Tracks Single`} x ${cuttingDeductions.sdTracksSingleQty}`}
                    value={cuttingDeductions.cuttingSDTracksSingle}
                />}
            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksCoupled) && cuttingDeductions.sdTracksCoupledQty > 0 &&
                <TextViewControlCutting
                    labelText={`${awning.productShortName} Tracks Multi Field x ${cuttingDeductions.sdTracksCoupledQty}`}
                    value={cuttingDeductions.cuttingSDTracksCoupled}
                />}


            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksSingle) && cuttingDeductions.sdTracksSingleQty > 0 &&
                awning.frameFeatureSteerAroundType &&
                <TextViewControlCutting
                    labelText={`${isSDSM(awning) ? 'Maxi Tracks' : `${awning.productShortName} TRKS Single H1`} x ${cuttingDeductions.sdTracksSingleQty}`}
                    value={'TBA'}
                />}
            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksSingle) && cuttingDeductions.sdTracksSingleQty > 0 &&
                [3, 4, 7, 8].findIndex(x => x === awning.frameFeatureSteerAroundType) >= 0 &&
                <TextViewControlCutting
                    labelText={`${isSDSM(awning) ? 'Maxi Tracks' : `${awning.productShortName} TRKS Single H2`} x ${cuttingDeductions.sdTracksSingleQty}`}
                    value={'TBA'}
                />}
            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksSingle) && cuttingDeductions.sdTracksSingleQty > 0 &&
                [1, 2, 3, 5, 6, 7].findIndex(x => x === awning.frameFeatureSteerAroundType) >= 0 &&
                <TextViewControlCutting
                    labelText={`${isSDSM(awning) ? 'Maxi Tracks' : `${awning.productShortName} TRKS Single H3`} x ${cuttingDeductions.sdTracksSingleQty}`}
                    value={'TBA'}
                />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingSDTracksSingle) && cuttingDeductions.sdTracksSingleQty > 0
                && awning.mountingBracketId === SD_CONTINUOUS_ANGLE_BRACKET_ID &&
                <TextViewControlCutting
                    labelText={`Continuous Angle`}
                    value={cuttingDeductions.cuttingSDTracksSingle}
                />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingCrossRail) && <TextViewControlCutting
                labelText="Cross Rail"
                value={cuttingDeductions.cuttingCrossRail}
            />}
            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingPostHeight) &&
                ((awning.installationBracketOption && awning.installationBracketOption.postCount > 0) ||
                    (awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption && awning.fieldConfiguration.installationBracketOption.postCount > 0)) &&
                <TextViewControlCutting
                    labelText={`Post Height x ${(awning.installationBracketOption ? awning.installationBracketOption.postCount : awning.fieldConfiguration && awning.fieldConfiguration.installationBracketOption ? awning.fieldConfiguration.installationBracketOption.postCount : 0).toString()}`}
                    value={cuttingDeductions.cuttingPostHeight}
                />}

            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingZipGuidance) && <TextViewControlCutting
                labelText="Zip Guidance"
                value={cuttingDeductions.cuttingZipGuidance}
            />}
            {awning.includeRainCover && !isNullEmptyOrWhitespace(cuttingDeductions.cuttingRainCover) &&
                <TextViewControlCutting
                    labelText="Rain Cover x 2"
                    value={cuttingDeductions.cuttingRainCover}
                />}
            {!isNullEmptyOrWhitespace(cuttingDeductions.cuttingGuideProfile) && <TextViewControlCutting
                labelText="Guide profile (nylon)"
                value={cuttingDeductions.cuttingGuideProfile}
            />}


            {is70mmROller && <div style={{marginTop: '-15px'}}><b>Note:</b> Roller &#x2300;70 for 3000 projection</div>}
        </Aux>
    );
};

AwningCuttingDeductionsControl.propTypes = {
    awning: PropTypes.object.isRequired,
    cuttingDeductions: PropTypes.object.isRequired,
    awningRequiresFabric: PropTypes.bool.isRequired,
    headerText: PropTypes.string.isRequired,
};

export default AwningCuttingDeductionsControl;