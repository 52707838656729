import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiDelete, apiRequest} from "../../utils/request";
import {
  DATE_SHORT_FORMAT,
  FABRIC_RECOVER_ORDER_STATUS_QUOTE,
  FabricRecoverOrderStatuses,
  INVOICE_TYPE_FABRICRECOVER,
  isNullEmptyOrWhitespace,
  obsfucate,  
} from "../../constants";
import moment from "moment";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import OrderInvoiceSheet from "../orders/OrderInvoiceSheet";
import Select from "react-select";
import {toastr} from 'react-redux-toastr';

const initState = {
  orders: [],
  columns: [],
  selectedOrder: null,
  isInvoiceOpen: false,
  filter: {
    statuses: [],
  }
};

class FabricRecoverOrdersPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchOrders = this.fetchOrders.bind(this);   
    this.setColumns = this.setColumns.bind(this);
    this.toggleInvoice = this.toggleInvoice.bind(this);
    this.handleArrayFieldFilterUpdate = this.handleArrayFieldFilterUpdate.bind(this);
    this.handleDeleteOrder = this.handleDeleteOrder.bind(this);
  }

  async fetchOrders() {
    let orders = (await apiRequest(`/api/fabricRecoverOrders`)).data;
    this.setState({
      orders,
    }, () => {
      this.setColumns()
    });
  }

  async handleDeleteOrder(e, orderId) {
    e.preventDefault();

    //eslint-disable-next-line
    if (confirm(`Are you sure you want to delete #${orderId}?`)) {
      let result = await apiDelete(`/api/fabricRecoverOrders/${orderId}`)      
      if (result && result.data && result.data.ok) {
        toastr.success(`Deleted #${orderId}`);
        await this.fetchOrders();
      }
    }
  }

  setColumns() {
    this.setState({
      columns: [{
        Header: 'Status',
        accessor: 'fabricRecoverOrderStatusString',
        maxWidth: 125,
      }, {
        id: 'id',
        Header: 'Id',
        accessor: d => d.id,
      }, {
        id: 'sequenceNumber',
        Header: 'PN',
        accessor: d => d.sequenceNumber,
      }, {
        id: 'lastName',
        Header: 'Last Name',
        accessor: d => d.lastName,
      }, {
        id: 'suburb',
        Header: 'Suburb',
        accessor: d => d.suburb,
      }, {
        id: 'createdDate',
        Header: `Created Date`,
        accessor: d => moment(d.createdDate).format(DATE_SHORT_FORMAT),
        sortMethod: (a, b) => {
          let aVal = moment(a, DATE_SHORT_FORMAT);
          let bVal = moment(b, DATE_SHORT_FORMAT);

          return aVal.isBefore(bVal) ? -1 : 1;
        },
        maxWidth: 120,
      },
        {
          id: 'actions',
          Header: '',
          accessor: c => {            
            return (
                <span>
                  <Link
                      to={`/fabricRecoverOrders/edit/${obsfucate(c.id)}`}
                      className={'mr-3'}
                  ><i className="fa fa-edit fa-lg" /></Link>
                  <a
                      href={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleInvoice(e, c);
                      }}
                      className={'mr-3'}
                  ><i className="fa fa-file-text-o fa-lg" /></a>
                  { c.fabricRecoverOrderStatus === FABRIC_RECOVER_ORDER_STATUS_QUOTE && <a
                      href={'#'}
                      onClick={(e) => this.handleDeleteOrder(e, c.id)}
                  ><i className="fa fa-times fa-lg" /></a> }
            </span>
            );
          },
          width: 125,
        }]
    });
  }
  
  toggleInvoice(e, order = null) {
    let {isInvoiceOpen} = this.state;
    let toBe = !isInvoiceOpen;

    this.setState({selectedOrder: order}, () => {
      this.setState({isInvoiceOpen: toBe});
    });
  }

  handleArrayFieldFilterUpdate(v, field){
    let {filter} = this.state;
    
    let statuses = isNullEmptyOrWhitespace(v) ? [] : v.split(',').map(x => parseInt(x));
    this.setState({filter: {...filter, statuses}});    
  }
  
  componentDidMount() {
    this.fetchOrders().then(_ => {});
  }

  render() {

    let {user,siteSettings} = this.props;
    let {orders, columns, selectedOrder, isInvoiceOpen, filter} = this.state;

    orders = orders.filter(x => filter.statuses.length === 0 || filter.statuses.findIndex(y => y === x.fabricRecoverOrderStatus) >= 0);
    
    return (
      <div>

        {selectedOrder &&
          <OrderInvoiceSheet
              order={selectedOrder}
              toggleOpen={this.toggleInvoice}
              isOpen={isInvoiceOpen}
              print={false}
              invoiceType={INVOICE_TYPE_FABRICRECOVER}
              siteSettings={siteSettings}
          />
        }
        
        <div className={"row mt-2"}>
          <div className={"col-6"}>
            <h2>Fabric Recover Orders</h2>            
          </div>
          <div className={"col-6 text-right"}>
            <div className="form-group">
              <button                  
                  onClick={(e)=>{
                    e.preventDefault();
                    this.props.history.push(`/fabricRecoverOrders/create`);
                  }}
                  className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
                  >
                <i className="icon-floppy pi-icon-left"/>&nbsp;New Order
              </button>
            </div>
          </div>
        </div>

        <div className={"row mt-2"}>
          <div className={"col-12"}>
            <form>
              <div className="form-group row">
                <div className="col-sm-6">
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="Status"
                      name="status"
                      options={FabricRecoverOrderStatuses}
                      onChange={this.handleArrayFieldFilterUpdate}
                      simpleValue={true}
                      value={filter.statuses}
                      placeholder={'Order status'}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        
        <div className={"row mt-2"}>
          <div className={"col-12"}>
            <ReactTable                
                filterable={false}
                data={orders}
                columns={columns}
            />
          </div>
        </div>

        
        
      </div>
    );
  }
}

FabricRecoverOrdersPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    user: state.user,
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabricRecoverOrdersPage));