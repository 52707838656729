import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import injectSheet from 'react-jss';
import {
  DATE_SHORT_FORMAT,
  IsNullOrUndefined,
  MoneyFormatter,
  REPORTSERVER_BASEURL,
  PRODUCTID_SDCZ,
  INVOICE_TYPE_ORDERAWNING
} from "../../constants";
import moment from 'moment';
import { loadFabricMakersSuccess } from "../../actions/lookupActions";
import jQuery from 'jquery';
import {apiDownloadReport, apiPost} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import {Base64} from "js-base64";

const styles = {
  divTable: {
    fontFamily: `Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif`,
    margin: '20px 0',
    fontSize: '14px',
    border: '2px solid #ddd',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
  },
  divThead: {
    display: 'table-header-group',
    fontWeight: '700'
  },
  divTbody: {
    display: 'table-header-group',
  },
  divTr: {
    display: 'table-row',
  },
  divTrHead: {
    display: 'table-row',
  },
  divTh: {
    display: 'table-cell',
    padding: '2px 5px',
    border: '1px solid #ddd',
    borderBottom: '2px solid #ddd',
  },
  divTd: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
  },
  divTdHardLeft: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
    marginLeft: '5px',
    borderLeft: '3px solid #ddd',
  },
  divTdHardRight: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
    borderRight: '3px solid #ddd',
  },
  divTdNoBorder: {
    display: 'table-cell',
    padding: '5px',
  },
  'divTd:first-of-type': {
    borderLeft: '1px solid #ddd',
  },
  'divtbody .div-tr:nth-of-type(2n)': {
    background: '#eee',
  },
  pcImage: {
    width: '40px',
    height: 'auto',
    maxHeight: '45px'
  },
  smallCell:{
    display: 'table-cell',
    padding: '0px'
  },
  smallCellWithPadding:{
    width: '10%',
  },
  divNoBorder:{
    border: '0px'
  },
  largerRow: {
    fontSize: 'larger'
  }



}

const Aux = props => props.children;

const BlankRowFields = (props) => {
  let {classes, hardLeftBorder} = props;
  return (<Aux>
    <div className={hardLeftBorder ? classes.divTdHardLeft : classes.divTd} style={{borderLeft: '3px solid #ddd'}}/>
    <div className={classes.divTd}/>
    <div className={classes.divTd}/>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTdHardRight} ${classes.smallCell}`}/>
  </Aux>);
};

class OrderFabricSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleEmailSelf = this.handleEmailSelf.bind(this);
    this.printMeRef = React.createRef();
  }

  handleEmail(e){
    this.handlePrint(e, 'email');
  }

  handleEmailSelf(e){
    this.handlePrint(e, 'email', true);
  }

  handlePrint(e, mode = 'print', emailSelf = false) {
    if(e) {
      e.preventDefault();
    }
    let {fabricSheetIndex, order, orderType} = this.props;
    //let {order} = this.state;
    let fabricSheet = null;
    if (fabricSheetIndex >= 0) {
      fabricSheet = order.fabricSheets[fabricSheetIndex];
    }

    if (!order || !fabricSheet) {
      return null;
    }

    let container = jQuery(`<div></div>`)
      .css('font-size', '18px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    jQuery(this.printMeRef.current)
        .clone()
        .css('margin-top', '10px')
        .appendTo(container);

    let opt = {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_fabric_sheet.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'landscape', output: 'datauristring'},
      pageBreak: {mode: ['avoid-all', 'css'], avoid: ['.pi-row'],  after: ['#fabricItems'], before: ['#freeText']},
    };

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": true,
      "templateName": "GeneratePdfDefault"
    };

    if (mode === 'print') {
      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), opt.filename);
//      html2pdf().set(opt).from(container[0]).save();
    } else {

      let _this = this;
      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), opt.filename, blob => {
      //html2pdf().set(opt).from(container[0]).outputPdf('datauristring').then(x => {

        let reader = new FileReader();
        reader.addEventListener("load", function () {
          toastr.info('Sending email...');          
          apiPost(`/api/${orderType===INVOICE_TYPE_ORDERAWNING ? 'orders': 'fabricRecoverOrders'}/${order.id}/email/fabricsheet`, {
            content: reader.result,
            fileName: opt.filename,
            fabricId: fabricSheet.fabricId,
            toSelf: emailSelf,
          })
            .then(y => {
              if (y.data && y.data.ok) {
                toastr.success('Email sent');
                _this.setState({
                  order: {
                    ...order, fabricSheets: [...order.fabricSheets.map(f => {
                      let retVal = {...f};
                      if (retVal.fabricId === fabricSheet.fabricId) {
                        if (retVal.fabricOrder === null) {
                          retVal.fabricOrder = {
                            sentDate: moment()
                          }
                        }
                      }
                      return retVal;
                    })]
                  }
                });
                //this.props.toggleOpen();
              } else {
                toastr.error('Error while sending email');
                console.log(y);
              }
            });
        }, false);
        reader.readAsDataURL(blob);
      });

      if (this.props.print) {
        setTimeout(() => {
          this.setState({printed: true});
          this.props.toggleOpen();
        }, 500);
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {classes, fabricSheetIndex, print, order, showEmail, showSequenceNumber} = this.props;
    let fabricSheet = null;
    let orderRef = showSequenceNumber ? order.sequenceNumber : order.customerReference;

    if(IsNullOrUndefined(order)){
      return null;
    }
    if(fabricSheetIndex >= 0 && !IsNullOrUndefined(order.fabricSheets)){
      fabricSheet = order.fabricSheets[fabricSheetIndex];
    }

    if(!order || !fabricSheet){
      return null;
    }

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>

            <div ref={this.printMeRef} style={{width: '98%', marginTop: '25px'}}>
              <div className="pi-row" style={{marginBottom: '15px'}}>
                <div className="pi-col-sm-8">
                  <h2>Fabric Order</h2>
                </div>
                <div className="pi-col-sm-4">
                  <h4><img src="img/logo-full.png" alt="Aluxor Logo"/></h4>
                </div>
              </div>

              <div className="pi-row" style={{fontSize: 'larger'}}>
                <div className="pi-col-sm-2">
                  <strong>To</strong>
                </div>
                <div className="pi-col-sm-4">
                  {order.fabricMakerDescription}
                </div>
              </div>
              { order.fabricOrderDate && <div className="pi-row" style={{fontSize: 'larger'}}>
                <div className="pi-col-sm-2">
                  <strong>Date</strong>
                </div>
                <div className="pi-col-sm-10">
                  {moment(order.fabricOrderDate).format(DATE_SHORT_FORMAT)}
                </div>
              </div> }
              <div className="pi-row" style={{fontSize: 'larger'}}>
                <div className="pi-col-sm-2">
                  <strong>Order</strong>
                </div>
                <div className="pi-col-sm-10">
                  {orderRef} {order.fabricSheets.length > 1 && <span> - F{fabricSheetIndex + 1}</span>}
                </div>
              </div>
              { !IsNullOrUndefined(order.organisationFabricMakerId) && order.organisationFabricMakerId === order.fabricMakerId && <div className="pi-row" style={{fontSize: 'larger'}}>
                <div className="pi-col-sm-2">
                  <strong>Customer Ref.</strong>
                </div>
                <div className="pi-col-sm-10">
                  {order.organisationShortName}-{order.customerReference}
                </div>
              </div> }

            <div id={'fabricItems'} style={{pageBreakInside: 'avoid'}}>

              <div className={classes.divTable} style={{marginBottom: '0px'}}>
                <div className={classes.divThead}>
                  <div className={classes.divTrHead}>
                    <div className={`${classes.divTh} ${classes.smallCellWithPadding}`}>Aluxor Ref</div>
                    <div className={classes.divTh}>Width x Proj/Drop</div>
                    <div className={classes.divTh}>Fabric type</div>
                    <div className={classes.divTh}>Valance type + size</div>
                    <div className={classes.divTh} style={{width: '30%'}}>Comment</div>
                    { fabricSheet.hasCosts && <div className={`${classes.divTh} ${classes.smallCellWithPadding}`} style={{width: '12%'}}>Price</div> }
                  </div>
                </div>
                <div className={classes.divTbody}>
                  {fabricSheet.fabricSheetItems.map(a => {
                    return (
                      <div className={`${classes.divTr} ${classes.largerRow}`} key={`fabricAwningRow_${a.awningId}`}>
                        <div className={`${classes.divTd} ${classes.smallCellWithPadding}`}>{orderRef}.{a.awningNumber}</div>
                        <div className={classes.divTd}>{a.width} x {a.projection}</div>
                        <div className={classes.divTd}>{a.fabricType}</div>
                        <div className={classes.divTd}>{(a.valanceType && a.valanceTrim) ? `${a.valanceType}, trim: ${a.valanceTrim}` : a.valanceType}</div>
                        <div className={classes.divTd} dangerouslySetInnerHTML={{__html: a.comment}} style={{width: '30%'}}/>
                        { fabricSheet.hasCosts && <div className={`${classes.divTd} ${classes.smallCellWithPadding}`} style={{textAlign: 'right', width: '12%'}}>{MoneyFormatter.format(a.cost)}</div> }
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.divTable} style={{border: '0px', marginTop: '0px'}}  id={'fabricItems'}>
                {fabricSheet.hasCosts &&
                <Aux>
                  <div className={`${classes.divTr} ${classes.largerRow}`}>
                    <div
                      className={`${classes.divTd} ${classes.divNoBorder} ${classes.smallCellWithPadding}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}
                         style={{borderRight: '1px solid #ddd', width: '25%'}}><b>GST</b></div>
                    <div className={`${classes.divTd} ${classes.smallCellWithPadding}`}
                         style={{textAlign: 'right', borderRight: '2px solid #ddd', width: '12%'}}>
                      {MoneyFormatter.format(fabricSheet.totalGst)}</div>
                  </div>
                  <div className={`${classes.divTr} ${classes.largerRow}`}>
                    <div
                      className={`${classes.divTd} ${classes.divNoBorder} ${classes.smallCellWithPadding}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                    <div className={`${classes.divTd} ${classes.divNoBorder}`}
                         style={{borderRight: '1px solid #ddd', width: '25%'}}><b>Total</b></div>
                    <div className={`${classes.divTd} ${classes.smallCellWithPadding}`}
                         style={{textAlign: 'right', borderRight: '2px solid #ddd', width: '12%'}}>
                      {MoneyFormatter.format(fabricSheet.totalCost)}</div>
                  </div>
                </Aux>
                }
                <div className={`${classes.divTr} ${classes.largerRow}`}>
                  <div className={`${classes.divTd} ${classes.divNoBorder} ${classes.smallCellWithPadding}`}>&nbsp;</div>
                  <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                  <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                  <div className={`${classes.divTd} ${classes.divNoBorder}`}>&nbsp;</div>
                  <div className={`${classes.divTd} ${classes.divNoBorder}`} style={{borderRight: '1px solid #ddd', width: '25%'}}><b>Invoice</b></div>
                  <div className={`${classes.divTd} ${classes.smallCellWithPadding}`} style={{borderRight: '2px solid #ddd', borderBottom: '2px solid #ddd', width: '12%'}}>&nbsp;</div>
                </div>
              </div>

            </div>

            <div className="pi-row" style={{fontSize: 'larger'}}>
              <div className="pi-col-sm-2">
                <strong>Materials</strong>
              </div>
              <div className="pi-col-sm-10">
                {fabricSheet.fabricBrandDescription} - {fabricSheet.fabricDescription}
              </div>
            </div>

              {fabricSheet.isSupplierOutOfStock &&
              <div className="pi-row" style={{fontSize: 'larger'}}>
                <div className="pi-col-sm-12">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{fabricSheet.expectedStockDate ? ', expected ' + moment(fabricSheet.expectedStockDate).format('MMM Do') : ', expected date unknown'}</label>
                </div>
              </div> }

            {fabricSheet.fabricTrimSummary && <div className="pi-row" style={{fontSize: 'larger'}}>
              <div className="pi-col-sm-2">
                <strong>Trim</strong>
              </div>
              <div className="pi-col-sm-10">
                &nbsp;
              </div>
            </div> }

            <div className={classes.divTable} id={'freeText'} style={{pageBreakInside: 'avoid'}}>
              <div className={classes.divTr} style={{height: '75px'}}>
                <div className={`${classes.divTd} ${classes.smallCellWithPadding}`} style={{width: '20%'}}><b>LM Fabric</b></div>
                <div className={`${classes.divTd}`} style={{width: '30%'}}>&nbsp;</div>
                <div className={`${classes.divTd} ${classes.smallCellWithPadding}`} style={{width: '20%'}}><b>Due Date</b></div>
                <div className={`${classes.divTd}`} style={{width: '30%'}}>&nbsp;</div>
              </div>
            </div>

              {order.anyCanSupplyOwnMotor && !IsNullOrUndefined(order.organisationFabricMakerId) && order.organisationFabricMakerId === order.fabricMakerId &&
                <div className="pi-row" style={{fontSize: 'larger'}}>
                  <div className="pi-col-sm-6">
                    <strong>Please supply the following motor{order.awnings.length > 1 ? 's' : ''}</strong>
                    <ul>
                      {order.awnings.filter(x => x.canSupplyOwnMotor).map(a => <li className={'list-group-item'} key={a.id}>{a.motorBrandDescription} - {a.motorDescription}</li>)}
                    </ul>
                  </div>
                </div> }

                {fabricSheet.fabricSheetItems.map(a => {

                  let isAcryllic = fabricSheet.fabricBrandType.indexOf('Acrylic') >= 0;
                  
                  let awning = order.awnings.find(y => y.id === a.awningId && y.productId === PRODUCTID_SDCZ);

                  return (IsNullOrUndefined(awning) ? null :
                  <Aux key={`fabric_attachment_${awning.id}`}>
                    <div className={'new-page'} />
                    <div className={"pi-row mb-4850"}>
                      <div className="pi-col-md-6">
                            <b>Dimensions for Sundream Compact Zip #{orderRef}.{a.awningNumber}</b>
                      </div>           
                      <div className="pi-col-md-3">
                            <b>Length of X</b>: {awning.cuttingDimension.cuttingWidth}
                      </div>
                      <div className="pi-col-md-3">
                        <b>Length of Y</b>: {awning.cuttingDimension.cuttingFabricProjection}
                      </div>
                    </div>
                    <div className={"pi-row"}>
                      <div className="pi-col-md-12" style={{textAlign: 'center'}}>
                        <img src={`img/SZ_FabricAttachment_${isAcryllic ? 'acrylic' : 'mesh'}.png`} style={{width:'850px', height: '797px'}} />
                      </div>
                    </div>

                  </Aux>);
                })}

          </div>

            {showEmail && <Aux>
             <div className={'pi-row'}>
              <div className={'pi-col-sm-8'}/>
              <div className={'pi-col-sm-2'}>
                <div><b>Email sent: </b></div>
              </div>
              <div className={'pi-col-sm-2'} style={{textAlign: 'right'}}>
                { fabricSheet.fabricOrder ? moment(fabricSheet.fabricOrder.sentDate).format(DATE_SHORT_FORMAT): 'not sent'}
              </div>
            </div>
            <div className={'pi-row'}>
              <div className={'pi-col-sm-8'}/>
              <div className={'pi-col-sm-2'}>
                <div><b>Email viewed: </b></div>
              </div>
              <div className={'pi-col-sm-2'} style={{textAlign: 'right'}}>
                { fabricSheet.fabricOrder && fabricSheet.fabricOrder.viewedDate ? moment(fabricSheet.fabricOrder.viewedDate).format(DATE_SHORT_FORMAT): 'not viewed'}
              </div>
            </div>
          </Aux> }

          </ModalBody>
          <ModalFooter>
            <button onClick={this.handlePrint}
                className="btn pi-btn-base pi-btn-icon-big"
                style={{marginRight: '10px'}}
            >
              <i className="fa fa-print"/>&nbsp;Print</button>
            {showEmail && <button onClick={this.handleEmail}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-envelope"/>&nbsp;Email</button> }
            { showEmail && <button onClick={this.handleEmailSelf}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-envelope"/>&nbsp;Email Self</button> }
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"/>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderFabricSheet.propTypes = {
  order: PropTypes.object.isRequired,
  fabricSheetIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
  showEmail: PropTypes.bool.isRequired,
  showSequenceNumber: PropTypes.bool.isRequired,
  orderType: PropTypes.number.isRequired,
};

export default injectSheet(styles)(OrderFabricSheet);