import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost, apiDownload} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {flatten, uniqBy, every, isEmpty, xor, sortBy} from 'lodash';
import Select from 'react-select';
import querySearch from "stringquery";
import { ModalBody, ModalFooter, Modal } from 'reactstrap';
import store from "../../store";
import {addCartItem} from "../../actions/resourceCartActions";
import {Aux, DATE_SEARCH_FORMAT, DATE_SHORT_FORMAT, ROLE_Administrator} from "../../constants";
import moment from "moment";

class InternalResourcesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      resources: [],
      resourceColumns: [],
      tags: [],
      selectedTags: [],
      filteredResources: [],
      selectedResource: null,
    };
    this.setColumns = this.setColumns.bind(this);
    this.loadData = this.loadData.bind(this);
    this.viewResource = this.viewResource.bind(this);
    this.toggleResource = this.toggleResource.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.addToCart = this.addToCart.bind(this)
  }



  downloadFile(e, resourceUri) {
    e.preventDefault();
    let fileName = resourceUri.replace('\/resources\/documents\/', '');
    apiDownload(`/api/resources/files/${fileName}`, fileName);
  }

  addToCart(e, resource) {
    e.preventDefault();
    let {resourceUri} = resource;

    let fileName = resourceUri.replace('\/resources\/documents\/', '');
    apiDownload(`/api/resources/files/${fileName}`, fileName, 'GET', (blob) => {
      store.dispatch(addCartItem({
        blob: blob,
        fileName: fileName,
      }));
    });
  }

  toggleResource(){
    this.setState({selectedResource: null});
  }

  setColumns() {
    let {resources, selectedTags} = this.state;
    let filteredResources = selectedTags.length === 0 ? resources :
      resources.filter(x =>
        every(selectedTags, t => x.tags.findIndex(rt =>  rt.id === t.id) >= 0));

    this.setState({
      resourceColumns: [
        {
          Header: 'Title',
          accessor: 'title',
        },
        {
          Header: 'Type',
          accessor: 'resourceTypeDescription',
          show: true,
        },
        {
          Header: '',
          accessor: 'description',
          Cell: c => <span>
            {c.original.resourceType === 2 ? <Aux> <a
              href={``}
              onClick={e => this.downloadFile(e, c.original.resourceUri)}
              data-toggle="tooltip" data-placement="top" title="Download now"
            >
              <i className={'fa fa-download'} />
            </a>
              <a
                  style={{marginLeft: '20px'}}
                  href={``}
                  onClick={e => this.addToCart(e, c.original)}
                  data-toggle="tooltip" data-placement="top" title="Add resource to cart for later download"
              >
                <i className={'fa fa-cart-plus'} />
              </a>
            </Aux> : <a
              href={``}
              onClick={e => this.viewResource(e, c.original)}
              data-toggle="tooltip" data-placement="top" title="View resource"
            >
              <i className={'fa fa-binoculars'} />
            </a> }

          </span>,
          maxWidth: 100,
        }
      ],
      filteredResources
    });
  }

  viewResource(e, resource){
    e.preventDefault();
    this.setState({selectedResource: resource});
  }

  loadData(){
    //TODO: List of tags needs to be restricted to organisation

    toastr.warning('Loading resources');
    apiRequest(`/api/resources?isInternalOnly=true`)
      .then(x => {
        if(x.data && x.data.length) {
          let resources = x.data;
          let tags = sortBy(uniqBy(flatten(resources.map(r => r.tags)), r => r.description.toLowerCase()), y => y.description.toLowerCase());
          let selectedTags =  tags.filter(t => this.props.tags.findIndex(a => a.toLowerCase() === t.description.toLowerCase()) >= 0);
          this.setState({resources, tags, selectedTags}, this.setColumns);
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(xor(prevProps.tags, this.props.tags))) {
      let selectedTags = this.state.tags.filter(t => this.props.tags.findIndex(a => a.toLowerCase() === t.description.toLowerCase()) >= 0);
      this.setState({selectedTags}, this.setColumns);
    }
  }

  componentDidMount() {
    this.setColumns();
    this.loadData();
  }

  render() {
    let {filteredResources, resourceColumns, tags, selectedTags, selectedResource} = this.state;
    let {user} = this.props;

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Internal Resources</h2>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.selectedResource}
               toggle={() => this.setState({selectedResource: null})}
               style={{minWidth: '940px', minHeight: '720px'}}
        >
          <ModalBody>
            <div className={'pi-row'} style={{paddingLeft: '15px'}}>
              <div className={'pi-col-md-12'} style={{textAlign: 'center'}}>
                {selectedResource && <iframe
                  width="800"
                  height="600"
                  src={`${selectedResource.resourceUri}?autoplay=0&fs=1`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen /> }
              </div>
            </div>
          </ModalBody>
        </Modal>        

        <div className="pi-row">
          <div className={'pi-col-md-1'}>
            <label>Tags</label>
          </div>
          <div className={'pi-col-md-8'}>
            <Select
              labelText="Tags"
              options={tags}
              valueKey={'id'}
              labelKey={'description'}
              onChange={(val) => {
                this.setState({selectedTags: val}, this.setColumns)
              }}
              value={selectedTags}
              clearable
              backspaceRemoves
              multi
            />
          </div>
        </div>

        <div className="pi-row">
          <div className={'pi-col-md-12'}>
            <ReactTable
              sortable={true}
              filterable={false}
              showPagination={true}
              data={filteredResources}
              columns={resourceColumns}
              defaultPageSize={15}
              minRows={6}
            />
          </div>
        </div>
      </div>);
  }
}

InternalResourcesPage.propTypes = {
  tags: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  let queryTags = querySearch(ownProps.location.search);
  let tags = Array.isArray(queryTags.tags) ? queryTags.tags : queryTags.tags ? [queryTags.tags] : [];

  return {
    tags,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InternalResourcesPage));
