import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Aux, IsNullOrUndefined,
    IsRoofSystem,
    MoneyFormatter
} from "../../../constants";
import CustomerAwningPriceSummary from "./CustomerAwningPriceSummary";
import {SummaryDivider} from "../../common/SummaryControls";
import CustomerPriceControl from "./CustomerPriceControl";
import AwningQuoteAddons from "./AwningQuoteAddons";
import AwningAttachments from "../AwningAttachments";

const CustomerOrderSummary = (props) => {
    let {order, quoteAddons, onReloadOrder, showPrice} = props;

    let anyNonRoofing = order.awnings.findIndex(x => !IsRoofSystem(x)) >= 0;
    let anyRoofing = order.awnings.findIndex(x => IsRoofSystem(x)) >= 0;    

    return (IsNullOrUndefined(order) ? null :
        <Aux>
            <div className={"row mt-2 mb-4"}>
                <div className={"col-12"}>
                    <h4>Quote Summary</h4>
                </div>
            </div>

            {order.awnings.map(awning =>
                <Aux key={awning.id}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <CustomerAwningPriceSummary
                                awning={awning}
                                order={order}
                                key={awning.id}
                                showPrice={showPrice}
                            />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-6"}>
                            <AwningQuoteAddons 
                                awning={awning} 
                                quoteAddons={quoteAddons} 
                                onSaved={onReloadOrder}
                                showPrice={showPrice}
                            />                            
                        </div>
                    </div>

                    {showPrice && <div className={"row"}>
                        <div className={"col-6"}>
                        <b>
                            <CustomerPriceControl
                                showPrice={showPrice}
                                labelText={`Sub-Total`}
                                retail={awning.costs.totalRetail + awning.quoteAddonCostTotal}
                                discount={awning.costs.organisationDiscount}
                            />
                        </b>
                        </div>
                    </div> }

                    <div className="row mt-4">
                        <div className="col-12"><h5>Notes to Quotation</h5></div>
                    </div>

                    {awning.notes.length > 0 && <div className={"row mt-3"}>
                        <div className={"col-6"}>
                            <span className={"font-weight-bold"}>Notes: </span>
                            {awning.notes.reduce((x, y) => IsNullOrUndefined(x.description) ? y.description : `${x.description} - ${y.description}`, [])}
                        </div>
                    </div> }

                    <div style={{marginTop: '20px', marginLeft: '15px'}} className={'awning-summary-attachments'}>
                        <AwningAttachments                            
                            attachments={!awning.attachments ? [] : awning.attachments}
                            headerText={'Site photos/attachments (edit quote to add)'}
                            readOnly={true}
                        />
                    </div>
                </Aux>)
            }


            <div className={"row"}>

                <div className={"col-6"}>
                    <div>
                        {(order.sensorCostsTotal > 0) &&
                            <SummaryDivider labelText={showPrice ? "Sensor Cost Summary" : "Sensor Summary"}/>}

                        {order.sensorCosts.map(c => {

                            return (<Aux key={`sensorcost_${c.rowKey}`}>
                                <div className="row">
                                    <div
                                        className="col-10">{c.sensorTypeDescription} {c.awningCount > 1 ? `set (${c.awningCount} awnings)` : `x 1`}</div>
                                    <div className="col-2"
                                         style={{textAlign: 'right'}}>{showPrice ? MoneyFormatter.format(c.total) : ""}</div>
                                </div>

                                {c.additionalCosts.remoteSurchargeRetail && showPrice &&
                                    <div className="row">
                                        <div className="col-10">Remote surcharge</div>
                                        <div className="col-2"
                                             style={{textAlign: 'right'}}>{MoneyFormatter.format(c.additionalCosts.remoteSurchargeRetail)}</div>
                                    </div>
                                }

                            </Aux>);


                        })}
                    </div>

                    {(order.sensorCostsTotal > 0 && showPrice) && <div className="row">
                        <div className="col-10"><b>Sensor Total</b></div>
                        <div className="col-2" style={{textAlign: 'right'}}>
                            <strong>{order.organisationWholesaleOnly || !anyNonRoofing ? '' : MoneyFormatter.format(order.sensorCostsTotal)}</strong>
                        </div>
                    </div>}

                    <div className={"row mt-5 font-weight-bold mb-5"}>
                        <div className={"col-12"}>
                            {showPrice && <CustomerPriceControl
                                showPrice={showPrice}
                                discount={order.organisationDiscount}
                                labelText="Order Total"
                                retail={order.costTotal}
                            />}
                        </div>
                    </div>
                    <div className={"row mt-5 font-weight-bold mb-5"}>
                        <div className={"col-12"}>
                            {showPrice && <CustomerPriceControl
                                showPrice={showPrice}
                                discount={order.organisationDiscount}
                                labelText="GST"
                                retail={order.costTotal * 0.1}
                            />}
                        </div>
                    </div>
                    <div className={"row mt-5 font-weight-bold mb-5"}>
                        <div className={"col-12"}>
                            {showPrice && <CustomerPriceControl
                                showPrice={showPrice}
                                discount={order.organisationDiscount}
                                labelText="Total incl GST"
                                retail={order.costTotal * 1.1}
                            />}
                        </div>
                    </div>

                </div>
            </div>
        </Aux>)
};


CustomerOrderSummary.propTypes = {
    order: PropTypes.object.isRequired,
    quoteAddons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
    })).isRequired,
    onReloadOrder: PropTypes.func.isRequired,
    showPrice: PropTypes.bool.isRequired,
};

export default CustomerOrderSummary;