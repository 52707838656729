import * as types from '../actions/actionTypes';
import initialState from './initialState';

function createUser(action){
  let user = {...action.user};

  user.isInRole = (role) => {
    let found = user.roles.find(x => x.description === role);
    if(found) {
      return found.description === role;
    }
    return false;
  };

  user.hasAccessTag = (accessTag) => {
    if(accessTag === undefined || accessTag === null || accessTag === ''){
      console.log('Check for AccessTag not defined', accessTag);
      return false;
    }

    let found = user.accessTags.find(x => x.description === accessTag);
    if(found) {
      return found.description === accessTag;
    }
    return false;
  };

  return user;
}

export default function userReducer(state = initialState.user, action){
  switch(action.type) {
    case types.LOAD_USER_SUCCESS:
      return createUser(action);
    default:
      return state;
  }
}
