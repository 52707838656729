import PropTypes from "prop-types";
import React from "react";
import TextViewControl from "../common/TextViewControl";
import {Aux, DATE_SHORT_FORMAT, isNullEmptyOrWhitespace, IsNullOrUndefined} from "../../constants";
import moment from 'moment';

const RecoverViewOrderSection = (props) => {
    let {order, onToggleEdit} = props;

    return (<Aux>
        <div className={"row mt-2"}>
            <div className={"col-6"}>
                <h4>Order Information</h4>
            </div>
        </div>
        <div className={"row mt-2 mb-4"}>
            <div className={"col-6"}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        {order.firstName} {order.lastName}        
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        {order.organisationAddressComplete}
                    </div>
                </div>                
            </div>
            <div className={"col-6"}>
                <TextViewControl
                    labelText="Email"
                    value={order.email1}
                />
                <TextViewControl
                    labelText="Phone"
                    value={order.phone1}
                />

                {!isNullEmptyOrWhitespace(order.quoteNote) && <div className={'row mt-2'}>
                    <div className={'col-12'}><b>Quote Notes</b></div>
                </div>}
                {!isNullEmptyOrWhitespace(order.quoteNote) && order.quoteNote.split('\n').filter(x=> !isNullEmptyOrWhitespace(x)).map(x => {
                    return (<div key={`${order.id}${x}`} className={'row'}>
                        <div className={'col-12'}>{x}</div>
                    </div>); })
                }
            </div>
        </div>
        <div className={"row mt-2 mb-4"}>
            <div className={"col-6"}>
                <a  href={''}                   
                    onClick={(e) => {
                        e.preventDefault()
                        onToggleEdit()
                    }}>
                    Edit Production Information
                </a>

                <TextViewControl
                    labelText="Order Status"
                    value={order.fabricRecoverOrderStatusString}
                />
                
                <TextViewControl
                    labelText="PN"
                    value={order.sequenceNumber}
                />
                
                <TextViewControl
                    labelText="Fabric Maker"
                    value={order.fabricMakerDescription}
                />

                <TextViewControl
                    labelText="Fabric Order Date"
                    value={IsNullOrUndefined(order.fabricOrderDate) ? 'N/A' : moment(order.fabricOrderDate).format(DATE_SHORT_FORMAT) }
                />

                <TextViewControl
                    labelText="Supplier Scheduled"
                    value={IsNullOrUndefined(order.fabricSupplierCompleteDate) ? 'N/A' : moment(order.fabricSupplierCompleteDate).format(DATE_SHORT_FORMAT) }
                />

                <TextViewControl
                    labelText="Supplier Completed"
                    value={IsNullOrUndefined(order.fabricSupplierCompletedDate) ? 'N/A' : moment(order.fabricSupplierCompletedDate).format(DATE_SHORT_FORMAT) }
                />
                
                <TextViewControl
                    labelText="Fabric Received Date"
                    value={IsNullOrUndefined(order.fabricRecDate) ? 'N/A' : moment(order.fabricRecDate).format(DATE_SHORT_FORMAT) }
                />
            </div>
        </div>
    </Aux>);
};

RecoverViewOrderSection.propTypes = {
    order: PropTypes.object.isRequired,
    onToggleEdit: PropTypes.func.isRequired,
}

export default RecoverViewOrderSection;