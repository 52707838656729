import React from 'react';
import PropTypes from 'prop-types';
import {IsNullOrUndefined} from "../../constants";


const TextViewControlV2 = (props) => {
  let {labelText, value, boldLabel, style, visible, col1, col2} = props;

  if (boldLabel === undefined) {
    boldLabel = false;
  }
  col1 = IsNullOrUndefined(col1) ? 4 : col1;
  col2 = IsNullOrUndefined(col2) ? 8 : col2;

  return IsNullOrUndefined(value) || visible === false ? null : (<div className={"col"}>
      <div className="row" style={style}>
        <div className={`col-${col1}`} style={{fontWeight: boldLabel ? 'bold' : ''}}>{labelText}</div>
        <div className={`col-${col2}`}>{value == null ? 'N/A' : value}</div>
      </div>
  </div>);
};

TextViewControlV2.propTypes = {
  labelText: PropTypes.string.isRequired,
};
export default TextViewControlV2;