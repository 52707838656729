import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  Aux,
  AwningBracketTotal,
  FRAME_FEATURE_SELECTAPITCH,
  isManual, isNullEmptyOrWhitespace,
  IsNullOrUndefined, PRODUCTID_ECO, PRODUCTID_S1,
  SIDE_LEFT
} from "../../constants";
import {min} from "moment";

const styles = {
  wrapper: {
    margin: '0 auto',
    position: 'relative',
    border: '1px solid black',
  },
  fixedWrapper: {
    position: 'absolute',
    border: '1px solid red',
    backgroundColor: 'red',
  },
  fixedWrapperItem: {
    position: 'fixed',
  }
};

const awning = {
  bracketTotal: 0,
  numberOfArms: 0,
  outsideArmSpacing: 0,
  insideArmSpacing: 0,
  width: 0,
  projection: 0,
  productDescription: '',
};

function round5(x)
{
  return Math.ceil(x/5)*5;
}

export function GetDataForFAModel({outsideArmSpacing, width, numberOfArms, insideArmSpacing, middleArmSide,
                                  bracketTotal,
                                    rollerBktEndCap,
                                    armBktCentreToOutsideX1,
                                    requiredSpaceMntBktX2,
                                    toleranceX3,
                                    armBktCentreToInside,
                                    centreOfMountBkt,
                                    allowancePosition,
}){

  let minSpace = round5(rollerBktEndCap + armBktCentreToOutsideX1 + requiredSpaceMntBktX2 + toleranceX3);
  let middleLeft = middleArmSide === SIDE_LEFT;
  let arms = [];
  let brackets = [];

  let fitsOutside = !(outsideArmSpacing < minSpace);
  let outsideMinusWidth = (width - outsideArmSpacing);

  let insideBracketSpacing = round5(armBktCentreToInside + centreOfMountBkt + allowancePosition);
  let minMountBracketPosition = minSpace - (armBktCentreToOutsideX1 + (requiredSpaceMntBktX2/2) + 10);
  let outsideBracketPosition =  ((outsideArmSpacing - minSpace) * 0.5) + minMountBracketPosition;

  //console.log({outsideArmSpacing, minMountBracketPosition, rollerBktEndCap, outsideBracketPosition, minSpace, fitsOutside});

  if(outsideArmSpacing - outsideBracketPosition > 200){
    outsideBracketPosition = outsideArmSpacing - 200;
  }
  let insideBracketPosition = outsideArmSpacing + insideBracketSpacing;

  let outsideLeft = fitsOutside ? outsideBracketPosition : insideBracketPosition;
  let outsideRight = fitsOutside ? width - outsideBracketPosition : width - insideBracketPosition;

  if (numberOfArms === 2) {
    arms = [
      {mm: outsideArmSpacing},
      {mm: outsideMinusWidth},
    ];
  } else if (numberOfArms === 3) {
    arms = [
      {mm: outsideArmSpacing},
      {mm: middleLeft ? insideArmSpacing : (width - insideArmSpacing)},
      {mm: outsideMinusWidth},
    ];
  } else if (numberOfArms === 4) {
    arms = [
      {mm: outsideArmSpacing},
      {mm: insideArmSpacing},
      {mm: width - insideArmSpacing},
      {mm: outsideMinusWidth},
    ];
  }

  if (bracketTotal === 2 && numberOfArms === 2) {
    brackets = [
      {mm: outsideLeft},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 3 && numberOfArms === 2) {
    brackets = [
      {mm: outsideLeft},
      {mm: width / 2},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 4 && numberOfArms === 2) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 5 && numberOfArms === 2) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: (width / 2)},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 5 && numberOfArms === 3) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: middleLeft ? insideArmSpacing - insideBracketSpacing : (width - insideArmSpacing) + insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 6 && numberOfArms === 3) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: middleLeft ? insideArmSpacing - insideBracketSpacing : (width - insideArmSpacing) + insideBracketSpacing},
      {mm: middleLeft ? insideArmSpacing + insideBracketSpacing : (width - insideArmSpacing) - insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 6 && (numberOfArms === 4)) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: insideArmSpacing - insideBracketSpacing},
      {mm: (width - insideArmSpacing) + insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 7 && (numberOfArms === 4)) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: insideArmSpacing - insideBracketSpacing},
      {mm: (width / 2)},
      {mm: (width - insideArmSpacing) + insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  } else if (bracketTotal === 8 && (numberOfArms === 4)) {
    brackets = [
      {mm: outsideLeft},
      {mm: fitsOutside ? outsideArmSpacing + insideBracketSpacing : outsideArmSpacing + insideBracketSpacing + insideBracketSpacing},
      {mm: insideArmSpacing - insideBracketSpacing},
      {mm: insideArmSpacing + insideBracketSpacing},
      {mm: (width - insideArmSpacing) - insideBracketSpacing},
      {mm: (width - insideArmSpacing) + insideBracketSpacing},
      {mm: fitsOutside ? outsideMinusWidth - insideBracketSpacing : outsideMinusWidth - insideBracketSpacing - insideBracketSpacing},
      {mm: outsideRight},
    ];
  }
 return {arms, brackets, fitsOutside, minSpace};
}

class DiagramFA extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      brackets: [],
      arms: [],
      awning: {...awning},
      containerWidth: props.width,
      scale: 1,
      numberOfArms: 2,
      bracketTotal: 2,
      unsupported: false,
    };

    this.setupAwning = this.setupAwning.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.awning && JSON.stringify(this.props.awning) !== JSON.stringify(prevProps.awning)) {
      this.setupAwning();
    }
  }

  componentDidMount() {
    this.setupAwning();
  }

  setupAwning() {
    let {awning} = this.props;
    let {containerWidth} = this.state;

    if (!awning) {
      return;
    }
    let {
      outsideArmSpacing, width, numberOfArms, insideArmSpacing, middleArmSide,
      productArmBracketDeadSpace, mountingBracketWidth, additionalMountingBrackets} = awning;

    width = parseInt(width);

    if (outsideArmSpacing === null || outsideArmSpacing < 0) {
      outsideArmSpacing = 0;
    }
    if (insideArmSpacing === null || insideArmSpacing < 0) {
      insideArmSpacing = 0;
    }

    let bracketTotal = AwningBracketTotal(awning).bracketTotal - additionalMountingBrackets;
    let scale = containerWidth / width;
    let bracketArmWidth = 80;

    let productData = getProductMountBracketData(awning.productId, awning.frameFeature);
    
    if(productData===null){
      this.setState({
        awning: {...awning, bracketTotal: bracketTotal},
        arms: [],
        scale,
        brackets: [],
        bracketArmWidth,
        unsupported: true,
      });
    } else {

    let {arms, brackets, fitsOutside, minSpace} = GetDataForFAModel({
      outsideArmSpacing, width, numberOfArms, insideArmSpacing, middleArmSide, bracketTotal,
      rollerBktEndCap: productData.rollerBktEndCap,
      armBktCentreToOutsideX1: productData.armBktCentreToOutsideX1,
      requiredSpaceMntBktX2: productData.requiredSpaceMntBktX2,
      toleranceX3: productData.toleranceX3,
      armBktCentreToInside: productData.armBktCentreToInside,
      centreOfMountBkt: productData.centreOfMountBkt,
      allowancePosition: productData.allowancePosition
    });

//      console.log(arms, brackets, fitsOutside, minSpace, productData, outsideArmSpacing);

      this.setState({
        awning: {...awning, bracketTotal: bracketTotal},
        arms,
        scale,
        brackets,
        bracketArmWidth,
        unsupported: (arms.length === 0 || brackets.length === 0 || IsNullOrUndefined(awning.mountingBracketId))
      });
    }
  }

  render() {
    let {arms, brackets, containerWidth, scale, unsupported, awning, bracketArmWidth} = this.state;
    let {classes} = this.props;
    let {additionalMountingBrackets} = awning;

    let round = (x) => {
      let switched = x > (awning.width / 2) ? awning.width - x : x;
      return Math.round(switched);
    };

    return (unsupported ? <div style={{textAlign: 'center', color: 'red'}}>
        <h4>Diagram not available for this configuration, please contact Aluxor</h4>
      </div> :
      <Aux>
        <div className="row">
          <div className={'col-12'}>
            <h4>Awning Spacing Diagram</h4>
          </div>
        </div>
        
        <div className={classes.wrapper} style={{width: containerWidth, height: '75px', border: '0px', textAlign: 'center'}}>
          <h4>{awning.numberOfArms} Arms / {awning.bracketTotal} Brackets{awning.oversquare ? ' Oversquare' : ''}</h4>
          {additionalMountingBrackets !== 0 && <h5>Standard bracket configuration, additional bracket positioning at customer discretion</h5> }
        </div>
        <div className={classes.wrapper} style={{width: containerWidth, height: '50px', marginTop: '9px'}}>
          {!isNullEmptyOrWhitespace(awning.sideOfOperation) &&
          <div style={{
            backgroundColor: '#f5b83c', paddingLeft: '3px',
            paddingTop: '0px', width: '20px', height: '20px', fontWeight: 'bolder', marginTop: '-38px', marginLeft: awning.sideOfOperation==="Left"? "0" : containerWidth - 20
          }}>{isManual(awning) ? "G" : "M"}</div>
          }
          
          {arms.map((x, i) => (<div key={i}
                                    style={{
                                      top: '2px', width: (bracketArmWidth * scale), height: '40px',
                                      backgroundColor: 'green', left: (((x.mm - (bracketArmWidth / 2)) * scale))
                                    }}
                                    className={classes.fixedWrapper}
          >
            <div style={{
              marginTop: '-25px',
              marginLeft: '-8px',
              fontSize: '11px',
              fontWeight: 'bold'
            }}>{round(x.mm)}</div>
            <div style={{
              marginTop: '13px',
              marginLeft: '-13px',
              fontSize: '10px',
              fontWeight: 'bold'
            }}>A
            </div>            
          </div>))}
        </div>
        <div className={classes.wrapper} style={{width: containerWidth, height: '75px'}}>
          {brackets.map((x, i) => (<div key={i}
                                        style={{
                                          top: '2px',
                                          width: (awning.mountingBracketWidth * scale),
                                          height: '40px',
                                          backgroundColor: 'orange',
                                          left: (((x.mm - (awning.mountingBracketWidth / 2)) * scale))
                                        }}
                                        className={classes.fixedWrapper}
          >
            <div style={{
              marginTop: '40px',
              marginLeft: '-7px',
              fontSize: '10px',
              fontWeight: 'bold'
            }}>{round(x.mm)}</div>
            <div style={{
              marginTop: '-50px',
              marginLeft: '-13px',
              fontSize: '10px',
              fontWeight: 'bold'
            }}>B
            </div>
          </div>))}
        </div>
        <div style={{textAlign: 'center', marginBottom: '10px'}}>
          Awning width {awning.width}mm x projection {awning.projection}mm
        </div>
        {(awning.includePelmet && (awning.productId === PRODUCTID_S1 || awning.productId === PRODUCTID_ECO)) && 
        <div style={{textAlign: 'center', marginBottom: '10px'}}>
          {awning.pelmetName} width {awning.width+20}mm
        </div>}
        {/*<div className={classes.wrapper} style={{width: containerWidth, border: '1.5px solid black', textAlign: 'center', marginBottom:'10px', marginTop:"5px"}}>*/}
        {/*  <div className="row">*/}
        {/*    <div className={'col-1'}><h5>Key</h5></div>*/}
        {/*  </div>*/}
        {/*  <div className="row">          */}
        {/*    <div className={'col-4'} style={{textAlign: 'center'}}>            */}
        {/*      <div style={{*/}
        {/*             top: '2px', width: '10px', height: '35px',*/}
        {/*             backgroundColor: 'green', margin: "auto"*/}
        {/*           }}                 */}
        {/*      >&nbsp;</div>Arm Bracket*/}
        {/*    </div>*/}
        {/*    <div className={'col-4'} style={{textAlign: 'center'}}>*/}
        {/*      <div style={{*/}
        {/*        top: '2px', width: '10px', height: '35px',*/}
        {/*        backgroundColor: 'orange', margin: "auto"*/}
        {/*      }}*/}
        {/*      >&nbsp;</div>Mounting Bracket*/}
        {/*    </div>*/}
        {/*    <div className={'col-4'} style={{textAlign: 'center'}}>*/}
        {/*      <div style={{*/}
        {/*        top: '2px', width: '20px', height: '35px',*/}
        {/*        backgroundColor: '#f5b83c', margin: "auto", paddingTop: "5px",*/}
        {/*        fontWeight:'bold'*/}
        {/*      }}*/}
        {/*      >{isManual(awning)?"G":"M"}</div>{isManual(awning)?"Gearbox":"Motor"}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Aux>);
  }
}

DiagramFA.propTypes = {
  awning: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
};

export default injectSheet(styles)(DiagramFA);

function getProductMountBracketData(productId, frameFeature = null) {
  let isSp = frameFeature === FRAME_FEATURE_SELECTAPITCH;
  let filtered = ProductMountBracketData.filter(x => x.productId === productId && x.isSp === isSp);
  return filtered.length === 1 ? filtered[0] : null;
}

const ProductMountBracketData = [
  {
    productId: 1,
    rollerBktEndCap: 42,
    armBktCentreToOutsideX1: 30,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 30,
    centreOfMountBkt: 27.5,
    allowancePosition: 40,
    isSp: false,
  },
  {
    productId: 2,
    rollerBktEndCap: 42,
    armBktCentreToOutsideX1: 45,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 70,
    centreOfMountBkt: 27.5,
    allowancePosition: 40,
    isSp: false,
  },
  {
    productId: 3,
    rollerBktEndCap: 52,
    armBktCentreToOutsideX1: 45,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 70,
    centreOfMountBkt: 27.5,
    allowancePosition: 40,
    isSp: false,
  },
  {
    productId: 2,
    rollerBktEndCap: 42,
    armBktCentreToOutsideX1: 30,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 60,
    centreOfMountBkt: 27.5,
    allowancePosition: 50,
    isSp: true,
  },
  {
    productId: 3,
    rollerBktEndCap: 52,
    armBktCentreToOutsideX1: 30,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 60,
    centreOfMountBkt: 27.5,
    allowancePosition: 50,
    isSp: true,
  },
  {
    productId: 4,
    rollerBktEndCap: 60,
    armBktCentreToOutsideX1: 45,
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToInside: 70,
    centreOfMountBkt: 27.5,
    allowancePosition: 40,
    isSp: false,
  },
  {
    productId: 6,
    rollerBktEndCap: 36,
    armBktCentreToOutsideX1: 45,
    requiredSpaceMntBktX2: 100,
    toleranceX3: 14,
    armBktCentreToInside: 70,
    centreOfMountBkt: 50,
    allowancePosition: 20,
    isSp: false,
  },
  {
    productId: 5,
    rollerBktEndCap: 60,    
    requiredSpaceMntBktX2: 55,
    toleranceX3: 10,
    armBktCentreToOutsideX1: 45,
    armBktCentreToInside: 32,    
    centreOfMountBkt: 100,
    allowancePosition: 30,
    isSp: false,
  }
];
