import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {DebounceInput} from 'react-debounce-input';
import {IsNullOrUndefined} from "../../constants";
import TextBoxControl from "./TextBoxControl";

const MODE_view = 'view';
const MODE_edit = 'edit';

class TextBoxControlWithEnter extends Component {

  constructor(props, context) {
    super(props, context);

    let initalValue = IsNullOrUndefined(props.value) ? null : props.value;

    this.state = {
      editValue: initalValue,
      mode: MODE_view,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleUpdateMode = this.handleUpdateMode.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleOnChange(evt) {
    this.setState({editValue: evt.target.value});
  }

  handleUpdateMode(e, newMode) {
    e.preventDefault();
    let {mode, editValue} = this.state;
    let {value, name} = this.props;

    if (mode !== newMode && mode === MODE_edit && editValue !== value) {
      this.props.onChange({target: {name, value: editValue}});
    }
    this.setState({mode: newMode});
  }

  handleCancel(e) {
    e.preventDefault();
    this.setState({mode: MODE_view, editValue: this.props.value});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {editValue, mode} = this.state;
    let editValueProps = this.props.editValue;

    if (editValue !== editValueProps && mode === MODE_view) {
      this.setState({editValue: editValueProps});
    }
  }

  render() {
    let {editValue} = this.state;
    let propsEdit = {...this.props, value: editValue, onChange: this.handleOnChange, disabled: this.state.mode === MODE_view};
    let propsView = {...this.props, onChange: this.handleOnChange, disabled: true, labelText: this.props.labelTextView, validationMessages: null};
    let {mode} = this.state;

    return (<div className={'row'}>
      <div className={'col-4'}>
         <TextBoxControl {...propsEdit} />
      </div>
      { mode === MODE_view &&  <div className={'col-4'}>
        <TextBoxControl {...propsView} />
      </div> }
      <div className={'col-2'}>
        <div className={'form-group'}>
          <label>&nbsp;</label>
          {mode === MODE_view && <button
              onClick={(e) => this.handleUpdateMode(e, MODE_edit)}
              className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing form-control"
              style={{marginLeft: '10px', background: '#f58b3c', border: '1px solid #f58b3c'}}>
            <i className="fa fa-edit"/>&nbsp;Edit
          </button>}
          {mode === MODE_edit && <button
              onClick={(e) => this.handleUpdateMode(e, MODE_view)}
              className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing form-control"
              style={{marginLeft: '10px', background: '#f58b3c', border: '1px solid #f58b3c'}}>
            <i className="fa fa-check-square"/>&nbsp;Done
          </button>}
        </div>
      </div>
      <div className={'col-2'}>
        <div className={'form-group'}>
          <label>&nbsp;</label>
          {mode === MODE_edit && <button
              onClick={this.handleCancel}
              className="btn pi-uppercase pi-weight-700 pi-letter-spacing form-control"
              style={{marginLeft: '15px', color:'#586266'}}>
            <i className="fa fa-undo"/>&nbsp;Cancel
          </button>}
        </div>
      </div>
    </div>);
  }
}

TextBoxControlWithEnter.propTypes = {
  labelText: PropTypes.string.isRequired,
  labelTextView: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  editValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  validationMessages: PropTypes.object,
};

export default TextBoxControlWithEnter;