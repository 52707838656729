import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import {Link} from "react-router-dom";
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const initState = {
  colours: [],
  columns: [
    {
      Header: 'Supplier',
      accessor: 'powderCoatSupplier',
      filterMethod: (filter, row) => {
        return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
      },
    },
    {
      Header: 'Range',
      accessor: 'powderCoatRange',
      filterMethod: (filter, row) => {
        return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      filterMethod: (filter, row) => {
        return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
      },
    },
    {
      Header: 'Surcharge',
      accessor: 'isSurcharge',
      Cell: c => <span>
            <i className={`fa ${c.original.isSurcharge ? 'fa-check' : 'fa-times'}`}/>
          </span>,
      maxWidth: 150,
      Filter: ({filter, onChange}) => <span></span>,
    },
  ],
};

class ColourLookupPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchColours = this.fetchColours.bind(this);

  }

  async fetchColours() {
    let colours = (await apiRequest(`/api/colours/custom`)).data;
    this.setState({
      colours,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentDidMount() {
    this.setState({...initState}, () => {
      this.fetchColours();
    });
  }

  render() {

    let {colours, columns} = this.state;

    return (
      <div>
        <h4 style={{marginTop: '10px'}}><Link to={`/products/armbracketspacing`}>Awning Specifications</Link> | <Link to={`/fabrics/lookup`}>Fabric Lookup</Link> | Colour Lookup | <Link to={`/sintesi/lookup`}>Sintesi Lookup</Link></h4>

        <p>View current available powder coating colours</p>

        <div className={'pi-row'} style={{marginBottom: '35px'}}>
          <div className='pi-col-md-12'>              

            { colours.length > 0 && <ReactTable
              sortable={true}
              filterable={true}
              showPagination={true}
              data={colours}
              columns={columns}
              defaultPageSize={15}
              minRows={10}
            /> }
          </div>


        </div>

      </div>
    );
  }
}

ColourLookupPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColourLookupPage));