import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import connect from "react-redux/lib/connect/connect";
import {
    DATE_SHORT_FORMAT,
    deobsfucate,
    INVOICE_TYPE_ORDERAWNING, INVOICE_TYPE_PCSTOCKORDER, isNullEmptyOrWhitespace,
    IsNullOrUndefined,
    view_order_view_fabric_sheets_in_production
} from "../../constants";
import {apiRequest} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import OrderPowderCoatingSheet from "../orders/OrderPowderCoatingSheet";
import OrderFabricSheet from "../fabric/OrderFabricSheet";
import PcStockOrderPcSheet from "../pc/PcStockOrderPcSheet";
import moment from "moment";

class SheetWrapperPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: null,
        };

        this.loadOrder = this.loadOrder.bind(this);
    }

    loadOrder() {
        let {orderId, orderType} = this.props;
        toastr.info('Loading sheet...');
        apiRequest(`/api/supplierorders/${orderId}?orderType=${orderType}`)
            .then(x => {
                toastr.success('Loaded!');
                this.setState({order: {...x.data[0]}});
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.orderId !== this.props.orderId && this.props.orderId > 0){
            this.loadOrder();
        } else if(prevProps.orderId !== this.props.orderId && this.props.orderId <= 0){
            this.setState({order: null});
        }
    }

    componentDidMount() {
        this.loadOrder();
    }

    render() {
        let  {order} = this.state;
        let  {sheetIndex, sheetName, canEmail, user, orderType, download} = this.props;

        let toggleOpen = () => {
            this.setState({order: null}, () => {
                this.props.history.push('/supplier/jobs');
            });
        };

        if(sheetName === 'fabricsheet') {
            return !IsNullOrUndefined(order) && order.fabricSheets ?
                (<OrderFabricSheet order={order} isOpen={!IsNullOrUndefined(order)} toggleOpen={toggleOpen} fabricSheetIndex={sheetIndex} print={false} 
                                   showEmail={canEmail}
                                   orderType={orderType}
                                   showSequenceNumber={IsNullOrUndefined(user.organisation.fabricMakerShowSequenceNumber) || user.organisation.fabricMakerShowSequenceNumber} />) : null;
        }

        if(sheetName === 'pcsheet') {
            if(IsNullOrUndefined(order)){
                return null;
            }            
            if(orderType === INVOICE_TYPE_PCSTOCKORDER){                
                return (<PcStockOrderPcSheet
                    colourDescription={IsNullOrUndefined(order.customColourDescription) ? order.colourDescription : order.customColourDescription}
                    orderRef={isNullEmptyOrWhitespace(order.sequenceNumber) ? order.orderRef : order.sequenceNumber}
                    dateSent={order.pcSentDate}
                    onPrint={()=>{}}
                    onToggle={toggleOpen}
                    sheetIsOpen={!IsNullOrUndefined(order)}
                    rowData={JSON.parse(order.pcSheetDataJson).rowDataItems}
                    powderCoater={{label: order.powderCoaterDescription}}
                    comment={order.reason}            
                    order={order}
                    showEmail={canEmail}
                    isDownload={download}
                />);
            }
            return !IsNullOrUndefined(order) ?
                (<OrderPowderCoatingSheet order={order} isOpen={!IsNullOrUndefined(order)} toggleOpen={toggleOpen} pcSectionIndex={sheetIndex} 
                                          print={false} showEmail={canEmail} orderType={orderType} isDownload={download} />) : null;
        }

        return null;
    }
}

SheetWrapperPage.propTypes = {
    user: PropTypes.object.isRequired,
    orderId: PropTypes.number.isRequired,
    sheetIndex: PropTypes.number.isRequired,
    sheetName: PropTypes.string.isRequired,
    canEmail: PropTypes.bool.isRequired,
    orderType: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    let orderId = -1;
    let sheetIndex = 0;
    let sheetName = '';
    let orderType = INVOICE_TYPE_ORDERAWNING;
    let download = false;
    
    if(ownProps.match.params && ownProps.match.params.orderId){
        orderId = parseInt(deobsfucate(ownProps.match.params.orderId));
    }
    if(ownProps.match.params && ownProps.match.params.sheetIndex){
        sheetIndex = parseInt(ownProps.match.params.sheetIndex);
    }
    if(ownProps.match.params && ownProps.match.params.sheetName){
        sheetName = ownProps.match.params.sheetName;
    }
    if(ownProps.match.params && ownProps.match.params.orderType){
        orderType = parseInt(ownProps.match.params.orderType);
    }
    if(ownProps.match.params && ownProps.match.params.download){
        if(parseInt(ownProps.match.params.download) === 1){
            download = true;
        }
    }    
    
    let {user} = state;

    return {
        user,
        orderId,
        sheetIndex,
        sheetName,
        orderType,
        canEmail: user.hasAccessTag(view_order_view_fabric_sheets_in_production),
        download,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SheetWrapperPage));