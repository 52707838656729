import {IsNullOrUndefined, MoneyFormatter} from "../../../constants";
import React from "react";
import PropTypes from "prop-types";

const CustomerPriceControl = (props) => {
    let {labelText, retail, renderAsTable, classes, showPrice} = props;   
    
    return (IsNullOrUndefined(retail)) ? null : renderAsTable ? (
        <div className={classes.divTr} style={{border: '1px solid #ddd'}}>            
            <div className={classes.divTd} style={{width: '66%', textAlign: 'left'}}>{labelText}</div>
            <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
            <div className={classes.divTdRight} style={{width: '20%'}}>{retail <= 0 ? "" : MoneyFormatter.format(retail)}</div>
        </div>
    ) : (
        <div className="row">
            <div className="col-10">{labelText}</div>            
            <div className="col-2" style={{textAlign: 'right'}}>{retail <= 0 ? "TBA" : showPrice ? MoneyFormatter.format(retail) : ""}</div>
        </div>
    );
};

CustomerPriceControl.propTypes = {
    labelText: PropTypes.string.isRequired,    
    retail: PropTypes.number,
    showPrice: PropTypes.bool.isRequired,
};

export default CustomerPriceControl;