import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import {toastr} from 'react-redux-toastr';
import initialState from '../reducers/initialState';
import {uniqBy, join, uniq, flatten, concat} from 'lodash';
import {
  DATE_SEARCH_FORMAT,
  DATE_SHORT_FORMAT,
  isNullEmptyOrWhitespace,
  orders_list_list_all_organisations_all, ROLE_Fabric, ROLE_PC
} from "../constants";
import moment from "moment";

export function loadSupplierOrdersSuccess({supplierOrders, filter, nonPostFilter}){  
  return {type: types.LOAD_SUPPLIER_ORDERS_SUCCESS, supplierOrders, filter, nonPostFilter};
}

export function loadSupplierOrders(filter = initialState.supplierOrders.filter, nonPostFilter = initialState.supplierOrders.nonPostFilter) {
  return function (dispatch, getState) {
    
    let user = getState().user;

    let canViewAll = user && user.hasAccessTag(orders_list_list_all_organisations_all);
    let isFabricMaker = user && user.isInRole(ROLE_Fabric);
    let isPowderCoater = user && user.isInRole(ROLE_PC);
    
    let filterToUse = isPowderCoater ? {...filter, isPcOrderAlxRecieved: false, isPcRecDate: false} :
        isFabricMaker ? {...filter, isFabricRecDate: false} :
            filter;       

    let url = constructFilter(filterToUse);    
    
    if(isFabricMaker || isPowderCoater || canViewAll){
      
      toastr.warning('Loading supplier orders...');
      dispatch({type: types.LOADING_SUPPLIER_ORDERS});
      
      return apiRequest(url)
          .then(x => {
            if (x.data) {
              toastr.success('Supplier orders loaded');
              dispatch(loadSupplierOrdersSuccess({supplierOrders: [...x.data], filter, nonPostFilter}));
            } else {
              toastr.error('Error while searching...');
            }
          });  
    } else {
      dispatch(loadSupplierOrdersSuccess({supplierOrders: [], filter, nonPostFilter}));
    }
  };
}

function constructFilter(filter) {
  let url = `/api/supplierorders?`;

  let urlSegmentArray = (name, arrValues) => {
    return arrValues.length === 0 ? '' : '&' + join(arrValues.map(x => `${name}=${x}`), "&");
  };
  let urlSegmentString = (name, value) => {
    return isNullEmptyOrWhitespace(value) ? '' : `&${name}=${value}`;
  };

  url += urlSegmentArray('organisationId', filter.organisationIds);
  url += urlSegmentArray('orderStatus', filter.orderStatuses);
  url += urlSegmentString('orderId', filter.orderId);
  url += urlSegmentString('customerRef', filter.customerRef);
  url += urlSegmentArray('productIds', filter.productIds);
  url += urlSegmentString('createdDateFrom', isNullEmptyOrWhitespace(filter.createdDateFrom) ? null : moment(filter.createdDateFrom).format(DATE_SEARCH_FORMAT));
  url += urlSegmentString('createdDateTo', isNullEmptyOrWhitespace(filter.createdDateTo) ? null : moment(filter.createdDateTo).format(DATE_SEARCH_FORMAT));
  url += urlSegmentString('outstandingFabric', filter.outstandingFabric);
  url += urlSegmentString('outstandingPc', filter.outstandingPc);
  url += urlSegmentString('sequenceNumber', filter.sequenceNumber);
  url += urlSegmentString('invoiceNumber', filter.invoiceNumber);
  url += urlSegmentString('projection', filter.projection);
  url += urlSegmentString('isPcOrderAlxRecieved', `${filter.isPcOrderAlxRecieved}`);
  url += urlSegmentString('isPcRecDate', `${filter.isPcRecDate}`);
  url += urlSegmentString('isFabricRecDate', `${filter.isFabricRecDate}`);

  if (filter.width !== null) {
    url += urlSegmentString('minWidth', filter.width.value.minWidth);
    url += urlSegmentString('maxWidth', filter.width.value.maxWidth);
  }
  url += urlSegmentArray('colourId', filter.colourId);
  url += urlSegmentString('motorBrandId', filter.motorBrandId);

  return url;
}

export function updateNonPostFilter(nonPostFilter = initialState.supplierOrders.nonPostFilter) {
  return function (dispatch, getState) {
    dispatch({type: types.SUPPLIER_ORDERS_UPDATE_NONPOSTFILTER, nonPostFilter});
  }
}

export function updateFilter(filter = initialState.supplierOrders.filter){
  return function (dispatch, getState) {
    //check if the filter has changed, if so construct API call
    let {currentFilter} = getState().supplierOrders.filter;

    if(JSON.stringify(currentFilter) !== JSON.stringify(filter))
    {
      dispatch({type: types.SUPPLIER_ORDERS_UPDATE_FILTER, filter});      
    } else {
      console.log('Filter unchanged - no-op');
    }
  }
}