import PropTypes from "prop-types";
import TextBoxControl from "../common/TextBoxControl";
import SelectControl from "../common/SelectControl";
import React from "react";
import RadioControlGroup from "../common/RadioControlGroup";
import CheckBoxControl from "../common/CheckBoxControl";
import {isNullEmptyOrWhitespace} from "../../constants";

const QuoteNotes = [
    {
        value: 1,
        label: 'This quote is subject to a check measure and site inspection of the awning',
    },{
        value: 2,
        label: 'Fabric selection to be confirmed',
    },{
        value: 3,
        label: 'Includes installation of new fabric and removal and disposal of old fabric',
    },{
        value: 4,
        label: 'Includes service of the awning',
    },{
        value: 5,
        label: 'Please allow 10 to 15 business days from order confirmation until installation (subject to material availability and weather)',
    },{
        value: 6,
        label: 'Payment terms: 50% deposit at the time of confirmation with the balance payable on installation',
    },{
        value: 7,
        label: '2% surcharge for credit card payments',
    },
];

const RecoverEditOrderSection = (props) => {
    let {order, handleOrderFieldChange, handleOrderSelectChange, validationMessages, states, handleSetAluxorAddress} = props;
    
    return (<div className={"row mt-2 mb-4"}>
        <div className={"col-6"}>
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="First Name"
                value={order.firstName}
                name="firstName"
                validationMessages={validationMessages}                
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Last Name"
                value={order.lastName}
                name="lastName"
                validationMessages={validationMessages}
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Email"
                value={order.email1}
                name="email1"
                validationMessages={validationMessages}
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Phone"
                value={order.phone1}
                name="phone1"
                validationMessages={validationMessages}
            />

            <label>Quote Notes</label>
            {QuoteNotes.map(x => {
                let checked = isNullEmptyOrWhitespace(order.quoteNote) ? false : order.quoteNote.includes(x.label); 
                
                return <CheckBoxControl key={x.value} labelText={x.label} value={checked} onChange={e => {
                    let startValue = isNullEmptyOrWhitespace(order.quoteNote) ? '' : order.quoteNote;
                    let newValue = e.target.checked ? startValue += isNullEmptyOrWhitespace(startValue) ? x.label : `\n${x.label}` 
                        : order.quoteNote.replaceAll(x.label, '');
                    handleOrderFieldChange({target: {
                            name: 'quoteNote',
                            type: 'text',
                            value: newValue.trim(),
                        }});
                }} name={x.value.toString()} />
            })}
            
            <div className="row">                
                <div className="col-12">
                        <textarea style={{maxWidth: '99%'}} className={'form-control'}
                                  value={order.quoteNote == null ? '' : order.quoteNote}
                                  onChange={handleOrderFieldChange}
                                  name='quoteNote'
                        />
                </div>
            </div>
        </div>
        <div className={"col-6"}>            
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Address Line 1"
                value={order.addressLine1}
                name="addressLine1"
                validationMessages={validationMessages}
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Address Line 2"
                value={order.addressLine2}
                name="addressLine2"
                validationMessages={validationMessages}
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Suburb"
                value={order.suburb}
                name="suburb"
                validationMessages={validationMessages}
            />
            <SelectControl
                validationMessages={validationMessages}
                labelText="State"
                name="stateId"
                options={states}
                valueKey={'id'}
                labelKey={'description'}
                onChange={(v) => handleOrderSelectChange(v, 'stateId', (v) => v.id)}
                value={order.stateId}
                placeholder="State"
            />
            <TextBoxControl
                onChange={handleOrderFieldChange}
                labelText="Post Code"
                value={order.postCode}
                name="postCode"
                validationMessages={validationMessages}
            />
            <button onClick={handleSetAluxorAddress}
                    className="btn btn-info"
            >Use Aluxor information</button>
        </div>
    </div>);
};

RecoverEditOrderSection.propTypes = {
    order: PropTypes.object.isRequired,
    handleOrderFieldChange: PropTypes.func.isRequired,
    handleOrderSelectChange: PropTypes.func.isRequired,
    validationMessages: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    handleSetAluxorAddress: PropTypes.func.isRequired,
}

export default RecoverEditOrderSection;