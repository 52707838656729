import React, {useState, useEffect} from "react";
import PropTypes, {func} from "prop-types";
import TextBoxControl from "../../common/TextBoxControl";
import SelectControl from "../../common/SelectControl";
import {EmailRegex, IsNullOrUndefined, IsStringValid, PhoneRegex, validationCreator} from "../../../constants";
import {apiPost, apiRequest} from "../../../utils/request";
import {Aux} from '../../../constants';
import TextViewControlV2 from "../../common/TextViewControlV2";
import TextAreaControl from "../../common/TextAreaControl";

const CustomerInformation = (props) => {

    const viewMode_new = 'new';
    const viewMode_edit = 'edit';
    const viewMode_view = 'view';
    
    let {states, orderId, onCustomerReady, parentRef} = props;
    
    useEffect(() =>{
        parentRef.current = { 
            handleEditClient : () => {
                     setViewMode(viewMode_edit);
                 }};            
    }, parentRef);
    
    const [customerEmail, setCustomerEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [validationMessages, setValidation] = useState({});
    const [viewMode, setViewMode] = useState(viewMode_new);

    useEffect(() => {
        apiRequest(`/api/customers/lookupByOrder?orderId=${orderId}`)
            .then(x => {
                let data = x.data ? x.error ? null : x.data : null;
                if (data !== null) {
                    let loadedCustomer = {...data};
                    setCustomer({...data});
                    setViewMode(viewMode_view);
                    onCustomerReady(loadedCustomer);
                }
            });
    }, [orderId]);    
    
    useEffect(() => {
        let ignore = false;

        if (IsStringValid(customerEmail, EmailRegex)) {
            apiRequest(`/api/customers/lookupByEmail?email=${customerEmail}`)
                .then(x => {
                    if (!ignore) {
                        let data = x.data ? x.error ? null : x.data : null;
                        if (data !== null) {
                            setCustomer({...data});
                            setViewMode(viewMode_edit);
                        }
                    }
                });
        }

        return () => {
            ignore = true;
        };
    }, [customerEmail]);

    function handleFieldChange(e) {

        let updatedCustomer = {...customer};
        let propName = e.target.name;

        if (e.target.type === "checkbox") {
            updatedCustomer[propName] = e.target.checked;
        } else {
            updatedCustomer[propName] = e.target.value;
        }

        setValidationMessages(propName, updatedCustomer, validationMessages);
        setCustomer(updatedCustomer);
    }

    function setValidationMessages(propName, currentCustomer, currentValidationMessages, setState = true) {
        let validation = CustomerValidationRules[propName];
        if (validation) {
            let validationResult = validation.validator(currentCustomer);
            if (!validationResult.isValid) {
                currentValidationMessages[propName] = validationResult.message;
            } else {
                delete currentValidationMessages[propName];
            }
        }
        if (setState) {
            setValidation(currentValidationMessages);
        } else {
            return currentValidationMessages;
        }
    }

    function IsValid() {
        let currentValidationMessages = {...validationMessages};

        for (let propName in customer) {
            currentValidationMessages = setValidationMessages(propName, customer, currentValidationMessages, false);
        }
        setValidation(currentValidationMessages);

        return Object.keys(currentValidationMessages).length === 0;
    }

    function SaveCustomer() {
        if (customer !== null && IsValid()) {
            apiPost(`/api/customers/customer_order?orderId=${orderId}`, customer)
                .then(x => {
                    let data = x.data ? x.error ? null : x.data : null;
                    if (data !== null) {
                        let savedCustomer = {...data};
                        setCustomer(savedCustomer);
                        onCustomerReady(savedCustomer);
                        setViewMode(viewMode_view);
                    }
                });
        }
    }


    return (<Aux>
        <div className="row">
            <div className={'col-md-8'}>
                <h4>Customer Information</h4>
            </div>
        </div>
        {customer === null &&
            <div className={"row mt-2 mb-4"}>
                <div className={"col-6"}>
                    <TextBoxControl
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        labelText="Email"
                        value={customerEmail}
                        name="email"
                        validationMessages={validationMessages}
                        isDebounce={true}
                    />
                    {IsStringValid(customerEmail, EmailRegex) ?
                        <button onClick={(e) => {
                            setCustomer({...initialCustomerState, email: customerEmail})
                            setViewMode(viewMode_edit);
                        }}
                                className="btn pi-btn-base pi-btn-icon-big">Next</button>
                    :
                    <button onClick={(e) => {
                        setCustomer({...initialCustomerState, anonymousEmail: true})
                        setViewMode(viewMode_edit);
                    }}
                            className="btn pi-btn-grey pi-btn-icon-big">No Email</button>
                    }
                </div> 
            </div>}
        {customer !== null && viewMode === viewMode_edit &&
            <div className={"row mt-2 mb-4"}>
                <div className={"col-6"}>
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Name"
                        value={customer.name}
                        name="name"
                        validationMessages={validationMessages}
                    />
                    { !customer.anonymousEmail && <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Email"
                        value={customer.email}
                        name="email"
                        validationMessages={validationMessages}
                        disabled={"disabled"}                        
                    /> }
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Contact Number"
                        value={customer.contactNumber}
                        name="contactNumber"
                        validationMessages={validationMessages}
                    />
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Address Line 1"
                        value={customer.addressLine1}
                        name="addressLine1"
                        validationMessages={validationMessages}
                    />
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Address Line 2"
                        value={customer.addressLine2}
                        name="addressLine2"
                        validationMessages={validationMessages}
                    />
                </div>
                <div className={"col-6"}>
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Suburb"
                        value={customer.suburb}
                        name="suburb"
                        validationMessages={validationMessages}
                    />
                    <SelectControl
                        validationMessages={validationMessages}
                        labelText="State"
                        name="stateId"
                        options={states}
                        valueKey={'id'}
                        labelKey={'description'}
                        onChange={(v) => handleFieldChange({target: {name: 'stateId', value: v.id}})}
                        value={customer.stateId}
                        placeholder="State"
                    />
                    <TextBoxControl
                        onChange={handleFieldChange}
                        labelText="Post Code"
                        value={customer.postCode}
                        name="postCode"
                        validationMessages={validationMessages}
                    />
                    
                    <TextAreaControl
                        onChange={handleFieldChange}
                        labelText="Notes"
                        value={customer.notes}
                        name="notes"
                        validationMessages={validationMessages}
                    />
                    <div className="form-group">
                        <label>&nbsp;</label>
                        <div className="pi-input-with-icon">
                            <button
                                onClick={(_) => {
                                    setCustomerEmail('');
                                    setCustomer(null);
                                    setValidation({});
                                }}
                                className="btn pi-btn pi-uppercase pi-letter-spacing mr-3">Clear
                            </button>
                            <button
                                onClick={(_) => {
                                    SaveCustomer()
                                }}
                                className="btn pi-btn-base pi-btn-icon-big">Done
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        }
        {customer !== null && viewMode === viewMode_view &&
            <div className={"row mt-2 mb-4"}>
                <div className={"col-6"} style={{marginLeft:'-15px'}}>
                    <TextViewControlV2
                        labelText="Name"
                        value={customer.name}
                    />
                    {!customer.anonymousEmail && <TextViewControlV2
                        labelText="Email"
                        value={customer.email}
                    /> }
                    <TextViewControlV2
                        labelText="Contact Number"
                        value={customer.contactNumber}
                    />
                    <TextViewControlV2
                        labelText="Address Line 1"
                        value={customer.addressLine1}
                    />
                    <TextViewControlV2
                        labelText="Address Line 2"
                        value={customer.addressLine2}
                    />
                </div>
                <div className={"col-6"}>
                    <TextViewControlV2
                        labelText="Suburb"
                        value={customer.suburb}
                    />
                    <TextViewControlV2
                        labelText="State"                        
                        value={customer.state}
                    />
                    <TextViewControlV2
                        labelText="Post Code"
                        value={customer.postCode}
                    />

                    <TextViewControlV2
                        labelText="Notes"
                        value={customer.notes}
                    />                    

                </div>
            </div>
        }

    </Aux>);

};

CustomerInformation.propTypes = {
    states: PropTypes.array.isRequired,
    orderId: PropTypes.number.isRequired,
    onCustomerReady: PropTypes.func.isRequired,
    parentRef: PropTypes.any,
};
export default CustomerInformation;


const initialCustomerState = {
    name: null,
    email: null,
    contactNumber: null,
    addressLine1: null,
    addressLine2: null,
    suburb: null,
    stateId: null,
    postCode: null,
    notes: 'Best hours to visit\nPhoto added to awning attachment',
    anonymousEmail: false,
}

const CustomerValidationRules = {
    name: {
        validator: (customer) => {
            return !IsStringValid(customer.name) ? validationCreator(false, 'Please enter a name') : validationCreator();
        }
    },
    contactNumber: {
        validator: (customer) => {
            return !IsStringValid(customer.contactNumber, PhoneRegex) ? validationCreator(false, 'Please enter a contact number') : validationCreator();
        }
    },
    email: {
        validator: (customer) => {
            return !IsStringValid(customer.email, EmailRegex) && !customer.anonymousEmail ? validationCreator(false, 'Please enter a valid email address') : validationCreator();
        }
    },
    addressLine1: {
        validator: (customer) => {
            return !IsStringValid(customer.addressLine1) ? validationCreator(false, 'Please enter an address line') : validationCreator();
        }
    },
    suburb: {
        validator: (customer) => {
            return !IsStringValid(customer.suburb) ? validationCreator(false, 'Please enter a suburb') : validationCreator();
        }
    },
    stateId: {
        validator: (customer) => {
            return customer.stateId === null ? validationCreator(false, 'Please select a state') : validationCreator();
        }
    },
    postCode: {
        validator: (customer) => {
            return !IsStringValid(customer.postCode) ? validationCreator(false, 'Please enter a post code') : validationCreator();
        }
    },

}
