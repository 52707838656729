import * as types from './actionTypes';
import initialState from '../reducers/initialState';
import {toastr} from 'react-redux-toastr';
import {apiPost} from "../utils/request";

//TODO: Add, remove and download


export function loadSparesSuccess({spares, filter}){
    return {type: types.LOAD_SPARES_SUCCESS, spares, filter};
}

export function addCartItem(item) {
    return function (dispatch, getState) {
        dispatch({type: types.RESOURCECART_ADD, item});
        toastr.success(`Resource added to cart, select View or Download`);
    };
}

export function removeCartItem(item) {
    return function (dispatch) {
        dispatch({type: types.RESOURCECART_REMOVE, item});
    };
}

export function downloadCart() {
    return function (dispatch, getState) {
        //iterate the current state then clear
        let {resourceCart} = getState();
        resourceCart.items.map(x => {
            let {fileName, blob} = x;
            let newBlob = blob;

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
            }, 100);

        });

        dispatch({type: types.RESOURCECART_CLEAR, state: initialState.resourceCart});
    }
}

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export function emailCart(emailOther = null) {
    return function (dispatch, getState) {
        //iterate the current state then clear
        let {resourceCart} = getState();
        let dataResults = [];

        resourceCart.items.map(x => {
            let {fileName, blob} = x;

            let reader = new FileReader();
            reader.addEventListener("load", function () {
                console.log('loaded');
                dataResults.push({fileName, content: reader.result});
            }, false);
            reader.readAsDataURL(blob);
        });

        let processResult = () => {
            apiPost(`/api/resources/emailCartItems`, {
                items: dataResults,
                emailOther,
            }).then(y => {
                if (y.data && y.data.ok) {
                    toastr.success('Email sent');
                    dispatch({type: types.RESOURCECART_CLEAR, state: initialState.resourceCart});
                } else {
                    toastr.error('Email failed to send');
                }
            });
        };

        sleep(500).then(x => {
            console.log('Waiting 1');
            if (dataResults.length < resourceCart.items.length) {
                sleep(500).then(x => {
                    console.log('Waiting 2');
                    if (dataResults.length < resourceCart.items.length) {
                        sleep(500).then(x => {
                            console.log('Waiting 3');
                            if (dataResults.length < resourceCart.items.length) {
                                sleep(500).then(x => {
                                    console.log('Waiting 4');
                                    if (dataResults.length < resourceCart.items.length) {

                                    } else {
                                        processResult();
                                    }
                                });
                            } else {
                                processResult();
                            }
                        });
                    } else {
                        processResult();
                    }
                });
            } else {
                processResult();
            }
        });
    }
}

export function clearCart() {
    return function (dispatch) {
        dispatch({type: types.RESOURCECART_CLEAR, state: initialState.resourceCart});
    };
}

export function toggleCart() {
    return function (dispatch, getState) {
        let {resourceCart} = getState();
        if(resourceCart.isOpen){
            dispatch({type: types.RESOURCECART_CLOSECART});
        } else {
            dispatch({type: types.RESOURCECART_OPENCART});
        }
    };
}