import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './userReducer';
import organisationsReducer from './organisationsReducer';
import lookupsReducer from './lookupsReducer';
import ordersReducer from './ordersReducer';
import sparesReducer from './sparesReducer';
import identityReducer from './identityReducer';
import resourceCartReducer from './resourceCartReducer';
import httpInfoReducer from './httpInfoReducer';
import supplierOrdersReducer from './supplierOrdersReducer';
import supportRequestsReducer from "./supportRequestsReducer";

const rootReducer = combineReducers({
    routing: routerReducer,
    toastr: toastrReducer,
    user: userReducer,
    organisations: organisationsReducer,
    lookups: lookupsReducer,
    orders: ordersReducer,
    spares: sparesReducer,
    identity: identityReducer,
    resourceCart: resourceCartReducer,
    httpInfo: httpInfoReducer,
    supplierOrders: supplierOrdersReducer,
    supportRequests: supportRequestsReducer,
});

export default rootReducer;
