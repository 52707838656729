import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from 'react-router-dom'
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import initialState from './reducers/initialState';


const createStoreWithMiddleware = compose(
    applyMiddleware(thunk, routerMiddleware(BrowserRouter), reduxImmutableStateInvariant())
)(createStore);

const store = createStoreWithMiddleware(rootReducer, initialState);

export default store;
