import React, {Component} from 'react';
import PropTypes from 'prop-types';
import store from "../../store";
import {clearCart, downloadCart, emailCart, removeCartItem, toggleCart} from "../../actions/resourceCartActions";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import TextBoxControl from "../common/TextBoxControl";
import {Aux, EmailRegex, isNullEmptyOrWhitespace, IsStringValid, validationCreator} from "../../constants";

const ValidationRules = {
    name: {
        validator: (val) => {
            return {isValid: !isNullEmptyOrWhitespace(val), message: `Please enter a name`};
        }
    },
    subject: {
        validator: (val) => {
            return {isValid: !isNullEmptyOrWhitespace(val), message: `Please enter a subject`};
        }
    },
    emailTo: {
        validator: (val) => {
            return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid email') : validationCreator();
        }
    }
};

const newEmail = () => {
    return {
        name: '',
        subject: '',
        emailTo: '',
        emailBodyText: '',
    };
};

class ResourceCartControl extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isEmail: false,
            validationMessages: {},
            email: newEmail(),
        };
        this.startEmail = this.startEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.cancelEmail = this.cancelEmail.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    startEmail(e){
        e.preventDefault();
        this.setState({isEmail: true, email: newEmail()});
    }

    sendEmail(e) {
        e.preventDefault();

        store.dispatch(emailCart(this.state.email));

        setTimeout(() => {
            this.setState({isEmail: false, email: newEmail()});
        }, 500);
    }

    cancelEmail(e){
        e.preventDefault();
        this.setState({isEmail: false, email: newEmail()});
    }

    handleFieldChange(e){
        let x = {...this.state.email};
        let messages = {...this.state.validationMessages};

        x[`${e.target.name}`] = e.target.value;

        let validation = ValidationRules[`${e.target.name}`];
        if(validation) {
            let validationResult = validation.validator(e.target.value);
            if (!validationResult.isValid) {
                messages[`${e.target.name}`] = validationResult.message;
            } else {
                delete messages[`${e.target.name}`];
            }
        }

        this.setState({email: x, validationMessages: messages});
    }

    render() {
        let {resourceCartState, isAdmin} = this.props;
        let {isEmail, email, validationMessages} = this.state;
        let {items, isOpen} = resourceCartState;

        let download = (e) => {
            e.preventDefault();
            store.dispatch(downloadCart());
        };

        let view = (e) => {
            e.preventDefault();
            store.dispatch(toggleCart());
        };

        let removeItem = (e, item) => {
            e.preventDefault();
            store.dispatch(removeCartItem(item));
        };

        return (
            <div className={"section"} style={{minWidth: '220px', paddingTop: '15px'}}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h5>Resource Cart</h5>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}><a onClick={view} href={''}>View</a>&nbsp;|&nbsp;<a onClick={download}
                                                                                                 href={''}>Download</a>
                    </div>
                    <div className={"col-6"}>{items.length} items</div>
                </div>
                <Modal isOpen={isOpen}
                       toggle={() => store.dispatch(toggleCart())}
                       style={{minWidth: '840px'}}
                >
                    <div className={"modal-header"}>
                        <h3 className={"modal-title"}>Resource Cart</h3>
                        <button className="btn pi-btn pi-btn-icon-big"
                                onClick={(e) => {
                                    e.preventDefault();
                                    store.dispatch(toggleCart());
                                }}>
                            <i className="fa fa-times"/>
                        </button>
                    </div>
                    <ModalBody>
                        <div className="section">
                            {items.map(x => {
                                return (<div key={x.fileName} className={"row"}>
                                    <div className={"col-10"}>{x.fileName}</div>
                                    <div className={"col-2"}><a href={""} onClick={(e) => removeItem(e, x)}><i
                                        className={"fa fa-times"}/></a></div>
                                </div>);
                            })}

                            {items.length === 0 && <div>
                                <h5>You don't currently have any items in your cart, please select product resources to
                                    add to cart</h5>
                            </div>}

                            { isEmail && <div className={'row'} style={{
                                borderTop: '1px solid #d2d2d2',
                                marginLeft: '-2px',
                                marginTop: '20px',
                                width: '98%'
                            }}>
                                <div className={'col-8'} style={{marginLeft: '20px', marginTop: '10px'}}>
                                    <form role="form" className="form-horizontal">
                                        <TextBoxControl
                                            onChange={this.handleFieldChange}
                                            labelText="Email to"
                                            value={email.emailTo}
                                            name="emailTo"
                                            type={'email'}
                                            validationMessages={validationMessages}
                                            inputBorderStyle={"1px solid rgb(133 133 133 / 45%)"}
                                        />
                                        <TextBoxControl
                                            onChange={this.handleFieldChange}
                                            value={email.subject}
                                            name="subject"
                                            labelText={'Email subject'}
                                            validationMessages={validationMessages}
                                            inputBorderStyle={"1px solid rgb(133 133 133 / 45%)"}
                                        />
                                        <TextBoxControl
                                            onChange={this.handleFieldChange}
                                            value={email.name}
                                            name="name"
                                            labelText={'Email to name'}
                                            validationMessages={validationMessages}
                                            inputBorderStyle={"1px solid rgb(133 133 133 / 45%)"}
                                        />
                                        <div className="pi-row">
                                            <div className="pi-col-sm-4"><label htmlFor="dispatchNote">Email text</label></div>
                                            <div className="pi-col-sm-8">
                                                <textarea style={{maxWidth: '99%',border: "1px solid rgb(133 133 133 / 45%)"}} className={'form-control'}
                                                          value={email.emailBodyText}
                                                          onChange={this.handleFieldChange}
                                                          name='emailBodyText'
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> }
                        </div>
                    </ModalBody>
                    {items.length > 0 && <ModalFooter>
                        { !isEmail &&
                        <Aux>
                            <button className="btn pi-btn pi-btn-icon-big"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        //eslint-disable-next-line
                                        if (confirm('Are you sure you want to clear?')) {
                                            store.dispatch(clearCart());
                                        }
                                    }}>
                                <i className="fa fa-cancel"/>&nbsp;Clear
                            </button>

                            <button className="btn pi-btn-base pi-btn-icon-big"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        store.dispatch(downloadCart());
                                    }}>
                                <i className="fa fa-cancel"/>&nbsp;Download
                            </button>

                            <button className="btn pi-btn-base pi-btn-icon-big"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        store.dispatch(emailCart());
                                    }}>
                                <i className="fa fa-cancel"/>&nbsp;Email to Self
                            </button>

                            { isAdmin && <button className="btn pi-btn-base pi-btn-icon-big"
                                    onClick={this.startEmail}>
                                <i className="fa fa-cancel"/>&nbsp;Email Other
                            </button> }
                        </Aux> }
                        { isEmail &&
                        <Aux>
                            <button className="btn pi-btn pi-btn-icon-big"
                                    onClick={this.cancelEmail}>
                                <i className="fa fa-cancel"/>&nbsp;Cancel Email
                            </button>

                            { Object.keys(validationMessages).length === 0 && <button className="btn pi-btn-base pi-btn-icon-big"
                                    onClick={this.sendEmail}>
                                <i className="fa fa-cancel"/>&nbsp;Send Email
                            </button> }
                        </Aux>}
                    </ModalFooter>}
                </Modal>
            </div>
        );
    }
}

ResourceCartControl.propTypes = {
    resourceCartState: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default ResourceCartControl;