import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {flatten, uniq, concat, uniqBy, orderBy} from 'lodash';
import {LOADING_SUPPLIER_ORDERS} from "../actions/actionTypes";

export default function supportRequestsReducer(state = initialState.supportRequests, action) {
  switch (action.type) {
    case types.SUPPORT_REQUESTS_UPDATE_FILTER: {
      return {
        ...state,
        filter: {...action.filter},
        requests: [],
        loaded: false,
      };
    }
    case types.SUPPORT_REQUESTS_LOAD_SUCCESS: {      
      return {
        ...state,
        filter: {...action.filter},
        requests: [...action.supportRequests],
        loaded: true,
      };
    }
    case types.SUPPORT_REQUESTS_LOADPRODUCTS: {
      return {
        ...state,        
        products: [...action.products],
      };
    }
    default:
      return state;
  }
}
