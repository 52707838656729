import React from 'react';
import PropTypes from 'prop-types';
import {DATE_SHORT_FORMAT} from "../../constants";
import DatePicker from "react-datepicker";

const DatePickerControl = (props) => {
  let {labelText, selected, onChange, name, validationMessages} = props;
  let isValid = !validationMessages || (validationMessages && (validationMessages[`${name}`] === '' || validationMessages[`${name}`] === undefined));
  
  let inputStyle = {borderColor: isValid ? '' : 'red'}; 

  return (
      <div className="form-group">
        <label htmlFor={name}>{labelText}</label>
        <div>
          <DatePicker
              selected={selected}
              onChange={onChange}
              className={'form-control'}
              dateFormat={DATE_SHORT_FORMAT}
              dropdownMode={"scroll"}
              style={inputStyle}
          />
          { !isValid && <div style={{color: 'red'}}>{validationMessages[`${name}`]}</div> }
        </div>
      </div>);

};

DatePickerControl.propTypes = {
    labelText: PropTypes.string.isRequired,
    selected: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    validationMessages: PropTypes.object,
    style: PropTypes.string,
};

export default DatePickerControl;