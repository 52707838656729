import React from 'react';
import PropTypes from 'prop-types';


const TextViewControlCutting = (props) => {
  let {labelText, value} = props;

  return value == null ? null : (
    <div className="pi-row">
      <div className="pi-col-sm-6">{labelText}</div>
      <div className="pi-col-sm-6">{value == null ? 'N/A' : value}</div>
    </div>
  );
};

TextViewControlCutting.propTypes = {
  labelText: PropTypes.string.isRequired,
};
export default TextViewControlCutting;