import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function sparesReducer(state = initialState.spares, action) {
  switch (action.type) {
    case types.LOAD_SPARES_SUCCESS:
      return {...state, loaded: true, items: [...action.spares], filter: {...action.filter}};
    case types.SPARES_UPDATE_FILTER:
      return {...state, filter: {...action.filter}};
    default:
      return state;
  }
}
