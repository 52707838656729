import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import initialState from '../reducers/initialState';

export function loadSparesSuccess({spares, filter}){
  return {type: types.LOAD_SPARES_SUCCESS, spares, filter};
}

export function loadSpares(organisationId, filter = initialState.spares.filter) {
  return function (dispatch) {
    return apiRequest(`/api/spares?organisationId=${organisationId}`)
      .then(x => {
        dispatch(loadSparesSuccess({spares: [...x.data], filter}));
      });
  };
}

export function updateFilter(filter = initialState.spares.filter){
  return function (dispatch) {
    dispatch({type: types.SPARES_UPDATE_FILTER, filter});
  }
}