import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PropTypes from "prop-types";
import SelectControl from "../common/SelectControl";
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    Aux,
    DATE_SHORT_FORMAT,
    FABRIC_VALANCE_WAVY,
    FabricInstructions,
    IsNullOrUndefined    
} from "../../constants";
import TextViewControl from "../common/TextViewControl";
import {find} from 'lodash';
import TextBoxControl from "../common/TextBoxControl";
import RadioControlGroup from "../common/RadioControlGroup";

const FabricRecoverAcceptOrder = (props) => {
    let {
        isOpen, toggle, order, onAwningFieldChange, onOrderFieldChange,
        fabricMakers, fabrics, trimBiasColours, onComplete, awningValidation, orderValidation,
        isValid
    } = props;    

    return (<Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>Accept for Production</ModalHeader>
        <ModalBody>            
            <TextBoxControl                         
                onChange={e => onOrderFieldChange(e, 'sequenceNumber', (v) => v.target.value)}
                labelText="PN"
                value={order.sequenceNumber}
                name="sequenceNumber"                
                validationMessages={orderValidation}
            />
            <SelectControl
                labelText="Fabric maker"
                name="fabricMakerId"
                options={fabricMakers}
                onChange={(val) => onOrderFieldChange(val, 'fabricMakerId', (v) => v.value)}
                value={order.fabricMakerId}
                placeholder="Select Fabric Maker"
                validationMessages={orderValidation}
            />
            <div className="form-group">
                <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Ordered&nbsp;</label>                
                <DatePicker
                    onChange={(val) => onOrderFieldChange(val, 'fabricOrderDate', (v) => v ? v.format() : null)}                    
                    selected={order.fabricOrderDate ? moment(order.fabricOrderDate) : null}
                    name="fabricOrderDate"
                    dateFormat={DATE_SHORT_FORMAT}
                    minDate={moment()}
                    onChangeRaw={(e)=> {
                        e.target.value = '';
                    }}
                />
            </div>
            <div className="form-group">
                <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Scheduled</label>
                <span>{IsNullOrUndefined(order.fabricSupplierCompleteDate) ? 'N/A' : moment(order.fabricSupplierCompleteDate).format(DATE_SHORT_FORMAT)}</span>
            </div>

            <div className="form-group">
                <label htmlFor="fabricOrderDate" style={{minWidth: '150px'}}>Completed</label>
                <span>{IsNullOrUndefined(order.fabricSupplierCompletedDate) ? 'N/A' : moment(order.fabricSupplierCompletedDate).format(DATE_SHORT_FORMAT)}</span>
            </div>

            <div className="form-group">
                <label htmlFor="fabricRecDate" style={{minWidth: '150px'}}>Received&nbsp;</label>
                <DatePicker
                    onChange={(val) => onOrderFieldChange(val, 'fabricRecDate', (v) => v ? v.format() : null)}                    
                    selected={order.fabricRecDate ? moment(order.fabricRecDate) : null}
                    name="fabricRecDate"
                    dateFormat={DATE_SHORT_FORMAT}
                    minDate={moment()}
                    onChangeRaw={(e)=> {
                        e.target.value = '';
                    }}
                />                
            </div>
            
            
            { order.awnings.map(awning => {
                let foundFabrics = find(fabrics, x => x.fabricBrandId === awning.fabricBrandId)
                let foundTrimBiasColours = find(trimBiasColours, x => x.fabricValance === awning.fabricValance)
                
                let awningFabrics = IsNullOrUndefined(foundFabrics) ? [] : foundFabrics.fabrics;
                let awningTrimBiasColours = IsNullOrUndefined(foundTrimBiasColours) ? [] : foundTrimBiasColours.trimBiasColours;
                let validationMessages = awningValidation.has(awning.id) ? awningValidation.get(awning.id) : {};
                
                return (<Aux key={awning.id}>
                    <h3>Awning #{awning.number}</h3>
                    <TextViewControl
                        labelText="Product Group"
                        value={awning.productGroupDescription}
                    />
                    <TextViewControl
                        labelText="Fabric Brand"
                        value={awning.fabricBrandDescription}
                    />                    
                    <SelectControl
                        labelText="Fabric"
                        name="fabricId"
                        options={awningFabrics}                        
                        simpleValue={true}
                        onChange={(val) => onAwningFieldChange(val, 'fabricId', awning, (v) => v)}
                        value={awning.fabricId}
                        placeholder="Select Fabric"
                        valueKey={'id'}
                        labelKey={'description'}
                        validationMessages={validationMessages}
                    />
                    <TextViewControl
                        labelText="Size"
                        value={`${awning.width} x ${awning.projection}`}
                    />
                    <TextViewControl
                        labelText="Valance"
                        value={awning.fabricValanceString}
                    />
                    { awning.fabricValance === FABRIC_VALANCE_WAVY && <SelectControl
                        labelText="Trim Bias Colour"
                        name="fabricTrimBiasColourId"
                        options={awningTrimBiasColours}
                        onChange={(val) => onAwningFieldChange(val, 'fabricTrimBiasColourId', awning, (v) => v.value)}                        
                        value={awning.fabricTrimBiasColourId}
                        placeholder="Select Trim Bias Colour"
                        validationMessages={validationMessages}
                    /> }

                    <RadioControlGroup
                        name={`fabricInstruction_${awning.id}`}
                        onChange={(val) => onAwningFieldChange(val, 'fabricInstruction', awning, (v) => v.simpleValue)}                        
                        value={awning.fabricInstruction}
                        labelText='Fabric Instructions'                        
                        options={FabricInstructions}
                        style={{marginLeft: '0px'}}
                        validationMessages={validationMessages}
                    />

                    <TextViewControl
                        labelText="Fabric Notes"
                        value={awning.fabricNote}
                    />
                </Aux>);
            })}
        </ModalBody>
        <ModalFooter>
            <button onClick={onComplete}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
                    disabled={!isValid}
            >
                <i className="fa fa-check" />&nbsp;Accept</button>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={toggle}>
                <i className="fa fa-times" />&nbsp;Close
            </button>
        </ModalFooter>
    </Modal>);
};

FabricRecoverAcceptOrder.propTypes = {
    order: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onAwningFieldChange: PropTypes.func.isRequired,
    onOrderFieldChange: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    fabricMakers: PropTypes.array.isRequired,
    fabrics: PropTypes.array.isRequired,
    trimBiasColours: PropTypes.array.isRequired,
    awningValidation: PropTypes.object.isRequired,
    orderValidation: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
};

export default FabricRecoverAcceptOrder;