import React from 'react';
import PropTypes from 'prop-types';
import {
    IsNullOrUndefined,
    PRODUCTID_DS,
    PRODUCTID_ECO,
    PRODUCTID_OLYMPUS,
    PRODUCTID_S1, PRODUCTID_S3, PRODUCTID_SD, PRODUCTID_SDC, PRODUCTID_SDCZ, PRODUCTID_SDM,
    PRODUCTID_TOGA
} from "../../constants";

const productInformationData = [
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Standard powdercoat",
        "description": "3 colours"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Not available"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "5910 x 3000"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 35°"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Not available"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual gearbox",
        "description": "No outer limit"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Warranty",
        "description": "2 years"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics",
        "description": "Not available if cradle fitted"
    },
    {
        "productId": 1,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics (Max)",
        "description": "W 5910 / P 3000 (when no cradle)"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Standard powdercoat",
        "description": "6 colours"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Available"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Single)",
        "description": "7000 x 4000"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "21000 x 4000"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 45°"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Available"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Oversquare",
        "description": "Available"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant",
        "description": "Available"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant (Max drop)",
        "description": "3500"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics ",
        "description": "Not available if cradle fitted"
    },
    {
        "productId": 2,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics",
        "description": "W 6000 / P 3000 (when no cradle)"
    },
    {
        "productId": null,
        "frameFeatureId": 1,
        "isOverSqaure": null,
        "label": "Available",
        "description": "Stratos I + Stratos III"
    },
    {
        "productId": null,
        "frameFeatureId": 1,
        "isOverSqaure": null,
        "label": "Width / Proj (Max)",
        "description": "W 6000 / P 3500"
    },
    {
        "productId": null,
        "frameFeatureId": 1,
        "isOverSqaure": null,
        "label": "Angle (Range)",
        "description": "7° to 35°"
    },
    {
        "productId": null,
        "frameFeatureId": 1,
        "isOverSqaure": null,
        "label": "If cradle fitted",
        "description": "Not available"
    },
    {
        "productId": null,
        "frameFeatureId": 1,
        "isOverSqaure": null,
        "label": "Stratos III Note",
        "description": "CARE: The angle of the arm brackets need to be adjusted to 7° to eliminate a gap between the pelmet and front rail when retracted"
    },
    {
        "productId": null,
        "frameFeatureId": null,
        "isOverSqaure": true,
        "label": "Available",
        "description": "Stratos I + Toga"
    },
    {
        "productId": null,
        "frameFeatureId": null,
        "isOverSqaure": true,
        "label": "Proj ≥ Width",
        "description": "Yes"
    },
    {
        "productId": null,
        "frameFeatureId": null,
        "isOverSqaure": true,
        "label": "Arm fitting",
        "description": "One sits below the other (see technical drawings)"
    },
    {
        "productId": null,
        "frameFeatureId": null,
        "isOverSqaure": true,
        "label": "Toga valance",
        "description": "The bottom half of the front extrusion profile is replaced with a fabric valance (depth 210mm)"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Standard powdercoat",
        "description": "6 colours"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Available"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Single)",
        "description": "7000 x 4000"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "21000 x 4000"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 45°"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Available"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Oversquare",
        "description": "Not available"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant",
        "description": "Not available"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "LED Lighting",
        "description": "Available"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "If cradle fitted",
        "description": "CARE: Motor limits may need adjusting over time if a gap develops between the front rail and pelmet"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics ",
        "description": "Not available if cradle fitted"
    },
    {
        "productId": 3,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics",
        "description": "W 6000 / P 3000 (when no cradle)"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Standard powdercoat",
        "description": "6 colours"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Available"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "7000 x 4000"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 45°"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Not available"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Oversquare",
        "description": "Available"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant",
        "description": "Available"
    },
    {
        "productId": 4,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual Override",
        "description": "Not available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Standard powdercoat",
        "description": "6 colours"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "5000 x 3000"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 35°"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Not available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Oversquare",
        "description": "Not available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant",
        "description": "Not available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual Override",
        "description": "Not available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "LED Lighting",
        "description": "Available"
    },
    {
        "productId": 5,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics ",
        "description": "W 5000 / P 3000"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Standard (Dulux Duralloy range)"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Single)",
        "description": "7000 x 4000"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "14000 x 4000"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Angle (Max)",
        "description": "To 35°"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Hardwired motor",
        "description": "Standard"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Selectapitch",
        "description": "Not available"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Oversquare",
        "description": "Not available"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Vario Volant",
        "description": "Not available"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual Override",
        "description": "Not available"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "LED Lighting",
        "description": "Available"
    },
    {
        "productId": 6,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mesh fabrics ",
        "description": "W 6000 / P 3000"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Standard (Dulux Duralloy range)"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Hardwired motor",
        "description": "Standard"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual operation",
        "description": "Not available"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Single)",
        "description": "6500 x 6500"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "24000 x 6000"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W (Min) Hardwired / Remote",
        "description": "1350 / 1390"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Width dimension",
        "description": "Track fixing centres"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Headbox width",
        "description": "Track centres + 60"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Projection dimension",
        "description": "Back of headbox to end of guiderail"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front rail when extended",
        "description": "80 from end of guiderail"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Intermediate roller",
        "description": "Fixed roller as specified (by awning size)"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Guiderail cantilever (Max)",
        "description": "1500 past end mounting bracket"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Soltis  / PVC fabric  (W x P)",
        "description": "5500 x 5500 (Max)"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Soltis  / PVC fabric",
        "description": "CARE: May result in creasing, particularly on larger awnings"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Wet weather",
        "description": "CARE: Not an all weather / rain protection system but suitable for light rain"
    },
    {
        "productId": 9,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Drive tapes",
        "description": "Are a consumable"
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "24000 x 6000"
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "W (Max Single field)",
        "description": "6000 (when Multi Field)"
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "Coupled",
        "description": "Has a common middle guiderail (single extrusion)",
        "imageUrl": "/img/powder_coating_sheet/SD_CPTKM.png",
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "Coupled Motor location",
        "description": "Must be on outside ends of headboxes"
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "Dual mount",
        "description": "Each field has individual guiderails, with common mounting fixing",
        "imageUrl": "/img/powder_coating_sheet/SD_DUALM.png",
    },
    {
        "productId": null,
        "frameFeatureId": 6,
        "isOverSqaure": null,
        "label": "Dual Mount Motor location",
        "description": "Motor can be either side of each headbox"
    },
    {
        "productId": null,
        "frameFeatureId": 4,
        "isOverSqaure": null,
        "label": "Remote control operation",
        "description": "Hardwired motor + External Receiver"
    },
    {
        "productId": null,
        "frameFeatureId": 4,
        "isOverSqaure": null,
        "label": "Soltis  / PVC fabric",
        "description": "CARE: May result in creasing, particularly on larger awnings"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Remote control operation",
        "description": "Hardwired motor + External Receiver"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Soltis  / PVC fabric",
        "description": "CARE: May result in creasing, particularly on larger awnings"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "W x P (Max Multi Field)",
        "description": "24000 x 6000"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "W (Max Single field)",
        "description": "6000 (when Multi Field)"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Coupled",
        "description": "Has a common middle guiderail (single extrusion)",
        "imageUrl": "/img/powder_coating_sheet/SD_CPTKM.png",
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Coupled Motor location",
        "description": "Must be on outside ends of headboxes"
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Dual mount",
        "description": "Each field has individual guiderails, with common mounting fixing",
        "imageUrl": "/img/powder_coating_sheet/SD_DUALM.png",
    },
    {
        "productId": null,
        "frameFeatureId": 5,
        "isOverSqaure": null,
        "label": "Dual Mount Motor location",
        "description": "Motor can be either side of each headbox"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Standard (Dulux Duralloy range)"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Hardwired motor",
        "description": "Standard"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual operation",
        "description": "Not available"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "6500 x 5500  /  5500 x 6500"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W (Min) Hardwired/Remote",
        "description": "1350 / 1390"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multi Fields",
        "description": "Not available"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multiple fields",
        "description": "Fitted adjacent (common post + headbox bracket)"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Steer Around",
        "description": "Not available"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Width dimension",
        "description": "Track fixing centres"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Headbox width",
        "description": "Track centres + 60"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Projection dimension",
        "description": "Back of headbox to end of guiderail"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front rail when extended",
        "description": "90 from end of guiderail"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Intermediate roller",
        "description": "Fixed roller as specified (by awning size)"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Guiderail cantilever (Max)",
        "description": "2500 past end mounting bracket / post"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front posts",
        "description": "Option: 0, 1 or 2"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front height (bottom cross rail)",
        "description": "2250 (standard)"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front height (range)",
        "description": "500 - 3500"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Soltis  / PVC fabric",
        "description": "CARE: May result in creasing, particularly on larger awnings"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Wet weather",
        "description": "CARE: Not an all weather / rain protection system but suitable for light rain"
    },
    {
        "productId": 28,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Drive tapes",
        "description": "Are a consumable"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powdercoat",
        "description": "Standard (Dulux Duralloy range)"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Hardwired motor",
        "description": "Standard"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual operation",
        "description": "Not available"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "6000 x 3000  /  4000 x 5500"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W (Min) ",
        "description": "1400"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multi Fields",
        "description": "Not available"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multiple fields",
        "description": "Fitted adjacent (separate mounting)"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Steer Around",
        "description": "Not available"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mounting posts",
        "description": "Not available"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Width dimension",
        "description": "Track fixing centres"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Headbox width",
        "description": "Track centes + 44"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Projection dimension",
        "description": "Back of headbox to end of guiderail"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front rail finish when extended",
        "description": "In line with end of guiderail"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Guiderail cantilever (Max)",
        "description": "300 past end mounting bracket"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Wet weather",
        "description": "CARE: Not an all weather / rain protection system but suitable for light rain"
    },
    {
        "productId": 29,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Drive tapes",
        "description": "Are a consumable"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Custom powderooat",
        "description": "Standard (Dulux Duralloy range)"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Hardwired motor",
        "description": "Standard"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Manual operation",
        "description": "Not available"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W x P (Max)",
        "description": "5000 x 4000  /  4000 x 5000"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "W (Min) ",
        "description": "1400"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multi Field",
        "description": "Not available"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Multiple fields",
        "description": "Fitted adjacent"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Steer Around",
        "description": "Not available"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Mounting posts",
        "description": "Not available"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Width dimension",
        "description": "Track fixing centres"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Projection dimension",
        "description": "Back of headbox to end of guiderail"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Front rail finish when extended",
        "description": "In line with end of guiderail"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Guiderail cantilever (Max)",
        "description": "500 past end mounting bracket"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Wet weather",
        "description": "CARE: Not an all weather / rain protection system but suitable for light rain"
    },
    {
        "productId": 30,
        "frameFeatureId": null,
        "isOverSqaure": null,
        "label": "Drive tapes",
        "description": "Are a consumable"
    }
];

const Wrapper = props => {
    let {title, children, id} = props;
    let collapseId = `collapse_${id}`;
    return (<div className={'card'} style={{marginBottom: '10px', marginLeft: '-15px'}}>
        <div className={'card-body'}>
            <h5 className="card-title">
                <a data-toggle="collapse" href={`#${collapseId}`} role="button"
                   aria-expanded="false" aria-controls={collapseId}>
                    <i className={'fa fa-info-circle'} /> {title} Information
                </a>
            </h5>
            <div className="collapse" id={collapseId}>
                <ul className={'list-group'}>
                    {children}
                </ul>
            </div>
        </div>
    </div>);
};

const WrapperItem = props => {
  let {label, description, imageUrl} = props;

  return (<li className={'list-group-item'}>
        <div className={'row'}>
            <div className={'col-4'}>{label}
                { imageUrl && <img src={imageUrl} alt={description} className={"ml-2"} />}
            </div>
            <div className={'col-8'}>{description}</div>
        </div>
  </li>);
};

const ResourceLinkWrapperItem = props => {
    let {resourceTags, onProductResourceSelect} = props;
    return resourceTags.length > 0 ? <WrapperItem label={'Additional resources'} description={(
            <a href={''} style={{marginTop: '-15px'}} onClick={onProductResourceSelect}><i
                className={'fa fa-link'}/>&nbsp;Show Product Resources</a>)}/>
        : null
};

export function GetProductResourceTags(productId) {
    switch (productId) {
        case PRODUCTID_DS:
            return ['Discus'];
        case PRODUCTID_OLYMPUS:
            return ['Olympus'];
        case PRODUCTID_TOGA:
            return ['Toga'];
        case PRODUCTID_ECO:
            return ['Eco'];
        case PRODUCTID_S1:
            return ['Stratos 1', 'Stratos I'];
        case PRODUCTID_S3:
            return ['Stratos 3', 'Stratos III'];
        case PRODUCTID_SD:
            return ['Sundream'];
        case PRODUCTID_SDC:
            return ['Sundream Compact'];
        case PRODUCTID_SDCZ:
            return ['Sundream Compact Zip'];
        case PRODUCTID_SDM:
            return ['Sundream MaxiRail'];
        default:
            return [];
    }
}

function GetProductInformation(productId = null, frameFeatureId = null, isOverSquare = null){
    return productInformationData.filter(x =>
        (!IsNullOrUndefined(productId) && x.productId === productId) ||
        (!IsNullOrUndefined(frameFeatureId) && x.frameFeatureId === frameFeatureId) ||
        (!IsNullOrUndefined(isOverSquare) && x.isOverSqaure === isOverSquare));
}

/**
 * @return {string}
 */
function GetTitle(productId = null, frameFeatureId = null, isOverSquare = null) {
    if (!IsNullOrUndefined(productId)) {
        let resource = GetProductResourceTags(productId);
        return resource.length > 0 ? resource[0] : '';
    }
    if (!IsNullOrUndefined(frameFeatureId)) {
        if (frameFeatureId === 1) {
            return 'Selectapitch';
        }
        if (frameFeatureId === 4) {
            return 'Steer Around';
        }
        if (frameFeatureId === 5) {
            return 'Steer Around Multi Field: Coupled vs Dual Mount';
        }
        if (frameFeatureId === 6) {
            return 'Multi Field: Coupled vs Dual Mount';
        }
    }
    if (isOverSquare) {
        return 'Oversquare';
    }
    return '';
}


const ProductInformationControl = (props) => {

    let {productId, onProductResourceSelect, frameFeatureId, isOversquare, id} = props;
    let resourceTags = GetProductResourceTags(productId);
    let productInformation = GetProductInformation(productId, frameFeatureId, isOversquare);

    if(IsNullOrUndefined(productId) && IsNullOrUndefined(frameFeatureId) && (IsNullOrUndefined(isOversquare) || isOversquare === false ) || productInformation.length < 1){
        return null;
    }
    return (<Wrapper title={GetTitle(productId, frameFeatureId, isOversquare)} id={id}>
        {productInformation.map((x,i) => {
            return (<WrapperItem key={`info_${i}`} label={x.label} description={x.description} imageUrl={x.imageUrl} />);
        })}
        { onProductResourceSelect && <ResourceLinkWrapperItem resourceTags={resourceTags} onProductResourceSelect={onProductResourceSelect} /> }
    </Wrapper>);
};

ProductInformationControl.propTypes = {
    productId: PropTypes.number,
    frameFeatureId: PropTypes.number,
    isOversquare: PropTypes.bool,
    onProductResourceSelect: PropTypes.func,
    id: PropTypes.string,
};

export default ProductInformationControl;