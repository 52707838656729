import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import injectSheet from 'react-jss';
import {DATE_SHORT_FORMAT} from "../../constants";
import moment from 'moment';
import {loadmotorMakersSuccess} from "../../actions/lookupActions";
import jQuery from 'jquery';

const styles = {
  divTable: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: `Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif`,
    margin: '20px 0',
    fontSize: '11px',
    border: '2px solid #ddd',
  },
  divThead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontWeight: '700'
  },
  divTbody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  divTr: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '55px'
  },
  divTrHead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    textAlign: 'center'
  },
  divTh: {
    flex: '1',
    flexBasis: '6%',
    padding: '2px 5px',
    border: '1px solid #ddd',
    borderBottom: '2px solid #ddd',
  },
  divTd: {
    flex: '1',
    flexBasis: '6%',
    padding: '5px',
    border: '1px solid #ddd',
  },
  divTdHardLeft: {
    flex: '1',
    flexBasis: '6%',
    padding: '5px',
    border: '1px solid #ddd',
    marginLeft: '5px',
    borderLeft: '3px solid #ddd',
  },
  divTdHardRight: {
    flex: '1',
    flexBasis: '6%',
    padding: '5px',
    border: '1px solid #ddd',
    borderRight: '3px solid #ddd',
  },
  divTdNoBorder: {
    flex: '1',
    padding: '5px',
  },
  'divTd:first-of-type': {
    borderLeft: '1px solid #ddd',
  },
  'divtbody .div-tr:nth-of-type(2n)': {
    background: '#eee',
  },
  pcImage: {
    width: '40px',
    height: 'auto',
    maxHeight: '45px'
  },
  smallCell:{
    flexBasis: '2%',
    padding: '0px'
  },
  smallCellWithPadding:{
    flexBasis: '3%',
  }
}

class OrderMotorSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.printSection = React.createRef();
  }

  handlePrint() {

    let {order, motorSheetIndex} = this.props;
    let motorSheet = null;
    if(motorSheetIndex >= 0){
      motorSheet = order.motorSheets[motorSheetIndex];
    }

    if(!order || !motorSheet){
      return null;
    }


    let container = jQuery(`<div></div>`)
      .css('font-size', '10px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    jQuery(this.printSection.current)
      .clone()
      .css('margin-top', '10px')
      .appendTo(container);


    html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_motor_sheet.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });

    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {order, classes, motorSheetIndex, print} = this.props;
    let motorSheet = null;
    if(motorSheetIndex >= 0){
      motorSheet = order.motorSheets[motorSheetIndex];
    }

    if(!order || !motorSheet){
      return null;
    }

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>

            <div ref={this.printSection} style={{width: '98%', marginTop: '25px'}}>
              <div className="pi-row" style={{marginBottom: '15px'}}>
                <div className="pi-col-sm-8">
                  <h2>Motor Order</h2>
                </div>
                <div className="pi-col-sm-4">
                  <h4><img src="img/logo-full.png" alt="Aluxor Logo"></img></h4>
                </div>
              </div>

              <div className="pi-row">
                <div className="pi-col-sm-1">
                  <strong>Brand</strong>
                </div>
                <div className="pi-col-sm-2">
                  {motorSheet.motorBrand}
                </div>
              </div>
              <div className="pi-row">
                <div className="pi-col-sm-1">
                  <strong>Date</strong>
                </div>
                <div className="pi-col-sm-11">
                  {moment().format(DATE_SHORT_FORMAT)}
                </div>
              </div>
              <div className="pi-row">
                <div className="pi-col-sm-1">
                  <strong>Order</strong>
                </div>
                <div className="pi-col-sm-11">
                  {order.id}
                </div>
              </div>


              <div className={classes.divTable} style={{flexDirection: 'column'}}>
                <div className={classes.divThead}>
                  <div className={classes.divTrHead}>
                    <div className={`${classes.divTh} ${classes.smallCellWithPadding}`}>Quantity</div>
                    <div className={classes.divTh}>Motor Description</div>
                    <div className={classes.divTh}>Supplier Code</div>
                  </div>
                </div>
                <div className={classes.divTbody}>
                  {motorSheet.items.map(a => {
                    return (
                      <div className={classes.divTr} key={`motorRow_${a.motorId}`}>
                        <div className={`${classes.divTd} ${classes.smallCellWithPadding}`} style={{textAlign: 'center'}}>{a.motorQuantity}</div>
                        <div className={classes.divTd}>{a.motorDescription}</div>
                        <div className={classes.divTd}>{a.supplierCode}</div>
                      </div>
                    );
                  })}
                </div>
              </div>

            </div>

          </ModalBody>
          <ModalFooter>
            <button onClick={this.handlePrint}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-print"></i>&nbsp;Print</button>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"></i>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderMotorSheet.propTypes = {
  order: PropTypes.object.isRequired,
  motorSheetIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(OrderMotorSheet);