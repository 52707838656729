import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import injectSheet from 'react-jss';
import {
  BasePowderCoatingImageUrl,
  DRIVE_MECHANISM_MANUAL,
  isManual,
  DATE_SHORT_FORMAT,
  IsNullOrUndefined, obsfucate, REPORTSERVER_BASEURL, FLASHINGTYPE_WallSeal
} from "../../constants";
import moment from 'moment';
import jQuery from 'jquery';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import maxBy from 'lodash/maxBy';
import chunk from 'lodash/chunk';
import reduce from 'lodash/reduce';
import {apiDownload, apiDownloadReport, apiPost} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import {Base64} from "js-base64";

export const styles = {
  divTable: {
    fontFamily: `Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif`,
    margin: '20px 0',
    fontSize: '14px',
    width: '100%',
    borderCollapse: 'collapse',
    display: 'table',
  },
  divThead: {
    display: 'table-header-group',
    background: '#333',
    color: 'white',
    fontWeight: '500',
    fontSize: '9px'
  },
  divTbody: {
    display: 'table-header-group',
    width: '100%',
  },
  divTr: {
    display: 'table-row',
    width: '100%',
    height: '55px'
  },
  divTrHead: {
    display: 'table-row',
    width: '100%'
  },
  divTh: {
    display: 'table-cell',
    padding: '0 10px',
  },
  divTd: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
  },
  divTdHardLeft: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
    marginLeft: '5px',
    borderLeft: '3px solid #ddd',
  },
  divTdHardRight: {
    display: 'table-cell',
    padding: '5px',
    border: '1px solid #ddd',
    borderRight: '3px solid #ddd',
  },
  divTdNoBorder: {
    display: 'table-cell',
    padding: '5px',
  },
  'divTd:first-of-type': {
    borderLeft: '1px solid #ddd',
  },
  'divtbody .div-tr:nth-of-type(2n)': {
    background: '#eee',
  },
  pcImage: {
    width: '40px',
    height: 'auto',
    maxHeight: '45px'
  },
  smallCell:{
    width: '7%',
  },
  smallCellWithPadding:{
    width: '5%',
  }
}

const Aux = props => props.children;

const Row = (props) => {
  let {pcRef, description, dimension, number, product, classes, showImage} = props;
  let image = showImage == undefined || showImage;
  //TODO: Remove addtional borders where there is no image
  return (<div className={classes.divTr}>
    <div className={image ? classes.divTd : classes.divTd}>{image &&
      <img className={classes.pcImage} src={`${BasePowderCoatingImageUrl}${product ? `${product}_${pcRef}` : pcRef}.png`}/>}</div>
    <div className={classes.divTd}>{product ? `${product}_${pcRef}` : pcRef}</div>
    {/* <div className={classes.divTd} style={{flexBasis: '10%'}}>{product ? `${description} - ${product}` : description}</div> */}
    <div className={classes.divTd}>{dimension}</div>
    <div className={classes.divTd}>{number}</div>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
  </div>)
}

export const RowFields = (props) => {
  let {pcRef, description, dimension, length, number, product, classes, showImage, hardLeftBorder, material} = props;
  let image = showImage == undefined || showImage;
  //TODO: Remove addtional borders where there is no image
  return (<Aux>
    <div className={hardLeftBorder ? classes.divTdHardLeft : classes.divTd} style={{borderLeft: '3px solid #ddd'}}>
      {product ? `${product}_${pcRef}` : pcRef}
    </div>
    <div className={classes.divTd} style={{width: '20%'}}>{product ? `${description} - ${product}` : description}</div>
    <div className={classes.divTd}>{dimension}</div>
    <div className={classes.divTd}>{material}</div>
    <div className={classes.divTd}>{image && <img className={classes.pcImage}
                                                  src={`${BasePowderCoatingImageUrl}${product ? `${product}_${pcRef}` : pcRef}.png`}/>}</div>
    <div className={classes.divTd}>{length}</div>
    <div className={`${classes.divTd} ${classes.smallCellWithPadding}`}>{number}</div>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTdHardRight} ${classes.smallCell}`}/>
  </Aux>);
}

export const BlankRowFields = (props) => {
  let {classes, hardLeftBorder} = props;
  return (<Aux>
    <div className={hardLeftBorder ? classes.divTdHardLeft : classes.divTd} style={{borderLeft: '3px solid #ddd'}}/>
    <div className={classes.divTd} style={{width: '15%'}}/>
    <div className={classes.divTd}/>
    <div className={classes.divTd}/>
    <div className={classes.divTd}/>
    <div className={classes.divTd}/>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTd} ${classes.smallCell}`}/>
    <div className={`${classes.divTdHardRight} ${classes.smallCell}`}/>
  </Aux>);
}

const DoubleRowHorizontal = (props) => {
  let {classes} = props;
  return (<div className={classes.divTr}>
    {props.one ? <RowFields {...props.one} classes={classes} hardLeftBorder={false} /> : <BlankRowFields classes={classes}  hardLeftBorder={false} /> }
    {props.two ? <RowFields {...props.two} classes={classes} hardLeftBorder={true} /> : <BlankRowFields classes={classes} hardLeftBorder={true} /> }
  </div>)
};

const AwningPcSections = (props) => {

  let {awning, pcSections} = props;
  let noArmsLeft = awning.arms.filter(x => x.sideDescription == 'Left').length;
  let noArmsRight = awning.arms.filter(x => x.sideDescription == 'Right').length;
  let noArms = awning.arms.length;
  let noSections = awning.sections;
  let noTotalCradles = awning.additionalCradles + awning.numberOfCradles;

  let nullToZero = (v) => v==null ? 0 : v;

  let pcsections = pcSections.map(s => {
      return s.pcItems.filter(a => a.awningNumber == awning.number).map((i, ii) => {

        //Used in eval :(
        let isManual = (awning) => {
          let id = awning ? awning.driveMechanismId : -1;
          return id == DRIVE_MECHANISM_MANUAL;
        };
        let number =
          (nullToZero(i.perArm) * noArms) +
          (nullToZero(i.perLeftArm) * noArmsLeft) +
          (nullToZero(i.perRightArm) * noArmsRight) +
          (nullToZero(i.perSection) * noSections) +
          (nullToZero(i.perCradle) * noTotalCradles) +
          nullToZero(i.perAwning) +
          (i.perAsEval !== null ? eval(i.perAsEval) : 0);

        let display = (i.displayAsEval == null ? true : eval(i.displayAsEval)) && number > 0;

        return {
          section: s.section == null ? 'default' : s.section,
          display,
          key: `${s.section}_${i.pcRef}`,
          pcRef: i.pcRef,
          description: i.description,
          length: i.size ? eval(i.size) : '',
          number,
          showImage: i.showImage,
          orderBy: i.orderBy,
          dimension: IsNullOrUndefined(i.surfaceArea) && IsNullOrUndefined(i.dimensionX) ? '' :
              IsNullOrUndefined(i.surfaceArea) ? `${i.dimensionX} x ${i.dimensionY} x ${i.dimensionZ}` : `${i.surfaceArea} m2`,
          material: i.material,
        };
      });
    }
  );
  return values(pcsections);
};


export const OrderPcSectionData = (props) => {
  let {order, pcSectionIndex} = props;

  if(pcSectionIndex < 0){
    return null;
  }
  let flattened;

  if(!order.isSparesOrder){
    let section = order.awningPcSections[pcSectionIndex];
    let allRows =  order.awnings
        .filter(a => section.awningNumbers.indexOf(a.number) >= 0)
        .map(x => AwningPcSections({awning: x, pcSections: section.pcSections}));
    flattened = flatten(flatten(allRows)).filter(x => x.display);
  } else {
    flattened = order.sparePcSections[pcSectionIndex].pcSections.map(i => {
      return {
        ...i, dimension: IsNullOrUndefined(i.surfaceArea) && IsNullOrUndefined(i.dimensionX) ? '' :
            IsNullOrUndefined(i.surfaceArea) ? `${i.dimensionX} x ${i.dimensionY} x ${i.dimensionZ}` : `${i.surfaceArea} m2`,
      };
    });
  }

  let summed = flattened.reduce((result, current)=> {
    let itemKey = current.key + current.dimension + current.length;
    if(result.has(itemKey)){
      let existing = result.get(itemKey);
      existing.number = existing.number + current.number;
      result.set(itemKey, existing);
    } else {
      result.set(itemKey, current);
    }
    return result;
  }, new Map());

  let sectionData = sortBy(Array.from(summed.values()), ['orderBy']);

  let reduced = reduce(sectionData, (result, value) => {
    if(value.length > result.maxProfileLength){
      result.maxProfileLength = value.length;
    }
    if(value.length > 0 && value.number > 0){
      result.numberOfProfiles += value.number;
    } else if(value.number > 0){
      result.numberOfComponents += value.number;
    }
    return result;
  }, {maxProfileLength: 0, numberOfProfiles: 0, numberOfComponents: 0});

  return {summary: reduced, sectionData};
};


const OrderPcSections = (props) => {
  let {classes, sectionData} = props;
  let final = sectionData;
  if(!final){
    return null;
  }

  let chunks = chunk(final, Math.round(final.length/2));
  let row = [];
  let sections = new Map();

  let chunkWithImage = (page, i) => {
    let returnValue = chunks[page][i];
    if (returnValue.showImage === null || returnValue.showImage === undefined) {
      returnValue.showImage = true;
    }
    if (sections.has(returnValue.section)) {
      let s = sections.get(returnValue.section);
      //if (s.has(returnValue.pcRef)) { when only one of an image per section was shown
        returnValue.showImage = true;
      //} else {
        s.add(returnValue.pcRef);
        sections.set(returnValue.section, s);
      //}
    } else {
      sections.set(returnValue.section, new Set([returnValue.pcRef]));
    }
    return returnValue;
  }

  for(let i = 0; i < final.length; i++) {

    let one = null;
    let two = null;
    if (chunks[0].length > i) {
      one = chunkWithImage(0, i);
    }
    if (chunks.length == 2 && chunks[1].length > i) {
      two = chunkWithImage(1, i);
    }

    let vals = final[i];

    let rowData = {
      key: `${vals.key}_${vals.dimension}`,
      pcRef: vals.pcRef,
      description: vals.description,
      length: vals.length,
      number: vals.number,
      showImage: true,
      dimension: vals.dimension,
      material: vals.material,
    };

    row.push(<div key={`row_${i}`} className={classes.divTr}><RowFields {...rowData} classes={classes} hardLeftBorder={false}/></div>);

    if(i > 0 && i % 12 == 0){
      row.push(<div key={`break_${i}`} className={'html2pdf__page-break'}/>);
    }
  }
  row.push(<div key={`blank_row`} className={classes.divTr}><BlankRowFields classes={classes}  hardLeftBorder={false} /></div>);
  return (<div className={`${classes.divTbody}`}>
    {row}
  </div>);
};


class OrderPowderCoatingSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };
    this.handlePrint = this.handlePrint.bind(this);
    this.getDataForPage = this.getDataForPage.bind(this);
    this.printMeRef = React.createRef();
    this.handleEmailInquiry = this.handleEmailInquiry.bind(this);
    this.handleDownloadData = this.handleDownloadData.bind(this);
  }

  handlePrint() {

    let {order} = this.props;

    let container = jQuery(`<div></div>`)
      .css('font-size', '10px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    jQuery(this.printMeRef.current)
      .clone()
      .css('margin-top', '10px')
      .appendTo(container);

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_PC_sheet.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });*/

    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${order.id}_PC_sheet.pdf`);

    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  handleEmailInquiry(e) {
    e.preventDefault();
    let {order, pcSectionIndex, orderType} = this.props;
    let {colour, numberOfProfiles, maxProfileLength, numberOfComponents, sectionData} = this.getDataForPage();

    toastr.info('Sending email...');

    apiPost(`/api/supplierorders/${order.id}/emailPcInquiry`, {
      numberOfProfiles,
      maxProfileLength,
      colour,
      sequenceNumber: order.sequenceNumber,
      powderCoaterId: order.powderCoaterId,
      sheetIndex: pcSectionIndex,
      obsfucatedOrderId: obsfucate(order.id),
      numberOfComponents,
      orderType,
      pcInquiryDataItems: sectionData.map(x => {
        return {
          pcRef: x.pcRef,
          quantity: x.number,
          length: x.length > 0 ? x.length : null,
        };
      }),
    }).then(x => {
      if (x.data && x.data.ok) {
        toastr.success('Sent');
      } else {
        toastr.error('Error while sending');
      }
    });
  }
  
  async handleDownloadData(){
    let {order, pcSectionIndex, orderType} = this.props;
    let {colour, numberOfProfiles, maxProfileLength, numberOfComponents, sectionData} = this.getDataForPage();
    
    await apiDownload(`/api/supplierorders/${order.id}/DownloadPcSupplierData`, 
        `ALX_PC_${order.customerReference}${pcSectionIndex > 0 ? `_${pcSectionIndex + 1}` : ''}.tsv`, 
        'POST', null, {
      numberOfProfiles,
      maxProfileLength,
      colour,
      sequenceNumber: order.sequenceNumber,
      powderCoaterId: order.powderCoaterId,
      sheetIndex: pcSectionIndex,
      obsfucatedOrderId: obsfucate(order.id),
      numberOfComponents,
      orderType,
      pcInquiryDataItems: sectionData.map(x => {
        return {
          pcRef: x.pcRef,
          quantity: x.number,
          length: x.length > 0 ? x.length : null,
        };
      }),
    });
  }

  getDataForPage(){
    let {order, pcSectionIndex } = this.props;

    let colour = '';

    if(pcSectionIndex >= 0 && !order.isSparesOrder){
      colour = order.awningPcSections[pcSectionIndex].customColourDescription;
    } else if(pcSectionIndex >= 0 && order.isSparesOrder){
      colour = order.sparePcSections[pcSectionIndex].customColourDescription;
    }

    let data = OrderPcSectionData({ order, pcSectionIndex});
    let sectionData = null;
    let summary = {maxProfileLength: 0, numberOfProfiles: 0, numberOfComponents: 0};
    if(!IsNullOrUndefined(data) ){
      summary = data.summary;
      sectionData = data.sectionData;
    }

    return {colour, numberOfProfiles: summary.numberOfProfiles, sectionData, maxProfileLength: summary.maxProfileLength, numberOfComponents: summary.numberOfComponents };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  componentDidMount() {
   if(this.props.isDownload){
     this.handleDownloadData();
   } 
  }  

  render(){
    let {order, classes, pcSectionIndex, print, showEmail} = this.props;

    if(!order){
      return null;
    }

    let {colour, numberOfProfiles, sectionData, maxProfileLength, numberOfComponents} = this.getDataForPage();

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-section">

              <div className="pi-row" style={{marginBottom: '10px'}}>
                <div className="pi-col-md-8">
                  <button onClick={this.handlePrint}
                          className="btn pi-btn-base pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-print"/>&nbsp;Print</button>
                  {showEmail && <button onClick={this.handleEmailInquiry}
                                        className="btn pi-btn-base pi-btn-icon-big"
                                        style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-envelope"/>&nbsp;Email Inquiry</button> }
                  <button className="btn pi-btn  pi-btn-icon-big"
                          onClick={this.props.toggleOpen}><i className="fa fa-times"/>&nbsp;Close</button>
                </div>
              </div>


              <div ref={this.printMeRef} style={{width: '98%'}}>

                <div className="pi-row">
                  <div className="pi-col-md-8">
                    <h4 className="pi-has-bg pi-margin-bottom-20">
                      Powder Coating Sheet Order #{order.sequenceNumber}
                    </h4>
                  </div>
                  <div className="pi-col-sm-4">
                    <h4><img src="img/logo-full.png" alt="Aluxor Logo"/></h4>
                  </div>
                </div>

                <div className="pi-row">
                  <div className="pi-col-md-2">
                    Powder coater:
                  </div>
                  <div className="pi-col-md-4">
                    {order.powderCoaterDescription}
                  </div>
                  <div className="pi-col-md-1"/>
                  <div className="pi-col-md-2">
                    Contact:
                  </div>
                  <div className="pi-col-md-3">
                  </div>
                </div>

                <div className="pi-row">
                  <div className="pi-col-md-2">
                    Date sent:
                  </div>
                  <div className="pi-col-md-4">
                    {order.pcSentDate ? moment(order.pcSentDate).format(DATE_SHORT_FORMAT) : ''}
                  </div>
                  <div className="pi-col-md-1"/>
                  <div className="pi-col-md-2">
                    Telephone:
                  </div>
                  <div className="pi-col-md-3">
                    02 9907 2211
                  </div>
                </div>

                <div className="pi-row">
                  <div className="pi-col-md-2">
                    Colour:
                  </div>
                  <div className="pi-col-md-4">
                    {colour}
                  </div>
                  <div className="pi-col-md-1"/>
                  <div className="pi-col-md-2">
                    Email:
                  </div>
                  <div className="pi-col-md-3">
                    <a href={'mailto:production@aluxor.com.au'}>production@aluxor.com.au</a>
                  </div>
                </div>
                <div className="pi-row">
                  <div className="pi-col-md-2">
                    No. Profiles:
                  </div>
                  <div className="pi-col-md-4">
                    {numberOfProfiles}
                  </div>
                  <div className="pi-col-md-1"/>
                  <div className="pi-col-md-2">
                    Max. Profile:
                  </div>
                  <div className="pi-col-md-3">
                    {maxProfileLength > 0 ? `${maxProfileLength}mm` : ''}
                  </div>
                </div>
                <div className="pi-row">
                  <div className="pi-col-md-2">
                    No. Comp.:
                  </div>
                  <div className="pi-col-md-4">
                    {numberOfComponents}
                  </div>
                </div>


                <div className={classes.divTable}>
                  <div className={classes.divThead}>
                    <div className={classes.divTrHead}>
                      <div className={classes.divTh}>Code</div>
                      <div className={classes.divTh} style={{width: '15%'}}>Description</div>
                      <div className={classes.divTh}>Dimension</div>
                      <div className={classes.divTh}>Material</div>
                      <div className={classes.divTh}>Image</div>
                      <div className={classes.divTh}>Length</div>
                      <div className={`${classes.divTh} ${classes.smallCell}`}>Number</div>
                      <div className={`${classes.divTh} ${classes.smallCell}`}>Check</div>
                      <div className={`${classes.divTh} ${classes.smallCell}`}>PC Chk</div>
                    </div>
                  </div>

                  <OrderPcSections order={order} pcSectionIndex={pcSectionIndex} classes={classes} sectionData={sectionData} />

                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"/>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderPowderCoatingSheet.propTypes = {
  order: PropTypes.object.isRequired,
  pcSectionIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
  showEmail: PropTypes.bool.isRequired,
  orderType: PropTypes.number,
  isDownload: PropTypes.bool,
};

export default injectSheet(styles)(OrderPowderCoatingSheet);