import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function httpInfoReducer(state = initialState.httpInfo, action){
    switch(action.type) {        
        case types.HTTP_INFO_PENDING_START:            
            return {...state, pendingCount: state.pendingCount + 1};
        case types.HTTP_INFO_PENDING_END:
            return {...state, pendingCount: state.pendingCount - 1};
        default:
            return state;
    }
}
