import React, {Component} from "react";
import injectSheet from "react-jss";
import {InvoiceTableStyles} from "./OrderInvoiceSheet";
import {MoneyFormatter} from "../../constants";
import PropTypes from "prop-types";

class SpareSummaryView extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {}
    }

    render(){
        let {classes, spares, isWholesaleOnly, wholesaleCostTotal} = this.props;

        return (<div>

            <div className={classes.divTable} style={{fontSize:'13px', lineHeight:'1.6', fontFamily: `'Open Sans', Arial, sans-serif`}}>
                <div className={classes.divThead}  style={{fontSize:'14px'}}>
                    <div className={classes.divTrHead}>
                        <div className={classes.divTh} style={{width: '50%'}}>Description</div>
                        <div className={classes.divTh} style={{width: '5%'}}>Side</div>
                        <div className={classes.divTh} style={{width: '5%'}}>Size</div>
                        { /* !isWholesaleOnly && <div className={classes.divTh} style={{width: '10%'}}>Retail</div> */}
                        <div className={classes.divTh} style={{width: '10%'}}>Wholesale</div>
                        <div className={classes.divTh} style={{width: '8%'}}>Qty</div>
                        <div className={classes.divTh} style={{width: '12%', textAlign: 'right', paddingRight: '5px'}}>Total Excl. GST</div>
                    </div>
                </div>
                <div className={classes.divTbody}>
                    {spares.map((x, i) => {
                        return (<div key={x.id} className={classes.divTr}>
                            <div className={classes.divTd} style={{width: '50%', textAlign:'left'}}>{x.spareDescription}</div>
                            <div className={classes.divTd} style={{width: '5%'}}>{x.side}</div>
                            <div className={classes.divTd} style={{width: '5%'}}>{x.spareSizeDescription}</div>
                            {/* !isWholesaleOnly &&  <div className={classes.divTdRight} style={{width: '10%'}}>{MoneyFormatter.format(x.retailPrice)}</div> */}
                            <div className={classes.divTdRight} style={{width: '10%'}}>{MoneyFormatter.format(x.wholesalePrice)}</div>
                            <div className={classes.divTd} style={{width: '8%'}}>{x.quantity}</div>
                            <div className={classes.divTdRight} style={{width: '12%'}}>{MoneyFormatter.format(x.wholesaleTotal)}</div>
                        </div>);
                    })}

                    <div className={classes.divTr}>
                        <div className={classes.divTd} style={{width: '50%', textAlign:'left'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                        {/* !isWholesaleOnly &&  <div className={classes.divTdRight} style={{width: '10%'}}>&nbsp;</div>*/}
                        <div className={classes.divTdRight} style={{width: '10%'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '8%'}}>&nbsp;</div>
                        <div className={classes.divTdRight} style={{width: '12%'}}>&nbsp;</div>
                    </div>
                    <div className={classes.divTr}>
                        <div className={classes.divTd} style={{width: '50%', textAlign:'left'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '5%'}}>&nbsp;</div>
                        {/* !isWholesaleOnly &&  <div className={classes.divTdRight} style={{width: '10%'}}>&nbsp;</div>*/}
                        <div className={classes.divTdRight} style={{width: '10%'}}>&nbsp;</div>
                        <div className={classes.divTd} style={{width: '8%'}}>&nbsp;</div>
                        <div className={classes.divTdRight} style={{width: '12%'}}>
                            <strong>
                                <span style={{float: 'left'}}>Sub Total:</span>{MoneyFormatter.format(wholesaleCostTotal)}<br />
                                <span style={{float: 'left'}}>GST:</span>{MoneyFormatter.format(wholesaleCostTotal * 0.1)}<br />
                                <span style={{float: 'left'}}>Total:</span>{MoneyFormatter.format(wholesaleCostTotal * 1.1)}
                            </strong>
                        </div>
                    </div>

                </div>
            </div>


        </div>);
    }
}

SpareSummaryView.propTypes = {
    classes: PropTypes.object.isRequired,
    spares: PropTypes.array.isRequired,
    isWholesaleOnly: PropTypes.bool.isRequired,
    wholesaleCostTotal: PropTypes.number.isRequired,
};

export default injectSheet(InvoiceTableStyles)(SpareSummaryView);