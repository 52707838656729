import {toastr} from 'react-redux-toastr';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import store from '../store';
import {login, logout} from '../actions/identityActions';
import {beginRequest, completeRequest} from "../actions/httpInfoActions";
import fetchIntercept from 'fetch-intercept';

const unregister = fetchIntercept.register({
  request: function (url, config) {
    store.dispatch(beginRequest());    
    return [url, config];
  },

  requestError: function (error) {
    store.dispatch(completeRequest());
    return Promise.reject(error);
  },

  response: function (response) {
    store.dispatch(completeRequest());
    return response;
  },

  responseError: function (error) {
    store.dispatch(completeRequest());
    return Promise.reject(error);
  }
});


function performFetch(url, options, showLogin = true, includeCredentials = true) {

  if(includeCredentials) {
    options.credentials = "include";
  }

  return fetch(url, options)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else if ((res.status === 401 || res.status === 403) && showLogin) {
        toastr.confirm(`It appears your session has timed out or you aren't logged in`, {
          timeOut: 0,
          onOk: () => {
            store.dispatch(logout());
            window.location = '/';
          },
          onCancel: () => {
            store.dispatch(logout());
            window.location = '/';
          },
          okText: 'Login',
        });
      } else if(showLogin && res.status !== 204) {
        throw res;
      }
    })
    .then((data) => ({data}))
    .catch((error) => {
      console.log(error);
      let text = error.statusText ? error.statusText : 'Unknown';
      let status = error.status ? error.status : 'Unknown';
      let message = `An error occurred, ${status}: ${text}`;

      error.json().then(x => {
        if (x && x.message) {
          toastr.error(x.message);
        } else if (x) {
          toastr.error(JSON.stringify(x));
        }
      }).catch(() => {
        toastr.error(message);
      });

      console.log(message, error);
      return {error: message, data: error};
    });
}

export function apiRequest(url, method = 'GET') {
  let identity = store.getState().identity;

  if(!identity.accessToken && url !== '/api/users/token'){
    store.dispatch(login());
  }
   else {
    const headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${identity.accessToken}`,
    };

    const options = {
      method,
      headers
    };

    return performFetch(url, options);
  }
}



export function apiPost(url, input) {
  let identity = store.getState().identity;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${identity.accessToken}`,
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(input)
  };

  return performFetch(url, options);
}

export function apiPostWithToken(url, token) {
  let identity = store.getState().identity;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };

  const options = {
    method: 'POST',
    headers,
    body: null
  };

  return performFetch(url, options);
}

export function apiPostNoAuth(url, body = null, includeCredentials = true) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  const options = {
    method: 'POST',
    headers,
    body
  };

  return performFetch(url, options, false, includeCredentials);
}

export function apiPut(url, input) {
  let identity = store.getState().identity;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${identity.accessToken}`,
  };

  const options = {
    method: 'PUT',
    headers,
    body: JSON.stringify(input)
  };

  return performFetch(url, options);
}

export function apiDelete(url, input) {
  let identity = store.getState().identity;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${identity.accessToken}`,
  };

  const options = {
    method: 'DELETE',
    headers,
    body: JSON.stringify(input)
  };

  return performFetch(url, options);
}

export function apiDownload(url, fileName, method = 'GET', onGotBlob = null, input = null) {
  let identity = store.getState().identity;

  if (!identity.accessToken) {
    store.dispatch(login());
  } else {
    const headers = {
      'Accept': 'application/json',      
      'Authorization': `Bearer ${identity.accessToken}`,
    };
    
    if(input !== null) {
      headers["Content-Type"] = 'application/json';
    }

    const options = {
      method: method,
      headers,
      credentials: "include",
      body: input !== null ? JSON.stringify(input) : undefined,
    };

    return performDownloadBlob(url, options, onGotBlob, fileName);
  }
}

function performDownloadBlob(url, options, onGotBlob = null, fileName) {
  return fetch(url, options)
      .then(r => {
        return r.blob();
      })
      .then(blob => {
        let newBlob = new Blob([blob], {type: 'application/octet-stream'});

        if (onGotBlob === null) {
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          const data = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = data;
          link.download = fileName;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        } else {
          onGotBlob(newBlob);
        }
      })
      .catch((error) => {
        console.log(error);
        let text = error.statusText ? error.statusText : 'Unknown';
        let status = error.status ? error.status : 'Unknown';
        let message = `An error occurred, ${status}: ${text}`;
        try {
          error.json().then(x => {
            if (x && x.message) {
              toastr.error(x.message);
            } else if (x) {
              toastr.error(JSON.stringify(x));
            }
          }).catch(() => {
            toastr.error(message);
          });

          console.log(message, error);
        } catch{}
        return {error: message, data: error};
      });
}

export function apiDownloadReport(url, body, fileName, onGotBlob = null) {
  const headers = {
    "content-type": "application/json",
  };
  const options = {
    method: "POST",
    headers,
    body,
  };
  toastr.info(`Downloading ${fileName}...`);
  return performDownloadBlob(url, options, onGotBlob, fileName)
      .then(_ => {
        toastr.success(`Done downloading ${fileName}`);
      });
}
