import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {flatten, uniq, concat, uniqBy, orderBy} from 'lodash';
import {LOADING_SUPPLIER_ORDERS} from "../actions/actionTypes";

export default function supplierOrdersReducer(state = initialState.supplierOrders, action) {
  switch (action.type) {
    case types.LOAD_SUPPLIER_ORDERS_SUCCESS:
      let colours = uniq(flatten(action.supplierOrders.map(y => {
            return concat(y.distinctColours, y.distinctCustomColours);
          }))).filter(y => y !== null).sort().map(y => {
            return {value: y, label: y};
          });
          let organisations = uniq(action.supplierOrders.map(y => y.organisationShortName)).sort().map(y => {
            return {value: y, label: y};
          });

      let products = uniq(flatten(action.supplierOrders.map(y => {
        return y.products;
      }))).filter(y => y !== null).sort().map(y => {
        return {value: y, label: y};
      });

      let motors = orderBy(uniqBy(flatten(action.supplierOrders.filter(y => y.motors !== null).map(y => {
        return y.motors;
      })), x => x.id), x => x.description).map(y => {
        return {value: y.id, label: y.description};
      });

      return {
        ...state,
        loaded: true,
        items: [...action.supplierOrders],
        colours,
        products,
        organisations,        
        motors,
        unfilteredItems: [...action.supplierOrders],
        filter: {
          ...action.filter
        },
        nonPostFilter: {
          ...action.nonPostFilter
        }
      };
    case types.SUPPLIER_ORDERS_UPDATE_FILTER: {
      return {
        ...state,
        filter: {...action.filter},
        items: [],
        loaded: false,
      };
    }
    case types.SUPPLIER_ORDERS_UPDATE_NONPOSTFILTER: {
      return {
        ...state,
        nonPostFilter: {...action.nonPostFilter},
      };
    }
    case types.LOADING_SUPPLIER_ORDERS: {
      return {
        ...state,
        loaded: false,
      };
    }
    case types.SUPPLIER_ORDERS_UPDATE_ORDER: {
      let items = state.items.map(i => {
        if (action.order.id === i.id) {
          return {...action.order};
        }
        return i;
      });
      
      return {
        ...state,
        items: [...items],
      };
    }
    default:
      return state;
  }
}
