import {
    Aux,
    isNullEmptyOrWhitespace,
    IsNullOrUndefined, MoneyFormatter,
    OrganisationOrderAwningSupplyOwnMotor, PRODUCTGROUPID_CONSERVATORY,
    PRODUCTGROUPID_ROOFING_SYSTEMS
} from "../../../constants";
import {SummaryDivider} from "../../common/SummaryControls";
import React from "react";
import CustomerPriceControl from "./CustomerPriceControl";
import {sum} from "lodash/math";

const CustomerAwningPriceSummary = (props) => {
    let {awning, order, renderAsTable, classes, showPrice} = props;

    let thisByThat = (x, y) => {
        return y > 1 ? `${x} x ${y}` : x;
    };

    let addNullableCosts = (costs) => {
      let returnValue = sum(costs.filter(x => !isNullEmptyOrWhitespace(x)));      
      return returnValue > 0 ? returnValue : null;
    };
    
    let cost = awning.costs;    

    return (cost ? renderAsTable
        ?
        (<Aux>
            <CustomerPriceControl
                showPrice={showPrice}
                labelText={`${awning.productGroupDescription} - ${awning.productDescription} - ${thisByThat(awning.width, awning.projection)}`}
                discount={cost.organisationDiscount}
                retail={addNullableCosts([cost.awningFrameRetail, cost.awningSurchargeRetail,
                    cost.mountingBracketSurcharge,
                    cost.installationBracketSurchargeRetail,
                    cost.joinerRetail, cost.cradlesTotal,
                    cost.gutterCreditRetail,
                    cost.sintesiPostCreditRetail, cost.sintesiFrontPostSurchargeRetail, awning.quoteAddonCostTotal])}
                renderAsTable={renderAsTable}
                classes={classes}
            />

            {cost.installationBracketsTotal > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Installation brackets"
                    retail={cost.installationBracketsTotal}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                <div className={classes.divTd} style={{width: '66%', textAlign: 'left', fontWeight:'bold'}}>Options (to be confirmed)</div>
                <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
            </div>

            {cost.isMotorRequired && cost.motorRetail == null && OrganisationOrderAwningSupplyOwnMotor(order, awning) !== true && awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Motor"
                    value='Please call Aluxor'
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ?
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Motor"
                    value={IsNullOrUndefined(awning.motorId) ? (IsNullOrUndefined(awning.frameFeature) ? 'TBD' : 'Multi Field') : cost.motorRetail === 0 ? 'Included' : cost.motorRetail}
                    retail={cost.motorRetail > 0 ? addNullableCosts([cost.motorRetail,
                        cost.motorJoinerSurchargeRetail, cost.multiRemoteSurchargeRetail]) : undefined}
                    renderAsTable={renderAsTable}
                    classes={classes}
                /> :
                cost.motorRetail > 0 ?
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText={awning.driveMechanismDescription}
                        retail={addNullableCosts([cost.motorRetail,
                            cost.motorJoinerSurchargeRetail, cost.multiRemoteSurchargeRetail])}
                        renderAsTable={renderAsTable}
                        classes={classes}
                    /> : null}


            {!IsNullOrUndefined(cost.installationBracketOptionCode) &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={cost.installationBracketOptionDescription}
                    retail={cost.installationBracketOptionRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.frameBaseCustomColourRetail ? <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText={`Frame ${awning.frameCustomColourDescription}`}
                retail={addNullableCosts([cost.frameBaseCustomColourRetail, cost.frameCustomColourRetail])}
                renderAsTable={renderAsTable}
                classes={classes}
            /> : <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText={"Frame powder coating"}
                retail={0}
                renderAsTable={renderAsTable}
                classes={classes}
            />}

            {cost.pelmetBaseRetail && <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText={`${awning.pelmetName} ${awning.pelmetColourShortDescription}`}
                retail={cost.pelmetBaseRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />}

            {cost.pelmetBaseRetail && !cost.pelmetCustomBaseRetail &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`${awning.pelmetName} powder coating`}
                    retail={0}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />
            }

            {cost.pelmetCustomBaseRetail && <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText={`${awning.pelmetName} ${awning.pelmetCustomColourDescription}`}
                retail={addNullableCosts([cost.pelmetCustomBaseRetail, cost.pelmetCustomRetail])}
                renderAsTable={renderAsTable}
                classes={classes}
            />}
            {/*cost.pelmetCustomRetail && <CustomerPriceControl
                    discount={cost.organisationDiscount}
                    labelText={`${awning.pelmetName} ${awning.pelmetCustomColourDescription} surcharge`}
                    retail={cost.pelmetCustomRetail}
                />*/}
            {cost.flashingBaseRetail && <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Flashing"
                retail={cost.flashingBaseRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />}
            {cost.flashingCustomBaseRetail && <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText={`Flashing ${awning.flashingCustomColourDescription}`}
                retail={addNullableCosts([cost.flashingCustomBaseRetail, cost.flashingCustomRetail])}
                renderAsTable={renderAsTable}
                classes={classes}
            />}
            
            {cost.fabricSurchargeRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Fabric surcharge"
                    retail={cost.fabricSurchargeRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}
            {cost.fabricFittingRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Fabric fitting"
                    retail={cost.fabricFittingRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}


            {cost.frameFeatureAwningFrameRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={awning.frameFeatureDescription}
                    retail={addNullableCosts([cost.frameFeatureAwningFrameRetail,
                        cost.frameFeatureFabricSurchargeRetail,
                        cost.frameFeatureCrankHandleRetail])}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.joinerRetail > 0 && <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Joiner surcharge"
                retail={cost.joinerRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />}

            {cost.stabiliserPolesRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Stabilier poles"
                    retail={cost.stabiliserPolesRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.crankHandleRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`Crank handle${awning.includeAdditionalCrankHandle ? 's' : ''}`}
                    retail={cost.crankHandleRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.mocoTypeRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Automation accessory"
                    retail={cost.mocoTypeRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.ledLightingRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="LED lighting"
                    retail={cost.ledLightingRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.motorSurchargeRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Motor surcharge"
                    retail={cost.motorSurchargeRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {cost.motorSurchargeSaRetail > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Motor surcharge SA RC"
                    retail={cost.motorSurchargeSaRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {awning.automationCableId &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Automation cable"
                    retail={cost.autoCableRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {awning.includeRainCover === true &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Rain Cover"
                    retail={cost.rainCoverRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {awning.additionalImRollerCount > 0 &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Additional IM Rollers"
                    retail={cost.imRollersRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            {awning.includeWedges &&
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Wedges"
                    retail={cost.wedgesRetail}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />}

            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Quattro"
                retail={cost.quattroRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Sintesi Free Standing"
                retail={addNullableCosts([cost.sintesiFreeStandingRetail, cost.sintesiBackPostSurchargeRetail])}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="LED On Wind Break"
                retail={cost.ledOnWindBreakRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Sintesi Infill Panel"
                retail={cost.sintesiInfillPanelMaterialRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Sintesi Downlights"
                retail={cost.sintesiDownlightsRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />

            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Additional Back Posts"
                retail={cost.additionalBackPostRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Additional Side Posts"
                retail={cost.additionalSidePostRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Additional Front Posts"
                retail={cost.additionalFrontPostRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Post Lights"
                retail={cost.sintesiPostLightsRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Audio Set"
                retail={cost.sintesiAudioSetRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Heaters"
                retail={addNullableCosts([cost.sintesiHeatersRetail, cost.sintesiHeaterExtRetail])}
                renderAsTable={renderAsTable}
                classes={classes}
            />

            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Windbreak PC Surcharge"
                retail={cost.sintesiWindbreakPcSurchargeRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />

            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Sintesi installation surcharge"
                retail={cost.sintesiInstallationSurchargeRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />

            <CustomerPriceControl
                showPrice={showPrice}
                discount={cost.organisationDiscount}
                labelText="Bracket Surcharge"
                retail={cost.bracketSurchargeRetail}
                renderAsTable={renderAsTable}
                classes={classes}
            />
        </Aux>)
            :
        (<div className="pi-row" key={`${cost.awningId}_${cost.name}`}>
            <div className="pi-col-sm-12">

                <div className="pi-row" style={{paddingTop: '10px', marginBottom: '0px'}}>
                    <div className="pi-col-sm-6"><h6
                        style={{marginBottom: '0px'}}>Awning {isNullEmptyOrWhitespace(awning.fieldNumber) ? `#${awning.number}` : `#${awning.number} - Field ${awning.fieldNumber}`}</h6>
                    </div>
                    {awning.number === 1 && showPrice &&
                        <div className="pi-col-sm-6" style={{textAlign: 'right', marginBottom: '0px'}}>
                            <h6>Retail</h6></div>}
                </div>

                <div className="row">
                    <div className="col-12"><h5>Standard Awning Including Installation</h5></div>
                </div>
                
                <CustomerPriceControl
                    showPrice={showPrice}
                    labelText={`${awning.productGroupDescription} - ${awning.productDescription} - ${thisByThat(awning.width, awning.projection)}`}                    
                    discount={cost.organisationDiscount}
                    retail={addNullableCosts([cost.awningFrameRetail, cost.awningSurchargeRetail, 
                        cost.mountingBracketSurcharge, 
                        cost.installationBracketSurchargeRetail,
                        cost.joinerRetail, cost.cradlesTotal,
                        cost.gutterCreditRetail, 
                        cost.sintesiPostCreditRetail, cost.sintesiFrontPostSurchargeRetail, awning.quoteAddonCostTotal])}
                    renderAsTable={renderAsTable}
                    classes={classes}
                />

                {cost.installationBracketsTotal > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Installation brackets"
                        retail={cost.installationBracketsTotal}
                        renderAsTable={renderAsTable}
                        classes={classes}
                    />}

                {/*cost.awningSurchargeRetail > 0 &&
                    <CustomerPriceControl
                        discount={cost.organisationDiscount}
                        labelText={`${awning.productGroupDescription} - ${awning.productDescription} surcharge`}
                        retail={cost.awningSurchargeRetail}
                    />*/}

                <div className="row mt-4">
                    <div className="col-12"><h5>Options Available</h5></div>
                </div>

                {cost.isMotorRequired && cost.motorRetail == null && OrganisationOrderAwningSupplyOwnMotor(order, awning) !== true && awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Motor"
                        value='Please call Aluxor'
                        renderAsTable={renderAsTable}
                        classes={classes}
                    />}

                {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ?
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Motor"
                        value={IsNullOrUndefined(awning.motorId) ? (IsNullOrUndefined(awning.frameFeature) ? 'TBD' : 'Multi Field') : cost.motorRetail === 0 ? 'Included' : cost.motorRetail}
                        retail={cost.motorRetail > 0 ? addNullableCosts([cost.motorRetail, 
                            cost.motorJoinerSurchargeRetail, cost.multiRemoteSurchargeRetail]) : undefined}
                    /> :
                    cost.motorRetail > 0 ?
                        <CustomerPriceControl
                            showPrice={showPrice}
                            discount={cost.organisationDiscount}
                            labelText={awning.driveMechanismDescription}
                            retail={addNullableCosts([cost.motorRetail, 
                                cost.motorJoinerSurchargeRetail, cost.multiRemoteSurchargeRetail])}
                        /> : null}


                {!IsNullOrUndefined(cost.installationBracketOptionCode) &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText={cost.installationBracketOptionDescription}
                        retail={cost.installationBracketOptionRetail}
                    />}

                {cost.frameBaseCustomColourRetail ? <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`Frame ${awning.frameCustomColourDescription}`}
                    retail={addNullableCosts([cost.frameBaseCustomColourRetail, cost.frameCustomColourRetail])}
                /> : <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={"Frame powder coating"}
                    retail={0}
                />}

                
                
                
                {/*cost.frameCustomColourRetail && <CustomerPriceControl
                    discount={cost.organisationDiscount}
                    labelText={`${awning.frameCustomColourDescription} surcharge`}
                    retail={cost.frameCustomColourRetail}
                />*/}
                {cost.pelmetBaseRetail && <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`${awning.pelmetName} ${awning.pelmetColourShortDescription}`}
                    retail={cost.pelmetBaseRetail}
                />}

                {/*cost.pelmetBaseRetail && !cost.pelmetCustomBaseRetail &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText={`${awning.pelmetName} powder coating`}
                        retail={0}
                    />*/
                } 
                
                {cost.pelmetCustomBaseRetail && <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`${awning.pelmetName} ${awning.pelmetCustomColourDescription}`}
                    retail={addNullableCosts([cost.pelmetCustomBaseRetail, cost.pelmetCustomRetail])}
                />}
                {/*cost.pelmetCustomRetail && <CustomerPriceControl
                    discount={cost.organisationDiscount}
                    labelText={`${awning.pelmetName} ${awning.pelmetCustomColourDescription} surcharge`}
                    retail={cost.pelmetCustomRetail}
                />*/}
                {cost.flashingBaseRetail && <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Flashing"
                    retail={cost.flashingBaseRetail}
                />}
                {cost.flashingCustomBaseRetail && <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText={`Flashing ${awning.flashingCustomColourDescription}`}
                    retail={addNullableCosts([cost.flashingCustomBaseRetail, cost.flashingCustomRetail])}
                />}
                {/*cost.flashingCustomRetail && <CustomerPriceControl
                    discount={cost.organisationDiscount}
                    labelText="Flashing PC surcharge"
                    retail={cost.flashingCustomRetail}
                />*/}
                {cost.fabricSurchargeRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Fabric surcharge"
                        retail={cost.fabricSurchargeRetail}
                    />}
                {cost.fabricFittingRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Fabric fitting"
                        retail={cost.fabricFittingRetail}
                    />}                
                

                {cost.frameFeatureAwningFrameRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText={awning.frameFeatureDescription}
                        retail={addNullableCosts([cost.frameFeatureAwningFrameRetail, 
                            cost.frameFeatureFabricSurchargeRetail,
                            cost.frameFeatureCrankHandleRetail])}
                    />}

                {cost.joinerRetail > 0 && <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Joiner surcharge"
                    retail={cost.joinerRetail}
                />}

                {cost.stabiliserPolesRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Stabilier poles"
                        retail={cost.stabiliserPolesRetail}
                    />}

                {cost.crankHandleRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText={`Crank handle${awning.includeAdditionalCrankHandle ? 's' : ''}`}
                        retail={cost.crankHandleRetail}
                    />}

                {cost.mocoTypeRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Automation accessory"
                        retail={cost.mocoTypeRetail}
                    />}

                {cost.ledLightingRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="LED lighting"
                        retail={cost.ledLightingRetail}
                    />}

                {cost.motorSurchargeRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Motor surcharge"
                        retail={cost.motorSurchargeRetail}
                    />}

                {cost.motorSurchargeSaRetail > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Motor surcharge SA RC"
                        retail={cost.motorSurchargeSaRetail}
                    />}

                {awning.automationCableId &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Automation cable"
                        retail={cost.autoCableRetail}
                    />}

                {awning.includeRainCover === true &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Rain Cover"
                        retail={cost.rainCoverRetail}
                    />}

                {awning.additionalImRollerCount > 0 &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Additional IM Rollers"
                        retail={cost.imRollersRetail}
                    />}

                {awning.includeWedges &&
                    <CustomerPriceControl
                        showPrice={showPrice}
                        discount={cost.organisationDiscount}
                        labelText="Wedges"
                        retail={cost.wedgesRetail}
                    />}

                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Quattro"
                    retail={cost.quattroRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Sintesi Free Standing"
                    retail={addNullableCosts([cost.sintesiFreeStandingRetail, cost.sintesiBackPostSurchargeRetail])}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="LED On Wind Break"
                    retail={cost.ledOnWindBreakRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Sintesi Infill Panel"
                    retail={cost.sintesiInfillPanelMaterialRetail}
                />
                <CustomerPriceControl
                    discount={cost.organisationDiscount}
                    labelText="Sintesi Downlights"
                    retail={cost.sintesiDownlightsRetail}
                />

                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Additional Back Posts"
                    retail={cost.additionalBackPostRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Additional Side Posts"
                    retail={cost.additionalSidePostRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Additional Front Posts"
                    retail={cost.additionalFrontPostRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Post Lights"
                    retail={cost.sintesiPostLightsRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Audio Set"
                    retail={cost.sintesiAudioSetRetail}
                />
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Heaters"
                    retail={addNullableCosts([cost.sintesiHeatersRetail, cost.sintesiHeaterExtRetail])}
                />
                
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Windbreak PC Surcharge"
                    retail={cost.sintesiWindbreakPcSurchargeRetail}
                />
                
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Sintesi installation surcharge"
                    retail={cost.sintesiInstallationSurchargeRetail}
                />
                
                <CustomerPriceControl
                    showPrice={showPrice}
                    discount={cost.organisationDiscount}
                    labelText="Bracket Surcharge"
                    retail={cost.bracketSurchargeRetail}
                />                
                
            </div>
        </div>)
        : (<div className="pi-row">
                <div className="pi-col-sm-12">
                    <SummaryDivider labelText="Aluxor to confirm price"/>
                </div>
            </div>));
};

export default CustomerAwningPriceSummary;