import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import 'react-table/react-table.css'
import '../../css/divTable.css';
import {IsNullOrUndefined} from "../../constants";

class PcDataPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      resources: [],
      resourceColumns: [],
    };
    this.loadData = this.loadData.bind(this);
  }

  loadData(){
    toastr.warning('Loading data');
    apiRequest(`/api/colours/pcdata`)
      .then(x => {
        if(x.data && x.data.length) {
          let resources = x.data;
          this.setState({resources});
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    let {resources} = this.state;

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Powder Coating Lookup Data</h2>
            </div>
          </div>
        </div>

        <div className={'pi-row'}>
          <div className={'pi-col-md-12'}>
            <div className={'divTable'} style={{flexDirection: 'column'}}>
              <div className={'divThead'}>
                <div className={'divTrHead'}>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>PcRef</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Image</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Description</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Dimensions</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Surface Area</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Material</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>$</div>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>$</div>
                </div>
              </div>
              <div className={'divTbody'}>
                {resources.map(x => {
                  return (
                  <div key={x.pcRef} className={'divTr'} style={{pageBreakInside: 'avoid'}}>
                    <div className={'divTd'}>
                      <div>{x.pcRef}</div>
                    </div>
                    <div className={'divTd'}>
                      <div><img className={''} src={`/img/powder_coating_sheet/${x.pcRef}.png`} style={{width:'100px'}} /></div>
                    </div>
                    <div className={'divTd'}>{x.description}</div>
                    <div className={'divTd'}>{!IsNullOrUndefined(x.dimensionX) ? `${x.dimensionX} x ${x.dimensionY} x ${x.dimensionZ}` : ''}</div>
                    <div className={'divTd'}>{x.surfaceArea}</div>
                    <div className={'divTd'}>{x.material}</div>
                    <div className={'divTd'}>&nbsp;</div>
                    <div className={'divTd'}>&nbsp;</div>
                  </div>);
                })}
              </div>
            </div>
          </div>
        </div>

      </div>);
  }
}

PcDataPage.propTypes = {
  tags: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PcDataPage));
