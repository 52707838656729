import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import {apiDownloadReport, apiPost} from "../../utils/request";
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import {DATE_SHORT_FORMAT, IsNullOrUndefined, REPORTSERVER_BASEURL} from '../../constants';
import injectSheet from 'react-jss';
import AwningFlashingSheet from './AwningFlashingSheet';
import {toastr} from 'react-redux-toastr';
import jQuery from 'jquery';
import { Base64 } from 'js-base64';

const styles = {
  table: { display: 'flex', flexDirection: 'column'},
  tr: { display: 'flex', flexDirection: 'row'},
  td: { display: 'flex', flexDirection: 'column'},
};

class OrderFlashingSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: props.order,
      printed: false,
    };

    this.handlePrint = this.handlePrint.bind(this);
    this.printSection = React.createRef();
    this.handleEmail = this.handleEmail.bind(this);
  }

  handlePrint(e, mode = 'print') {

    if(e) {
      e.preventDefault();
    }

    let {order} = this.props;

    for(let i = 0; i<order.awnings.length; i++) {
      if(order.awnings[i].flashingType > 0) {
        let awningControl = this.refs[`awningFlashingSheet_${order.awnings[i].id}`];
        let awningFlashingState = awningControl.getCurrentState();

        apiPost(`/api/awnings/${awningFlashingState.id}/awningFlashing`, awningFlashingState.awningFlashing)
          .then(() => {
            toastr.success('Saved awning flashing information');
          })
          .catch((e) => {
            toastr.error('Error while saving awning flashing');
          });
      }
    }

    let container = jQuery(`<div></div>`);

    jQuery(this.printSection.current).clone().appendTo(container);

    container
      .css('font-size', '12px')
      .css('padding-right', '20px')
      .css('margin-top', '5px')
      .css('padding-top', '25px')
      .css('font-family', "'Open Sans', Arial, sans-serif")
      .css('line-height', "1.6em");

    container.find('h4').css('font-size', '12px');
    container.find('h5').css('font-size', '11px');
    container.find('h6').css('font-size', '10px');
    container.find('.Select').each((i,el) => {
      jQuery(el)
        .parent()
        .removeClass('form-group')
        .addClass('pi-row')
        .html(`<div class="pi-col-sm-4"><b>${jQuery(el).siblings('label').text()}</b></div><div class="pi-col-sm-8">${jQuery(el).find('.Select-value-label').text()}</div>`);
    });
    container.find('.pi-row:first-child').css('margin-top', '0px');
    container.find('.print-hide').remove();
    container.find('input').prop("disabled", false);

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": false,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf(container[0], {
      margin: [10, 10, 20, 10],
      filename: `Order_${order.id}_flashing.pdf`,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    });*/
    let fileName = `Order_${order.id}_flashing.pdf`;

    if(mode==='print'){
      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName);
    } else {
      let _this = this;
      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName, blob => {

        let reader = new FileReader();
        reader.addEventListener("load", function () {
          toastr.info('Sending email...');
          apiPost(`/api/orders/${order.id}/email/flashingsheet`, {
            content: reader.result,
            fileName: fileName,
            sequenceNumber: order.sequenceNumber,
          }).then(x => {
            if (x.data && x.data.ok) {
              toastr.success('Email sent');
              _this.setState({order: {...order, flashingEmailSentDate: moment()}});
            } else {
              toastr.error('Unable to send email');
            }
          });

        }, false);
        reader.readAsDataURL(blob);
      });
    }



    if(this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  handleEmail(e){
    this.handlePrint(e, 'email');
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.order || nextProps.order.id != this.state.order.id) {
      this.setState({order: nextProps.order});
    }
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {classes, print} = this.props;
    let {order, printed} = this.state;

    if(!order){
      return null;
    }

    let awnings = order.awnings.filter(x => x.flashingType > 0);

    if(print && !printed) {
      setTimeout(this.handlePrint, 500);
    }

    return (
      <div>
        
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>
            <div className="pi-section">

              <div className="pi-row">
                <div className="pi-col-md-8">
                  <button onClick={this.handlePrint}
                          className="btn pi-btn-base pi-btn-icon-big"
                          style={{marginRight: '10px'}}
                  >
                    <i className="fa fa-print"></i>&nbsp;Print</button>

                  <button onClick={this.handleEmail}
                                        className="btn pi-btn-base pi-btn-icon-big"
                                        style={{marginRight: '10px'}}
                          title={`Email ${IsNullOrUndefined(order.flashingEmailSentDate) ? 'not yet sent' : 'sent ' + moment(order.flashingEmailSentDate).format(DATE_SHORT_FORMAT)}`}
                  >
                    <i className="fa fa-envelope"/>&nbsp;Email</button>

                  <button className="btn pi-btn  pi-btn-icon-big"
                          onClick={this.props.toggleOpen}><i className="fa fa-times"></i>&nbsp;Close</button>
                </div>
              </div>

              <div ref={this.printSection}>

                {awnings.map((awning, i) => {
                  return (
                    <div key={awning.id}>
                      <AwningFlashingSheet
                        colorBondColours={this.props.colorBondColours}
                        order={order}
                        awning={awning}
                        ref={`awningFlashingSheet_${awning.id}`}
                      />
                      { (i + 1) < awnings.length && <div className={'html2pdf__page-break'}></div>}
                    </div>);
                })}

              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"></i>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>      
    );
  }
}
OrderFlashingSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  colorBondColours: PropTypes.array.isRequired,
  print: PropTypes.bool.isRequired,
};

export default injectSheet(styles)(OrderFlashingSheet);