import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {loadSiteSettings} from "../../actions/lookupActions";
import {apiPost} from "../../utils/request";
import {loadOrganisations} from "../../actions/organisationActions";
import {loadCurrentUser} from "../../actions/userActions";

class SiteSettingsPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {      
      siteSettings: {...props.siteSettings},
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);    
    this.handleSave = this.handleSave.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  handleFieldChange(e){
    let {value, id, type, checked} = e.target;

    let siteSettings = {...this.state.siteSettings};
    siteSettings[id] = type === 'checkbox' ? checked : value;

    this.setState({siteSettings});
  }

  handleSave() {
    let {siteSettings} = this.state;

    toastr.warning('Saving...');

    let post = [];
    
    for (let prop in siteSettings){
      post.push({ settingKey: prop, settingValue: siteSettings[prop]});
    }
    
    apiPost(`/api/siteSettings`, post)
        .then(x => {
          toastr.success('Saved');
          this.props.dispatch(loadSiteSettings());
        });
  }

  loadData(){    
    this.setState({siteSettings: {...this.props.siteSettings}}, 
        ()=> this.props.dispatch(loadSiteSettings()));    
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {    
    if(JSON.stringify(prevProps.siteSettings) !== JSON.stringify(this.props.siteSettings)){      
     this.setState({siteSettings: {...this.props.siteSettings}}); 
    }    
  }

  render() {

    let {siteSettings} = this.state;
    
    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Site Settings</h2>
            </div>
          </div>

          <div className="pi-row">            
            <div className={'pi-col-md-4 pi-col-md-offset-8 text-right'} style={{marginBottom: '5px'}}>
              <button                  
                  className="btn pi-btn-base pi-btn-icon-big"
                  onClick={this.handleSave}>
                <i className="fa fa-save"></i>&nbsp;Save
              </button>
              <button
                  style={{marginLeft: '10px'}}
                  className="btn pi-btn pi-btn-icon-big"
                  onClick={(e) => {
                    e.preventDefault();
                    this.loadData();
                  }}>
                <i className="fa fa-reload"></i>&nbsp;Reset
              </button>
            </div>
          </div>
          
          <div className={'pi-row mt-5'}>
            <div className={'pi-col-12'}>
          
              <form>
                <div className="mb-3">
                  <label htmlFor="AluxorUpdatesMessage">Aluxor Updates Message</label>
                  <textarea className="form-control is-invalid" id="AluxorUpdatesMessage"
                            onChange={this.handleFieldChange}
                            required value={siteSettings.AluxorUpdatesMessage}></textarea>              
                </div>
                <div className="mb-3">
                  <label htmlFor="ProductPriceListMessage">Product Price List Message</label>
                  <textarea className="form-control is-invalid" id="ProductPriceListMessage" 
                            onChange={this.handleFieldChange}
                            required value={siteSettings.ProductPriceListMessage}></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="ProductSintesiPriceListMessage">Product Sintesi Price List Message</label>
                  <textarea className="form-control is-invalid" id="ProductSintesiPriceListMessage"
                            onChange={this.handleFieldChange}
                            required value={siteSettings.ProductSintesiPriceListMessage}></textarea>
                </div>
              </form>
            </div>
          </div>
          
        </div>
      </div>);
  }
}

SiteSettingsPage.propTypes = {
  user: PropTypes.object.isRequired,  
};

function mapStateToProps(state, ownProps) {  
  return {
    user: state.user,    
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSettingsPage));
