import React from 'react';
import moment from 'moment';
import {DATE_SHORT_FORMAT, isNullEmptyOrWhitespace} from '../../constants';
import TextViewControl from '../common/TextViewControl';

const CustomerDetailsView = (props) => {
  let {order, showSequenceNumber} = props;

  let returnVal = (<div className="pi-row" style={{marginBottom: '15px'}}>
    <div className="pi-col-sm-6">
      <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
        <div className="pi-row">
          <div className="pi-col-sm-12"><h6>Customer Details</h6></div>
        </div>
          <TextViewControl labelText={'Customer'} value={order.organisationDescription} />
          <TextViewControl labelText={'Email'} value={order.organisationEmail} />
          <TextViewControl labelText={'Contact No.'} value={order.organisationPhone} />
          <TextViewControl labelText={'Delivery Address'} value={order.organisationAddressComplete} />
        </div>
      </div>
    <div className="pi-col-sm-6">
      <div className="pi-section" style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px'}}>
        <div className="pi-row">
          <div className="pi-col-sm-12"><h6>&nbsp;</h6></div>
        </div>
        <TextViewControl labelText={'Order Date'} value={moment(order.orderDisplayDate).format(DATE_SHORT_FORMAT)} />
        <TextViewControl labelText={'Order Ref'} value={order.customerReference} />
        { !isNullEmptyOrWhitespace(order.sequenceNumber) && showSequenceNumber === true && <TextViewControl labelText={'PN'} value={order.sequenceNumber} /> }
        <TextViewControl labelText={'Created By'} value={order.createdByPersonName} />
        { !order.isSparesOrder && <TextViewControl labelText={'Number in Order'} value={order.awnings.length} /> }
      </div>
    </div>
  </div>);

  return returnVal;
};

export default CustomerDetailsView;