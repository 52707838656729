import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const SelectControlV2 = (props) => {
  let {labelText, name, validationMessages, creatable, clearable, backspaceRemoves, deleteRemoves, toolTip} = props;
  let isValid = !validationMessages || (validationMessages && (validationMessages[`${name}`] == '' || validationMessages[`${name}`] == undefined));

  const SelectComponent = creatable
    ? Select.Creatable
    : Select;  

  return (
    <div className="form-group">
      {labelText &&
          <label htmlFor={name}>{labelText}
            {toolTip && <i className={"ml-2 text-info fa fa-lg fa-info-circle"} data-toggle="tooltip" title={toolTip} /> }
          </label>
      }
      <SelectComponent
        {...props}
        style={{borderColor: isValid ? '' : 'red'}}
        clearable={clearable == undefined ? false : clearable}        
        deleteRemoves={deleteRemoves == undefined ? false : deleteRemoves}
        backspaceRemoves={backspaceRemoves == undefined ? false : backspaceRemoves}        
      />
      { !isValid && <div style={{color: 'red'}}>{validationMessages[`${name}`]}</div> }
    </div>
  );
};

SelectControlV2.propTypes = {
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validationMessages: PropTypes.object,
};

export default SelectControlV2;