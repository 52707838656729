import React from "react";
import PropTypes from 'prop-types';
import {CalcPriceView} from "./CalcPriceView";
import {IsNullOrUndefined} from "../../constants";
import {FabricRecoverAwningViewWorksheetData} from "./FabricRecoverAwningViewWorksheetData";

const FabricRecoverAwningCosts = (props) => {

    let {awning, fabricBrands, baseCosts} = props;
    let workSheetData = FabricRecoverAwningViewWorksheetData({awning, fabricBrands, baseCosts});
    
    
    return IsNullOrUndefined(workSheetData) ? null : (<div className={'row mb-4 mt-4'}>
        <div className='col-6'>&nbsp;</div>        
        <div className='col-3' style={{border: '2px solid black', paddingTop: '10px', paddingBottom: '10px'}}>

            <h3>Costs</h3>

            <CalcPriceView labelText={'Base cost'} value={workSheetData.baseCost} />
            <CalcPriceView labelText={'Multiplier'} value={workSheetData.baseCostMultiplier} isMoney={false} />
            <CalcPriceView labelText={'Surcharge'} value={workSheetData.surchargeCost} />
            <CalcPriceView labelText={'Wholesale'} value={workSheetData.wholesaleCost} />
            <CalcPriceView labelText={'Retail'} value={workSheetData.retailCost} />
            <CalcPriceView labelText={'Labour'} value={workSheetData.labourCost} />
            <CalcPriceView labelText={'Sub total'} value={workSheetData.subTotal} />
            <CalcPriceView labelText={'GST'} value={workSheetData.gst} />
            <CalcPriceView labelText={'Retail total'} value={workSheetData.retailTotal} />

        </div>        
        <div className='col-3' style={{borderRight: '2px solid black', borderBottom: '2px solid black', borderTop: '2px solid black', paddingTop: '10px', paddingBottom: '10px'}}>
            <h3>Recovery % Guide</h3>
            <CalcPriceView labelText={'EC, S1'} value={'30%'} isMoney={false} />
            <CalcPriceView labelText={'S3, TG'} value={'32.5%'} isMoney={false} />
            <CalcPriceView labelText={'OL, DS'} value={'35%'} isMoney={false} />
            <CalcPriceView labelText={'SD'} value={'40%'} isMoney={false} />
            <CalcPriceView labelText={'VS'} value={'35%'} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />
            <CalcPriceView labelText={''} value={''} isMoney={false} />

        </div>
    </div>);
}

FabricRecoverAwningCosts.propTypes = {
    fabricBrands: PropTypes.array.isRequired,
    baseCosts: PropTypes.array.isRequired,
    awning: PropTypes.object.isRequired,
};

export  default FabricRecoverAwningCosts;