import React from 'react';
import PropTypes from 'prop-types';
import {RadioGroup, Radio} from '../common/RadioControl';
import {IsNullOrUndefined} from "../../constants";

const RadioItemImage = (props) => {
  let url = (props.imageUrlSelector && props.objectValue) ? props.imageUrlSelector(props.objectValue) : null;
  return url ? <div><img src={url} style={{paddingLeft: '10px', paddingRight: '10px', width: '100px', marginLeft: '-10px'}}/></div> : null;
}

const RadioControlGroup = (props) => {
  let {labelText, name, validationMessages, value, onChange,
    options, valueKey, labelKey, onClearSelection,
    visible, valid, style, imageUrlSelector, clearSelectionText, toolTip, validationName, displayAsList} = props;
  
  validationName = IsNullOrUndefined(validationName) ?  name.split('_')[0] : validationName;

  let isValid = !validationMessages || (validationMessages && (validationMessages[`${validationName}`] == '' || validationMessages[`${validationName}`] == undefined));
  if(valid !== undefined && !valid){
    isValid = false;
  }

  if(valueKey == undefined){
    valueKey = 'value';
  }
  if(labelKey == undefined){
    labelKey = 'label';
  }

  if(visible == undefined){
    visible= true;
  }
  if(clearSelectionText === undefined){
    clearSelectionText = 'Clear selection';
  }

  return (options && options.length > 0 && visible) ? (
    <div className='pi-row' style={style == undefined ? {marginBottom: '20px', marginTop: '20px'} : {...style, marginBottom: '20px', marginTop: '20px'}}>
      <div className='pi-col-12'>
        <h6 style={{color: isValid ? '' : 'red'}}>{labelText} {onClearSelection &&
          <a href='#' onClick={onClearSelection}>{clearSelectionText}</a>
        }
          {/*!isValid && <span style={{color: 'red'}}>{validationMessages[`${validationName}`]}</span> */}
          {toolTip &&
            <i className={"ml-2 text-info fa fa-lg fa-info-circle"} data-toggle="tooltip" title={toolTip} /> }
        </h6>
        <RadioGroup
          name={name}
          selectedValue={value}
          onChange={onChange}
          style={{display: displayAsList ? '' : 'flex', flexWrap: 'wrap'}}
        >
          {options.map(x => {
            return (<div key={`${name}_${x[valueKey]}`} className={'rad-item'} style={{cursor: 'pointer'}} onClick={(e) => {
              if(e.target.type !== "radio"){
                e.preventDefault();
              }
              onChange({simpleValue:  x[valueKey], objectValue: x},{
                target: {
                  name,
                  value: x[valueKey],
                }});
            }}>
                <div>
                  <Radio value={x[valueKey]} objectValue={x}/>
                  <span className={'rad-item-label'}>{x[labelKey]}</span>
                </div>
              <RadioItemImage objectValue={x}
                              imageUrlSelector={imageUrlSelector} />
            </div>);
          })}
        </RadioGroup>
      </div>

    </div>
  ) : null;
};

RadioControlGroup.propTypes = {
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validationMessages: PropTypes.object,
};

export default RadioControlGroup;