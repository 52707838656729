import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {flatten, uniqBy, every, isEmpty, xor, sortBy} from 'lodash';
import Select from 'react-select';
import querySearch from "stringquery";
import { ModalBody, ModalFooter, Modal } from 'reactstrap';
import '../../css/divTable.css';


class VideoResourcesPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      resources: [],
      resourceColumns: [],
      tags: [],
      selectedTags: [],
      filteredResources: [],
      selectedResource: null,
    };
    this.loadData = this.loadData.bind(this);
    this.viewResource = this.viewResource.bind(this);
    this.toggleResource = this.toggleResource.bind(this);
  }

  toggleResource(){
    this.setState({selectedResource: null});
  }

  viewResource(e, resource){
    e.preventDefault();
    if(this.state.selectedResource && resource.id === this.state.selectedResource.id){
      this.setState({selectedResource: null});
    } else {
      this.setState({selectedResource: resource});
    }
  }

  loadData(){
    //TODO: List of tags needs to be restricted to organisation

    toastr.warning('Loading videos');
    apiRequest(`/api/resources?type=1`)
      .then(x => {
        if(x.data && x.data.length) {
          let resources = x.data;
          let tags = sortBy(uniqBy(flatten(resources.map(r => r.tags)), r => r.description.toLowerCase()), y => y.description.toLowerCase());
          let selectedTags =  tags.filter(t => this.props.tags.findIndex(a => a.toLowerCase() === t.description.toLowerCase()) >= 0);
          this.setState({resources, tags, selectedTags});
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEmpty(xor(prevProps.tags, this.props.tags))) {
      let selectedTags = this.state.tags.filter(t => this.props.tags.findIndex(a => a.toLowerCase() === t.description.toLowerCase()) >= 0);
      this.setState({selectedTags});
    }
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    let {tags, selectedTags, resources} = this.state;

    let filteredResources = selectedTags.length === 0 ? resources :
      resources.filter(x =>
        every(selectedTags, t => x.tags.findIndex(rt =>  rt.id === t.id) >= 0));

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Product Videos</h2>
            </div>
          </div>
        </div>

        <div className="pi-row">
          <div className={'pi-col-md-1'}>
            <label>Tags</label>
          </div>
          <div className={'pi-col-md-8'}>
            <Select
              labelText="Tags"
              options={tags}
              valueKey={'id'}
              labelKey={'description'}
              onChange={(val) => {
                this.setState({selectedTags: val})
              }}
              value={selectedTags}
              clearable
              backspaceRemoves
              multi
            />
          </div>
        </div>

        <div className={'pi-row'}>
          <div className={'pi-col-md-12'}>
            <div className={'divTable'} style={{flexDirection: 'column'}}>
              <div className={'divThead'}>
                <div className={'divTrHead'}>
                  <div className={'divTh'} style={{paddingLeft: '15px'}}>Title</div>
                </div>
              </div>
              <div className={'divTbody'}>
                {filteredResources.map(x => {
                  let selected = this.state.selectedResource && this.state.selectedResource.id === x.id;

                  return (<div key={x.id} className={'divTr'}>
                    <div className={'divTd'}>
                      <div style={{marginBottom: '10px'}}><a href='#' onClick={(e) => this.viewResource(e, x)}><i className={selected ? 'fa fa-angle-down' : 'fa fa-angle-right'} />&nbsp;{x.title}</a></div>
                      { selected &&  <div>
                          <iframe
                              width="800"
                              height="600"
                              src={`${x.resourceUri}?autoplay=0&fs=1`}
                              frameBorder="0"
                              allow="autoplay; encrypted-media"
                              allowFullScreen />
                      </div> }
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </div>


        {/*<div className="pi-row">
          <div className={'pi-col-md-12'}>
            <ReactTable
              sortable={false}
              filterable={false}
              showPagination={false}
              data={filteredResources}
              columns={resourceColumns}
              defaultPageSize={250}
              minRows={6}
            />
          </div>
        </div> */}


      </div>);
  }
}

VideoResourcesPage.propTypes = {
  tags: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {

  let queryTags = querySearch(ownProps.location.search);
  let tags = Array.isArray(queryTags.tags) ? queryTags.tags : queryTags.tags ? [queryTags.tags] : [];

  return {
    tags
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoResourcesPage));
