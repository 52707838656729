import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import {
  Aux,
  AwningBracketTotal, fieldWidthAddition, headBoxWith,
  IsMotorRequiredOnField,
  IsNullOrUndefined, isScSz, isSDorSM, isSDSM,
  isSteerAround,
  PRODUCTID_SDM,
} from "../../constants";

const styles = {
  wrapper: {
    margin: '0 auto',
    position: 'relative',
    borderTop: '1px solid black',
  },
  fixedWrapper: {
    position: 'absolute',
    border: '1px solid red',
    backgroundColor: 'red',
  },
  fixedWrapperItem: {
    position: 'fixed',
  }
};

const awning = {
  bracketTotal: 0,
  width: 0,
  projection: 0,
  productDescription: '',
  fieldConfiguration: {
    numberOfFields: 0,
    sideOfOperation: null,
  },
  sideOfOperation: "Left",
};
const armWidth = 52;
const headboxHeight = 100;
const representedOutsidespacing = 400;

class DiagramST extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      arms: [],
      awning: {...awning},
      containerWidth: props.width,
      scale: 1,
      unsupported: true,
      measures: [],
    };

    this.setupAwning = this.setupAwning.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.awning && JSON.stringify(this.props.awning) !== JSON.stringify(prevProps.awning)) {
      this.setupAwning();
    }
  }

  componentDidMount() {
    this.setupAwning();
  }

  setupAwning() {
    let {awning} = this.props;
    let {containerWidth} = this.state;

    if (!awning) {
      return;
    }
    let outsideLabel = headBoxWith(awning) / 2;
    let {fieldConfiguration, width} = awning;
    let isSdSm = true;

    let bracketTotal = AwningBracketTotal(awning).bracketTotal;

    if (IsNullOrUndefined(fieldConfiguration)) {
      width = awning.width + (2 * representedOutsidespacing);
      let total = representedOutsidespacing;

      let scale = containerWidth / width;
      let arms = [
        {mm: representedOutsidespacing, motor: {
            motorLeft: false,
            motorRight: awning.sideOfOperation === "Left",
            rightOffset: false,
          }},
        {mm: representedOutsidespacing + awning.width, label: awning.width, motor: {
            motorLeft: awning.sideOfOperation === "Right",
            motorRight: false,
            rightOffset: false,
          }}
      ];
      
      let measures = [
        {mm: 100, label: outsideLabel, start: 0, end: representedOutsidespacing},
        {mm: representedOutsidespacing + (awning.width / 2), label: awning.width, start: representedOutsidespacing, end: representedOutsidespacing + awning.width},
        {mm: width - 275, label: outsideLabel, start: width - representedOutsidespacing, end: width}
      ];

      this.setState({awning: {...awning, bracketTotal}, arms, scale, unsupported: (arms.length === 0), measures});

    } else {

      //if this is also SM, then need to do with addition

      let totalWidth = fieldConfiguration.fields.reduce((t, c) => {
        return fieldWidthAddition(c.width, c, fieldConfiguration, awning) + t;
      }, 0);

      width = totalWidth + (2 * representedOutsidespacing);

      //If right and 1 motor put on second one, if left and 1 motor then first, otherwise both
      let scale = containerWidth / width;
      let arms = [
        {mm: representedOutsidespacing, label: outsideLabel, motor: IsMotorRequiredOnField(null, fieldConfiguration.fields[0])},
        {mm: width - representedOutsidespacing, label: outsideLabel, motor: IsMotorRequiredOnField(fieldConfiguration.fields[fieldConfiguration.fields.length-1], null)}
      ];

      let total = representedOutsidespacing;

      let hasAdditionalArm = isSdSm || isScSz(awning);

      for (let i = 0; i < fieldConfiguration.fields.length - 1; i++) {
        let field = fieldConfiguration.fields[i];
        let nextField = fieldConfiguration.fields.length - 1 >= i+1 ? fieldConfiguration.fields[i+1] : null;
        let fieldWidth = fieldWidthAddition(field.width, field, fieldConfiguration, awning);
        arms.push({mm: total + fieldWidth, label: fieldWidth, motor: IsMotorRequiredOnField(field, nextField, hasAdditionalArm)});
        //Each field requires two arms, the motor for the second arm need to come from the subsequent field
        if(hasAdditionalArm) {
          let fieldWidthNext = fieldWidthAddition(nextField.width, nextField, fieldConfiguration, awning);
          arms.push({
            mm: total + fieldWidth + 100,
            label: fieldWidthNext,
            motor: null,
          });
        }
        total += fieldWidth;
      }

      total = representedOutsidespacing;

      let measures = [{mm: 100, label: `${outsideLabel}mm`, start: 0, end: representedOutsidespacing}, {mm: width - 275, label: `${outsideLabel}mm`, start: width - representedOutsidespacing, end: width}];
      for (let i = 0; i < fieldConfiguration.fields.length; i++) {
        let start = total;
        let field = fieldConfiguration.fields[i];
        let fieldWidth = fieldWidthAddition(field.width, field, fieldConfiguration, awning);

        let end = total + fieldWidth;

        measures.push({mm: total + (fieldWidth / 2), label: `${fieldWidth}mm`, start, end, isArm: true });
        total += fieldWidth;
      }

      this.setState({awning: {...awning, bracketTotal}, arms, scale, unsupported: (arms.length === 0), measures});
    }
  }

  render() {
    let {arms, containerWidth, scale, unsupported, awning, measures} = this.state;
    let {classes} = this.props;

    const outsideFieldSpacing = headBoxWith(awning) / 2;

    let totalWidth = awning && awning.fieldConfiguration ? awning.fieldConfiguration.fields ? awning.fieldConfiguration.fields.reduce((t, c) => {
      return fieldWidthAddition(c.width, c, awning.fieldConfiguration, awning) + t;
    }, 0) + (2 * outsideFieldSpacing) : 0 : awning.width + (2 * outsideFieldSpacing);

    let projection = 175;
    //let projection = (awning.projection * scale);

    //console.log(measures);
    
    return ((unsupported || awning.width <= 0) ? <div style={{textAlign: 'center', color: 'red'}}>
          {awning.width > 0 && <h4>Diagram not available for this configuration, please contact Aluxor</h4> }
      </div>
      :
      <Aux>
        <div className="row">
          <div className={'col-12'}>
            <h4>Awning Spacing Diagram</h4>
          </div>
        </div>
        <div className={classes.wrapper} style={{
          width: containerWidth, height: projection + 10, 
          borderLeft: '0px', borderRight: '0px', borderBottom: '0px'
        }}>

          <div style={{
            marginTop: '-0px',
            marginLeft: '-0px',
            fontSize: '11px',
            width: containerWidth,
            height: (headboxHeight * scale),
            border: '1px solid black'
          }}>&nbsp;</div>


          {arms.map((x, i) => (<div key={i}
                                    style={{
                                      top: (headboxHeight * scale),
                                      width: (armWidth * scale),
                                      height: projection,
                                      backgroundColor: 'green',
                                      left: (((x.mm - (i === 0 ? 0 : armWidth)) * scale))
                                    }}
                                    className={classes.fixedWrapper}
          >
            {(x.motor && x.motor.motorLeft) ? <div style={{ backgroundColor: '#f5b83c', paddingLeft:'4px',
            paddingTop: '0px', marginLeft: '-24px', width: '20px', height: '20px', fontWeight: 'bolder'}}>M</div> : null}

            {(x.motor && x.motor.motorRight) ? <div style={{ backgroundColor: '#f5b83c', paddingLeft:'4px',
              paddingTop: '0px', marginLeft: x.motor.rightOffset ? (armWidth * scale) + 10 : 10, width: '20px', height: '20px', fontWeight: 'bolder', marginTop: x.motor.motorRight && x.motor.motorLeft ? '-20px' : '0px' }}>M</div> : null}

            {/*<div style={{*/}
            {/*  marginTop: x.motor && (x.motor.motorRight || x.motor.motorLeft) ? 13 : 33,*/}
            {/*  marginLeft: '-13px',*/}
            {/*  fontSize: '10px',*/}
            {/*  fontWeight: 'bold'*/}
            {/*}}>A*/}
            {/*</div> */}
            
          </div>))}

          <div style={{
            top: projection + 20,
            width: containerWidth,
            height: '1px',
            border: '1px solid black',
            borderLeft: '0px',
            borderRight: '0px',
            borderBottom: '0px',
            backgroundColor: 'black',
          }}
               className={classes.fixedWrapper}
          >&nbsp;</div>

          {measures.map((x, i) => (<div key={`ms_${i}`}
                                        className={classes.fixedWrapper}
                                        style={{
                                          top: projection + 20,
                                          left: (x.start * scale),
                                          backgroundColor: 'black',
                                          border: '1px',
                                          width: '2px',
                                          height: '10px',
                                        }} />)) }

          {measures.map((x, i) => (<div key={`me_${i}`}
                                        className={classes.fixedWrapper}
                                        style={{
                                          top: projection + 20,
                                          left: (x.end * scale),
                                          backgroundColor: 'black',
                                          border: '1px',
                                          width: '2px',
                                          height: '10px',
                                        }} />)) }

          {measures.map((x, i) => (<div key={`m_${i}`}
                                        className={classes.fixedWrapper}
                                        style={{
                                          top: projection + 32,
                                          left: (x.mm * scale) - 3,
                                          fontSize: '11px',
                                          fontWeight: 'bold',
                                          backgroundColor: 'white',
                                          border: '0px',
                                        }}>{x.label}</div>)) }

        </div>

        {isSteerAround(awning) ? (<Aux>          
          <div style={{textAlign: 'center', marginTop: '50px', marginBottom: '20px'}}>
            Total system width {totalWidth}mm
          </div>
          <div style={{textAlign: 'center', marginBottom: '60px'}}>
            Steer around angle diagram prepared separately
          </div>
        </Aux>) : (
            <Aux>
              <div style={{textAlign: 'center', marginTop: '50px'}}>
                Width measurement to outside of guiderails/posts
              </div>
              <div style={{textAlign: 'center', marginTop: '4px', marginBottom: '20px'}}>
          Total system width {totalWidth}mm{awning.projection > 0 ? ` x finished projection ${awning.projection}mm` : ''}
            </div></Aux>)}        
      </Aux>);
  }
}

DiagramST.propTypes = {
  awning: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  title: PropTypes.string,
};

export default injectSheet(styles)(DiagramST);