import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import RadioControlGroup from '../common/RadioControlGroup';
import {
  COLOUR_STANDARD_CUSTOM_ID,
  isCustomerCollect,
  isNullEmptyOrWhitespace,
  IsNullOrUndefined
} from '../../constants';
import {union} from 'lodash';
import {apiRequest} from "../../utils/request";

class ColourSelectorControl extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      standardColours: props.standardColours ? props.standardColours : [],
      customColours: props.customColours ? props.customColours : [],
      selectedStandardColourId: props.selectedStandardColourId ? props.selectedStandardColourId : null,
      selectedCustomColourId: props.selectedCustomColourId ? props.selectedCustomColourId : null,
      colourType: null,
      singleColourSelection: isNullEmptyOrWhitespace(props.singleColourSelection) ? false : props.singleColourSelection,
      selectedSingleColour: null,
      colorBondColour: null,
    }

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onStateChanged = this.onStateChanged.bind(this);
    this.getSingleColourListData = this.getSingleColourListData.bind(this);
    this.handleSingleColourSelect = this.handleSingleColourSelect.bind(this);
    this.fetchColorBondColour = this.fetchColorBondColour.bind(this);
  }

  onStateChanged() {
    let {selectedStandardColourId, selectedCustomColourId} = this.state;
    this.fetchColorBondColour();
    this.props.onChange({selectedStandardColourId, selectedCustomColourId});
  }
  
  fetchColorBondColour() {    
    let {selectedStandardColourId, selectedCustomColourId} = this.state;
    let {colorBondColourLookup} = this.props;    

    if (colorBondColourLookup && (!IsNullOrUndefined(selectedStandardColourId) || !IsNullOrUndefined(selectedCustomColourId))) {
      apiRequest(`/api/colours/colorbondbycolourandpc?colourId=${selectedStandardColourId}&powderCoatColourId=${selectedCustomColourId}`)
          .then(x => {
            let data = x.data ? x.error ? null : x.data : null;
            this.setState({colorBondColour: data});
          });
    } else {
      this.setState({colorBondColour: null});
    }
  }

  handleSelectChange(field, val){
    let state = this.state;    
    
    state[`${field}`] = val;

    if(field == 'selectedStandardColourId' && val != COLOUR_STANDARD_CUSTOM_ID){
      state.selectedCustomColourId = null;
    }
    if(field == 'selectedStandardColourId' && val == COLOUR_STANDARD_CUSTOM_ID){
      state.colourType = 'custom';
    }

    this.setState(state, this.onStateChanged);
  }

  handleSingleColourSelect(val){

    let state = {...this.state};
    let {frameStandardColourId, frameCustomColourId} = this.props;

    if(isNullEmptyOrWhitespace(val)){
      state.selectedCustomColourId = null;
      state.selectedStandardColourId = null;
      state.colourType = null;
    } else if(val.type === 'custom'){
      state.selectedCustomColourId = val.value;
      state.selectedStandardColourId = COLOUR_STANDARD_CUSTOM_ID;
      state.colourType = 'custom';
    } else if(val.type === 'standard'){
      state.selectedCustomColourId = null;
      state.selectedStandardColourId = val.value;
      state.colourType = 'standard';
    } else if(val.type === 'frame'){
      state.selectedCustomColourId = frameCustomColourId;
      state.selectedStandardColourId = frameStandardColourId;
      state.colourType = 'frame';

      let singleColourList = this.getSingleColourListData();
      let foundColour = singleColourList
          .find(x => x.type===(IsNullOrUndefined(frameCustomColourId)?"standard":"custom") 
                  && x.value===(IsNullOrUndefined(frameCustomColourId)?frameStandardColourId: frameCustomColourId));
      val = foundColour;
    }    
    
    state.selectedSingleColour = val;

    
    
    this.setState(state, this.onStateChanged);

  }

  componentDidUpdate(prevProps, prevState) {
    if((prevState.selectedStandardColourId !== this.state.selectedStandardColourId ||
        prevState.selectedCustomColourId !== this.state.selectedCustomColourId) && this.props.colorBondColourLookup){
      this.fetchColorBondColour();
    }
    
    if((prevState.selectedStandardColourId != this.state.selectedStandardColourId ||
        prevState.selectedCustomColourId != this.state.selectedCustomColourId) && prevState.selectedStandardColourId){
      this.onStateChanged()
    }
    if(prevProps.singleColourSelection !== this.props.singleColourSelection){
      this.setState({
        singleColourSelection: this.props.singleColourSelection,
        selectedSingleColour: this.props.singleColourSelection ? this.state.selectedSingleColour : null,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let state = {
      standardColours: nextProps.standardColours,
      customColours: nextProps.customColours,
      selectedStandardColourId: this.state.colourType == 'frame' ? nextProps.frameStandardColourId : nextProps.selectedStandardColourId,
      selectedCustomColourId: this.state.colourType == 'frame' ? nextProps.frameCustomColourId : nextProps.selectedCustomColourId,
      colourType: (((nextProps.selectedStandardColourId == nextProps.frameStandardColourId &&
                    nextProps.selectedCustomColourId == nextProps.frameCustomColourId) &&
                  (nextProps.frameStandardColourId || nextProps.frameCustomColourId)) || this.state.colourType == 'frame')
        ? 'frame' :
        nextProps.selectedStandardColourId ? (nextProps.selectedStandardColourId == COLOUR_STANDARD_CUSTOM_ID ? 'custom' : 'standard') : this.state.colourType,
    };

    if(this.state.colourType == 'frame' && (nextProps.frameStandardColourId != this.state.selectedStandardColourId ||
        nextProps.frameCustomColourId != this.state.selectedCustomColourId)){      
      setTimeout(() => this.onStateChanged(), 500);
    }

    this.setState(state);
  }

  getSingleColourListData() {
    let {standardColours, customColours} = this.state;

    let returnValue = union(standardColours.map(x => {
        return {...x, label: x.colourDescription, value: x.id, type: 'standard'};
      }),
      customColours.map(x => {
        return {...x, type: 'custom'};
      }));

    return returnValue;
  }
  
  componentDidMount() {
    this.fetchColorBondColour();
  }

  render(){
    let {validationMessages, name, awningId, frameStandardColourId, frameCustomColourId, linkText, onLink, onlyFrameColour, showSameAsFrame} = this.props;
    let isValid = !validationMessages || (validationMessages && (validationMessages[`${name}`] == '' || validationMessages[`${name}`] == undefined));
    let {customColours, selectedCustomColourId, singleColourSelection, selectedSingleColour, standardColours, selectedStandardColourId, colorBondColour} = this.state;    

    let singleColourList = null;

    if(singleColourSelection) {
      singleColourList = this.getSingleColourListData();

      if (IsNullOrUndefined(selectedSingleColour)) {
        if (!IsNullOrUndefined(selectedCustomColourId)) {
          let c = singleColourList.find(y => y.value === selectedCustomColourId && y.type === 'custom');
          selectedSingleColour = c;
        } else if (!IsNullOrUndefined(selectedStandardColourId)) {
          let s = singleColourList.find(y => y.value === selectedStandardColourId && y.type === 'standard');
          selectedSingleColour = s;
        }
      }
    }

    //let isStandardMessage = !isValid && validationMessages[`${name}`].includes('standard');
    //let isCustomMessage = !isValid && validationMessages[`${name}`].includes('custom');

    let colourTypes = [{value: 'standard', label: 'Standard'}];

    if(this.state.standardColours.findIndex(x => x.id == COLOUR_STANDARD_CUSTOM_ID) >= 0){
      colourTypes.push({value: 'custom', label: 'Custom'});
    }
    if(frameStandardColourId || frameCustomColourId){
      colourTypes.push({value: 'frame', label: 'Same as frame'});
    }

    if(onlyFrameColour){
      colourTypes = colourTypes.filter(x => x.value === 'frame');
    }

    let isColourSurcharge = (selectedCustomColourId && customColours.findIndex(x => x.value == selectedCustomColourId && x.isSurcharge) >= 0);

    let pcRangeName = isColourSurcharge ?  customColours.find(x => x.value == selectedCustomColourId && x.isSurcharge) : null;

    return (<div>

      {!singleColourSelection && <div style={{display: this.state.standardColours.length > 0 && !this.props.disabled ? 'block' : 'none'}}>
        <RadioControlGroup
          name={`${name}_${awningId}`}
          onChange={(x, e) => {
            let s = {
              colourType: x.simpleValue,
              selectedStandardColourId: x.simpleValue === 'custom' ? COLOUR_STANDARD_CUSTOM_ID : (x.simpleValue === 'frame' ? frameStandardColourId : null),
              selectedCustomColourId: x.simpleValue === 'frame' ? frameCustomColourId : null,
            };
            this.setState(s, this.onStateChanged);
          }}
          value={this.state.colourType}
          labelText={`${this.props.label} Colour`}
          options={colourTypes}
          valid={isValid}
          style={this.props.radioStyle}
          onClearSelection={onLink}
          clearSelectionText={linkText}
        />        
      </div> }

      {singleColourSelection &&
      <div className="form-group"
           style={{display: this.state.standardColours.length > 0 && !this.props.disabled ? 'block' : 'none'}}>
        <label htmlFor={`${this.props.label}`}>{this.props.label} Colour {onLink && <a href='#' onClick={onLink}>{linkText}</a> }
          {showSameAsFrame && <a href={""} onClick={(e)=>{
            e.preventDefault();
            this.handleSingleColourSelect({type:'frame'});
          }}>Same as frame</a>}
        </label>
        <Select
          disabled={this.props.disabled}
          name={`${this.props.label}`}
          options={singleColourList}
          simpleValue={false}
          onChange={this.handleSingleColourSelect}
          value={selectedSingleColour}
          placeholder="Select Colour"
        />
        { !isValid && <div style={{color: 'red'}}>{validationMessages[`${name}`]}</div> }
      </div>
      }

      {(this.state.colourType == 'standard' || (this.state.colourType == 'frame' && this.state.selectedStandardColourId != COLOUR_STANDARD_CUSTOM_ID)) && !singleColourSelection &&
        <div className="form-group" style={{display: this.state.standardColours.length > 0 && !this.props.disabled ? 'block' : 'none'}}>
          <label htmlFor={`${this.props.label}`}>Standard {this.props.label} Colour</label>

          <Select
            disabled={this.props.disabled || this.state.colourType == 'frame'}
            name={`${this.props.label}`}
            options={this.state.standardColours}
            simpleValue={true}
            valueKey={'id'}
            labelKey={'colourDescription'}
            onChange={(val) => this.handleSelectChange('selectedStandardColourId', val)}
            value={this.state.selectedStandardColourId}
            placeholder="Select Frame Colour"
          />
        </div> }
      { (this.state.colourType == 'custom' ||
          (this.state.colourType == 'frame' && this.state.selectedStandardColourId == COLOUR_STANDARD_CUSTOM_ID))
      && !singleColourSelection && <div className="form-group" style={{display: this.state.customColours.length > 0 && this.state.selectedStandardColourId == 7 ? 'block' : 'none'}}>
        <label htmlFor={`${this.props.label}custom`}>Custom {this.props.label} Colour</label>
        <Select
          disabled={this.state.selectedStandardColourId != 7 || this.state.colourType == 'frame'}
          name={`${this.props.label}custom`}
          options={this.state.customColours}
          simpleValue={true}
          onChange={(val) => this.handleSelectChange(`selectedCustomColourId`, val) }
          value={this.state.selectedCustomColourId}
          placeholder={`Select Custom ${this.props.label} Colour`}
          noResultsText={'Please select custom from Standard colour'}
        />
      </div> }

      { isColourSurcharge && !this.props.disabled && <div style={{color: 'red'}}>A powder coat surcharge applies: { pcRangeName ? pcRangeName.rangeName : 'unknown' } finish</div> }
      { colorBondColour && !this.props.disabled && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}><i className={'fa fa-info-circle'} />
        &nbsp;Please note minor colour variation between powdercoat and Colorbond flashing colour, the matching Colorbond colour is: {colorBondColour.label}
      </div> }
      { (selectedStandardColourId || selectedCustomColourId) && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
        Dulux has discontinued some colours from the standard range and will be made to order. If these colours are selected they will now be POA. More information  <a href={`/terms/00.435_PC_Dulux_Update0622_PX136.pdf`} target={'_blank'}>here.</a>
      </div> }
      
    </div>);
  }
}


ColourSelectorControl.propTypes = {
  standardColours: PropTypes.array.isRequired,
  customColours: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  validationMessages: PropTypes.object,
  selectedStandardColourId: PropTypes.number,
  selectedCustomColourId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  awningId: PropTypes.number.isRequired,
  frameStandardColourId: PropTypes.number,
  frameCustomColourId: PropTypes.number,
  onlyFrameColour: PropTypes.bool,
  colorBondColourLookup: PropTypes.bool,
};

export default ColourSelectorControl;