import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import { apiPost} from "../../utils/request";
import {throttle} from 'lodash';
import {AdminValidationRules, CustomerAdminControl} from "./CustomerAdminControl";
import TextBoxControl from '../common/TextBoxControl';
import {UserValidationRules} from "./CustomerUsersPage";
import {ROLE_CustomerAdmin, ROLE_CustomerAdmin_Id} from "../../constants";

const initialState = {
  organisation: {
    'description': '',
    'phone': '',
    'addressLine1': '',
    'addressLine2': '',
    'suburb': '',
    'stateId': null,
    'postCode': '',
    'countryId': 1,
    'deliveryAddressLine1': '',
    'deliveryAddressLine2': '',
    'deliverySuburb': '',
    'deliveryStateId': null,
    'deliveryPostCode': '',
    'deliveryCountryId': 1,
    'emailGeneral': '',
    'emailOrders': '',
    'emailFreightReceiver': '',
    'emailAccounts': '',
    'rating': 0,
    'websiteURL': '',
    'discountAwning': 0,
    'discountFrame': 0,
    'discountSpareParts': 0,
    'discountAutomation': 0,
    'discountBand1': 0,
    'discountBand2': 0,
    'discountBand3': 0,
    'discountBand4': 0,
    'discountBand5': 0,
    'abn': '',
    'isActive': true,
    'acknowledgePrivacyDate': null,
    'acknowledgeTermsAndConditionsDate': null,
    'acknowledgeTermsAndConditionsSintesiDate': null,
    'notes': '',
    'statusCreditApplicationId': null,
    'organisationStatusId': null,
    'creditTermsId': null,
    'creditLimit': 0,
    'shortName': '',
    'legalName': '',
    'dispatchCentreId': null,
    'dispatchTypeId': null,
    'wholesaleOnly': false,
    'privacyAndTermsAccepted': false,
    'allTermsAccepted': true,
    'mailingAddressLine1': '',
    'mailingAddressLine2': '',
    'mailingSuburb': '',
    'mailingStateId': null,
    'mailingPostCode': '',
    'mailingCountryId': 1,
    'deliveryAddressOneLine': '',
    'stateDescription': null,
    'id': -2,
    'isAllowedFrameOnly':true,
    'enableCustomerInformation':false,
    'requiresAcceptedTerms': true,
  },
  firstUser: {
    userName: '',
    firstName: '',
    lastName: '',
    contactNumber: '',
    jobTitle: '',
    roles: [{id: ROLE_CustomerAdmin_Id, description: ROLE_CustomerAdmin}],
  },
  validationMessages: {},
  isValid: true,
};

class NewCustomerPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.validate = this.validate.bind(this);
    this.handleFieldChangeUser = this.handleFieldChangeUser.bind(this);
  }

  componentDidUpdate(prevProps){

  }

  handleSave() {
    let {organisation, firstUser} = this.state;

    if (!this.validate()) {
      return;
    }

    toastr.warning('Saving...');

    apiPost(`/api/organisations/new`, {organisation: {...organisation}, firstUser: {...firstUser}})
      .then(x => {
        if(x.data && x.data.id) {
          toastr.success('Created organisation');
          this.props.history.push(`/manage/customers/${x.data.id}/portalservices`);
        } else {
          toastr.error(`Unable to create organisation: ${x.data}`);
        }
      });
  }

  handleFieldChange(e){
    let {value, name, type, checked} = e.target;

    let organisation = {...this.state.organisation};
    organisation[name] = type === 'checkbox' ? checked : value;

    this.setState({organisation}, throttle(() => {
      this.validate();
    }, 1000));
  }

  handleFieldChangeUser(e){
    let {value, name, type, checked} = e.target;

    let firstUser = {...this.state.firstUser};
    firstUser[name] = type === 'checkbox' ? checked : value;

    this.setState({firstUser}, throttle(() => {
      this.validate();
    }, 1000));
  }

  handleSelectChange(val, name, valSelector){
    let organisation = {...this.state.organisation};

    organisation[name] = valSelector(val);

    this.setState({organisation}, throttle(this.validate, 1000));
  }

  validate(){
    let {organisation, firstUser} = this.state;

    let messages = {};
    let isValid = true;

    let runValidator = (rules, obj) => {
      for (let propName in rules) {

        let rule = rules[propName];
        let val = obj[propName];

        let valResult = rule.validator(val, this.state, this.props);
        if (!valResult.isValid) {
          messages[propName] = valResult.message;
          isValid = false;
        }
      }
    };

    runValidator(AdminValidationRules, organisation);
    runValidator(UserValidationRules, firstUser);

    this.setState({validationMessages: messages, isValid});

    return isValid;
  }

  componentDidMount() {
    this.setState(initialState);
  }


  render() {
    let {organisation, validationMessages, firstUser} = this.state;

    return organisation ? (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>New Customer Profile</h2>

            </div>
            <div className={'pi-col-md-4'} style={{textAlign: 'right'}}>
              <button
                className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleSave}>
                <i className="fa fa-save"></i>&nbsp;Save
              </button>
            </div>

          </div>

          <div className={'form-horizontal'}>

            <CustomerAdminControl
              organisation={organisation}
              validationMessages={validationMessages}
              handleFieldChange={this.handleFieldChange}
              creditTerms={this.props.creditTerms}
              handleSelectChange={this.handleSelectChange}
              creditApplicationStatuses={this.props.creditApplicationStatuses}
              organisationStatuses={this.props.organisationStatuses}
              dispatchTypes={this.props.dispatchTypes}
              dispatchCentres={this.props.dispatchCentres}
              style={{marginTop: '25px', marginBottom: '25px', border: '1px solid #afb8bb', padding:'20px'}}
              powderCoaters={this.props.powderCoaters}
              fabricMakers={this.props.fabricMakers}
            />

            <div className={'pi-row'} style={{marginTop: '25px', marginBottom: '25px', border: '1px solid #afb8bb', padding:'20px'}}>
              <div className={'pi-col-md-12'}>
                <h4>Customer Admin User</h4>
                <TextBoxControl
                  onChange={this.handleFieldChangeUser}
                  labelText="Email"
                  value={firstUser.userName}
                  name="userName"
                  validationMessages={validationMessages}
                />
                <TextBoxControl
                  onChange={this.handleFieldChangeUser}
                  labelText="First Name"
                  value={firstUser.firstName}
                  name="firstName"
                  validationMessages={validationMessages}
                />
                <TextBoxControl
                    onChange={this.handleFieldChangeUser}
                    labelText="Last Name"
                    value={firstUser.lastName}
                    name="lastName"
                    validationMessages={validationMessages}
                />
                <TextBoxControl
                  onChange={this.handleFieldChangeUser}
                  labelText="Contact Number"
                  value={firstUser.contactNumber}
                  name="contactNumber"
                  validationMessages={validationMessages}
                />
                <TextBoxControl
                  onChange={this.handleFieldChangeUser}
                  labelText="Job Title"
                  value={firstUser.jobTitle}
                  name="jobTitle"
                  validationMessages={validationMessages}
                />
              </div>
            </div>

          </div>

        </div>
      </div>) : null;
  }
}

NewCustomerPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  dispatchTypes: PropTypes.array.isRequired,
  dispatchCentres: PropTypes.array.isRequired,
  creditTerms: PropTypes.array.isRequired,
  creditApplicationStatuses: PropTypes.array.isRequired,
  organisationStatuses: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
    dispatchTypes: state.lookups.dispatchTypes,
    dispatchCentres: state.lookups.dispatchCentres,
    creditTerms: state.lookups.creditTerms,
    creditApplicationStatuses: state.lookups.creditApplicationStatuses,
    organisationStatuses: state.lookups.organisationStatuses,
    states: state.lookups.states,
    powderCoaters: state.lookups.powderCoaters,
    fabricMakers: state.lookups.fabricMakers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCustomerPage));
