import React from 'react';
import PropTypes from 'prop-types';
import {IsNullOrUndefined, MoneyFormatter} from '../../constants';

const TextViewControlPrice = (props) => {
  let {labelText, value, discount, retail, wholesaleOnly, wholesale} = props;
  
  if(discount === undefined){
    discount = 0;
  }
  if(wholesale === undefined){    
    wholesale = ((100 - discount)/100) * retail;
  }

  return (value == null && retail == null) ? null : (
    <div className="pi-row">
      <div className="pi-col-sm-6">{labelText}</div>
      {IsNullOrUndefined(retail) ?
        <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{value == null ? 'N/A' : value}</div> :
        (wholesaleOnly ?
          <div className="pi-col-sm-3" style={{textAlign: 'right'}}>&nbsp;</div> :
          <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{MoneyFormatter.format(retail)}</div> )
      }
      {IsNullOrUndefined(retail) ?
        <div className="pi-col-sm-3" style={{textAlign: 'right'}}>&nbsp;</div> :
        <div className="pi-col-sm-3" style={{textAlign: 'right'}}>{MoneyFormatter.format(wholesale)}</div>
      }
    </div>
  );
};

TextViewControlPrice.propTypes = {
  labelText: PropTypes.string.isRequired,
  wholesale: PropTypes.number,
  retail: PropTypes.number,
};
export default TextViewControlPrice;