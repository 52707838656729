import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RadioControlGroup from "../common/RadioControlGroup";
import {
  Aux, FABRIC_VALANCE_WAVY,
  IsNullOrUndefined, PRODUCTGROUPID_FOLDING_ARM,
  TextInputParseInt, validationCreator
} from "../../constants";
import TextBoxControl from "../common/TextBoxControl";
import SelectControl from "../common/SelectControl";
import FabricRecoverAwningCosts from "./FabricRecoverAwningCosts";
import {FabricRecoverAwningViewWorksheetData} from "./FabricRecoverAwningViewWorksheetData";

export const NewFabricRecoverAwning = (id = -1, number = 1) => {
  return {
    id,
    number: number,
    width: '',
    productGroupId: null,
    projection: '',
    fabricBrandId: null,
    fabricValance: null,
    wholeSalePercentage: 65,
    retailPercentage: 60,
    recoverPercentage: 35,
    men: 1,
    hours: 1,
    rate: 80,
    fabricInstruction: 1,    
  };
};

export const OrderAwningValidationRules = {
  productGroupId: {
    validator: (order, awning) => {
      return IsNullOrUndefined(awning.productGroupId) ? validationCreator(false, 'Please select a product group') : validationCreator();
    }
  },
  fabricBrandId: {
    validator: (order, awning) => {
      return IsNullOrUndefined(awning.fabricBrandId) ? validationCreator(false, 'Please select a fabric brand') : validationCreator();
    }
  },
  width: {
    validator: (order, awning) => {
      return awning.width < 1000 || awning.width > 12000 ? validationCreator(false, 'Please enter a valid width') : validationCreator();
    }
  },
  projection: {
    validator: (order, awning) => {
      return IsNullOrUndefined(awning.projection) ? validationCreator(false, 'Please enter a valid projection') : validationCreator();
    }
  },
  men: {
    validator: (order, awning) => {
      return awning.men < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  hours: {
    validator: (order, awning) => {
      return awning.hours < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  rate: {
    validator: (order, awning) => {
      return awning.rate < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  recoverPercentage: {
    validator: (order, awning) => {
      return awning.recoverPercentage < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  retailPercentage: {
    validator: (order, awning) => {
      return awning.retailPercentage < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  wholeSalePercentage: {
    validator: (order, awning) => {
      return awning.wholeSalePercentage < 1 ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  },
  fabricValance: {
    validator: (order, awning) => {
      return awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && IsNullOrUndefined(awning.fabricValance)
          ? validationCreator(false, 'Please enter a valid positive number') : validationCreator();
    }
  }, fabricId: {
    validator: (order, awning, isAcceptOrderOpen) => {      
      return isAcceptOrderOpen && awning.fabricId <= 0
          ? validationCreator(false, 'Please select a fabric') : validationCreator();
    }
  }, fabricTrimBiasColourId: {
    validator: (order, awning, isAcceptOrderOpen) => {
      return isAcceptOrderOpen && awning.fabricValance === FABRIC_VALANCE_WAVY && IsNullOrUndefined(awning.fabricTrimBiasColourId)
          ? validationCreator(false, 'Please select a trim bias colour') : validationCreator();
    }
  }, fabricInstruction: {
    validator: (order, awning, isAcceptOrderOpen) => {
      return isAcceptOrderOpen && awning.fabricInstruction <= 0
          ? validationCreator(false, 'Please select a fabric instruction') : validationCreator();
    }
  }
};

class FabricRecoverAwningEditor extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {

    let {
      awning,
      validationMessages,
      productGroups,
      projections,
      fabricBrands,
      handleAwningFieldUpdate,
      valances,
      baseCosts
    } = this.props;
    let workSheetData = FabricRecoverAwningViewWorksheetData({awning, fabricBrands, baseCosts});

    return (
        <div>
          <h3 style={{marginTop: '10px'}}>Awning #{awning.number} Fabric Recovering Calculator</h3>
          <p>Fill in the required details and the applicable pricing will be displayed</p>

          <div className={'pi-row'}>
            <div className='pi-col-md-6'>
              <RadioControlGroup
                  style={{marginLeft: '0px'}}
                  name={`productGroupId_${awning.id}`}
                  onChange={e => handleAwningFieldUpdate(e, "productGroupId", awning, v => v.simpleValue)}
                  value={awning.productGroupId}
                  labelText='Product Group'
                  validationMessages={validationMessages}
                  options={productGroups}
              />

              <SelectControl
                  validationMessages={validationMessages}
                  labelText="Fabric Brand"
                  name="fabricBrandId"
                  options={fabricBrands}
                  simpleValue={true}
                  onChange={e => handleAwningFieldUpdate(e, "fabricBrandId", awning, v => v)}
                  value={awning.fabricBrandId}
                  placeholder="Select Fabric Brand"
                  valueKey={'id'}
                  labelKey={'description'}
              />

              {awning.productGroupId > 0 &&
              <Aux>
                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={e => handleAwningFieldUpdate(e, "width", awning, v => TextInputParseInt(v.target.value))}
                    labelText="Width"
                    value={awning.width.toString()}
                    name="width"
                    type="number"
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={e => handleAwningFieldUpdate(e, "projection", awning, v => TextInputParseInt(v.target.value))}
                    labelText="Projection"
                    value={awning.projection.toString()}
                    name="projection"
                    type="number"
                    validationMessages={validationMessages}
                />

                <RadioControlGroup
                    style={{marginLeft: '0px'}}
                    name={`fabricValance_${awning.id}`}
                    onChange={e => handleAwningFieldUpdate(e, "fabricValance", awning, v => TextInputParseInt(v.simpleValue))}
                    labelText="Valance"
                    validationMessages={validationMessages}
                    options={valances}
                    visible={awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM}
                    value={awning.fabricValance}
                />

                <div className="row mt-4">
                  <div className="col-3"><label htmlFor="fabricNote">Fabric Note</label></div>
                  <div className="col-9">
                  <textarea className={'form-control'} style={{height: "100px"}}
                            value={awning.fabricNote ? awning.fabricNote : ''}
                            onChange={e => handleAwningFieldUpdate(e, "fabricNote", awning, v => v.target.value)}
                            name='fabricNote'/>
                  </div>
                </div>

              </Aux>
              }
            </div>
            {workSheetData ?
                <div className='pi-col-md-3'>
                  <h4>Labour</h4>
                  <TextBoxControl
                      isDebounce={true}
                      debounceeTimeOut={100}
                      onChange={e => handleAwningFieldUpdate(e, "men", awning, v => TextInputParseInt(v.target.value))}
                      labelText="Men"
                      value={awning.men}
                      name="men"
                      type="number"
                      validationMessages={validationMessages}
                  />

                  <TextBoxControl
                      isDebounce={true}
                      debounceeTimeOut={100}
                      onChange={e => handleAwningFieldUpdate(e, "hours", awning, v => TextInputParseInt(v.target.value))}
                      labelText="Hours"
                      value={awning.hours}
                      name="hours"
                      type="number"
                      validationMessages={validationMessages}
                  />

                  <TextBoxControl
                      isDebounce={true}
                      debounceeTimeOut={100}
                      onChange={e => handleAwningFieldUpdate(e, "rate", awning, v => TextInputParseInt(v.target.value))}
                      labelText="Rate"
                      value={awning.rate}
                      name="rate"
                      type="number"
                      validationMessages={validationMessages}
                  />
                </div>
                :
                <div className='pi-col-md-6'>
                  <div
                      className={`alert alert-${IsNullOrUndefined(validationMessages['dataOutOfRange']) ? 'primary' : 'danger'}`}
                      role="alert">
                    Invalid configuration, please input Width and Projection
                  </div>
                </div>
            }

            {workSheetData && <div className='pi-col-md-3'>
              <h4>Margin</h4>
              <TextBoxControl
                  isDebounce={true}
                  debounceeTimeOut={100}
                  onChange={e => handleAwningFieldUpdate(e, "recoverPercentage", awning, v => TextInputParseInt(v.target.value),
                      (newAwning) => {
                        return {...newAwning, wholeSalePercentage: 100 - newAwning.recoverPercentage};
                      })
                  }
                  labelText="Recover %"
                  value={awning.recoverPercentage}
                  name="recoverPercentage"
                  type="number"
                  validationMessages={validationMessages}
              />

              <TextBoxControl
                  isDebounce={true}
                  debounceeTimeOut={100}
                  onChange={e => handleAwningFieldUpdate(e, "wholeSalePercentage", awning, v => TextInputParseInt(v.target.value),
                      (newAwning) => {
                        return {...newAwning, recoverPercentage: 100 - newAwning.wholeSalePercentage};
                      })
                  }
                  labelText="Wholesale %"
                  value={awning.wholeSalePercentage}
                  name="wholeSalePercentage"
                  type="number"
                  validationMessages={validationMessages}
              />

              <TextBoxControl
                  isDebounce={true}
                  debounceeTimeOut={100}
                  onChange={e => handleAwningFieldUpdate(e, "retailPercentage", awning, v => TextInputParseInt(v.target.value))}
                  labelText="Retail %"
                  value={awning.retailPercentage}
                  name="retailPercentage"
                  type="number"
                  validationMessages={validationMessages}
              />
            </div>
            }
          </div>
          <FabricRecoverAwningCosts awning={awning} fabricBrands={fabricBrands} baseCosts={baseCosts}/>
        </div>
    );
  }
}

FabricRecoverAwningEditor.propTypes = {
  productGroups: PropTypes.array.isRequired,
  fabricBrands: PropTypes.array.isRequired,
  baseCosts: PropTypes.array.isRequired,
  projections: PropTypes.array.isRequired,
  valances: PropTypes.array.isRequired,
  awning: PropTypes.object.isRequired,
  validationMessages: PropTypes.object.isRequired,
  handleAwningFieldUpdate: PropTypes.func.isRequired,
};

export default FabricRecoverAwningEditor;