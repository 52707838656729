import React from 'react';
import PropTypes from 'prop-types';
import {
  Aux, AwningRequiresFabric, DRIVE_MECHANISM_MotorisedPrepOnly,
  edit_order_can_deselect_pelmet_amendment_requested,
  edit_order_can_deselect_pelmet_quote,
  isHardwired,
  isNullEmptyOrWhitespace,
  IsNullOrUndefined, IsRoofSystem,
  isScSz,
  isSDSM,
  ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED,
  ORDER_STATUS_QUOTE, orders_list_list_all_organisations_all,
  PRODUCTGROUPID_CONSERVATORY,
  PRODUCTGROUPID_FOLDING_ARM,
  PRODUCTID_TOGA,
  REPORTSERVER_BASEURL, ROLE_Administrator, ROLE_ProductManager,
  SpaceString
} from "../../constants";
import jQuery from 'jquery';
import RadioControlGroup from "../common/RadioControlGroup";
import TextBoxControl from "../common/TextBoxControl";
import AwningView, {getDefaultAwning} from "../orders/AwningView";
import AwningFieldsControl from "../orders/AwningFieldsControl";
import DiagramSD from "./DiagramSD";
import DiagramFA from "./DiagramFA";
import {AwningProductSpecifications} from "../orders/AwningProductSpecifications";
import store from "../../store";
import {addCartItem, toggleCart} from "../../actions/resourceCartActions";
import {apiDownloadReport, apiPost, apiPostNoAuth, apiRequest} from "../../utils/request";
import { Base64 } from 'js-base64';
import AwningSADimensionControl from "../orders/AwningSADimensionControl";
import SelectControl from "../common/SelectControl";
import AwningCuttingDeductionsControl from "../common/AwningCuttingDeductionsControl";
import {ValidationRules} from "../orders/AwningValidators";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import {Modal, ModalBody} from "reactstrap";
import ProductResourcesPage from "../portal/ProductResourcesPage";

const MiddleArmOptions = [{value: 1, label: 'Left'}, {value: 2, label: 'Right'}];

class LookupAwningDiagram extends AwningView {
  constructor(props, context) {
    super(props, context);
   
    this.state = {...this.state, isCuttingDeductionsOpen: false, cuttingDimensionData: null};
    
    this.handlePrint = this.handlePrint.bind(this);
    this.printRef = React.createRef();
    this.fetchMotorOptions = this.fetchMotorOptions.bind(this);
    this.handleRequestDeductions = this.handleRequestDeductions.bind(this);   
    
  }
  
  async handleRequestDeductions() {

    let {order, validationMessages, isCuttingDeductionsOpen} = this.state;

    let isValid = this.isValid();


    let result = await apiPost('api/CuttingDeductions', order);

    if (!IsNullOrUndefined(result.data) && result.data.length > 0) {
      this.setState({cuttingDimensionData: result.data, isCuttingDeductionsOpen: true, cuttingDeductionWarning: null});
    } else {
      this.setState({
        cuttingDimensionData: null,
        isCuttingDeductionsOpen: false,
        cuttingDeductionWarning: "Please complete more information to retrieve cutting deductions"
      });
    }

  }

  handlePrint(e, toCart = false) {
    e.preventDefault();

    let container = jQuery(`<div></div>`)
        .css('font-size', '10px')
        .css('font-family', "'Open Sans', Arial, sans-serif")
        .css('line-height', "1.6em");

    jQuery(this.printRef.current)
        .clone()
        .css('margin-top', '10px')
        .appendTo(container);

    container.find('button').remove();

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": true,
      "templateName": "GeneratePdfDefault"
    };

    let {awning} = this.state;
    let {productDescription, width, projection, mountingBracketDescription, driveMechanism, sideOfOperation, includePelmet, additionalMountingBrackets, fieldConfiguration} = awning;
    if (!IsNullOrUndefined(fieldConfiguration)) {
      width = '';
      for (let i = 0; i < fieldConfiguration.fields.length; i++) {
        width += (fieldConfiguration.fields[i].width + ((i === fieldConfiguration.fields.length - 1) ? '' : '_'));
      }
    }

    let name = SpaceString([productDescription, width, projection, mountingBracketDescription, driveMechanism, sideOfOperation, includePelmet, additionalMountingBrackets], '_');

    let fileName = `${name.replace(/\s/g, '_')}_spec.pdf`;

    let opt = {
      margin: [10, 10, 20, 10],
      filename: fileName,
      image: {type: 'jpeg', quality: 1},
      html2canvas: {dpi: 192, letterRendering: true},
      jsPDF: {unit: 'mm', format: 'letter', orientation: 'portrait'}
    };

    if (toCart) {
      /*html2pdf()
          .set(opt)
          .from(container[0])
          .outputPdf('blob')
          .then((blob) => {
            store.dispatch(addCartItem({
              blob: blob,
              fileName: fileName,
            }));

            store.dispatch(toggleCart());
          });*/

      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName, (blob) => {
        store.dispatch(addCartItem({
          blob: blob,
          fileName: fileName,
        }))
      });
    } else {
//      html2pdf().set(opt).from(container[0]).save();
      apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), fileName);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user.organisation.id !== this.state.order.organisationId) {     
      this.setState({order: {...this.state.order, organisationId: this.props.user.organisation.id}}, () => {        
        super.fetchProductGroups();
        this.raiseAwningChanged();
      });
    }
    super.componentDidUpdate(prevProps, prevState);
    
    this.fetchMotorOptions(prevState);
  }

  fetchMotorOptions(prevState){
    let {driveMechanismId, productLookupDataId} = this.state.order.awnings[0];
    let {user} = this.props;

    if((user.isInRole(ROLE_Administrator) || user.isInRole(ROLE_ProductManager)) && prevState && (prevState.order.awnings[0].driveMechanismId !== driveMechanismId) || (prevState.order.awnings[0].productLookupDataId !== productLookupDataId)){
      if(driveMechanismId <= 0 || productLookupDataId <= 0){
        this.setState({motorOptions: []});
      } else {
        apiRequest(`/api/motors/motoroptions?driveMechanismId=${driveMechanismId}&productLookupDataId=${productLookupDataId}`)
            .then(x => {
              this.setState({motorOptions: [...x.data]});
            });
      }
    }
  }

  componentDidMount() {    
    super.componentDidMount();
  }  

  render() {

    let {awning, validationMessages, order, selectedProduct, motorOptions, cuttingDimensionData, isCuttingDeductionsOpen, cuttingDeductionWarning} = this.state;
    let {user, date, reference, customerName} = this.props;
    let pelmetName = (selectedProduct && selectedProduct.hasPelmet) ? 'Cassette Profile' : 'Pelmet';
    let driveMechanisms = this.state.driveMechanisms.filter(x => x.value !== DRIVE_MECHANISM_MotorisedPrepOnly);
    let awningRequiresFabric = true;
    
    /*.filter(x => x.value === 1 || x.value === 2).map(x => {
      if (x.value === 2) {
        x.label = 'Motorised'
      }
      return x;
    });*/
    let pdfOnly = selectedProduct && selectedProduct.pdfOrderOnly;
    let headboxWidth = IsRoofSystem(awning) ? 40 : IsNullOrUndefined(selectedProduct) || IsNullOrUndefined(selectedProduct.headboxWidth) ? 0 : selectedProduct.headboxWidth;    
    
    return (
      <div>        
        
        <RadioControlGroup
          style={{marginLeft: '0px'}}
          name={`productGroupId_${awning.id}`}
          onChange={(x, e) => {
            this.handlFieldChangeWithAwningId(e, (v, newState) => {
              return {
                ...newState,
                awning: {...getDefaultAwning(newState.awning), productId: null, projection: 0,},
                selectedProduct: null,
                products: [],
                installationBrackets: [],
                flashingOptions: [],
                installationBracketTypes: [],
              };
            }, "number")
          }}
          value={awning.productGroupId}
          labelText='Product Group'
          validationMessages={validationMessages}
          options={this.state.productGroups.filter(x => x.value === PRODUCTGROUPID_CONSERVATORY || x.value === PRODUCTGROUPID_FOLDING_ARM)}
        />

        <RadioControlGroup
          style={{marginLeft: '0px'}}
          name={`productId_${awning.id}`}
          valueKey={'id'}
          labelKey={'description'}
          onChange={(val, e) => this.handleRadioChangeWithAwningId(val, e, (v) => v.objectValue.id, 'selectedProduct', (v, newState) => {
            return {
              ...newState, awning: {...getDefaultAwning(newState.awning)},
              installationBrackets: [],
              flashingOptions: [],
              installationBracketTypes: [],
              remoteColours: [],
              remotes: [],
            };
            return newState;
          })}
          value={awning.productId}
          labelText='Product'
          validationMessages={validationMessages}
          options={this.state.products}
        />

        {pdfOnly ? <div>
            This product cannot yet be ordered online, please download the&nbsp;
            {this.state.selectedProduct.id === 9 ? <span>
              <a href={`/order-forms/${this.state.selectedProduct.id}_MaxiRail.pdf`} target={'_blank'}>Standard/MaxiRail Aluxor Order Form</a>&nbsp;or&nbsp;
                <a href={`/order-forms/${this.state.selectedProduct.id}_SteerAround.pdf`} target={'_blank'}>Steer Around Aluxor Order Form</a>
            </span> :
              <a href={`/order-forms/${this.state.selectedProduct.id}.pdf`} target={'_blank'}>Aluxor Order
                Form</a>}&nbsp;for this product
          </div> :
          <Aux>

            {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && this.frameFeaturesControl({marginLeft: '0px'})}

            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`frameFeatureFieldType_${awning.id}`}
              onChange={(x, e) => {
                this.handlFieldChangeWithAwningId(e, null, "number");
              }}
              value={awning.frameFeatureFieldType}
              labelText='Multi Field Configuration'
              validationMessages={validationMessages}
              options={this.state.frameFeatureFieldTypes}
            />

            <RadioControlGroup
                style={{marginLeft: '0px'}}
                name={`fieldConfigurationOption_${awning.id}`}
                onChange={this.handleFieldConfigurationOptionUpdate}
                value={IsNullOrUndefined(awning.fieldConfiguration) ? null : awning.fieldConfiguration.fieldConfigurationOptionId}
                labelText='Field Options'
                validationMessages={validationMessages}
                options={this.state.fieldConfigurationOptions}
            />

            <AwningSADimensionControl
                awningId={awning.id}
                onSteerAroundDimensionsUpdated={this.handleSteerAroundDimensionUpdate}
                onAwningFieldUpdated={this.handlFieldChangeWithAwningId}
                steerAroundTypes={this.state.frameFeatureSteerAroundTypes}
                steerAroundDimensions={awning.steerAroundDimensions}
                steerAroundType={parseInt(awning.frameFeatureSteerAroundType)}
            />

            <AwningFieldsControl
              style={{marginLeft: '0px'}}
              awningId={awning.id}
              fieldConfiguration={awning.fieldConfiguration}
              onFieldConfigurationUpdated={this.handleFrameFeatureFieldValueUpdate}
              headboxWidth={headboxWidth}
              sideOfOperation={awning.sideOfOperation}              
              explicitEdit={isSDSM(awning) || isScSz(awning)}
              awning={awning}
              fieldWidthMessage={IsRoofSystem(awning) ? "Field width is outside of headbox/guiderail excluding end-caps" : `Field widths are track centres (headbox width minus ${headboxWidth}mm)`}
            />

            {awning.productId > 0 && isNullEmptyOrWhitespace(awning.fieldConfiguration) &&
            <TextBoxControl
              style={{marginLeft: '0px'}}
              isDebounce={true}
              debounceeTimeOut={300}
              onChange={(e) => this.handlFieldChange(e, (v, newState) => {
                return {
                  ...newState, awning: {
                    ...newState.awning,
                    projection: null,
                  }
                };
                return newState;
              })}
              labelText={awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? `Width (is field track centres, being headbox width minus ${selectedProduct.headboxWidth}mm)` : `Width`}
              value={awning.width.toString()}
              name="width"
              type="number"
              validationMessages={validationMessages}
            />
            }


            {selectedProduct && !selectedProduct.fixedProjection ?
              <TextBoxControl
                style={{marginLeft: '0px'}}
                isDebounce={true}
                debounceeTimeOut={300}
                onChange={this.handlFieldChange}
                labelText="Projection (is back of headbox to end of guiderail)"
                value={awning.projection ? awning.projection.toString() : ''}
                name="projection"
                type="number"
                validationMessages={validationMessages}
              /> :
              <RadioControlGroup
                style={{marginLeft: '0px'}}
                name={`projection_${awning.id}`}
                onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                value={awning.projection}
                labelText='Projection'
                validationMessages={validationMessages}
                options={this.state.projections}
              />
            }

            <RadioControlGroup
              name={`driveMechanismId_${awning.id}`}
              onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                return {
                  ...newState, awning: {
                    ...newState.awning,
                    automationCableId: null,
                    automationSensorType: null,
                    mocoType: null,
                    motorId: null,
                    wallSwitch: isHardwired(newState.awning) ? 'Wall Switch' : null,
                  }
                };
              }, "number")}
              value={awning.driveMechanismId}
              labelText='Drive Mechanism'
              validationMessages={validationMessages}
              options={driveMechanisms}
              clearSelectionText={awning.number > 1 ? 'Same as awning 1' : ''}
              onClearSelection={awning.number > 1 ? (e) => this.setDriveSameAsAwning(e, 1) : undefined}
              style={{marginLeft: '0px'}}              
            />
            
            { motorOptions && motorOptions.length > 0 &&
            <Aux>
              <div className={"pi-row"}>
                <div className={"pi-col-sm-12"}>
                  <h5>Available Motor Options</h5>
                </div>
              </div>
              <div className={"pi-row"}>
                <div className={"pi-col-sm-6"}>
                  <ul className={"list-group"}>
                    {motorOptions.map(m => {
                      return (<li className={"list-group-item"} key={m.id}>{m.description}</li>);
                    })}
                  </ul>
                </div>              
              </div>
            </Aux>}
            

            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`sideOfOperation_${awning.id}`}
              onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
              value={awning.sideOfOperation}
              labelText='Side of Operation'
              validationMessages={validationMessages}
              options={this.state.sideOfOperations}
              visible={IsNullOrUndefined(awning.fieldConfiguration) && awning.productLookupDataId > 0}
            />

            <RadioControlGroup
                style={{marginLeft: '0px'}}
                name={`middleArmSide_${awning.id}`}
                onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                value={awning.middleArmSide}
                labelText={"Third Arm Side"}
                validationMessages={validationMessages}
                options={MiddleArmOptions}
                visible={awning.numberOfArms === 3 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM}
            />

            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`includePelmet_${awning.id}`}
              onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n) => {
                let boolVal = v;
                return {
                  ...n, awning: {
                    ...n.awning,
                    pelmetColourId: boolVal ? n.awning.pelmetColourId : null,
                    pelmetCustomColourId: boolVal ? n.awning.pelmetCustomColourId : null,
                    includePelmet: boolVal,
                  }
                };
              })}
              value={awning.includePelmet}
              labelText={`Include ${pelmetName}`}
              validationMessages={validationMessages}
              options={this.state.pelmetOptions}
              visible={awning.productId > 0 && this.state.selectedProduct &&
              ((this.state.selectedProduct.hasPelmet &&
                (
                  ((order.orderStatus === ORDER_STATUS_QUOTE && user.hasAccessTag(edit_order_can_deselect_pelmet_quote)) ||
                    (order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED && user.hasAccessTag(edit_order_can_deselect_pelmet_amendment_requested)))
                  && !this.state.awning.productId === PRODUCTID_TOGA
                ) || this.state.selectedProduct.pelmetDecision))}
            />

            {awning.productId > 0 && <RadioControlGroup
              name={`mountingBracketId_${awning.id}`}
              style={{marginLeft: '0px'}}
              onChange={(x, e) => {
                this.setState({
                  awning: {
                    ...this.state.awning,
                    mountingBracketWidth: x.objectValue.width,
                    mountingBracketDescription: x.objectValue.description
                  }
                }, () => {
                  this.handlFieldChangeWithAwningId(e);
                });
              }}
              labelText="Mounting Bracket"
              validationMessages={validationMessages}
              options={this.state.mountingBrackets}
              value={awning.mountingBracketId}
              valueKey={'id'}
              labelKey={'description'}
              imageUrlSelector={(x) => x.pcRef ? `/img/powder_coating_sheet/${x.pcRef}.png` : `/img/powder_coating_sheet/blank.png`}
            />}

            {awning.productId > 0 && this.state.selectedProduct && this.state.selectedProduct.hasAdditionalMountingBrackets &&
            <TextBoxControl
              onChange={this.handlFieldChange}
              labelText="Additional Mounting Brackets"
              value={awning.additionalMountingBrackets.toString()}
              name="additionalMountingBrackets"
              type="number"
              validationMessages={validationMessages}
            />}                      

            { awning.fieldConfiguration && <SelectControl
                validationMessages={validationMessages}
                labelText="Fabric Brand"
                //disabled={!awningRequiresFabric}
                name="fabricBrandId"
                options={this.state.fabricBrands}
                simpleValue={true}
                onChange={(val) => this.handleSelectChange('fabricBrandId', val, (v) => v)}
                value={awning.fabricBrandId}
                placeholder="Select Fabric Brand"
                valueKey={'id'}
                labelKey={'description'}
                toolTip={awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM ?
                    "Soltis / Mesh PVC fabrics cannot be used on awnings where a cradle is fitted due to increased risk of marking or damage to the fabric. Aluxor will not warrant awnings fitted with fabrics out of specifications" :
                    awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? "Soltis / PVC fabric cannot be supplied for awnings with width or projection greater than 5500 due to spring operation constraints and fabric presentation issues, including increased risk of fabric creasing. Aluxor will not warrant awnings fitted with fabrics out of specifications" : ""}
            /> }

            {awning.productLookupDataId > 0 && user && user.hasAccessTag(orders_list_list_all_organisations_all) && <button
                onClick={this.handleRequestDeductions}
                className="btn pi-btn-base pi-uppercase pi-letter-spacing">Show Deductions
            </button> }

            {cuttingDeductionWarning && <div className="alert alert-warning mt-3" role="alert"><i className={'fa fa-info-circle'} />&nbsp;{cuttingDeductionWarning}</div>}

            <Modal isOpen={isCuttingDeductionsOpen && cuttingDimensionData !== null}
                   toggle={() => this.setState({isCuttingDeductionsOpen: false})}
            >
              <ModalHeader>
                <div className={"container"}>
                  <div className={"row"}>
                    <div className={"col-10"}><h3>Cutting Deductions</h3></div>
                    <div className={"col-2 text-right"}>
                      <button
                          className="btn pi-btn-base"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({isCuttingDeductionsOpen: false})
                          }}
                      ><i className={'fa fa-times'}/></button>
                    </div>
                  </div>                
                </div>
              </ModalHeader>
              <ModalBody>
                <div className={"container"}>
                  
                { cuttingDimensionData !== null && cuttingDimensionData.map(x => {
                      return (
                          <div className={"row"} key={x.item1}>
                            <div className={"col-12"}>
                          <AwningCuttingDeductionsControl awning={awning}
                                                          cuttingDeductions={x.item2}
                                                          awningRequiresFabric={awningRequiresFabric}
                                                          headerText={x.item1 ? `Field #${x.item1} Size ${awning.width} x ${awning.projection}` : `Awning Size ${awning.width} x ${awning.projection}`} />
                            </div>
                          </div>
                      );
                    }) 
                }
                </div>
              </ModalBody>
            </Modal>            
            
            {awning.productLookupDataId > 0 && <div ref={this.printRef} style={{width: '98%', marginTop: '25px'}}>

              <div className="pi-row" style={{pageBreakInside: 'avoid'}}>
                <div className="pi-col-sm-12">

                  <div className="pi-row">
                    <div className={'pi-col-sm-8'} style={{textAlign: 'left', paddingTop: '30px'}}>
                      &nbsp;
                    </div>
                    <div className={'pi-col-sm-1'}/>
                    <div className={'pi-col-sm-3'}>
                      <img src="img/logo-base.png" alt=""/>
                    </div>
                  </div>


                  <Aux>
                    <button
                      onClick={this.handlePrint}
                      className="btn pi-btn-base pi-uppercase pi-letter-spacing">
                      <i className="fa fa-print"/>&nbsp;Print
                    </button>

                    <button
                        style={{marginLeft: '10px'}}
                        onClick={(e) => this.handlePrint(e, true)}
                        className="btn pi-btn-base pi-uppercase pi-letter-spacing">
                      <i className="fa fa-cart-plus"/>&nbsp;Add to Cart
                    </button>

                    <AwningProductSpecifications awning={awning} showSize={true} showProduct={true} customerName={customerName} reference={reference} date={date} cols={2} />

                    <div style={{marginBottom: '20px'}}>&nbsp;</div>
                  </Aux>                  

                  {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY
                  && <DiagramSD awning={awning} width={800}/>}

                  {awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM
                  && <DiagramFA awning={awning} width={800}/>}


                </div>
              </div>

            </div> }
          </Aux>}

      </div>
    );
  }
}

LookupAwningDiagram.propTypes = {
  customerName: PropTypes.string,
  reference: PropTypes.string,
  date: PropTypes.string,  
};

export default LookupAwningDiagram;