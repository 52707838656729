import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import {
    DATE_SHORT_FORMAT, isNullEmptyOrWhitespace,
    obsfucate,
} from "../../constants";
import moment from "moment";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Select from "react-select";
import {toastr} from 'react-redux-toastr';
import {SupportRequestStatusTypes, SupportRequestTypes} from "./SupportRequestPage";
import {loadProducts, loadSupportRequests, updateFilter} from "../../actions/supportRequestActions";

class SupportRequestsPage extends Component {
    constructor(props, context) {
        super(props, context);        

        this.state = {columns: [], products:[]};
        
        this.fetchRequests = this.fetchRequests.bind(this);
        this.setColumns = this.setColumns.bind(this);            
        this.loadData = this.loadData.bind(this);
        this.updateFilterField = this.updateFilterField.bind(this);
        this.handleArrayFieldFilterUpdate = this.handleArrayFieldFilterUpdate.bind(this);
        this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    }

    updateFilterField(e) {
        this.handleFilterFieldChange(e.target.value, e.target.name);
    }

    handleFilterFieldChange(val, field) {
        let filter = { ...this.props.storeState.filter };

        filter[field] = val;

        this.props.dispatch(updateFilter(filter));
    }
    
    handleArrayFieldFilterUpdate(v, field){
        let updatedVal = isNullEmptyOrWhitespace(v) ? [] : v.split(',').map(x => parseInt(x));
        this.handleFilterFieldChange(updatedVal, field);
    }
    
    fetchRequests() {
        this.props.dispatch(loadSupportRequests(this.props.storeState.filter));
    }

    loadData() {
        this.props.dispatch(loadProducts());
    }
    
    setColumns() {
        this.setState({
            columns: [{
                Header: 'Status',                
                accessor: 'supportRequestStatusTypeString',               
            },{
                Header: 'Type',
                accessor: 'supportRequestTypeString',
            }, {                
                Header: 'Product',
                accessor: 'productDescription',
            }, {
                Header: 'Raised By',
                accessor: 'createdByPersonName',
            }, {
                Header: 'Organisation',
                accessor: 'organisationDescription',
            }, {
                id: 'createdDate',
                Header: `Created Date`,
                accessor: d => moment(d.createdDate).format(DATE_SHORT_FORMAT),
                sortMethod: (a, b) => {
                    let aVal = moment(a, DATE_SHORT_FORMAT);
                    let bVal = moment(b, DATE_SHORT_FORMAT);

                    return aVal.isBefore(bVal) ? -1 : 1;
                },
                maxWidth: 120,
            },
                {
                    id: 'actions',
                    Header: '',
                    accessor: c => {
                        return (
                            <span>
                  <Link
                      to={`/supportRequests/${obsfucate(c.id)}`}
                      className={'mr-3'}
                  ><i className="fa fa-edit fa-lg" /></Link>                                                  
            </span>
                        );
                    },
                    width: 125,
                }]
        });
    }

    componentDidMount() {
        this.setColumns();
        this.fetchRequests();
        this.loadData();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.user.organisation.id !== this.props.user.organisation.id){
            this.fetchRequests();
        }        
    }

    render() {

        let {user,siteSettings, organisations, storeState} = this.props;
        let {columns} = this.state;
        let {requests, filter, loaded, products} = storeState;        

        return (
            <div>

                <div className={"row mt-5"}>
                    <div className={"col-6"}>
                        <h2>Product Support</h2>
                    </div>
                    <div className={"col-6 text-right"}>
                        <div className="form-group">
                            <button
                                onClick={(e)=>{
                                    e.preventDefault();
                                    this.props.history.push(`/supportRequests/create`);
                                }}
                                className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing mr-2"
                            >
                                <i className="icon-floppy pi-icon-left"/>&nbsp;New Request
                            </button>

                            <button
                                onClick={(e)=>{
                                    this.fetchRequests();
                                }}
                                className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
                            >
                                <i className="icon-floppy pi-icon-left"/>&nbsp;Search
                            </button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <form>
                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <Select
                                        clearable={true}
                                        deleteRemoves={true}
                                        backspaceRemoves={true}
                                        multi={true}
                                        labelText="Status"
                                        name="statuses"
                                        options={SupportRequestStatusTypes}
                                        onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'statuses')}
                                        simpleValue={true}
                                        value={filter.statuses}
                                        placeholder={'Order status'}
                                    />                                    
                                </div>
                                <div className="col-sm-6">
                                    <Select
                                        clearable={true}
                                        deleteRemoves={true}
                                        backspaceRemoves={true}
                                        multi={true}
                                        labelText="Organisation"
                                        name="organisationId"
                                        options={organisations}
                                        onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'organisations')}
                                        simpleValue={true}
                                        value={filter.organisations}
                                        placeholder={'Organisation'}
                                        valueKey={'id'}
                                        labelKey={'description'}
                                    />                                    
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <input type={'text'}
                                           className={'form-control'}
                                           value={filter.customerReferenceNumber}
                                           onChange={this.updateFilterField}
                                           name={'customerReferenceNumber'}
                                           placeholder={'Customer Reference Number'} />                                    
                                    
                                </div>
                                <div className="col-sm-6">
                                    <input type={'text'}
                                           className={'form-control'}
                                           value={filter.sequenceNumber}
                                           onChange={this.updateFilterField}
                                           name={'sequenceNumber'}
                                           placeholder={'Production Number'} />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <Select
                                        clearable={true}
                                        deleteRemoves={true}
                                        backspaceRemoves={true}
                                        multi={true}
                                        labelText="Product"
                                        name="productIds"
                                        options={products}
                                        onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'productIds')}
                                        simpleValue={true}
                                        value={filter.productIds}
                                        placeholder={'Product'}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Select
                                        clearable={true}
                                        deleteRemoves={true}
                                        backspaceRemoves={true}
                                        multi={true}
                                        labelText="Support Type"
                                        name="supportRequestTypeIds"
                                        options={SupportRequestTypes}
                                        onChange={(val) => this.handleArrayFieldFilterUpdate(val, 'supportRequestTypeIds')}
                                        simpleValue={true}
                                        value={filter.supportRequestTypeIds}
                                        placeholder={'Support Type'}                                        
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        { loaded ? 
                        <ReactTable
                            filterable={false}
                            data={requests}
                            columns={columns}
                        /> : <div>Click seach to find product support requests</div> }
                    </div>
                </div>



            </div>
        );
    }
}

SupportRequestsPage.propTypes = {
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        siteSettings: state.lookups.siteSettings,
        organisations: state.organisations.items,
        storeState: state.supportRequests,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportRequestsPage));