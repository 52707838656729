import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody} from "reactstrap";
import {DATE_SHORT_FORMAT, IsNullOrUndefined, obsfucate, REPORTSERVER_BASEURL} from "../../constants";
import {Base64} from "js-base64";
import {apiDownload, apiDownloadReport, apiPost} from "../../utils/request";
import injectSheet from "react-jss";
import {RowFields, styles} from "../orders/OrderPowderCoatingSheet";
import jQuery from 'jquery';
import moment from "moment";
import {toastr} from "react-redux-toastr";
import row from "react-bootstrap/lib/Row";

class PcStockOrderPcSheet extends Component {
    constructor(props, context) {
        super(props, context);

        this.printMeRef = React.createRef();
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEmailInquiry = this.handleEmailInquiry.bind(this);
        this.handleDownloadData = this.handleDownloadData.bind(this);
    }

    async handlePrint() {
        let container = jQuery(`<div></div>`)
            .css('font-size', '10px')
            .css('font-family', "'Open Sans', Arial, sans-serif")
            .css('line-height', "1.6em");

        jQuery(this.printMeRef.current)
            .clone()
            .css('margin-top', '10px')
            .appendTo(container);

        let body = {
            "base64HtmlHead": Base64.encode(document.head.innerHTML),
            "base64HtmlBody": Base64.encode(container[0].innerHTML),
            "pdfOutput": true,
            "isLandscape": false,
            "templateName": "GeneratePdfDefault"
        };
        await apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `PC_sheet.pdf`);
    }

    handleEmailInquiry(e) {
        e.preventDefault();
        let {order, colourDescription} = this.props;
        //let {colour, numberOfProfiles, maxProfileLength, numberOfComponents} = this.getDataForPage();

        toastr.info('Sending email...');

        apiPost(`/api/supplierorders/${order.id}/emailPcInquiry`, {
            numberOfProfiles: null,
            maxProfileLength: null,
            colour: colourDescription,
            sequenceNumber: order.sequenceNumber,
            powderCoaterId: order.powderCoaterId,
            sheetIndex: 0,
            obsfucatedOrderId: obsfucate(order.id),
            numberOfComponents: 0,
            orderType: 3,
        }).then(x => {
            if (x.data && x.data.ok) {
                toastr.success('Sent');
            } else {
                toastr.error('Error while sending');
            }
        });
    }

    async handleDownloadData() {
        let {order, colourDescription, rowData} = this.props;        

        console.log(order);
        
        await apiDownload(`/api/supplierorders/${order.id}/DownloadPcSupplierData`,
            `ALX_PC_${order.sequenceNumber}.tsv`,
            'POST', null,
            {
                numberOfProfiles: null,
                maxProfileLength: null,
                colour: colourDescription,
                sequenceNumber: order.sequenceNumber,
                powderCoaterId: order.powderCoaterId,
                sheetIndex: 0,
                obsfucatedOrderId: obsfucate(order.id),
                numberOfComponents: 0,
                orderType: 3,
                pcInquiryDataItems: rowData.map(x => {
                    return {
                        pcRef: x.pcRef,
                        quantity: x.number,
                        length: x.length > 0 ? x.length : null,
                    };
                }),
            });
    }

    componentDidMount() {
        if(this.props.isDownload){
            this.handleDownloadData();
        }
    }

    render(){
        let {
            sheetIsOpen,
            onToggle,
            powderCoater,
            orderRef,
            dateSent,
            colourDescription,
            rowData,
            onSave,      
            classes,
            comment,
            showEmail,
            order,
        } = this.props;

        return IsNullOrUndefined(orderRef) ? null : (<Modal isOpen={sheetIsOpen}
                                                            toggle={onToggle}
                                                            style={{minWidth: '940px'}}
        >
            <ModalBody>
                <div className="pi-section">
                    <div className="row" style={{marginBottom: '10px'}}>
                        <div className={'col-12'}>                            

                            <button onClick={this.handlePrint}
                                    className="btn pi-btn-base pi-btn-icon-big"
                                    style={{marginRight: '10px'}}
                            >
                                <i className="fa fa-print"/>&nbsp;Print
                            </button>
                            {onSave && <button onClick={onSave}
                                               className="btn pi-btn-icon-big pi-btn-blue"
                                               style={{marginRight: '10px'}}
                            >
                                <i className="fa fa-save"/>&nbsp;Save to Supplier Jobs
                            </button> }
                            {showEmail && <button onClick={this.handleEmailInquiry}
                                                  className="btn pi-btn-base pi-btn-icon-big"
                                                  style={{marginRight: '10px'}}
                            >
                                <i className="fa fa-envelope"/>&nbsp;Email Inquiry</button> }
                            <button className="btn pi-btn  pi-btn-icon-big"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onToggle();
                                    }}><i className="fa fa-times"/>&nbsp;Close</button>
                        </div>
                    </div>

                    <div ref={this.printMeRef} style={{width: '98%'}}>

                        <div className="pi-row">
                            <div className="pi-col-sm-8">
                                <h4 className="pi-has-bg pi-margin-bottom-20">
                                    Powder Coating Sheet Order #{orderRef}
                                </h4>
                            </div>
                            <div className="pi-col-sm-4">
                                <h4><img src="img/logo-full.png" alt="Aluxor Logo"/></h4>
                            </div>
                        </div>

                        <div className="pi-row">
                            <div className="pi-col-md-2">
                                Powder coater:
                            </div>
                            <div className="pi-col-md-5">
                                {IsNullOrUndefined(powderCoater) ? '' : powderCoater.label}
                            </div>                            
                            <div className="pi-col-md-2">
                                Contact:
                            </div>
                            <div className="pi-col-md-3">
                            </div>
                        </div>

                        <div className="pi-row">
                            <div className="pi-col-md-2">
                                Date sent:
                            </div>
                            <div className="pi-col-md-5">
                                {moment(dateSent).format(DATE_SHORT_FORMAT)}
                            </div>                            
                            <div className="pi-col-md-2">
                                Telephone:
                            </div>
                            <div className="pi-col-md-3">
                                02 9907 2211
                            </div>
                        </div>

                        <div className="pi-row">
                            <div className="pi-col-md-2">
                                Colour:
                            </div>
                            <div className="pi-col-md-5">
                                {colourDescription}
                            </div>                            
                            <div className="pi-col-md-2">
                                Email:
                            </div>
                            <div className="pi-col-md-3">
                                <a href={'mailto:production@aluxor.com.au'}>production@aluxor.com.au</a>
                            </div>
                        </div>
                        <div className="pi-row">
                            <div className="pi-col-md-2">
                                &nbsp;
                            </div>
                            <div className="pi-col-md-5">
                                &nbsp;
                            </div>                            
                            <div className="pi-col-md-2">
                                Comment:
                            </div>
                            <div className="pi-col-md-3">
                                {comment}
                            </div>
                        </div>


                        <div className={classes.divTable}>
                            <div className={classes.divThead}>
                                <div className={classes.divTrHead}>
                                    <div className={classes.divTh}>Code</div>
                                    <div className={classes.divTh} style={{width: '15%'}}>Description</div>
                                    <div className={classes.divTh}>Dimension</div>
                                    <div className={classes.divTh}>Material</div>
                                    <div className={classes.divTh}>Image</div>
                                    <div className={classes.divTh}>Length</div>
                                    <div className={`${classes.divTh} ${classes.smallCell}`}>Number</div>
                                    <div className={`${classes.divTh} ${classes.smallCell}`}>Check</div>
                                    <div className={`${classes.divTh} ${classes.smallCell}`}>PC Chk</div>
                                </div>
                            </div>

                            <div className={`${classes.divTbody}`}>
                                {rowData.map(row => {
                                    return (<div key={`row_${row.key}`} className={classes.divTr}><RowFields {...row} classes={classes} hardLeftBorder={false}/></div>);
                                })}                                
                            </div>

                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>);
    }
}



PcStockOrderPcSheet.propTypes = {
    sheetIsOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    powderCoater: PropTypes.object,
    orderRef: PropTypes.string,
    dateSent: PropTypes.string,
    colourDescription: PropTypes.string,
    rowData: PropTypes.array.isRequired,
    onSave: PropTypes.func,
    onPrint: PropTypes.func.isRequired,
    comment: PropTypes.string,
    order: PropTypes.object,
    showEmail: PropTypes.bool.isRequired,
    isDownload: PropTypes.bool,
};

export default injectSheet(styles)(PcStockOrderPcSheet);