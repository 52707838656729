import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import SelectControl from "../common/SelectControl";
import {Link} from "react-router-dom";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {find} from "lodash";
import moment from "moment";
import {IsNullOrUndefined} from "../../constants";

const initState = {
  fabricBrands: [],
  fabrics: [],
  fabricBrandId: null,
  validationMessages: {},
  columns: [],
};

class FabricLookupPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchFabricBrands = this.fetchFabricBrands.bind(this);
    this.fetchFabrics = this.fetchFabrics.bind(this);
    this.getColumns = this.getColumns.bind(this);
  }

  async fetchFabricBrands(prevState) {
    let fabricBrands = (await apiRequest(`/api/fabricbrands/all?isActive=true`)).data;
    this.setState({
      fabricBrands,
    });
  }

  async fetchFabrics(prevState) {
    let {fabricBrandId, fabricBrands} = this.state;
    let prevFabricBrandId = prevState.fabricBrandId;
    let fabrics = this.state.fabrics;
    let surcharge = 0;

    if (prevFabricBrandId === fabricBrandId) {
      return;
    }

    if (fabricBrandId > 0) {
      let fabricBrand = find(fabricBrands, x => x.id === fabricBrandId);
      surcharge = !fabricBrand ? 0 : fabricBrand.surcharge;
      this.setState({fabrics: []});
      fabrics = (await apiRequest(`/api/fabricbrands/byBrand?fabricBrandId=${fabricBrandId}`)).data;
    }
    this.setState({
      fabrics,
      columns: this.getColumns(surcharge),
    });
  }
  
  getColumns(surcharge){
    return [
      {
        Header: 'Description',
        accessor: 'description',
        filterMethod: (filter, row) => {
          return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
        },
      },
      {
        Header: 'Code',
        accessor: 'code',
        filterMethod: (filter, row) => {
          return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0;
        },
      },
      {
        Header: 'Is Stocked',
        accessor: 'isStocked',
        Cell: c => <span>
            <i className={`fa ${c.original.isStocked ? 'fa-check' : 'fa-times'}`}/>
          </span>,
        maxWidth: 150,
        Filter: ({filter, onChange}) => <span></span>
      },
      {
        Header: 'Supplier Stock',
        accessor: 'isSupplierOutOfStock',
        Cell: c => <span>
            <i className={`fa ${!c.original.isSupplierOutOfStock ? 'fa-check' : 'fa-times'}`}/>
          </span>,
        maxWidth: 150,
        Filter: ({filter, onChange}) => <span></span>
      },
      {
        Header: 'Supplier Expected',
        accessor: 'expectedStockDate',
        Cell: c => <span>
          {IsNullOrUndefined(c.original.expectedStockDate) ? '' : moment(c.original.expectedStockDate).format('MMM Do')}            
          </span>,
        maxWidth: 150,
        Filter: ({filter, onChange}) => <span></span>
      },
      {
        Header: 'Surcharge Multiplier',
        Cell: c => <span>{surcharge}</span>,
        maxWidth: 150,
        Filter: ({filter, onChange}) => <span></span>,
      }
    ];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.fetchFabrics(prevState);
  }

  componentDidMount() {
    this.setState({...initState}, () => {
      this.fetchFabricBrands();
    });
  }

  render() {

    let {fabricBrandId, validationMessages, fabricBrands, fabrics, columns} = this.state;


    return (
      <div>
        <h4 style={{marginTop: '10px'}}><Link to={`/products/armbracketspacing`}>Awning Specifications</Link> | Fabric Lookup | <Link to={`/colours/lookup`}>Colour Lookup</Link> | <Link to={`/sintesi/lookup`}>Sintesi Lookup</Link>
        </h4>

        <p>View current available fabrics</p>

        <div className={'pi-row'} style={{marginBottom: '35px'}}>
          <div className='pi-col-md-12'>

              <SelectControl
                validationMessages={validationMessages}
                labelText="Fabric Brand"
                name="fabricBrandId"
                options={fabricBrands}
                simpleValue={true}
                onChange={(x) => {
                  this.setState({fabricBrandId: x})
                }}
                value={fabricBrandId}
                placeholder="Select Fabric Brand"
                valueKey={'id'}
                labelKey={'description'}
              />

            { fabrics.length > 0 && <ReactTable
              sortable={true}
              filterable={true}
              showPagination={true}
              data={fabrics}
              columns={columns}
              defaultPageSize={15}
              minRows={10}
            /> }
          </div>


        </div>

      </div>
    );
  }
}

FabricLookupPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabricLookupPage));