import React from "react";
import PropTypes from "prop-types";
import {
    Aux,
    DATE_SHORT_FORMAT, GetOrderPriceMessage,
    INVOICE_TYPE_FABRICRECOVER,
    INVOICE_TYPE_ORDERAWNING,
    isCustomerCollect,
    isNullEmptyOrWhitespace,
    IsNullOrUndefined,
    IsRoofSystem,
    MoneyFormatter, QUOTE_ADDON_TYPE_GENERALNOTE, QUOTE_ADDON_TYPE_INSTALL_COST, QUOTE_ADDON_TYPE_NOTE, SpaceString
} from "../../../constants";
import CustomerAwningPriceSummary from "./CustomerAwningPriceSummary";
import {SummaryDivider} from "../../common/SummaryControls";
import CustomerPriceControl from "./CustomerPriceControl";
import AwningQuoteAddons from "./AwningQuoteAddons";
import TextViewControl from "../../common/TextViewControl";
import moment from "moment/moment";
import injectSheet from 'react-jss';
import {InvoiceTableStyles} from "../OrderInvoiceSheet";
import CustomerPrintPriceControl from "./CustomerPrintPriceControl";

const CustomerOrderPrintSummary = (props) => {
    let {order, quoteAddons, onReloadOrder, classes, quoteCustomer} = props;

    let anyNonRoofing = order.awnings.findIndex(x => !IsRoofSystem(x)) >= 0;
    let anyRoofing = order.awnings.findIndex(x => IsRoofSystem(x)) >= 0;

    const sensorSummary = (showSubTotal) => {        
        
        return (order.sensorCostsTotal > 0) &&
            <Aux>
                <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                    <div className={classes.divTd}
                         style={{width: '66%', textAlign: 'left', fontWeight:'bold'}}>Sensor Options (to be confirmed)
                    </div>
                    <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                    <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                </div>

                {order.sensorCosts.map(c => {

                    return (
                        <Aux key={c.rowKey}>
                            <CustomerPriceControl
                                showPrice={true}
                                labelText={`${c.sensorTypeDescription} ${c.awningCount > 1 ? `set (${c.awningCount} awnings)` : `x 1`}`}
                                retail={c.total}
                                discount={0}
                                renderAsTable={true}
                                classes={classes}
                            />

                            {c.additionalCosts.remoteSurchargeRetail &&
                                <CustomerPriceControl
                                    showPrice={true} labelText="Remote surcharge"
                                    retail={c.additionalCosts.remoteSurchargeRetail}
                                    discount={0}
                                    renderAsTable={true}
                                    classes={classes}
                                />}

                        </Aux>);
                })}

                {showSubTotal && <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                    <div className={classes.divTd}
                         style={{width: '66%', textAlign: 'left'}}>&nbsp;</div>
                    <div className={classes.divTd} style={{width: '14%', fontWeight:'bold'}}>Sub Totals:</div>
                    <div className={classes.divTdRight}
                         style={{width: '20%', fontWeight:'bold'}}>{MoneyFormatter.format(order.sensorCostsTotal)}</div>
                </div>}
            </Aux>
    };
    
    return (IsNullOrUndefined(order) ? null :
        <Aux>
            <div className="pi-section" style={{paddingLeft: '60px', paddingRight: '60px', paddingTop:'60px'}}>

                <div className="pi-row" style={{marginBottom: '15px', marginTop: '25px'}}>
                    <div className="pi-col-sm-6">
                        <h4><img src="img/logo-full.png" width={300} alt="Aluxor Logo"/></h4>
                    </div>
                    <div className="pi-col-sm-6">
                        <div className={"pi-row"}>
                            <div className="pi-col-sm-12">
                                <div className={"pi-row"}>
                                    <div className="pi-col-sm-5">
                                        ABN: 43 326 634 246
                                    </div>
                                    <div className="pi-col-sm-7" style={{textAlign: 'right'}}>
                                        Telephone: +61 2 5112 2530
                                    </div>
                                </div>
                                <div className={"pi-row"}>
                                    <div className="pi-col-sm-5">
                                        151 Newcastle Street
                                    </div>
                                    <div className="pi-col-sm-7" style={{textAlign: 'right'}}>
                                        NSW DFT Licence 241699C
                                    </div>
                                </div>
                                <div className={"pi-row"}>
                                    <div className="pi-col-sm-5">
                                        FYSHWICK ACT 2609
                                    </div>
                                    <div className="pi-col-sm-7" style={{textAlign: 'right'}}>
                                        Email: act@aluxor.com.au
                                    </div>
                                </div>                                
                            </div>
                        </div>

                    </div>
                </div>

                {quoteCustomer && <div className="pi-row" style={{marginBottom: '15px'}}>
                    <div className="pi-col-sm-6">
                        <TextViewControl labelText={'Customer'} value={quoteCustomer.name} />
                        <TextViewControl labelText={'Address'} value={SpaceString([
                            quoteCustomer.addressLine1, quoteCustomer.addressLine2, quoteCustomer.suburb, 
                            quoteCustomer.state, quoteCustomer.postCode], ', ')} />
                        { !quoteCustomer.anonymousEmail && <TextViewControl labelText={'Email'} value={quoteCustomer.email} /> }
                        <TextViewControl labelText={'Phone'} value={quoteCustomer.contactNumber} />
                    </div>
                    <div className="pi-col-sm-6">
                        <h1 style={{textAlign: 'right', fontSize: '40px'}}>Preliminary Quote</h1>
                    </div>
                </div>}
                
                <div className="pi-row">
                    <div className="pi-col-md-12">

                        <div className={classes.divTable} style={{marginBottom: '0px'}}>

                            <div className={classes.divThead}>
                                <div className={classes.divTrHead}>
                                    <div className={classes.divTh} style={{width: '33%'}}>Order Ref</div>
                                    <div className={classes.divTh} style={{width: '33%'}}>PF Invoice Date</div>
                                    <div className={classes.divTh} style={{width: '34%'}}>PF Invoice #</div>
                                </div>
                            </div>
                            <div className={classes.divTbody}>
                                <div className={classes.divTr}>
                                    <div className={classes.divTd}
                                         style={{width: '33%'}}>{order.customerReference}</div>
                                    <div className={classes.divTd}
                                         style={{width: '33%'}}>{moment().format(DATE_SHORT_FORMAT)}</div>
                                    <div className={classes.divTd} style={{width: '34%'}}>PF-{order.id}</div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.divTable} style={{marginBottom: '0px', marginTop: '0px'}}>
                            <div className={classes.divThead}>
                                <div className={classes.divTrHead}>                                    
                                    <div className={classes.divTh} style={{width: '66%'}}>Description</div>
                                    <div className={classes.divTh} style={{width: '14%'}}>&nbsp;</div>
                                    <div className={classes.divTh}
                                         style={{width: '20%', textAlign: 'right', paddingRight: '5px'}}>Amount excl.
                                        GST
                                    </div>
                                </div>
                            </div>
                            <div className={classes.divTbody}>
                                {order.awnings.map(awning => {
                                    return (<Aux>
                                        <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                            <div className={classes.divTd}
                                                 style={{width: '66%', textAlign: 'left', fontWeight: 'bold'}}>Awning #{awning.number} (Installed)</div>                                            
                                            <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                            <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                                        </div>

                                        <CustomerAwningPriceSummary
                                            awning={awning}
                                            order={order}
                                            key={awning.id}
                                            renderAsTable={true} classes={classes}
                                        />                                        
                                        
                                        {/*awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_INSTALL_COST)
                                            .map(addOn => {
                                                return (<CustomerPriceControl key={addOn.id}
                                                                              showPrice={true}
                                                                              labelText={`${addOn.shortDescription} - ${addOn.description}`}
                                                                              retail={addOn.costRetail}
                                                                              discount={0}
                                                                              renderAsTable={true}
                                                                              classes={classes}
                                                />);
                                            })*/}

                                        {order.awnings.length === 1 && sensorSummary(false) }
                                        
                                        {awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_NOTE).length > 0 && <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                            <div className={classes.divTd}
                                                 style={{width: '66%', textAlign: 'left', fontWeight: 'bold'}}>Specifications / Installation (to be confirmed)</div>
                                            <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                            <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                                        </div> }
                                        
                                        {awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_NOTE)
                                            .map(addOn => {
                                                return (<CustomerPriceControl key={addOn.id}
                                                                              showPrice={true}
                                                                              labelText={`${addOn.description}`}
                                                                              retail={addOn.costRetail}
                                                                              discount={0}
                                                                              renderAsTable={true}
                                                                              classes={classes}
                                                />);
                                            })}

                                        {awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_GENERALNOTE).length > 0 && <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                            <div className={classes.divTd}
                                                 style={{width: '66%', textAlign: 'left', fontWeight: 'bold'}}>General Notes</div>
                                            <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                            <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                                        </div> }

                                        {awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_GENERALNOTE)
                                            .map(addOn => {
                                                return (<CustomerPriceControl key={addOn.id}
                                                                              showPrice={true}
                                                                              labelText={`${addOn.description}`}
                                                                              retail={addOn.costRetail}
                                                                              discount={0}
                                                                              renderAsTable={true}
                                                                              classes={classes}
                                                />);
                                            })}
                                        
                                        
                                        {awning.notes.length > 0 && 
                                            <Aux>
                                                <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                                    <div className={classes.divTd}
                                                         style={{width: '66%', textAlign: 'left', fontWeight: 'bold'}}>Notes</div>
                                                    <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                                    <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                                                </div>

                                                <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                                    <div className={classes.divTd}
                                                         style={{width: '66%', textAlign: 'left'}}>{awning.notes.reduce((x, y) => IsNullOrUndefined(x.description) ? y.description : `${x.description} - ${y.description}`, [])}</div>
                                                    <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                                    <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                                                </div>
                                            </Aux>                                           
                                         }

                                        { order.awnings.length > 1 && <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                            <div className={classes.divTd}
                                                 style={{width: '66%', textAlign: 'left'}}>&nbsp;</div>                                            
                                            <div className={classes.divTd} style={{width: '14%', fontWeight:'bold'}}>Awning #{awning.number} Total:</div>
                                            <div className={classes.divTdRight}
                                                 style={{width: '20%', fontWeight:'bold'}}>{MoneyFormatter.format(awning.costs.totalRetail + awning.quoteAddonCostTotal)}</div>
                                        </div> }

                                    </Aux>);
                                })}

                                {order.awnings.length > 1 && sensorSummary(true)}

                            </div>
                        </div>
                        <div className={classes.divTable} style={{marginBottom: '0px', marginTop: '0px'}}>
                            <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                <div className={classes.divTd} style={{width: '26%', textAlign: 'left'}}>&nbsp;</div>
                                <div className={classes.divTd} style={{width: '40%', textAlign: 'left'}}>&nbsp;</div>
                                <div className={classes.divTd} style={{width: '14%'}}>&nbsp;</div>
                                <div className={classes.divTdRight} style={{width: '20%'}}>&nbsp;</div>
                            </div>

                            <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                <div className={classes.divTd} style={{width: '26%', textAlign: 'left', fontWeight:'bold'}}>EFT Payments</div>
                                <div className={classes.divTd} style={{width: '40%', textAlign: 'left'}}>Commonwealth Bank, Aluxor Industries Pty Ltd<br />
                                    BSB: 062-000 AccountNo: 1165 5586</div>                                
                                <div className={classes.divTd} style={{width: '14%', fontWeight:'bold'}}>Sub Total:</div>
                                <div className={classes.divTdRight} style={{width: '20%', fontWeight:'bold'}}>{MoneyFormatter.format(order.costTotal)}</div>
                            </div>
                            <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                <div className={classes.divTd} style={{width: '26%', textAlign: 'left', fontWeight:'bold'}}>Cheque Payments</div>
                                <div className={classes.divTd} style={{width: '40%', textAlign: 'left'}}>Aluxor Industries Pty Ltd<br />
                                    4A/151 Newcastle St Fyshwick ACT 2609</div>
                                <div className={classes.divTd} style={{width: '14%', fontWeight:'bold'}}>GST:</div>
                                <div className={classes.divTdRight} style={{width: '20%', fontWeight:'bold'}}>{MoneyFormatter.format(order.costTotal * 0.1)}</div>
                            </div>
                            <div className={classes.divTr} style={{border: '1px solid #ddd'}}>
                                <div className={classes.divTd} style={{width: '26%', textAlign: 'left'}}>&nbsp;</div>
                                <div className={classes.divTd} style={{width: '40%', textAlign: 'left'}}>&nbsp;</div>
                                <div className={classes.divTd} style={{width: '14%', fontWeight:'bold'}}>Total incl GST:</div>
                                <div className={classes.divTdRight} style={{width: '20%', fontWeight:'bold'}}>{MoneyFormatter.format(order.costTotal * 1.1)}</div>
                            </div>
                        </div>
                        
                    </div>
                    

                </div>
            </div>        


</Aux>)
};


CustomerOrderPrintSummary.propTypes = {
    order: PropTypes.object.isRequired,
    quoteAddons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
    })).isRequired,
    onReloadOrder: PropTypes.func.isRequired,
};

export default injectSheet(InvoiceTableStyles)(CustomerOrderPrintSummary);