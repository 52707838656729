import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import {
  Aux,
  IsNullOrUndefined,
  PRODUCTGROUPID_CONSERVATORY,
  PRODUCTGROUPID_FOLDING_ARM, PRODUCTGROUPID_ROOFING_SYSTEMS,
  REPORTSERVER_BASEURL
} from "../../constants";
import jQuery from 'jquery';
import DiagramFA from "./DiagramFA";
import DiagramSD from "./DiagramSD";
import {AwningProductSpecifications} from "../orders/AwningProductSpecifications";
import {Base64} from "js-base64";
import {apiDownloadReport} from "../../utils/request";
import DiagramST from "./DiagramST";

class OrderDiagramsSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      printed: false,
    };

    this.handlePrint = this.handlePrint.bind(this);
    this.printMeRef = React.createRef();
  }

  handlePrint() {

    let {order} = this.props;

    if (!order) {
      return null;
    }

    let container = jQuery(`<div></div>`)
        .css('font-size', '10px')
        .css('font-family', "'Open Sans', Arial, sans-serif")
        .css('line-height', "1.6em");

    jQuery(this.printMeRef.current)
        .clone()
        .css('margin-top', '10px')
        .appendTo(container);

    let body = {
      "base64HtmlHead": Base64.encode(document.head.innerHTML),
      "base64HtmlBody": Base64.encode(container[0].innerHTML),
      "pdfOutput": true,
      "isLandscape": true,
      "templateName": "GeneratePdfDefault"
    };

    /*html2pdf()
        .set({
          margin: [10, 10, 20, 10],
          filename: `Order_${order.id}_abs_sheet.pdf`,
          image: {type: 'jpeg', quality: 1},
          html2canvas: {dpi: 192, letterRendering: true},
          jsPDF: {unit: 'mm', format: 'letter', orientation: 'landscape'},
          pageBreak: {mode: ['avoid-all', 'css'], avoid: ['.pi-row']},
        })
        .from(container[0].innerHTML)
        .toPdf()
        .save();*/
    apiDownloadReport(`${REPORTSERVER_BASEURL}/reports/generatepdf`, JSON.stringify(body), `Order_${order.id}_abs_sheet.pdf`);

    if (this.props.print) {
      setTimeout(() => {
        this.setState({printed: true});
        this.props.toggleOpen();
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && nextProps.print){
      this.setState({printed: false});
    }
  }

  render(){
    let {order, print} = this.props;

    if(!order){
      return null;
    }

    if(print && !this.state.printed) {
      setTimeout(this.handlePrint, 500);
    }

    let awnings = order.awnings.filter(x => IsNullOrUndefined(x.parentOrderAwningId));

    return (
      <div>
        <Modal isOpen={this.props.isOpen}
               toggle={this.props.toggleOpen}
               style={{minWidth: '940px'}}
        >
          <ModalBody>

            <div ref={this.printMeRef} style={{width: '98%', marginTop: '25px'}}>
              <div className="pi-row" style={{marginBottom: '15px'}}>
                <div className="pi-col-sm-4">
                  <h4><img src="img/logo-full.png" alt="Aluxor Logo"/></h4>
                </div>
                <div className="pi-col-sm-4">&nbsp;</div>
                <div className="pi-col-sm-4" style={{textAlign: 'right'}}>
                  <h4>Specifications</h4>
                </div>
              </div>

              { awnings.map((awning, i) => {
                return (
                  <div key={awning.id} style={{pageBreakAfter: i >= awnings.length-1 ? 'auto': 'always'}}>
                    <AwningProductSpecifications awning={awning} showSize={true} showProduct={true} cols={2} />
                    <div style={{marginBottom: '20px'}}>&nbsp;</div>

                    {awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM &&
                    <Aux>
                      <DiagramFA awning={awning} width={800}/>
                      <div style={{marginBottom: '10px'}}/>
                    </Aux>}

                    {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY &&
                    <Aux>
                      <DiagramSD awning={awning} width={800}/>
                      <div style={{marginBottom: '10px'}}/>
                    </Aux>}

                    {awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS &&
                    <Aux>
                      <DiagramST awning={awning} width={800}/>
                      <div style={{marginBottom: '10px'}}/>
                    </Aux>}

                  </div>);
              })}


            </div>

          </ModalBody>
          <ModalFooter>
            <button onClick={this.handlePrint}
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginRight: '10px'}}
            >
              <i className="fa fa-print"/>&nbsp;Print</button>
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.props.toggleOpen}>
              <i className="fa fa-times"/>&nbsp;Close
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
OrderDiagramsSheet.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  print: PropTypes.bool.isRequired,
};

export default OrderDiagramsSheet;