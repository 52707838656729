import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {apiRequest, apiPost} from "../../utils/request";
import {toastr} from 'react-redux-toastr';
import CheckBoxControl from '../common/CheckBoxControl';
import {loadCurrentUser} from '../../actions/userActions';
import { Link } from 'react-router-dom'

class CustomerTermsPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      organisation: null,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.organisationId !== prevProps.organisationId && this.props.organisationId !== 0){
      this.loadData();
    }
  }

  loadData() {
    if(this.props.organisationId > 0) {
      toastr.warning('Loading customer');
      apiRequest(`/api/organisations?id=${this.props.organisationId}`)
        .then(x => this.setState({organisation: x.data}));
    }
  }

  handleSave() {
    let {organisation} = this.state;

    toastr.warning('Saving...');

    apiPost(`/api/organisations/acceptTerms?organisationId=${organisation.id}`, {})
      .then(x => {
        toastr.success('Saved');
        this.setState({organisation: x.data});
        this.props.dispatch(loadCurrentUser());
      });
  }

  handleFieldChange(e){
    let {value, name} = e.target;

    let organisation = {...this.state.organisation};
    organisation[name] = value;

    this.setState({organisation});
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    let {organisation} = this.state;

    if(organisation == null){
      return null;
    }

    let hasAccepted = (() => {
      let general = organisation.acknowledgePrivacyDate !== null && organisation.acknowledgeTermsAndConditionsDate !== null;

      if(organisation.hasSintesi){
        return organisation.acknowledgeTermsAndConditionsSintesiDate !== null && general;
      }  else {
        return general;
      }
    })();

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Customer Terms</h2>
            </div>
          </div>
          <div className={'pi-row'} style={{marginTop: '25px'}}>
            <div className={'pi-col-md-10'}>
                Aluxor terms and conditions must be accepted before allowing full portal access,
                please read the terms before accepting, if you have any questions, please email <a href={'mailto:info@aluxor.com.au'}>info@aluxor.com.au</a>
            </div>
          </div>

          <div className={'form-horizontal'}>
            <div className={'pi-row'} style={{marginTop: '25px'}}>
              <div className={'pi-col-md-10'}>
                <CheckBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Accept Privacy Terms"
                  value={organisation.acknowledgePrivacyDate !== null}
                  name="acknowledgePrivacyDate"
                  disabled={organisation.acknowledgePrivacyDate !== null}
                />
                <a href='/terms/Aluxor-Online-Portal-Privacy-Policy.pdf' target='_blank'>
                  View privacy terms
                </a>
                <CheckBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Accept Terms"
                  value={organisation.acknowledgeTermsAndConditionsDate !== null}
                  name="acknowledgeTermsAndConditionsDate"
                  disabled={organisation.acknowledgeTermsAndConditionsDate !== null}
                />
                <a href='/terms/Aluxor-Awnings-Terms-and-Conditions-of-Sale.pdf' target='_blank'>
                  View terms
                </a>
                { organisation.hasSintesi && <CheckBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Accept Sintesi Terms"
                  value={organisation.acknowledgeTermsAndConditionsSintesiDate !== null}
                  name="acknowledgeTermsAndConditionsSintesiDate"
                  disabled={organisation.acknowledgeTermsAndConditionsSintesiDate !== null}
                /> }
                { organisation.hasSintesi &&
                <a href='/terms/Aluxor-Sintesi-Terms-and-Conditions-of-Sale.pdf' target='_blank'>
                  View Sintesi terms
                </a> }

                { !organisation.allTermsAccepted && <div>
                  {hasAccepted && <button
                    className="btn pi-btn-base pi-btn-icon-big"
                    style={{marginTop: '25px'}}
                    onClick={this.handleSave}>
                    <i className="fa fa-save"></i>&nbsp;Accept Terms
                  </button>}
                  <button
                    style={{marginLeft: hasAccepted ? '10px' : '0px',marginTop: '25px'}}
                    className="btn pi-btn pi-btn-icon-big"
                    onClick={(e) => {
                      e.preventDefault();
                      this.loadData();
                    }}>
                    <i className="fa fa-reload"></i>&nbsp;Reset
                  </button>
                </div> }
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

CustomerTermsPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerTermsPage));
