import React, {useEffect,useState} from "react";
import PropTypes, {func} from "prop-types";
import {apiPost} from "../../../utils/request";
import SelectControl from "../../common/SelectControl";
import CustomerPriceControl from "./CustomerPriceControl";
import {Aux, QUOTE_ADDON_TYPE_INSTALL_COST, QUOTE_ADDON_TYPE_NOTE, QUOTE_ADDON_TYPE_GENERALNOTE} from "../../../constants";

const AwningQuoteAddons = (props) => {

    const viewMode_view = 'view';
    const viewMode_edit = 'edit';
    
    let { awning, quoteAddons, onSaved, showPrice } = props;
    
    const [viewMode, setViewMode] = useState({mode: viewMode_view, addonType: QUOTE_ADDON_TYPE_INSTALL_COST});
    const [selectedCostAddOns, setSelectedCostAddons] = useState([]);
    const [selectedNoteAddOns, setSelectedNoteAddons] = useState([]);
    const [selectedGeneralNoteAddOns, setSelectedGeneralNoteAddons] = useState([]);
    
    useEffect(()=>{
        setSelectedCostAddons([...awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_INSTALL_COST)]);
        setSelectedNoteAddons([...awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_NOTE)]);
        setSelectedGeneralNoteAddons([...awning.quoteAddons.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_GENERALNOTE)]);
    }, [awning.quoteAddons])
    
    function saveAddons(e) {
        setViewMode({mode: viewMode_view, addonType: QUOTE_ADDON_TYPE_INSTALL_COST});
        
        let selectedAddOns = [...selectedCostAddOns, ...selectedNoteAddOns, ...selectedGeneralNoteAddOns];
        
        apiPost(`/api/orders/${awning.orderId}/${awning.id}/awningAddons`, selectedAddOns)
            .then(x => {
                let data = x.data ? x.error ? null : x.data : null;
                if (data !== null) {
                    setSelectedCostAddons([...data.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_INSTALL_COST)]);
                    setSelectedNoteAddons([...data.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_NOTE)]);
                    setSelectedGeneralNoteAddons([...data.filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_GENERALNOTE)]);
                    onSaved();
                }
            });
    }    
    
    return (viewMode.mode === viewMode_view ? 
        <Aux>
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <a href={'#'} onClick={(e) => {
                        e.preventDefault();
                        setViewMode({mode: viewMode_edit, addonType: QUOTE_ADDON_TYPE_INSTALL_COST});
                    }}>Edit Installation Options</a>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>                
                    {selectedCostAddOns
                        .filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_INSTALL_COST)
                        .map(addOn => {
                            return (<CustomerPriceControl
                                showPrice={false}
                                key={addOn.id}
                                labelText={`${addOn.shortDescription} - ${addOn.description}`}
                                retail={addOn.costRetail}
                                discount={0}
                        />);
                    })}
                </div>
            </div>
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <a href={'#'} onClick={(e) => {
                        e.preventDefault();
                        setViewMode({mode: viewMode_edit, addonType: QUOTE_ADDON_TYPE_NOTE});
                    }}>Edit Specifications / Installation Options</a>
                </div>
            </div>
            <div className={"row mb-2"}>
                <div className={"col-12"}>
                    {selectedNoteAddOns
                        .filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_NOTE)
                        .map(addOn => {
                        return (<CustomerPriceControl key={addOn.id}
                                                      showPrice={showPrice}
                                                      labelText={`${addOn.shortDescription} - ${addOn.description}`}
                                                      retail={addOn.costRetail}
                                                      discount={0}
                        />);
                    })}                    
                </div>
            </div>
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <a href={'#'} onClick={(e) => {
                        e.preventDefault();
                        setViewMode({mode: viewMode_edit, addonType: QUOTE_ADDON_TYPE_GENERALNOTE});
                    }}>Edit General Notes</a>
                </div>
            </div>
            <div className={"row mb-2"}>
                <div className={"col-12"}>
                    {selectedGeneralNoteAddOns
                        .filter(x => x.quoteAddonType === QUOTE_ADDON_TYPE_GENERALNOTE)
                        .map(addOn => {
                            return (<CustomerPriceControl key={addOn.id}
                                                          showPrice={showPrice}
                                                          labelText={`${addOn.shortDescription} - ${addOn.description}`}
                                                          retail={addOn.costRetail}
                                                          discount={0}
                            />);
                        })}
                </div>
            </div>
        </Aux>            
            :
        <div className={"row mt-4 mb-4"}>
            <div className={"col-12"}>
                <SelectControl
                    validationMessages={{}}
                    labelText={viewMode.addonType === QUOTE_ADDON_TYPE_INSTALL_COST ? "Awning Installation Costs" : viewMode.addonType === QUOTE_ADDON_TYPE_GENERALNOTE ? "General Notes" : "Specifications / Installation Options"}
                    name="awningAddons"
                    options={quoteAddons.filter(x => x.quoteAddonType === viewMode.addonType)}
                    labelKey={'shortDescription'}
                    valueKey={'id'}
                    simpleValue={false}
                    multi={true}
                    onChange={(v) => viewMode.addonType === QUOTE_ADDON_TYPE_INSTALL_COST ?
                        setSelectedCostAddons(v) : viewMode.addonType === QUOTE_ADDON_TYPE_GENERALNOTE ? setSelectedGeneralNoteAddons(v) : setSelectedNoteAddons(v)}
                    value={viewMode.addonType === QUOTE_ADDON_TYPE_INSTALL_COST ? 
                        selectedCostAddOns : viewMode.addonType === QUOTE_ADDON_TYPE_GENERALNOTE ? selectedGeneralNoteAddOns : selectedNoteAddOns}
                    placeholder="Quote Addons"
                />                
                <button onClick={saveAddons} className="btn pi-btn-base pi-btn-icon-big">Save</button>
                <button onClick={()=> 
                    setViewMode({mode: viewMode_view, addonType: QUOTE_ADDON_TYPE_INSTALL_COST})} 
                        className="ml-2 btn pi-btn-grey pi-btn-icon-big">Cancel</button>
            </div>
            
        </div>
        );
};

AwningQuoteAddons.propTypes = {
    awning: PropTypes.object.isRequired,
    quoteAddons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        shortDescription: PropTypes.string,
        description: PropTypes.string,
        quoteAddonType: PropTypes.number,
    })).isRequired,
    onSaved: PropTypes.func.isRequired,
    showPrice: PropTypes.bool.isRequired,
    
};

export default AwningQuoteAddons;