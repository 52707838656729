import React from 'react';
import PropTypes from 'prop-types';


const CheckBoxControl = (props) => {
  let {labelText, value, onChange, name, style, disabled, visible} = props;
  let isDisabled = disabled === undefined ? false : disabled;
  return visible === false ? null : (
    <div className="checkbox" style={style}>
      <input type='checkbox'
        checked={value}
        onChange={onChange}
        name={name}
        placeholder={labelText}
        disabled={disabled}
      />
      {labelText}
    </div>
  );
};

CheckBoxControl.propTypes = {
  labelText: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default CheckBoxControl;