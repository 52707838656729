import * as types from './actionTypes';

export function beginRequest() {
  return function (dispatch) {
    dispatch({type: types.HTTP_INFO_PENDING_START});
  };
}

export function completeRequest() {
  return function (dispatch) {
    dispatch({type: types.HTTP_INFO_PENDING_END});
  };
}

