import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {toastr} from 'react-redux-toastr';
import {apiRequest, apiPost} from "../../utils/request";
import TextBoxControl from '../common/TextBoxControl';
import SelectControl from '../common/SelectControl';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import {
  ROLE_Administrator,
  validationCreator,
  EmailRegex,
  IsStringValid,
  IsNullOrUndefined,
  PhoneRegex
} from "../../constants";
import {throttle} from 'lodash';
import { Link } from 'react-router-dom';
import {CustomerAdminControl, AdminValidationRules} from "./CustomerAdminControl";
import {loadOrganisations} from "../../actions/organisationActions";
import {loadCurrentUser} from "../../actions/userActions";

const ValidationRules = {

  description: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid trading name') : validationCreator();
    }
  },

  legalName: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid legal name') : validationCreator();
    }
  },

  abn: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /^(\d *?){11}$/g) ? validationCreator(false, 'Please enter a valid abn') : validationCreator();
    }
  },

  phone: {
    validator: (val, state, props) => {
      return !IsStringValid(val, PhoneRegex) ? validationCreator(false, 'Please enter a valid phone number') : validationCreator();
    }
  },

  emailGeneral: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid general email address') : validationCreator();
    }
  },

  emailAccounts: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid accounts email address') : validationCreator();
    }
  },

  emailOrders: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid orders email address') : validationCreator();
    }
  },

  emailFreightReceiver: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid freight receivers email address') : validationCreator();
    }
  },

  addressLine1: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid address') : validationCreator();
    }
  },

  postCode: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /^((\d{4})|(\d{6}))$/g) ? validationCreator(false, 'Please enter a valid postcode') : validationCreator();
    }
  },

  suburb: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid suburb') : validationCreator();
    }
  },

  stateId: {
    validator: (val, state, props) => {
      return !val ? validationCreator(false, 'Please enter a valid state') : validationCreator();
    }
  },
  deliveryAddressLine1: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid address') : validationCreator();
    }
  },

  deliveryPostCode: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /^((\d{4})|(\d{6}))$/g) ? validationCreator(false, 'Please enter a valid postcode') : validationCreator();
    }
  },

  deliverySuburb: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid suburb') : validationCreator();
    }
  },

  deliveryStateId: {
    validator: (val, state, props) => {
      return !val ? validationCreator(false, 'Please enter a valid state') : validationCreator();
    }
  },

  mailingAddressLine1: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid address') : validationCreator();
    }
  },

  mailingPostCode: {
    validator: (val, state, props) => {
      return !IsStringValid(val, /^((\d{4})|(\d{6}))$/g) ? validationCreator(false, 'Please enter a valid postcode') : validationCreator();
    }
  },

  mailingSuburb: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a valid suburb') : validationCreator();
    }
  },

  mailingStateId: {
    validator: (val, state, props) => {
      return !val ? validationCreator(false, 'Please enter a valid state') : validationCreator();
    }
  },

};

class CustomerPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      organisation: null,
      validationMessages: {},
      isValid: true,
      firstLoadValid: false,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.organisationId !== prevProps.organisationId && this.props.organisationId !== 0){
      this.loadData();
    }
  }

  loadData() {
    if(this.props.organisationId > 0) {
      toastr.warning('Loading customer');
      apiRequest(`/api/organisations?id=${this.props.organisationId}`)
        .then(x => this.setState({organisation: x.data}, () => this.validate(true)));
    }
  }

  handleSave() {
    let {organisation} = this.state;
    let {user} = this.props;

    if(!this.validate()){
      //eslint-disable-next-line
      if(user.isInRole(ROLE_Administrator) && confirm('Not all fields are complete, are you sure you want to save?')) {
        console.log('Override validation');
      } else {
        toastr.error('Your profile is not complete!');
        return;
      }
    }

    toastr.warning('Saving...');

    apiPost(`/api/organisations`, {...organisation})
      .then(x => {
        toastr.success('Saved');
        this.setState({organisation: x.data}, () => this.validate(true));
        this.props.dispatch(loadOrganisations());
        this.props.dispatch(loadCurrentUser());
      });
  }

  handleFieldChange(e){
    let {value, name, type, checked} = e.target;

    let organisation = {...this.state.organisation};
    organisation[name] = type === 'checkbox' ? checked : value;

    this.setState({organisation}, throttle(() => {
      this.validate();
    }, 1000));
  }

  handleSelectChange(val, name, valSelector){
    let organisation = {...this.state.organisation};

    organisation[name] = valSelector(val);

    this.setState({organisation}, throttle(this.validate, 1000));
  }

  toggleAdmin(){
    this.setState({ adminCollapse: !this.state.adminCollapse });
  }

  validate(firstLoad){
    //console.log('called');
    let {organisation, adminCollapse} = this.state;
    let {user} = this.props;
    let messages = {};
    let isValid = true;

    let runValidator = (rules) => {
      for (let propName in rules) {

        let rule = rules[propName];
        let val = organisation[propName];

        let valResult = rule.validator(val, this.state, this.props);
        if (!valResult.isValid) {
          messages[propName] = valResult.message;
          isValid = false;
        }
      }
    };

    runValidator(ValidationRules);
    if(user.isInRole(ROLE_Administrator)) {
      let wasValid = isValid;
      runValidator(AdminValidationRules);
      adminCollapse = (wasValid && !isValid) || adminCollapse;
    }

    this.setState({validationMessages: messages, adminCollapse, isValid, firstLoadValid: firstLoad && isValid});

    return isValid;
  }

  componentDidMount() {
    this.loadData();
  }


  render() {

    let {organisation, validationMessages, firstLoadValid} = this.state;
    let {user} = this.props;

    let isValid = true;
    
    if(!IsNullOrUndefined(organisation) && !IsNullOrUndefined(user)){
      if(!user.isInRole(ROLE_Administrator)){
        isValid = Object.keys(validationMessages).length === 0;  
      }      
    }    
    
    return organisation ? (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">
          <div className="pi-row pi-grid-big-margins">
            <div className={'pi-col-md-8'}>
              <h2>Customer Profile</h2>
            </div>
          </div>
          <div className="pi-row">
            {user.isInRole(ROLE_Administrator) &&  <div className={'pi-col-md-4'} style={{marginBottom: '5px'}}>

                <Button color="primary" onClick={this.toggleAdmin}>
                  {this.state.adminCollapse ? 'Close Admin': 'Open Admin' }
                </Button>

                <Button
                  style={{marginLeft: '10px'}}
                  className="btn pi-btn-base pi-btn-icon-big"
                  onClick={(e) =>{
                    e.preventDefault();
                    this.props.history.push(`/manage/new-customer`);
                  }}>
                  <i className="fa fa-plus"></i>&nbsp;New Customer
                </Button>

            </div> }
            <div className={user.isInRole(ROLE_Administrator) ? 'pi-col-md-4 pi-col-md-offset-4': 'pi-col-md-4 pi-col-md-offset-8'} style={{marginBottom: '5px'}}>
              <button
                  disabled={!isValid}
                  className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleSave}>
                <i className="fa fa-save"></i>&nbsp;Save
              </button>
              <button
                style={{marginLeft: '10px'}}                
                className="btn pi-btn pi-btn-icon-big"
                onClick={(e) => {
                  e.preventDefault();
                  this.loadData();
                }}>
                <i className="fa fa-reload"></i>&nbsp;Reset
              </button>
            </div>
          </div>
          <div className="pi-row">


          </div>

          { firstLoadValid && !organisation.allTermsAccepted && <div className={'pi-row'}>
            <div className={'pi-col-md-12'} style={{color: 'red'}}>Customer terms have not yet been accepted, click <Link to={`/manage/customers/${organisation.id}/terms`} style={{color: 'red', textDecoration: 'underline'}}>here</Link> to complete</div>
          </div> }

          <div className={'form-horizontal'}>
            <Collapse isOpen={this.state.adminCollapse}>
              <CustomerAdminControl
                organisation={organisation}
                validationMessages={validationMessages}
                handleFieldChange={this.handleFieldChange}
                creditTerms={this.props.creditTerms}
                handleSelectChange={this.handleSelectChange}
                creditApplicationStatuses={this.props.creditApplicationStatuses}
                organisationStatuses={this.props.organisationStatuses}
                dispatchTypes={this.props.dispatchTypes}
                dispatchCentres={this.props.dispatchCentres}
                fabricMakers={this.props.fabricMakers}
                powderCoaters={this.props.powderCoaters}
                style={{marginTop: '25px', marginBottom: '25px', border: '1px solid #afb8bb', padding:'20px'}}
              />
            </Collapse>


            <div className={'pi-row'} style={{marginTop: '25px'}}>
              <div className={'pi-col-md-5'}>

                <h5 style={{marginLeft: '-15px'}}>Business Details</h5>

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Trading Name"
                  value={organisation.description}
                  name="description"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Legal Name"
                  value={organisation.legalName}
                  name="legalName"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="ABN"
                  value={organisation.abn}
                  name="abn"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Standard Order Note"
                  value={organisation.standardAwningNote}
                  name="standardAwningNote"
                  validationMessages={validationMessages}
                />

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Preferred Payment Method"
                    name="paymentMethodType"
                    options={this.props.paymentMethods}
                    onChange={(v) => this.handleSelectChange(v, 'paymentMethodType', (v) => v.value)}
                    value={organisation.paymentMethodType}
                    placeholder="Preferred Payment Method"
                />

              </div>
              <div className={'pi-col-md-1'}>
                &nbsp;
              </div>
              <div className={'pi-col-md-5'}>

                <h5 style={{marginLeft: '-15px'}}>Business Contact Details</h5>

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Phone"
                  value={organisation.phone}
                  name="phone"
                  type={'phone'}
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Email General"
                  value={organisation.emailGeneral}
                  name="emailGeneral"
                  type={'emailGeneral'}
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Email Orders"
                  value={organisation.emailOrders}
                  name="emailOrders"
                  type={'emailOrders'}
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                    onChange={this.handleFieldChange}
                    labelText="Email Freight Rec."
                    value={organisation.emailFreightReceiver}
                    name="emailFreightReceiver"
                    type={'emailFreightReceiver'}
                    validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Email Accounts"
                  value={organisation.emailAccounts}
                  name="emailAccounts"
                  type={'emailAccounts'}
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Website"
                  value={organisation.websiteURL}
                  name="websiteURL"
                  type={'url'}
                  validationMessages={validationMessages}
                />

              </div>
            </div>

            <div className={'pi-row'} style={{marginTop: '25px'}}>
              <div className={'pi-col-md-10'}>
                <h4 style={{marginLeft: '-15px'}}>Address Details</h4>
              </div>
            </div>

            <div className={'pi-row'} style={{marginTop: '25px'}}>
              <div className={'pi-col-md-4'} style={{paddingRight: '40px'}}>

                <h5 style={{marginLeft: '-15px'}}>Business Premise Address</h5>
                <h6>&nbsp;</h6>
                <h6>&nbsp;</h6>
                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 1"
                  value={organisation.addressLine1}
                  name="addressLine1"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 2"
                  value={organisation.addressLine2}
                  name="addressLine2"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Postcode"
                  value={organisation.postCode}
                  name="postCode"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Suburb"
                  value={organisation.suburb}
                  name="suburb"
                  validationMessages={validationMessages}
                />

                <SelectControl
                  validationMessages={validationMessages}
                  labelText="State"
                  name="stateId"
                  options={this.props.states}
                  valueKey={'id'}
                  labelKey={'description'}
                  onChange={(v) => this.handleSelectChange(v, 'stateId', (v) => v.id)}
                  value={organisation.stateId}
                  placeholder="Delivery State"
                />

              </div>
              <div className={'pi-col-md-4'} style={{paddingRight: '40px'}}>
                <h5 style={{marginLeft: '-15px'}}>Mailing Address</h5>
                <h6><a href={'#'} onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    organisation: {...this.state.organisation,
                      mailingAddressLine1: this.state.organisation.addressLine1,
                      mailingAddressLine2: this.state.organisation.addressLine2,
                      mailingPostCode: this.state.organisation.postCode,
                      mailingSuburb: this.state.organisation.suburb,
                      mailingStateId: this.state.organisation.stateId,
                    }
                  }, this.validate);
                }}>Same as business premise address</a></h6>
                <h6>&nbsp;</h6>
                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 1"
                  value={organisation.mailingAddressLine1}
                  name="mailingAddressLine1"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 2"
                  value={organisation.mailingAddressLine2}
                  name="mailingAddressLine2"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Postcode"
                  value={organisation.mailingPostCode}
                  name="mailingPostCode"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Suburb"
                  value={organisation.mailingSuburb}
                  name="mailingSuburb"
                  validationMessages={validationMessages}
                />

                <SelectControl
                  validationMessages={validationMessages}
                  labelText="State"
                  name="mailingStateId"
                  options={this.props.states}
                  valueKey={'id'}
                  labelKey={'description'}
                  onChange={(v) => this.handleSelectChange(v, 'mailingStateId', (v) => v.id)}
                  value={organisation.mailingStateId}
                  placeholder="Mailing State"
                />
              </div>
              <div className={'pi-col-md-4'}>
                <h5 style={{marginLeft: '-15px'}}>Delivery Address</h5>
                <h6><a href={'#'} onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    organisation: {...this.state.organisation,
                      deliveryAddressLine1: this.state.organisation.addressLine1,
                      deliveryAddressLine2: this.state.organisation.addressLine2,
                      deliveryPostCode: this.state.organisation.postCode,
                      deliverySuburb: this.state.organisation.suburb,
                      deliveryStateId: this.state.organisation.stateId,
                    }
                  }, this.validate);
                }}>Same as business premise address</a></h6>
                <h6><a href={'#'} onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    organisation: {...this.state.organisation,
                      deliveryAddressLine1: this.state.organisation.mailingAddressLine1,
                      deliveryAddressLine2: this.state.organisation.mailingAddressLine2,
                      deliveryPostCode: this.state.organisation.mailingPostCode,
                      deliverySuburb: this.state.organisation.mailingSuburb,
                      deliveryStateId: this.state.organisation.mailingStateId,
                    }
                  }, this.validate);
                }}>Same as mailing address</a></h6>
                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 1"
                  value={organisation.deliveryAddressLine1}
                  name="deliveryAddressLine1"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Address Line 2"
                  value={organisation.deliveryAddressLine2}
                  name="deliveryAddressLine2"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Postcode"
                  value={organisation.deliveryPostCode}
                  name="deliveryPostCode"
                  validationMessages={validationMessages}
                />

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Suburb"
                  value={organisation.deliverySuburb}
                  name="deliverySuburb"
                  validationMessages={validationMessages}
                />

                <SelectControl
                  validationMessages={validationMessages}
                  labelText="State"
                  name="deliveryStateId"
                  options={this.props.states}
                  valueKey={'id'}
                  labelKey={'description'}
                  onChange={(v) => this.handleSelectChange(v, 'deliveryStateId', (v) => v.id)}
                  value={organisation.deliveryStateId}
                  placeholder="Delivery State"
                />

              </div>
            </div>
          </div>


        </div>
      </div>) : null;
  }
}

CustomerPage.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  dispatchTypes: PropTypes.array.isRequired,
  dispatchCentres: PropTypes.array.isRequired,
  creditTerms: PropTypes.array.isRequired,
  creditApplicationStatuses: PropTypes.array.isRequired,
  organisationStatuses: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  return {
    user: state.user,
    organisationId,
    dispatchTypes: state.lookups.dispatchTypes,
    dispatchCentres: state.lookups.dispatchCentres,
    creditTerms: state.lookups.creditTerms,
    creditApplicationStatuses: state.lookups.creditApplicationStatuses,
    organisationStatuses: state.lookups.organisationStatuses,
    states: state.lookups.states,
    paymentMethods: state.lookups.paymentMethods,
    powderCoaters: state.lookups.powderCoaters,
    fabricMakers: state.lookups.fabricMakers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPage));
