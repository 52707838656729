import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {login} from '../../actions/identityActions';
import TextBoxControl from '../common/TextBoxControl';
import {validationCreator, IsStringValid, EmailRegex} from "../../constants";
import {apiPost} from '../../utils/request';
import {toastr} from 'react-redux-toastr';

const ValidationRules = {

  userName: {
    validator: (val, state, props) => {
      return !IsStringValid(val, EmailRegex) ? validationCreator(false, 'Please enter a valid email address') : validationCreator();
    }
  },
  password: {
    validator: (val, state, props) => {
      return !IsStringValid(val) ? validationCreator(false, 'Please enter a password') : validationCreator();
    }
  },
};

const InitialState = {
  isReset: false,
  login: {
    userName: '',
    password: '',
  }
};

class LoginPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = InitialState;

    this.handleLogin = this.handleLogin.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.validate = this.validate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleFieldChange(e) {
    let {value, name, type, checked} = e.target;

    let login = {...this.state.login};
    login[name] = type === 'checkbox' ? checked : value;

    this.setState({login}, this.validate);
  }

  handleCancel(){
    this.setState(InitialState);
  }

  validate(onlyRules = null){
    let {login} = this.state;
    let messages = {};
    let isValid = true;

    let runValidator = (rules) => {
      for (let propName in rules) {
        if(onlyRules === null || onlyRules.findIndex(x => x === propName) >= 0) {
          let rule = rules[propName];
          let val = login[propName];

          let valResult = rule.validator(val, this.state, this.props);
          if (!valResult.isValid) {
            messages[propName] = valResult.message;
            isValid = false;
          }
        }
      }
    };

    runValidator(ValidationRules);

    this.setState({validationMessages: messages});

    return isValid;
  }


  handleLogin(e){
    e.preventDefault();
    if(!this.validate()){
      return;
    }

    let {userName, password} = this.state.login;

    this.props.dispatch(login(userName, password));
  }

  handleReset(e) {
    e.preventDefault();
    let {isReset} = this.state;

    if(isReset){
      if(!this.validate(['userName'])){
        return;
      }
      let {userName} = this.state.login;

      apiPost(`/api/users/reset?userName=${userName}`)
        .then(x => {
          if(x.data && x.data.ok){
            toastr.success('Password reset, please check your email');
            this.setState(InitialState);
          } else {
            toastr.error(`Unable to reset: ${x.data}`);
          }
        });
    } else {
      this.setState({isReset: true});
    }
  }

  render() {
    let {validationMessages, login, isReset} = this.state;

    return (
      <div className="pi-section-w pi-section-white">
        <div className="pi-section pi-padding-bottom-10">

          <form className={'form-horizontal'} onSubmit={this.handleLogin}>

            <div className="pi-row">
              <div className={'pi-col-md-6'}>

                <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Email"
                  value={login.userName}
                  name="userName"
                  type={'email'}
                  validationMessages={validationMessages}
                  style={{marginLeft: '0px'}}
                />

                { !isReset &&  <TextBoxControl
                  onChange={this.handleFieldChange}
                  labelText="Password"
                  value={login.password}
                  name="password"
                  type={'password'}
                  validationMessages={validationMessages}
                  style={{marginLeft: '0px'}}
                /> }
              </div>
            </div>

            <div className="pi-row">
              <div className={'pi-col-md-6'}>&nbsp;</div>
            </div>

            {!isReset && <div className="pi-row">
              <div className={'pi-col-md-6'}>
                <button
                  className="btn pi-btn-base pi-btn-icon-big"
                  type={'submit'}
                  onClick={this.handleLogin}>
                  <i className="fa fa-save"></i>&nbsp;Login
                </button>
                <button
                  style={{marginLeft: '10px'}}
                  className="btn pi-btn pi-btn-icon-big"
                  onClick={this.handleReset}>
                  <i className="fa fa-reload"></i>&nbsp;Forgot my password
                </button>
              </div>
            </div> }

          {isReset && <div className="pi-row">
            <div className={'pi-col-md-6'}>
              <button
                className="btn pi-btn-base pi-btn-icon-big"
                onClick={this.handleReset}
                type={'submit'}>
                <i className="fa fa-save"></i>&nbsp;Reset
              </button>
              <button
                style={{marginLeft: '10px'}}
                className="btn pi-btn pi-btn-icon-big"
                onClick={this.handleCancel}>
                <i className="fa fa-reload"></i>&nbsp;Cancel
              </button>
            </div>
            </div> }

            <div className="pi-row">
              <div className={'pi-col-md-6'}>
                &nbsp;
              </div>
            </div>
          </form>
        </div>
      </div>);
  }
}

LoginPage.propTypes = {
  user: PropTypes.object.isRequired,
  identity: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    identity: state.identity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));