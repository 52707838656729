import {IsNullOrUndefined, MoneyFormatter} from "../../constants";
import React from "react";

export const CalcPriceView = (props) => {
    let {labelText, value, isMoney} = props;
    if(isMoney===undefined){
        isMoney = true;
    }

    return value == null ? null : (
        <div className="pi-row">
            <div className="pi-col-sm-6" style={{fontWeight: 'bold'}}>{labelText}&nbsp;</div>
            <div className="pi-col-sm-6" style={{textAlign: 'right'}}>{IsNullOrUndefined(value) ? 'N/A' : isMoney ? MoneyFormatter.format(value) : value}</div>
        </div>
    );
};