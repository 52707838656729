import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextBoxControl from '../common/TextBoxControl';
import SelectControl from '../common/SelectControl';
import {apiDownload, apiPost, apiRequest} from "../../utils/request";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  ORDER_STATUS_QUOTE,
  COLOUR_STANDARD_CUSTOM_ID,
  deobsfucate,
  obsfucate,
  PORTALSERVICE_SpareQuote,
  Aux,
  SPARE_SD_AWNING_TAPE,
  SPARE_SD_AWNING_BUCKLE,
  IsNullOrUndefined,
  SPARE_SCSZ_AWNING_TAPE,
  PAYMENT_METHOD_CC,
  PRODUCTID_SD, PRODUCTID_MOTORS_SD, isNullEmptyOrWhitespace
} from '../../constants';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import RadioControlGroup from '../common/RadioControlGroup';
import initialState from '../../reducers/initialState';
import { MoneyFormatter } from '../../constants';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ColourSelectorControl from '../common/ColourSelectorControl';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import {loadSpares} from "../../actions/spareActions";
import CheckBoxControl from "../common/CheckBoxControl";

export const LeftRightOptions = [{value: 'Left', label: 'Left'},{value: 'Right', label: 'Right'},{value: 'Both', label: 'Both'}];

const ValidationRules = {
  customerReference: {
    validator: (val) => {
      return {isValid: (val.trim() !== ''), message: `Please enter a Customer Reference Number`};
    }
  },
  paymentMethodType: {
    validator: (val) => {
      return {isValid: (!IsNullOrUndefined(val)), message: `Please select a payment method`};
    }
  }
};

const ButtonWrapper = (props) => {
  let propsToUse = {...props, visible: undefined};
  return (props.visible || props.visible === undefined) ?
    <button {...propsToUse}>{props.children}</button> : null;
};

const CellLeft = (props) => {
  return <div style={{textAlign: 'left'}}>{props.children}</div>;
};

const CellRight = (props) => {
  return <div style={{textAlign: 'right'}}>{props.children}</div>;
};

const CellCenter = (props) => {
  return <div style={{textAlign: 'center'}}>{props.children}</div>;
};

function newOrder(organisation){
  return {
    id: -1,
    customerReference: '',
    organisationId: organisation.id,
    orderStatus: ORDER_STATUS_QUOTE,
    statusChanges: [],
    sensorSets: [],
    orderNote: '',
    spares: [{
      spareId: 1,
      quantity: 1,
      spareDescription: 'Handling',
      retailPrice: 30,
      wholesalePrice: 30,
      myobCode: 'Handling',
      requiresColour: false,
      wholesaleTotal: 30,
      retailTotal: 30,
      canRemove: false,
    }],
    costTotal: 30,
    wholesaleCostTotal: 30,
  };
}

class SpareOrderPage extends Component {

  constructor(props, context) {
    super(props, context);

    let initialOrder = {...newOrder(props.user.organisation)};
    this.state = {
      order: initialOrder,
      validationMessages: {},
      loaded: !props.orderId || props.orderId <= 0,
      selectedProductGroup: null,
      selectedProduct: null,
      spares: props.spares,
      productGroups: [],
      products: [],
      colours: [],
      customColours: [],
      filter: initialState.spares.filter,
      organisations: [],
      organisationDiscount: -1,
      organisationWholesaleOnly: false,
      openColour: false,
      columns: [],
      cartColumns: [],
      selectedSize: null,
      selectedSide: null,
      selectedProjection: null,
      selectedCustomColourId: null,
      selectedStandardColourId: null,
      selectedSpare: null,
      selectedProjectionMultiplier: 1,
      selectedImage: null,
      showCart: false,
      saving: false,
      selectedIncludeBuckles: false,
    };

    this.setColumns = this.setColumns.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.fetchProductColours = this.fetchProductColours.bind(this);
    this.fetchCustomColours = this.fetchCustomColours.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.filterSpares = this.filterSpares.bind(this);
    this.addToOrder = this.addToOrder.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.toggleOpenColour = this.toggleOpenColour.bind(this);
    this.handleOrderFieldChange = this.handleOrderFieldChange.bind(this);
    this.loadOrder = this.loadOrder.bind(this);
    this.incrementDecrementCart = this.incrementDecrementCart.bind(this);
    this.createSparesToAdd = this.createSparesToAdd.bind(this);
    this.additionalInformationComplete = this.additionalInformationComplete.bind(this);
    this.selectedSpareQuantityCalculation = this.selectedSpareQuantityCalculation.bind(this);
    this.addPcSurcharges = this.addPcSurcharges.bind(this);
    this.toggleImage = this.toggleImage.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
    this.handleBulkQty = this.handleBulkQty.bind(this);
  }

  handleBulkQty(e, spare, current){
    e.preventDefault();
    let val = e.target.value;
    if(val === '') {
      return
    }
    this.incrementDecrementCart(e, spare, false, val);
  }

  toggleCart(e) {
    e.preventDefault();
    this.setState({showCart: !this.state.showCart});
  }

  toggleImage(img) {
    this.setState({selectedImage: img ? img : null});
  }

  setFilters() {
    let {spares} = this.props;
    if (!spares.loaded || this.state.products.length > 0 || this.state.productGroups.length > 0 || this.state.spares.loaded) {
      return;
    }
    this.setState({spares: {...spares}});
  }

  fetchProducts(prevState) {
    let {selectedProductGroup} = this.state;
    let {organisationId} = this.state.order;

    if (prevState && prevState.selectedProductGroup === selectedProductGroup && organisationId === prevState.order.organisationId) {
      return;
    }
    if (!selectedProductGroup) {
      this.setState({selectedProduct: null});
    } else {
      apiRequest(`/api/products/spares?productGroupId=${selectedProductGroup}&organisationId=${organisationId}`)
        .then(x => {
          if (!x.error) {
            this.setState({products: x.data, selectedProduct: null});
          }
        });
    }
  }

  fetchProductColours(prevState) {
    let {selectedProduct} = this.state;
    if (prevState && prevState.selectedProduct === selectedProduct) {
      return;
    }
    if (!selectedProduct) {
      this.setState({colours: []});
    } else {
      apiRequest(`/api/colours/product?productId=${selectedProduct}`)
        .then(x => {
          if (!x.error) {
            this.setState({colours: x.data.map(x => { return {id: x.value, colourDescription: x.label}})});
          }
        });
    }
  }

  fetchCustomColours() {
    apiRequest(`/api/colours/customframe`)
      .then(x => {
        if (!x.error) {
          this.setState({customColours: x.data});
        }
      });
  }

  filterSpares(prevState) {
    //filter based on selected product and group

    let {selectedProductGroup, selectedProduct} = this.state;
    let {spares} = this.props;

    if (prevState && prevState.selectedProductGroup === selectedProductGroup && prevState.selectedProduct === selectedProduct) {
      return;
    }

    let newSpares = {
      ...spares, items: spares.items.filter(s => {
        if(IsNullOrUndefined(s.spareGroupId)){
          return  false;
        } else if (s.productGroups.length === 0 && s.products.length === 0) {
          return true;
        } else if (s.productGroups.length > 0 && s.productGroups.findIndex(i => i.id === selectedProductGroup) >= 0 && s.products.length === 0) {
          return true;
        } else if (s.products.length > 0 && s.products.findIndex(i => i.id === selectedProduct) >= 0) {
          return true;
        }
        return false;
      })
    };

    this.setState({spares: newSpares, filter: initialState.spares.filter});
  }

  updateFilter(filter) {
    this.setState({filter});
  }

  addPcSurcharges(order){
    //get the distinct custom PC colours


    let getItem = (spare) => {
      let customColourDescription = spare.customColourDescription;
      let colourDescription = spare.colourDescription;

      let pcItemFilter = this.props.spares.items.filter(x => x.id === 209);
      if (pcItemFilter.length !== 1) {
        console.error('Unable to lookup custom PC surcharge');
        return null;
      }
      let pcItem = pcItemFilter[0];

      return {
        ...pcItem,
        id: -1,
        spareId: 209, //TODO: Move to constant
        quantity: 1,
        wholesaleTotal: pcItem.wholesalePrice,
        retailTotal: pcItem.retailPrice,
        canRemove: false,
        colourId: spare.colourId,
        customColourId: spare.customColourId,
        colourDescription,
        customColourDescription,
        spareDescription: `Custom PC surcharge${customColourDescription !== '' ? ` (${customColourDescription})` : colourDescription !== '' ? ` (${colourDescription})` : ''}`,
      };
    };

    let colours = uniqBy(order.spares.filter(x => x.customColourId != null && x.spareId !== 209), x => x.customColourId);

    order.spares = [...order.spares.filter(x => x.spareId !== 209)];

    for(let i = 0; i < colours.length; i++) {
      order.spares.push(getItem(colours[i]));
    }

    order.costTotal = order.spares.reduce((a, b) => {
      return a + b.retailTotal;
    }, 0);

    order.wholesaleCostTotal = order.spares.reduce((a, b) => {
      return a + b.wholesaleTotal;
    }, 0);

    return order;
  }

  incrementDecrementCart(e, spare, increment = false, setValue = null) {
    if (e) {
      e.preventDefault();
    }

    let order = {...this.state.order};

    let spareMatcher = (x) => {      
      return (x.spareId === spare.spareId && x.colourId === spare.colourId && x.customColourId === spare.customColourId
        && x.myobCode === spare.myobCode && x.spareDescription === spare.spareDescription && x.side === spare.side);
    };

    if (order.spares.findIndex(spareMatcher) >= 0) {
      order.spares = order.spares.map(x => {
        let retVal = {...x};
        if(spareMatcher(retVal)){
          if(setValue != null){
            retVal.quantity = setValue;
          } else if(increment){
            retVal.quantity += 1;
          } else {
            if(spare.calculatedQuantity){
              retVal.quantity = 0;
            } else {
              retVal.quantity -= 1;
            }
          }          
          retVal.wholesaleTotal = retVal.quantity * retVal.wholesalePrice;
          retVal.retailTotal = retVal.quantity * retVal.retailPrice;
          if(retVal.quantity <= 0){
            return null;
          }
        }
        return retVal;
      }).filter(x => x != null);

      order = this.addPcSurcharges(order);

      this.setState({order}, this.setColumns);
    }
  }

  createSparesToAdd(spare, selectedColour = false){
    let colourFromId = (id, colours) => {
      if(id == null) {
        return '';
      }
      return colours.filter(x => x.id === id)[0].colourDescription;
    };
    let customColourFromId = (id, colours) => {
      if(id == null) {
        return '';
      }
      return colours.filter(x => x.value === id)[0].label;
    };

    let {selectedCustomColourId, customColours, selectedStandardColourId, colours, selectedIncludeBuckles} = this.state;

    let customColourDescription = selectedColour ? customColourFromId(selectedCustomColourId, customColours) : '';
    let colourDescription = selectedColour ? colourFromId(selectedStandardColourId, colours) : '';
    let calcQuantity = this.selectedSpareQuantityCalculation();
    let desc = `${spare.description}${customColourDescription !== '' ? ` (${customColourDescription})` : colourDescription !== '' ? ` (${colourDescription})` : ''}`;

    let quantity =  calcQuantity ? calcQuantity.quantity : 1;

    if(calcQuantity){
      desc += ` calculated ${(calcQuantity.calculatedQuantity).toFixed(2)} for ${(calcQuantity.itemQuantity)} item${calcQuantity.itemQuantity > 1 ? 's': ''}`;
    }

    let getBaseSpare = (s) => {
      return {
        ...s,
        myobCodeLeft: undefined,
        myobCodeRight: undefined,
        spareId: s.id,
        id: -1,
        quantity,
        calculatedQuantity: calcQuantity ? calcQuantity.calculatedQuantity : null,
        itemQuantity: calcQuantity ? calcQuantity.itemQuantity : null,
        wholesalePrice: s.wholesalePrice,
        wholesaleTotal: quantity * s.wholesalePrice,
        retailTotal: quantity * s.retailPrice,
        colourId: selectedColour ? this.state.selectedStandardColourId : null,
        customColourId: selectedColour ? this.state.selectedCustomColourId : null,
        colourDescription,
        customColourDescription,
        spareDescription: desc,
        spareSizeId: this.state.selectedSize ? this.state.selectedSize.id : null,
        spareSizeDescription: this.state.selectedSize ? this.state.selectedSize.size : null,
        side: null,
        myobCode: s.myobCode,
        canRemove: true,
        includeBuckles: !IsNullOrUndefined(calcQuantity) ? selectedIncludeBuckles : false,
      };
    };

    let baseSpareToAdd = getBaseSpare(spare);

    let returnValues = [];


    let {selectedSize, selectedSide} = this.state;
    if(selectedSize) {
      let spareSize = {
        ...baseSpareToAdd,
        retailPrice: selectedSize.retailPrice,
        retailTotal: selectedSize.retailPrice,
        wholesalePrice: selectedSize.wholesalePrice,
        wholesaleTotal: selectedSize.wholesalePrice,
        myobCode: selectedSize.myobCode,
      };
      let left = {...spareSize, side: 'Left', myobCode: selectedSize.myobCodeLeft};
      let right = {...spareSize, side: 'Right', myobCode: selectedSize.myobCodeRight};

      if (selectedSide === 'Both') {
        returnValues = [left, right];
      } else if (selectedSide === 'Left') {
        returnValues = [left];
      } else if (selectedSide === 'Right') {
        returnValues = [right];
      } else {
        returnValues = [spareSize];
      }
    } else if(selectedSide) {
      let spareSize = {...baseSpareToAdd};
      let left = {...spareSize, side: 'Left', myobCode: spare.myobCodeLeft};
      let right = {...spareSize, side: 'Right', myobCode: spare.myobCodeRight};

      if (selectedSide === 'Both') {
        returnValues = [left, right];
      } else if (selectedSide === 'Left') {
        returnValues = [left];
      } else if (selectedSide === 'Right') {
        returnValues = [right];
      }

    } else if(selectedIncludeBuckles && !IsNullOrUndefined(calcQuantity)){

      let buckleFilter = this.state.spares.items.filter(x => x.id === SPARE_SD_AWNING_BUCKLE);
      if(buckleFilter.length !== 1){
        console.error('Unable to add buckles, can find spare by ID');
        returnValues = [baseSpareToAdd];
      } else {
        let buckle = {... getBaseSpare({...buckleFilter[0]}),
          quantity: calcQuantity.itemQuantity,
          calculatedQuantity: null,
          itemQuantity: null,
          spareDescription: buckleFilter[0].description,
          spareId: buckleFilter[0].id,
          includeBuckles: false,
          wholesaleTotal: calcQuantity.itemQuantity * buckleFilter[0].wholesalePrice,
          retailTotal: calcQuantity.itemQuantity * buckleFilter[0].retailPrice,
        };
        returnValues = [baseSpareToAdd, buckle];
      }
    }
    else {
      returnValues = [baseSpareToAdd];
    }

    return returnValues;
  }


  addToOrder(e, spare, selectedColour = false) {
    if (e) {
      e.preventDefault();
    }

    let order = {...this.state.order};

    let spareMatcher = (x, y) => {
      return x.spareId === y.spareId &&
              x.colourId === y.colourId &&
              x.customColourId === y.customColourId &&
              x.myobCode === y.myobCode && !x.requiresProjection;
    };

    if ((spare.sizes.length > 0 && !selectedColour)
        || (spare.requiresColour && !selectedColour)
        || (spare.requiresProjection && !selectedColour)
        || (spare && spare.myobCodeLeft && spare.myobCodeRight && !selectedColour)) {
      this.setState({
        openColour: true,
        selectedSpare: spare,
        selectedSize: null,
        selectedSide: null,
        selectedProjection: null,
        selectedCustomColourId: null,
        selectedStandardColourId: null,
        selectedProjectionMultiplier: 1,
        selectedIncludeBuckles: false,
      });
    } else {

      let spares = this.createSparesToAdd(spare, selectedColour);
      let sparesToAdd = spares.filter(x => order.spares.findIndex(y => spareMatcher(x,y)) < 0);

      order.spares = order.spares.map(x => {
        let retVal = {...x};

        let existing = spares.filter(y => spareMatcher(retVal, y));
        if (existing.length === 1) {
          retVal.quantity += existing[0].quantity;
          retVal.wholesaleTotal = retVal.quantity * retVal.wholesalePrice;
          retVal.retailTotal = retVal.quantity * retVal.retailPrice;
        }
        return retVal;
      });

      for(let i = 0; i< sparesToAdd.length; i++){
        order.spares.push(sparesToAdd[i]);
      }

      order = this.addPcSurcharges(order);

      this.setState({order, openColour: false,
        selectedSize: null,
        selectedSide: null,
        selectedProjection: null,
        selectedProjectionMultiplier: 1,
        selectedCustomColourId: null,
        selectedStandardColourId: null,
        selectedSpare: null,
        showCart: true,
      }, this.setColumns);
    }
  }

  saveOrder(e, goToPreview = false) {
    e.preventDefault();

    let messages = {};
    let {order} = this.state;
    let toPost = {...this.state.order};

    let isValid = true;

    for (let propName in ValidationRules) {
      let rule = ValidationRules[propName];
      let val = order[propName];
      let valResult = rule.validator(val);
      if (!valResult.isValid) {
        messages[propName] = valResult.message;
        isValid = false;
      }
    }

    if(isValid) {
      this.setState({saving: true});
      apiPost(`/api/orders/${this.state.order.id}`, toPost)
        .then(x => {
          this.setState({order: {...x.data}, saving: false});
          if (goToPreview) {
            this.props.history.push(`/orders/view/${obsfucate(x.data.id)}`);
          } else {
            this.props.history.push('/orders/list');
          }
        })
        .catch(x => {
          console.log(x);
          this.setState({order: toPost, saving: false});
        });
    } else {
      this.setState({validationMessages: messages});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.setFilters();
    this.fetchProducts(prevState);
    this.filterSpares(prevState);
    this.fetchProductColours(prevState);

    if((prevState.order.organisationId !== this.state.order.organisationId
        && this.state.order.organisationId > 0 && this.state.organisations.length > 0) || this.state.organisationDiscount === -1){
      let org = this.state.organisations.filter(x => x.id === this.state.order.organisationId);
      if(org.length === 1){
        let spares = this.state.order.spares.map(x => {
          let retVal = {...x};
          if(retVal.spareId !== 1) {
            retVal.wholesaleTotal = retVal.wholesalePrice * retVal.quantity;
          }
          return retVal;
        });

        this.setState({
          organisationDiscount: org[0].discountSpareParts,
          organisationWholesaleOnly: org[0].wholesaleOnly,
          order: {
            ...this.state.order, spares,
            organisationId: org[0].id,
            costTotal: spares.reduce((a, b) => {
              return a + b.retailTotal;
            }, 0),
            wholesaleCostTotal: spares.reduce((a, b) => {
              return a + b.wholesaleTotal;
            }, 0)
          }
        }, ()=> {
          apiRequest(`/api/productgroups/spares?organisationId=${this.state.order.organisationId}`)
            .then(x => {
              if (!x.error) {
                this.setState({productGroups: x.data});
                this.props.dispatch(loadSpares(org[0].id));
              }
            });
          this.setColumns();
        });
      }
    }
  }

  loadOrder(){
    if (this.props.orderId > 0) {
      apiRequest(`/api/orders?id=${this.props.orderId}`)
        .then(x => {
          if (!x.error) {
            let order = {...x.data[0]};
            this.setState({order, loaded: true, showCart: true, saving: false}, this.setColumns);
          }
        });
    } else {
      let order = newOrder(this.props.user.organisation);
      this.setState({order});
    }
  }

  componentWillMount() {
    this.loadOrder();
    this.setColumns();
    apiRequest(`/api/organisations`)
      .then(x => {
        if (!x.error) {
          this.setState({organisations: x.data});
        }
      });

    this.fetchCustomColours();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.orderId !== this.state.order.id && nextProps.orderId > 0) {
      this.setState({loaded: false});
      apiRequest(`/api/orders?id=${nextProps.orderId}`)
        .then(x => {
          if (!x.error) {
            let order = {...x.data[0]};
            this.setState({order, loaded: true});
          }
        });
    }

    if (nextProps.user.organisation.id !== this.state.order.organisationId && this.state.order.organisationId <= 0) {
      this.setState({order: {...this.state.order, organisationId: nextProps.user.organisation.id}});
    }
  }

  setColumns() {
    let columns = [{
      Header: <CellLeft>Description</CellLeft>,
      accessor: 'description',
      filterMethod: (filter, rows) => {
        let retVal = rows ? rows.filter(r => r._subRows ?
            r._subRows.findIndex(s => s.description ? s.description.toLowerCase().indexOf(filter.value) >= 0 : false) >= 0 : false): [];
        return retVal;
      },
      filterAll: true,
      Aggregated: row => <span />,
      Cell: row => {
        return (<span>{row.value}{row.original && row.original.imageName && <i className={'fa fa-image fa-lg'}
                                                                                style={{cursor: 'pointer', marginLeft: '10px'}}
                                                                               onClick={(e) => {
                                                                                 e.preventDefault();
                                                                                 this.toggleImage(row.original.imageName);
                                                                               }} />}</span>);
      },
    },
      {
        id: 'spareGroupDescription',
        Header: <CellLeft>Group</CellLeft>,
        accessor: c => c.spareGroupDescription == null ? 'General' : c.spareGroupDescription,
        filterMethod: (filter, row) => {
          return row[filter.id] !== null ? row[filter.id].toLowerCase().indexOf(filter.value) >= 0 : false;
        },
        sortMethod: (a, b) => {
          let items = this.state.spares.items;
          let aF = items.filter(x => x.spareGroupDescription === a);
          let bF = items.filter(x => x.spareGroupDescription === b);
          if(aF.length > 0  && bF.length > 0){

            let aVal = parseInt(aF[0].spareGroupOrderBy);
            let bVal = parseInt(bF[0].spareGroupOrderBy);
            return aVal > bVal ? 1 : -1;
          }
          return -1;
        },
      },
      {
        id: 'retailPrice',
        Header: 'Retail (range)',
        accessor: c => {
          if(this.state.organisationWholesaleOnly){
            return null;
          }

          if(c.sizes.length > 0){
            let max = maxBy(c.sizes, x => x.retailPrice).retailPrice;
            let min = minBy(c.sizes, x => x.retailPrice).retailPrice;
            return `${MoneyFormatter.format(min)} - ${MoneyFormatter.format(max)}`;
          }

          return MoneyFormatter.format(c.retailPrice);
        },
        maxWidth: 200,
        Aggregated: <span />,
        Cell: row => <CellRight>{row.value}</CellRight>,
        show: false, //!this.state.organisationWholesaleOnly,
      },
      {
        id: 'wholesalePrice',
        Header: `Wholesale${this.state.organisationWholesaleOnly ? '' : ``}`,
        accessor: c => {
          if(c.sizes.length > 0){
            let max = maxBy(c.sizes, x => x.wholesalePrice).wholesalePrice;
            let min = minBy(c.sizes, x => x.wholesalePrice).wholesalePrice;
            //return `${MoneyFormatter.format(((100 - this.state.organisationDiscount) / 100) * min)} - ${MoneyFormatter.format(((100 - this.state.organisationDiscount) / 100) * max)}`;
            return `${MoneyFormatter.format(min)} - ${MoneyFormatter.format(max)}`;
          }
          return MoneyFormatter.format(c.wholesalePrice);
        },
        maxWidth: 200,
        Aggregated: row => {
          return (<span />);
        },
        Cell: row => <CellRight>{row.value}</CellRight>,
      },
      {
        id: 'actions',
        Header: '',
        Filter: ({filter, onChange}) => <span />,
        accessor: c => {
          return (
            <span>
                <ButtonWrapper
                  onClick={(e) => this.addToOrder(e, c)}
                >
                  <i className="fa fa-plus"/>
                </ButtonWrapper>
              </span>
          );
        },
        maxWidth: 100,
        Aggregated: row => {
          return (<span />);
        },
      }, {
        accessor: 'orderBy',
        sortMethod: (a, b) => {
          let aVal = parseInt(a);
          let bVal = parseInt(b);
          return aVal > bVal ? 1 : -1;
        },
        show: false,
      }];
    let cartColumns = [{
      Header: <CellLeft>Description</CellLeft>,
      accessor: 'spareDescription',
    },
      {
        Header: <CellLeft>Side</CellLeft>,
        accessor: 'side',
        maxWidth: 65,
      },
      {
        Header: <CellLeft>Size</CellLeft>,
        accessor: 'spareSizeDescription',
        maxWidth: 75,
      },
      {
        id: 'retailPrice',
        Header: <CellRight>Retail</CellRight>,
        accessor: c => !this.state.organisationWholesaleOnly ? MoneyFormatter.format(c.retailPrice) : null,
        maxWidth: 80,
        Cell: row => <CellRight>{row.value}</CellRight>,
        show: false, //!this.state.organisationWholesaleOnly,
        //Footer: (<CellRight><strong>{!this.state.organisationWholesaleOnly ? MoneyFormatter.format(this.state.order.costTotal) : ''}</strong></CellRight>),
      },
      {
        id: 'wholesalePrice',
        Header: <CellRight>Wholesale</CellRight>,
        accessor: c => MoneyFormatter.format(c.wholesalePrice),
        maxWidth: 80,
        Cell: row => <CellRight>{row.value}</CellRight>,
      },
      {
        Header: <CellCenter>Qty</CellCenter>,
        accessor: 'quantity',
        maxWidth: 65,
        Cell: row => <CellCenter>{row.original.canRemove ?
          <input
            style={{width: '45px', textAlign: 'right'}}
            type={'number'}
            onChange={(e) => this.handleBulkQty(e, row.original, row.value)} value={row.value} /> :
          row.value}</CellCenter>,
      },
      {
        id: 'total',
        Header: <CellRight>Total</CellRight>,
        accessor: c => MoneyFormatter.format(c.wholesaleTotal),
        Footer: (<CellRight>
          <strong><span style={{float: 'left'}}>GST:</span>{MoneyFormatter.format(this.state.order.wholesaleCostTotal * 0.1)}<br />
            <span style={{float: 'left'}}>Total:</span>{MoneyFormatter.format(this.state.order.wholesaleCostTotal * 1.1)}</strong>
        </CellRight>),
        maxWidth: 110,
        Cell: row => <CellRight>{row.value}</CellRight>,
      },
      {
        id: 'actions',
        Header: '',
        Filter: ({filter, onChange}) => <span />,
        accessor: c => {
          return (
            <span>
              {c.canRemove && !c.calculatedQuantity && <ButtonWrapper
                onClick={(e) => this.incrementDecrementCart(e, c, true)}
              >
                  <i className="fa fa-plus"/>
                </ButtonWrapper> }&nbsp;
              {c.canRemove && <ButtonWrapper
                  onClick={(e) => this.incrementDecrementCart(e, c, false)}
                >
                  <i className="fa fa-minus"/>
                </ButtonWrapper> }
              </span>
          );
        },
        maxWidth: 80,
      }

        ];

    this.setState({
      columns,
      cartColumns
    });
  }

  handleOrderFieldChange(e){
    let x = {...this.state.order};
    let messages = {...this.state.validationMessages};

    if(e.target.type==="checkbox"){
      x[`${e.target.name}`] = e.target.checked;
    } else {
      x[`${e.target.name}`] = e.target.value;
    }

    let validation = ValidationRules[`${e.target.name}`];
    if(validation) {
      let validationResult = validation.validator(e.target.value);
      if (!validationResult.isValid) {
        messages[`${e.target.name}`] = validationResult.message;
      } else {
        messages[`${e.target.name}`] = '';
      }
    }

    this.setState({order: x, validationMessages: messages});
  }

  toggleOpenColour() {
    this.setState({openColour: !this.state.openColour});
  }

  additionalInformationComplete(){
    let {selectedSpare, selectedSize, selectedSide, selectedCustomColourId, selectedStandardColourId, selectedProjection, selectedProjectionMultiplier} = this.state;
    let messages = [];

    if(!selectedSpare){
      return messages;
    }

    if((selectedSpare.myobCodeLeft || selectedSpare.myobCodeRight) && !selectedSide){
      messages.push('Please select a side for this part');
    }

    if(selectedSpare.sizes.length > 0 && !selectedSize){
      messages.push('Please select a size for this part');
    }

    if(selectedSpare.requiresColour &&
      ((selectedStandardColourId === COLOUR_STANDARD_CUSTOM_ID && !selectedCustomColourId) ||
        (!selectedStandardColourId && !selectedCustomColourId))){
      messages.push('Please select a colour this part');
    }
    if(selectedSpare.requiresProjection && (selectedProjection <= 0 || selectedProjection == null)){
      messages.push('Please specify a projection for this part');
    }

    if(selectedSpare.requiresProjection && (selectedProjectionMultiplier <= 0 || selectedProjectionMultiplier == null)){
      messages.push('Please specify the number required');
    }


    return messages;
  }

  selectedSpareQuantityCalculation(){
    let {selectedSpare, selectedProjection, selectedProjectionMultiplier} = this.state;

    if(selectedSpare && selectedProjection && selectedSpare.requiresProjection && selectedProjection > 0 && selectedProjectionMultiplier > 0){
      let x = eval('`'+ selectedSpare.quantityFormula +'`');
      let y = eval(x)/1000;
      let formulaWorking = selectedSpare.quantityFormula.replace('${selectedProjection}',selectedProjection).replace('${selectedProjectionMultiplier}', selectedProjectionMultiplier).replace('*','x');
      return {calculatedQuantity:  y, quantity: Math.ceil(y), formulaWorking, itemQuantity: selectedProjectionMultiplier};
    } else {
      return null;
    }
  }

  render() {

    let {siteSettings} = this.props;
    let {spares, filter, order, saving, validationMessages} = this.state;

    let canViewScreen = this.props.user && this.props.user.portalServices.filter(x => x.id === PORTALSERVICE_SpareQuote).length === 1;

    if(!canViewScreen){
      return <div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              You don't have permission to edit this order
            </h4>
          </div>
        </div>
      </div>;
    }

    let additionalComplete = this.additionalInformationComplete();
    let quantityFromFormula = this.selectedSpareQuantityCalculation();

    return this.state.loaded ? (
      <div className="pi-section">
        <Modal isOpen={this.state.openColour}
               toggle={this.toggleOpenColour}
        >
          <ModalBody>
            <div className={'pi-row'} style={{paddingLeft: '15px'}}>
              <div className={'pi-col-md-12'}>
                <h6>{this.state.selectedSpare ? this.state.selectedSpare.description : ''}</h6>

              {(this.state.selectedSpare && this.state.selectedSpare.requiresColour) && <ColourSelectorControl
                onChange={(val)=> this.setState({selectedStandardColourId: val.selectedStandardColourId, selectedCustomColourId: val.selectedCustomColourId})}
                label='Spare'
                customColours={this.state.customColours}
                standardColours={this.state.colours}
                selectedStandardColourId={this.state.selectedStandardColourId}
                selectedCustomColourId={this.state.selectedCustomColourId}
                name={'spare'}
                validationMessages={{}}
                disabled={false}
                awningId={0}
                radioStyle={{paddingLeft: '15px'}}
              />}

              {(this.state.selectedSpare && this.state.selectedSpare.sizes.length > 0) && <SelectControl
                validationMessages={{}}
                labelText="Size"
                name="spareSizeId"
                options={this.state.selectedSpare.sizes}
                simpleValue={false}
                onChange={(val) => this.setState({selectedSize: val})}
                value={this.state.selectedSize}
                valueKey={'id'}
                labelKey={'size'}
                placeholder="Select Size"
              />}

              {((this.state.selectedSize && this.state.selectedSize.myobCodeLeft && this.state.selectedSize.myobCodeRight) ||
                (this.state.selectedSpare && this.state.selectedSpare.myobCodeLeft && this.state.selectedSpare.myobCodeRight))&&
              <RadioControlGroup
                name={`side_spare_side`}
                onChange={(x, e) => {
                  this.setState({selectedSide: x.simpleValue});
                }}
                value={this.state.selectedSide}
                labelText='Side'
                validationMessages={{}}
                options={LeftRightOptions}
                style={{paddingLeft: '15px'}}
              />}

                {this.state.selectedSpare && this.state.selectedSpare.requiresProjection &&
                <TextBoxControl
                  isDebounce={true}
                  debounceeTimeOut={300}
                  onChange={(e) => {
                    e.preventDefault();
                    let i = parseInt(e.target.value);
                    if(i > 0){
                      this.setState({selectedProjection: i});
                    }
                  }}
                  labelText="Please enter awning projection"
                  value={this.state.selectedProjection != null ? this.state.selectedProjection.toString() : '0'}
                  name="projection"
                  type="number"
                  validationMessages={{}}
                /> }

                {this.state.selectedSpare && this.state.selectedSpare.requiresProjection &&
                <TextBoxControl
                  isDebounce={true}
                  debounceeTimeOut={300}
                  onChange={(e) => {
                    e.preventDefault();
                    let i = parseInt(e.target.value);
                    if(i > 0){
                      this.setState({selectedProjectionMultiplier: i});
                    }
                  }}
                  labelText="Number required"
                  value={this.state.selectedProjectionMultiplier != null ? this.state.selectedProjectionMultiplier.toString() : '0'}
                  name="projectionMultiplier"
                  type="number"
                  validationMessages={{}}
                /> }

                {this.state.selectedSpare && this.state.selectedSpare.requiresProjection && quantityFromFormula && quantityFromFormula.quantity > 0 &&
                <Aux>
                  <div className='pi-row' style={{marginLeft: '0px'}}>
                    <div className='pi-col-8'>
                      <strong>Formula to calculate: </strong>
                    </div>
                    <div className='pi-col-4'>
                      {quantityFromFormula.formulaWorking}
                    </div>
                  </div>
                  <div className='pi-row' style={{marginLeft: '0px'}}>
                    <div className='pi-col-8'>
                      <strong>Lineal meters required: </strong>
                    </div>
                    <div className='pi-col-4'>
                      {quantityFromFormula.calculatedQuantity}
                    </div>
                  </div>
                </Aux>
                }
                            {this.state.selectedSpare && (this.state.selectedSpare.id === SPARE_SD_AWNING_TAPE || this.state.selectedSpare.id === SPARE_SCSZ_AWNING_TAPE) &&
                <CheckBoxControl
                    onChange={(e) => {
                      this.setState({selectedIncludeBuckles: e.target.checked});
                    }}
                    labelText="Add buckles to order"
                    value={this.state.selectedIncludeBuckles}
                    name="isActive"
                />}

              <ul style={{color:'red'}}>
                {additionalComplete.map((x,i) => <li key={i}>{x}</li>)}
              </ul>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            { additionalComplete.length === 0 && <button className="btn pi-btn pi-btn-base pi-btn-icon-big"
                                                         onClick={(e) => this.addToOrder(e, this.state.selectedSpare, true)}>
              <i className="fa fa-times"/>&nbsp;Select
            </button> }
            <button className="btn pi-btn pi-btn-icon-big"
                    onClick={this.toggleOpenColour}>
              <i className="fa fa-times"/>&nbsp;Cancel
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.selectedImage!=null}
               toggle={() => this.toggleImage()}
        >
          <ModalBody>
            <div className={'pi-row'} style={{paddingLeft: '15px'}}>
              <div className={'pi-col-md-12'} style={{textAlign: 'center'}}>
                <img className={''} src={`/img/powder_coating_sheet/${this.state.selectedImage}`} style={{width:'100px'}} />
              </div>
            </div>
          </ModalBody>
        </Modal>

        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              {this.state.order.id > 0 ? `Edit Order #${this.state.order.id}`: 'Create Spares Order'}
            </h4>

            <div className="pi-row" style={{marginBottom: '10px'}}>
              <div className="pi-col-md-8">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.goBack(-1);
                  }}
                  className="btn pi-btn pi-uppercase pi-letter-spacing">
                  <i className="fa fa-angle-left"/>&nbsp;Back
                </button>
              </div>
            </div>

            <SelectControl
              validationMessages={this.state.validationMessages}
              labelText="Customer Name"
              name="organisationId"
              options={this.state.organisations}
              simpleValue={true}
              onChange={(val) => this.setState({order: {...this.state.order, organisationId: val}})}
              value={order.organisationId}
              valueKey={'id'}
              labelKey={'description'}
              placeholder="Select Organisation"
            />

            <TextBoxControl
              onChange={this.handleOrderFieldChange}
              labelText="Customer Order Ref"
              value={order.customerReference}
              name="customerReference"
              validationMessages={validationMessages}
            />

            <SelectControl
                validationMessages={validationMessages}
                labelText="Payment Method"
                name="paymentMethodType"
                options={this.props.paymentMethods}
                onChange={(val) => this.setState({order: {...this.state.order, paymentMethodType: val}})}
                value={order.paymentMethodType}
                placeholder="Payment Method"
                simpleValue={true}
            />
            
            <div className="form-group" style={{marginTop: '20px'}}>
              <label>Order Note</label>
              <textarea className={'form-control'}
                        value={order.orderNote == null ? '' : order.orderNote}
                        onChange={this.handleOrderFieldChange}
                        name='orderNote'
              />
            </div>            

          </div>
        </div>

        {this.state.order.spares.length > 1 && <div className={"pi-row"}>
          <div className="pi-col-md-12">
            {this.state.order.spares.length > 0 &&
            <div className="pi-box pi-border pi-round pi-border-top" style={{paddingLeft: '5px', paddingRight: '0px', paddingTop: '10px'}}>
              <h6 className="pi-weight-600" onClick={this.toggleCart} style={{cursor: 'pointer'}}>
                <a href={'#'} onClick={(e) => e.preventDefault()}>
                  Cart&nbsp;&nbsp;
                  <i className={`fa ${this.state.showCart ? 'fa-angle-down' : 'fa-angle-right'} fa-lg`}/>
                </a>
              </h6>

              <ReactTable
                sortable={false}
                filterable={false}
                showPagination={false}
                data={this.state.order.spares}
                columns={this.state.cartColumns}
                defaultPageSize={100}
                minRows={3}
                style={{display: this.state.showCart ? '' : 'none'}}
              />
              
              { order.paymentMethodType === PAYMENT_METHOD_CC && <div className="mt-2">
                <b><i>You have selected Credit Card Payment, price excludes 2% Credit Card fee</i></b>
              </div> }              
              <div className="mt-2">
                <b><i>In the event of a discrepancy between the hard copy price list distributed and the above summary price, the hard copy price list will apply. {siteSettings.ProductPriceListMessage}</i></b> 
                {/*<br /><b><i><span style={{color:"red"}}>Current prices are valid for orders placed before 1 May 2022. New prices will apply from that date.</span></i></b>*/}
              </div>
              { !isNullEmptyOrWhitespace(siteSettings.AluxorUpdatesMessage) && <div className={'mt-2 mb-2'}>
                <b>Aluxor Updates<br /></b><i>{siteSettings.AluxorUpdatesMessage}</i>                
              </div> }

              <ButtonWrapper
                style={{marginTop: '10px'}}
                className={'btn btn-base'}
                disabled={saving}
                onClick={(e) => this.saveOrder(e, true)}>Preview Quote/Order</ButtonWrapper>
            </div> }

          </div>
        </div> }

        <div className="pi-box pi-border pi-round pi-border-top" style={{paddingLeft: '5px', paddingRight: '0px', paddingTop: '10px'}}>
          <h6 className="pi-weight-600">
            Available Spares
          </h6>
        <div className="pi-row">
          <div className="pi-col-md-8">

            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`productGroupId_spares`}
              onChange={(x, e) => {
                this.setState({selectedProductGroup: x.simpleValue});
              }}
              value={this.state.selectedProductGroup}
              labelText='Product Group'
              validationMessages={{}}
              options={this.state.productGroups}
            />

            <RadioControlGroup
              style={{marginLeft: '0px'}}
              name={`products_spares`}
              onChange={(x, e) => {
                this.setState({selectedProduct: x.simpleValue});
              }}
              value={this.state.selectedProduct}
              labelText='Product'
              validationMessages={{}}
              options={this.state.products}
            />

          </div>
        </div>
          {(this.state.selectedProduct === PRODUCTID_SD || this.state.selectedProduct === PRODUCTID_MOTORS_SD) &&
          <div className={'pi-row'}>
            <div className="pi-col-md-12">
              <div className="alert alert-info" role="alert"><i className={'fa fa-info-circle'} />
                <strong>&nbsp;&nbsp;Sundream spring and motor changes 2021</strong>
                <br /><br />
                In 2021, Somfy changed the size specifications of its Orea RTS range motors (shortening the length of the motor). These motors are used in remote control operated Sundreams. As a consequence of the change by Somfy, the German manufacture of the Sundream system needed to also modify the design of the drive spring so as to accommodate the new size motor.
                <br /><br />
                From 2021 the current model Somfy Orea motor is not compatible with the old model spring and the current model spring is not compatible with the old model Orea motor. Accordingly, for Sundream awnings supplied prior to 2021 if either the spring or remote control motor needs replacing, both the spring and motor need to be replaced.
                <br /><br />
                Please note, these changes do not affect hardwired motors / springs or bearing springs.<br />
                <a href={'#'} onClick={(e) => {
                  e.preventDefault();
                  apiDownload(`api/resources/files/44.14_SD_Motor_Springs_PX135.pdf`, `44.14_SD_Motor_Springs_PX135.pdf`);
                }}>Download further information here</a>                
              </div>
            </div>
          </div> }
          
        <div className={'pi-row'}>
          <div className="pi-col-md-12">
            {this.state.selectedProduct ? <ReactTable
              collapseOnDataChange={false}
              showPaginationTop={false}
              filterable={false}
              data={spares.items}
              columns={this.state.columns}
              sorted={filter.sorted}
              sortable={false}
              page={filter.pageIndex}
              pageSize={filter.pageSize}
              filtered={filter.filtered}
              onSortedChange={(newSorted, column, shiftKey)=> this.updateFilter({...filter, sorted: [...newSorted]})}
              onPageChange={(pageIndex) => this.updateFilter({...filter, pageIndex})}
              onPageSizeChange={(pageSize, pageIndex) => this.updateFilter({...filter, pageIndex, pageSize})}
              onFilteredChange={(column, value) => this.updateFilter({...filter, filtered: [...column], pageIndex: 0})}
              pivotBy={['spareGroupDescription']}
            /> : <p><b><i>Please select a product group and product to view available spares</i></b></p> }
          </div>
        </div>

        </div>
        </div>
        ) :
      <div className="pi-section">
        <div className="pi-row">
          <div className="pi-col-md-8">
            <h4 className="pi-has-bg pi-margin-bottom-20">
              Loading... <i className={'fa fa-spinner fa-spin'} />
            </h4>
          </div>
        </div>
      </div>
  }
}

SpareOrderPage.propTypes = {
  user: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  spares: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  let orderId = -1;
  if(ownProps.match.params && ownProps.match.params.orderId){
    orderId = parseInt(deobsfucate(ownProps.match.params.orderId));
  }
  return {
    user: state.user,
    orderId,
    spares: state.spares,
    paymentMethods: state.lookups.paymentMethods,
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpareOrderPage));