import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SensorTypes, isRemote, isHardwired } from '../../constants';
import Select from 'react-select';


class OrderSensorSetsControl extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      orderId: props.order ? props.order.id : 0,
      sensorSets: []
    };

    this.createSets = this.createSets.bind(this);
    this.handleCombineSet = this.handleCombineSet.bind(this);
    this.handleRemoveFromSet = this.handleRemoveFromSet.bind(this);
    this.currentState = this.currentState.bind(this);
  }

  componentWillMount(){
    this.createSets(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.createSets(nextProps);
  }

  createSets(props, existing = null) {
    if(props.order && props.order.sensorSets && props.order.sensorSets.length > 0 && this.state.sensorSets.length == 0)
    {
      existing = props.order.sensorSets;
    }

    existing = existing == null ? this.state.sensorSets : existing;
    let awningInSet = (a) => {
      return existing.findIndex(y => {
        let foundAwning = y.awningNumbers.findIndex(n => n == a.number) >= 0;
        return foundAwning && y.automationSensorType.value == a.automationSensorType
          && y.isRemote == isRemote(a) && y.isHardwired == isHardwired(a);
      }) >= 0;
    };

    let newSets = props.order.awnings
      .filter(x => x.automationSensorType !== null && !awningInSet(x) && (isRemote(x) || isHardwired(x)))
      .map((x, i) => {
        let t = SensorTypes(x).filter(y => y.value == x.automationSensorType);
        return {
          id: (-1 - i),
          setNumber: (i + 1),
          automationSensorType: t[0],
          awningNumbers: [x.number],
          isRemote: isRemote(x),
          isHardwired: isHardwired(x),
        };
      });

    let existingFoundInNew = (awningNumber) => {
      let found = newSets.findIndex(s => s.awningNumbers.findIndex(x => x == awningNumber) >= 0) >= 0;
      return found;
    };

    let foundInOrder = (awningNumber, sensorType) => {
      let found = this.props.order.awnings.findIndex(a => a.number == awningNumber && a.automationSensorType == sensorType) >= 0;
      return found;
    };

    existing = existing.map((x, i) => {
      let r = {...x};
      r.awningNumbers = r.awningNumbers.filter(e => {
        return !existingFoundInNew(e) && foundInOrder(e, r.automationSensorType.value);
      });
      return r;
    }).filter(x => x.awningNumbers.length > 0);

    for (let i = 0; i < newSets.length; i++) {
      existing.push(newSets[i]);
    }

    let sensorSets = existing
      .map((x, i) => {
        let r = {...x};
        if(r.id <= 0) {
          r.id = -1 - i;
        }
        r.setNumber = i + 1;
        return r;
      });

    this.setState({sensorSets});
  }

  handleCombineSet(sourceSet, targetSet) {
    let sensorSets = this.state.sensorSets.filter(x => x.id != sourceSet.id)
      .map((x, i) => {
        let r = {...x};
        if (r.id == targetSet.id) {
          r.awningNumbers = [...targetSet.awningNumbers, ...sourceSet.awningNumbers];
        }
        r.id = -1 - i;
        r.setNumber = i+1;
        return r;
      });

    this.setState({sensorSets});
  }

  handleRemoveFromSet(e, set, awningNumber){
    e.preventDefault();
    let sensorSets = this.state.sensorSets
      .map(x => {
        let r = {...x};
        if (r.id == set.id) {
          r.awningNumbers = r.awningNumbers.filter(y => y != awningNumber);
        }
        return r;
      });

    this.setState({sensorSets}, ()=> this.createSets(this.props));
  }

  currentState(){
    return this.state.sensorSets;
  }

  render(){
    let {sensorSets} = this.state;

    return sensorSets.length > 0 ? (
      <div>
        <h6>Sensor Set Combinations</h6>

        <table
          className="pi-table pi-table-complex pi-table-hovered pi-round pi-table-shadow pi-table-all-borders">
          <thead>
          <tr>
            <th style={{width: '25%'}}>Set</th>
            <th style={{width: '40%'}}>Sensor Type</th>
            <th style={{width: '25%'}}>Awnings</th>
            <th style={{width: '10%'}}>Combine</th>
          </tr>
          </thead>
          <tbody>
          {sensorSets.map(x => {
            let availableSets = sensorSets.filter(y => y.id !== x.id
              && x.automationSensorType.value == y.automationSensorType.value
              && x.isRemote == y.isRemote && x.isHardwired == y.isHardwired);
            return (
              <tr key={x.id}>
                <td>Set No. {x.setNumber}</td>
                <td>{x.automationSensorType.label} {x.isRemote && <span>RC</span>}{x.isHardwired && <span>HW</span>}</td>
                <td>{x.awningNumbers.map(y => {
                  return (<span
                    key={y}
                    style={{padding: '4px', border: '1px solid lightsalmon', marginRight: '10px', float: 'left', marginBottom: '2px'}}
                >No.{y}{x.awningNumbers.length > 1 && <a href="#" onClick={(e) => this.handleRemoveFromSet(e, x, y)}>&nbsp;Remove</a>}</span>);
                })}</td>
                <td>
                  { availableSets.length > 0 ?

                  <Select
                    style={{minWidth: '145px'}}
                    options={sensorSets.filter(y => y.id !== x.id && x.automationSensorType.value == y.automationSensorType.value)}
                    valueKey={'id'}
                    labelKey={'setNumber'}
                    onChange={(val)=> this.handleCombineSet(x,val)}
                    placeholder={'Combine with...'}
                  /> : <span>No other sets</span> }
                </td>
              </tr>);
          })}</tbody>
        </table>
      </div>
    ) : null;
  }
}

OrderSensorSetsControl.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderSensorSetsControl;