import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import ReduxToastr from 'react-redux-toastr';
import { HashRouter } from 'react-router-dom';

import 'react-select/dist/react-select.css';
import Main from './containers/Main';
import jQuery from 'jquery';

import 'core-js/fn/array/includes';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/array/find-index';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/keys';
import 'core-js/fn/object/values';

import { AppContainer } from 'react-hot-loader';

//eslint-disable-next-line
window.$ = window.jQuery = jQuery;

//routes - https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf

const xRender = () => {
  ReactDOM.render(      
       <Provider store={store}>
        <div>
          <HashRouter>
            <AppContainer>
              <Main />
            </AppContainer>
          </HashRouter>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar />
        </div>
      </Provider>,
    document.getElementById('root')
  );
};

xRender();

if (module.hot) {
  module.hot.accept('./containers/Main', () => {
    xRender();
  });
}