import React, { Component } from 'react';
import LookupAwningDiagram from "../awningdiagrams/LookupAwningDiagram";
import {newOrder} from "./EditOrderPage";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import AwningDiagramTestHarness from "../awningdiagrams/AwningDiagramTestHarness";
import {Aux, DATE_SHORT_FORMAT, IsNullOrUndefined} from "../../constants";
import TextBoxControl from "../common/TextBoxControl";
import moment from "moment";

const initialAwning = {
  width: 0,
  outsideArmSpacing: 0,
  numberOfArms: 0,
  bracketTotal: 0,
  insideArmSpacing: 0,
  middleArmSideDescription: 'Left',
  productArmBracketDeadSpace: 0,
  mountingBracketWidth: 0,
  number: 1,
  //productGroupId: PRODUCTGROUPID_FOLDING_ARM,
  productId: null,
  projection: 0,
  driveMechanismId: null,
  id: 0,
  fabricSuppliedBy: 1,  
};

class LookupArmBracketSpacingSheet extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: null,
      customerName: null,
      reference: null,      
    };
    
    this.handleOnSelectedAwningChange = this.handleOnSelectedAwningChange.bind(this);
  }

  componentDidMount() {
    let {user} = this.props;
    
    let order = newOrder(user.organisation.id);
    order.awnings = [initialAwning];
    
    this.setState({      
      order,
    });
  }

  handleOnSelectedAwningChange(awning) {
    let {order} = this.state;
    if(JSON.stringify(order.awnings[0]) !== JSON.stringify(awning))
    {
      this.setState({order: {
          ...this.state.order,
          awnings: [{...awning}],
          organisationId: this.props.user.organisation.id,
        }
      });
    }
  }

  render(){

    let {order, customerName, reference} = this.state;
    let {user, siteSettings} = this.props;
    
    return (
      <div>
        <h4 style={{marginTop: '10px'}}>Awning Specifications | <Link to={`/fabrics/lookup`}>Fabric Lookup</Link> | <Link to={`/colours/lookup`}>Colour Lookup</Link> | <Link to={`/sintesi/lookup`}>Sintesi Lookup</Link>          
        </h4>
        <p>Fill in the required details and the applicable arm bracket spacing diagram will be displayed</p>

        {/*<AwningDiagramTestHarness />*/}

        { !IsNullOrUndefined(order) &&
            <Aux>

              <TextBoxControl
                  onChange={e =>  this.setState({customerName: e.target.value})}
                  labelText="Customer Name"
                  value={customerName}
                  name="customerName"                 
              />
              <TextBoxControl
                  onChange={e =>  this.setState({reference: e.target.value})}
                  labelText="Reference"
                  value={reference}
                  name="reference"
              />
            
              <LookupAwningDiagram
                  ref={`awning-1`}
                  key={`awningview-1`}
                  awning={order.awnings[0]}
                  isVisible={true}
                  onChange={this.handleOnSelectedAwningChange}
                  onMessagesChange={() => {
                  }}
                  order={order}
                  user={user}
                  customerName={customerName}
                  reference={reference}
                  date={moment().format(DATE_SHORT_FORMAT)}
                  siteSettings={siteSettings}
              />

            </Aux>
        }
      </div>      
    );
  }
}

LookupArmBracketSpacingSheet.propTypes = {
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    siteSettings: state.lookups.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LookupArmBracketSpacingSheet));